import React, { useEffect } from "react";
import styled from "styled-components";
import philosophie from "../../assets/images/home/1_philosophie.jpg";
import products from "../../assets/images/home/2_produkte.jpg";
import community from "../../assets/images/home/3_community.jpg";
import bestellen from "../../assets/images/home/4_bestellen.jpg";
import whoweare from "../../assets/images/home/5_werwirsind.jpg";
import contact from "../../assets/images/home/6_kontakt.jpg";
import heroImg from "../../assets/images/home/headerbild-min.jpg";
import HeadingOne from "../../components/HeadingOne";
import HomepageSquare from "../../components/HomepageSquare";
import Header from "../../components/styledHeader/StyledHeader";
import TablerIcon from "../../components/TablerIcon";
import colors from "../../constants/colors";
import constants from "../../constants/constants";

export default function Homepage() {
  useEffect(() => {
    document.title = "Kontakt";
  }, []);
  return (
    <Main>
      <GridContainer>
        <TopContainer>
          <Header />
          <HeroImg>
            <HeadingOnePositioner>
              <HeadingOne title="Hier sind wir." color="WHITE" />
            </HeadingOnePositioner>
          </HeroImg>
        </TopContainer>
        <HomepageSquare
          color="#E6D793"
          bgImage={philosophie}
          showHover={false}
          alwaysHover={true}
        />
        <HomepageSquare color="#D7C061" bgImage={products} showHover={false} />
        <HomepageSquare color="#CCB342" bgImage={community} showHover={false} />
        <HomepageSquare color="#B2982A" bgImage={bestellen} showHover={false} />
        <HomepageSquare color="#8D7833" bgImage={whoweare} showHover={false} />
        <HomepageSquare
          color="#7D6C2D"
          title="kontakt"
          bgImage={contact}
          showHover={false}
          alwaysHover={true}
          titleColor="#fff"
          hoverText={[
            <Link2 href="tel:00436644142827">Tel. +43 664 414 28 27</Link2>,
            <br />,
            <Link2 href="mailto:info@gutgereift.at">info@gutgereift.at</Link2>,
            <br />,
            <br />,
            <Paragraph2>REIFELAGER</Paragraph2>,
            <Paragraph2>MOOS 383, 6866 ANDELSBUCH</Paragraph2>,
            <Paragraph2>VERKÖSTIGUNGEN AUF ANFRAGE.</Paragraph2>,

            <SpaceTop>
              <Link
                href="https://www.instagram.com/gutgereiftimbregenzerwald/"
                passHref
                target={"_blank"}
              >
                <span style={{ marginLeft: "-3px" }}>
                  <TablerIcon
                    strokeWidth={1}
                    size={35}
                    color={"#fff"}
                    iconName={"brand-instagram"}
                  ></TablerIcon>
                </span>
              </Link>

              <Link
                href="https://www.facebook.com/gutgereiftimbregenzerwald/"
                passHref
                target={"_blank"}
              >
                <span style={{ marginLeft: "5px" }}>
                  <TablerIcon
                    strokeWidth={1}
                    size={35}
                    color={"#fff"}
                    iconName={"brand-facebook"}
                  ></TablerIcon>
                </span>
              </Link>
            </SpaceTop>,
          ]}
        />
      </GridContainer>

      <IphoneContainer>
        <Top>
          <Header />
        </Top>
        <div style={{ height: "60%" }}>
          <Row>
            <SmallHeading>GUT GEREIFT</SmallHeading>
            <Paragraph>IM BREGENZERWALD</Paragraph>
          </Row>
          <Row>
            <Paragraph>REIFELAGER</Paragraph>
            <Paragraph>MOOS 383, 6866 ANDELSBUCH</Paragraph>
            <Paragraph>VERKÖSTIGUNGEN AUF ANFRAGE.</Paragraph>
          </Row>
          <Row>
            <a href={"tel:+43 664 414 28 27"}>
              <Paragraph>TEL. +43 664 414 2827</Paragraph>
            </a>
          </Row>
          <Row>
            <Paragraph>
              <MailTo href={"mailto: info@gutgereift.at"}>
                INFO@GUTGEREIFT.AT
              </MailTo>
            </Paragraph>
          </Row>
          <Row>
            <div style={{ marginTop: "15px" }}>
              <Link
                href="https://www.instagram.com/gutgereiftimbregenzerwald/"
                passHref
                target={"_blank"}
              >
                <span style={{ marginLeft: "-3px" }}>
                  <TablerIcon
                    strokeWidth={1}
                    size={35}
                    color={"#b2982a"}
                    iconName={"brand-instagram"}
                  ></TablerIcon>
                </span>
              </Link>

              <Link
                href="https://www.facebook.com/gutgereiftimbregenzerwald/"
                passHref
                target={"_blank"}
              >
                <span style={{ marginLeft: "5px" }}>
                  <TablerIcon
                    strokeWidth={1}
                    size={35}
                    color={"#b2982a"}
                    iconName={"brand-facebook"}
                  ></TablerIcon>
                </span>
              </Link>
            </div>
          </Row>
        </div>
      </IphoneContainer>
    </Main>
  );
}

const Main = styled.div`
  height: 100vh;
  width: 100vw;
  @media (min-width: ${constants.nexusPortraitWidth}px) and (max-height: 500px) {
    height: 100%;
  }
  @media (max-height: 767px) {
    height: unset;
  }
  @media (max-width: ${constants.iPhonePortraitWidth}px) {
    height: unset;
  }
`;
const TopContainer = styled.div`
  grid-column: 1/7;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    grid-column: 1/4;
  }
  @media (max-width: ${constants.iPhonePortraitWidth}px) {
    grid-column: 1/3;
  }
`;

const Display = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`;
const HeroImg = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${heroImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center calc(65%);
  @media (min-width: ${constants.nexusPortraitWidth}px) and (max-height: 500px) {
    height: 384px;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    height: 300px;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) and (min-height: 960px) {
    height: 100%;
  }
  @media (max-width: 670px) and (min-height: 800px) {
    height: 100%;
  }
  @media (max-width: ${constants.iPhonePortraitWidth}px) {
    display: none;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;
const HeadingOnePositioner = styled.div`
  height: 60%;
  display: flex;
  justify-content: center;
  @media (min-height: 940px) {
    margin-top: 120px;
  }
`;
const GridContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-rows: auto calc(100vw / 6);
  grid-template-columns: auto auto auto auto auto auto;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    grid-template-rows: auto calc(100vw / 3) calc(100vw / 3);
    grid-template-columns: auto auto auto;
  }
  @media (max-width: ${constants.iPhonePortraitWidth}px) {
    grid-template-rows: 206px calc(100vw / 2) calc(100vw / 2) calc(100vw / 2);
    grid-template-columns: auto auto;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Text = styled.p`
  color: white;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 20px;
  /* height: 100vh; */
  @media (max-width: 1500px) {
    margin-top: 5px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: white;
  margin: 0;
  &:hover {
    color: white;
  }
`;

const Top = styled.div`
  height: 33%;
`;

const IphoneContainer = styled.div`
  height: 95vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  @media (min-width: 1200px) {
    display: none;
  }
`;

const Row = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SmallHeading = styled.h1`
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #333333;
  margin: 0;
`;

const Paragraph = styled.p`
  margin: 0;
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #333333;
  margin-top: 0;
  text-align: center;
`;

const MailTo = styled.a`
  color: #b2982a;
  text-decoration: none;
`;

const SpaceTop = styled.div`
  margin-top: 15px;
  @media (max-width: 1300px) {
    margin-top: 5px;
  }
`;

const Paragraph2 = styled.p`
  text-decoration: none;
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 12px;
`;

const Link2 = styled.a`
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: white;
  }
`;
