import copy from "copy-to-clipboard";
import { React, styled } from "../../imports";
import Alert from "../Alert";
import InfoComponent from "../InfoComponent";
import StateInput from "../StateInput";
import StyledButton from "../StyledButton";


var QRCode = require("qrcode.react");

const LinkAlert = ({ setShowAlert, inviteLink }) => {

  return (
    <Alert isVisible={true}>
      <ParagraphRight>
        Gruppen-Link erfolgreich generiert!
              </ParagraphRight>
      <StateInput
        name={"Listenname"}
        id={"link"}
        forwardedRef={inviteLink}
        onChange={(e) => { }}
        type={"text"}
        style={{ width: "80%" }}
        placeholder={"InviteLink"}
        disabled
      ></StateInput>
      <LinkWrapper>
        <InfoComponent
          paddingTop={0}
          text="Wie teile ich einen QR-code auf whatsapp? https://faq.whatsapp.com/iphone/contacts/how-to-share-your-whatsapp-qr-code/?lang=de"
        />
        <QRCode
          value={inviteLink}
          style={{ height: "86px", width: "86px" }}
        />

        <div style={{ width: "20px", height: "10px" }} />
        <ButtonBox>
          <StyledButton
            onClick={() => {
              copy(inviteLink);
              setShowAlert();
            }}
            scale={"ORDER"}
            title={"kopieren"}
            color="#CCB242"
            fontSize={"20px"}
          />
          <div style={{ width: "20px", height: "5px" }} />
          <StyledButton
            onClick={() => {
              setShowAlert();
            }}
            scale={"ORDER"}
            title={"abbrechen"}
            color="#8D7833"
            fontSize={"20px"}
          />
        </ButtonBox>
      </LinkWrapper>
    </Alert>

  );
};

const ParagraphRight = styled.p`
  color: #808080;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-family: "Montserrat";
  font-weight: 300;
  padding: 0;
  text-align: right;
  line-height: 1.2;
`;
const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ButtonBox = styled.div`
  /* margin-top: 20px; */
`;
export default LinkAlert;
