import React from 'react';

/**
 * @author Marcel Simma
 * @param {string} iconName The name of the icon that should be displayed.
 * @param {int} strokeWidth The line width of the icon.
 * @param {int} size The height and width of the icon.
 * @param {color} color The color of the icon.
 * @param {color} fill The fill color of the icon. Does not always work.
 * @example <TablerIcon 
 *              strokeWidth={3} 
 *              size={20} 
 *              color={"black"} 
 *              iconName={"check"} 
 *          />
 */

const defaultSize = "40px", defaultStrokeWidth = "2", defaultFill = "none", defaultStroke = "black";

const TablerIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-accessible" width={props.size ? props.size : defaultSize} height={props.size ? props.size : defaultSize} viewBox="0 0 24 24" strokeWidth={props.strokeWidth ? props.strokeWidth : defaultStrokeWidth} stroke={props.color ? props.color : defaultStroke} fill={props.fill ? props.fill : defaultFill} strokeLinecap="round" strokeLinejoin="round">
{
            renderIcon(props)
}
        </svg>

    )
}

export default TablerIcon;

function renderIcon(props) {
    switch (props.iconName) {
        case "accessible": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />   
                    <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
                </>
            )
        case "activity": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="21 12 17 12 14 20 10 4 7 12 3 12" />
                </>
            )
        case "ad": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="5" width="18" height="14" rx="2" />   
                    <path d="M7 15v-4a2 2 0 0 1 4 0v4" />   
                    <line x1="7" y1="13" x2="11" y2="13" />   
                    <path d="M17 9v6h-1.5a1.5 1.5 0 1 1 1.5 -1.5" />
                </>
            )
        case "adjustments": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="6" cy="10" r="2" />   
                    <line x1="6" y1="4" x2="6" y2="8" />   
                    <line x1="6" y1="12" x2="6" y2="20" />   
                    <circle cx="12" cy="16" r="2" />   
                    <line x1="12" y1="4" x2="12" y2="14" />   
                    <line x1="12" y1="18" x2="12" y2="20" />   
                    <circle cx="18" cy="7" r="2" />   
                    <line x1="18" y1="4" x2="18" y2="5" />   
                    <line x1="18" y1="9" x2="18" y2="20" />
                </>
            )
        case "alarm": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="13" r="7" />   
                    <polyline points="12 10 12 13 14 13" />   
                    <line x1="7" y1="4" x2="4.25" y2="6" />   
                    <line x1="17" y1="4" x2="19.75" y2="6" />
                </>
            )
        case "alert-circle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="12" y1="8" x2="12" y2="12" />   
                    <line x1="12" y1="16" x2="12.01" y2="16" />
                </>
            )
        case "alert-triangle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12 9v2m0 4v.01" />   
                    <path d="M5.07 19H19a2 2 0 0 0 1.75 -2.75L13.75 4a2 2 0 0 0 -3.5 0L3.25 16.25a2 2 0 0 0 1.75 2.75" />
                </>
            )
        case "alien": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M11 17a2.5 2.5 0 0 0 2 0" />   
                    <path d="M12 3C7.336 3 4.604 5.331 4.138 8.595a11.816 11.816 0 0 0 1.998 8.592 10.777 10.777 0 003.199 3.064h0c1.666.999 3.664.999 5.33 0h0a10.777 10.777 0 0 0 3.199 -3.064 11.89 11.89 0 001.998-8.592C19.396 5.33 16.664 3 12 3z" />   
                    <line x1="8" y1="11" x2="10" y2="13" />   
                    <line x1="16" y1="11" x2="14" y2="13" />
                </>
            )
        case "align-center": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="6" x2="20" y2="6" />   
                    <line x1="8" y1="12" x2="16" y2="12" />   
                    <line x1="6" y1="18" x2="18" y2="18" />
                </>
            )
        case "align-justified": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="6" x2="20" y2="6" />   
                    <line x1="4" y1="12" x2="20" y2="12" />   
                    <line x1="4" y1="18" x2="20" y2="18" />
                </>
            )
        case "align-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="6" x2="20" y2="6" />   
                    <line x1="4" y1="12" x2="14" y2="12" />   
                    <line x1="4" y1="18" x2="18" y2="18" />
                </>
            )
        case "align-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="6" x2="20" y2="6" />   
                    <line x1="10" y1="12" x2="20" y2="12" />   
                    <line x1="6" y1="18" x2="20" y2="18" />
                </>
            )
        case "ambulance": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="7" cy="17" r="2" />   
                    <circle cx="17" cy="17" r="2" />   
                    <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" />   
                    <path d="M6 10h4m-2 -2v4" />
                </>
            )
        case "anchor": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12 9v12m-8 -8a8 8 0 0 0 16 0m1 0h-2m-14 0h-2" />   
                    <circle cx="12" cy="6" r="3" />
                </>
            )
        case "aperture": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="3.6" y1="15" x2="14.15" y2="15" />   
                    <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(72 12 12)" />   
                    <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(144 12 12)" />   
                    <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(216 12 12)" />   
                    <line x1="3.6" y1="15" x2="14.15" y2="15" transform="rotate(288 12 12)" />
                </>
            )
        case "apps": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="6" height="6" rx="1" />   
                    <rect x="4" y="14" width="6" height="6" rx="1" />   
                    <rect x="14" y="14" width="6" height="6" rx="1" />   
                    <line x1="14" y1="7" x2="20" y2="7" />   
                    <line x1="17" y1="4" x2="17" y2="10" />
                </>
            )
        case "archive": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="4" width="18" height="4" rx="2" />   
                    <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />   
                    <line x1="10" y1="12" x2="14" y2="12" />
                </>
            )
        case "arrow-back-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1" />
                </>
            )
        case "arrow-back": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                </>
            )
        case "arrow-bar-down": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="20" x2="12" y2="10" />   
                    <line x1="12" y1="20" x2="16" y2="16" />   
                    <line x1="12" y1="20" x2="8" y2="16" />   
                    <line x1="4" y1="4" x2="20" y2="4" />
                </>
            )
        case "arrow-bar-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="12" x2="14" y2="12" />   
                    <line x1="4" y1="12" x2="8" y2="16" />   
                    <line x1="4" y1="12" x2="8" y2="8" />   
                    <line x1="20" y1="4" x2="20" y2="20" />
                </>
            )
        case "arrow-bar-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="20" y1="12" x2="10" y2="12" />   
                    <line x1="20" y1="12" x2="16" y2="16" />   
                    <line x1="20" y1="12" x2="16" y2="8" />   
                    <line x1="4" y1="4" x2="4" y2="20" />
                </>
            )
        case "arrow-bar-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="4" x2="12" y2="14" />   
                    <line x1="12" y1="4" x2="16" y2="8" />   
                    <line x1="12" y1="4" x2="8" y2="8" />   
                    <line x1="4" y1="20" x2="20" y2="20" />
                </>
            )
        case "arrow-down-circle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="8" y1="12" x2="12" y2="16" />   
                    <line x1="12" y1="8" x2="12" y2="16" />   
                    <line x1="16" y1="12" x2="12" y2="16" />
                </>
            )
        case "arrow-down-left-circle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="15" y1="9" x2="9" y2="15" />   
                    <polyline points="15 15 9 15 9 9" />
                </>
            )
        case "arrow-down-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="17" y1="7" x2="7" y2="17" />   
                    <polyline points="16 17 7 17 7 8" />
                </>
            )
        case "arrow-down-right-circle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="15" y1="15" x2="9" y2="15" />   
                    <polyline points="15 9 15 15 9 9" />
                </>
            )
        case "arrow-down-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="7" y1="7" x2="17" y2="17" />   
                    <polyline points="17 8 17 17 8 17" />
                </>
            )
        case "arrow-down": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="5" x2="12" y2="19" />   
                    <line x1="18" y1="13" x2="12" y2="19" />   
                    <line x1="6" y1="13" x2="12" y2="19" />
                </>
            )
        case "arrow-forward-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 13l4 -4l-4 -4m4 4h-11a4 4 0 0 0 0 8h1" />
                </>
            )
        case "arrow-forward": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 11l4 4l-4 4m4 -4h-11a4 4 0 0 1 0 -8h1" />
                </>
            )
        case "arrow-left-circle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="8" y1="12" x2="16" y2="12" />   
                    <line x1="8" y1="12" x2="12" y2="16" />   
                    <line x1="8" y1="12" x2="12" y2="8" />
                </>
            )
        case "arrow-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="5" y1="12" x2="19" y2="12" />   
                    <line x1="5" y1="12" x2="11" y2="18" />   
                    <line x1="5" y1="12" x2="11" y2="6" />
                </>
            )
        case "arrow-narrow-down": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="5" x2="12" y2="19" />   
                    <line x1="16" y1="15" x2="12" y2="19" />   
                    <line x1="8" y1="15" x2="12" y2="19" />
                </>
            )
        case "arrow-narrow-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="5" y1="12" x2="19" y2="12" />   
                    <line x1="5" y1="12" x2="9" y2="16" />   
                    <line x1="5" y1="12" x2="9" y2="8" />
                </>
            )
        case "arrow-narrow-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="5" y1="12" x2="19" y2="12" />   
                    <line x1="15" y1="16" x2="19" y2="12" />   
                    <line x1="15" y1="8" x2="19" y2="12" />
                </>
            )
        case "arrow-narrow-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="5" x2="12" y2="19" />   
                    <line x1="16" y1="9" x2="12" y2="5" />   
                    <line x1="8" y1="9" x2="12" y2="5" />
                </>
            )
        case "arrow-right-circle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="16" y1="12" x2="8" y2="12" />   
                    <line x1="16" y1="12" x2="12" y2="16" />   
                    <line x1="16" y1="12" x2="12" y2="8" />
                </>
            )
        case "arrow-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="5" y1="12" x2="19" y2="12" />   
                    <line x1="13" y1="18" x2="19" y2="12" />   
                    <line x1="13" y1="6" x2="19" y2="12" />
                </>
            )
        case "arrow-up-circle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="12" y1="8" x2="8" y2="12" />   
                    <line x1="12" y1="8" x2="12" y2="16" />   
                    <line x1="16" y1="12" x2="12" y2="8" />
                </>
            )
        case "arrow-up-left-circle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="9" x2="15" y2="15" />   
                    <polyline points="15 9 9 9 9 15" />
                </>
            )
        case "arrow-up-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="7" y1="7" x2="17" y2="17" />   
                    <polyline points="16 7 7 7 7 16" />
                </>
            )
        case "arrow-up-right-circle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="15" y1="9" x2="9" y2="15" />   
                    <polyline points="15 15 15 9 9 9" />
                </>
            )
        case "arrow-up-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="17" y1="7" x2="7" y2="17" />   
                    <polyline points="8 7 17 7 17 16" />
                </>
            )
        case "arrow-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="5" x2="12" y2="19" />   
                    <line x1="18" y1="11" x2="12" y2="5" />   
                    <line x1="6" y1="11" x2="12" y2="5" />
                </>
            )
        case "arrows-diagonal-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="16 20 20 20 20 16" />   
                    <line x1="14" y1="14" x2="20" y2="20" />   
                    <polyline points="8 4 4 4 4 8" />   
                    <line x1="4" y1="4" x2="10" y2="10" />
                </>
            )
        case "arrows-diagonal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="16 4 20 4 20 8" />   
                    <line x1="14" y1="10" x2="20" y2="4" />   
                    <polyline points="8 20 4 20 4 16" />   
                    <line x1="4" y1="20" x2="10" y2="14" />
                </>
            )
        case "arrows-horizontal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="7 8 3 12 7 16" />   
                    <polyline points="17 8 21 12 17 16" />   
                    <line x1="3" y1="12" x2="21" y2="12" />
                </>
            )
        case "arrows-maximize": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="16 4 20 4 20 8" />   
                    <line x1="14" y1="10" x2="20" y2="4" />   
                    <polyline points="8 20 4 20 4 16" />   
                    <line x1="4" y1="20" x2="10" y2="14" />   
                    <polyline points="16 20 20 20 20 16" />   
                    <line x1="14" y1="14" x2="20" y2="20" />   
                    <polyline points="8 4 4 4 4 8" />   
                    <line x1="4" y1="4" x2="10" y2="10" />
                </>
            )
        case "arrows-minimize": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="5 9 9 9 9 5" />   
                    <line x1="3" y1="3" x2="9" y2="9" />   
                    <polyline points="5 15 9 15 9 19" />   
                    <line x1="3" y1="21" x2="9" y2="15" />   
                    <polyline points="19 9 15 9 15 5" />   
                    <line x1="15" y1="9" x2="21" y2="3" />   
                    <polyline points="19 15 15 15 15 19" />   
                    <line x1="15" y1="15" x2="21" y2="21" />
                </>
            )
        case "arrows-sort": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 9l4-4l4 4m-4 -4v14" />   
                    <path d="M21 15l-4 4l-4-4m4 4v-14" />
                </>
            )
        case "arrows-vertical": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="8 7 12 3 16 7" />   
                    <polyline points="8 17 12 21 16 17" />   
                    <line x1="12" y1="3" x2="12" y2="21" />
                </>
            )
        case "artboard": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="8" y="8" width="8" height="8" rx="1" />   
                    <line x1="3" y1="8" x2="4" y2="8" />   
                    <line x1="3" y1="16" x2="4" y2="16" />   
                    <line x1="8" y1="3" x2="8" y2="4" />   
                    <line x1="16" y1="3" x2="16" y2="4" />   
                    <line x1="20" y1="8" x2="21" y2="8" />   
                    <line x1="20" y1="16" x2="21" y2="16" />   
                    <line x1="8" y1="20" x2="8" y2="21" />   
                    <line x1="16" y1="20" x2="16" y2="21" />
                </>
            )
        case "at": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="4" />   
                    <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
                </>
            )
        case "atom-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="3" />   
                    <line x1="12" y1="21" x2="12" y2="21.01" />   
                    <line x1="3" y1="9" x2="3" y2="9.01" />   
                    <line x1="21" y1="9" x2="21" y2="9.01" />   
                    <path d="M8 20.1a9 9 0 0 1 -5 -7.1" />   
                    <path d="M16 20.1a9 9 0 0 0 5 -7.1" />   
                    <path d="M6.2 5a9 9 0 0 1 11.4 0" />
                </>
            )
        case "atom": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="12" x2="12" y2="12.01" />   
                    <path d="M12 2a4 10 0 0 0 -4 10a4 10 0 0 0 4 10a4 10 0 0 0 4 -10a4 10 0 0 0 -4 -10" transform="rotate(45 12 12)" />   
                    <path d="M12 2a4 10 0 0 0 -4 10a4 10 0 0 0 4 10a4 10 0 0 0 4 -10a4 10 0 0 0 -4 -10" transform="rotate(-45 12 12)" />
                </>
            )
        case "award": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="9" r="6" />   
                    <polyline points="9 14.2 9 21 12 19 15 21 15 14.2" transform="rotate(-30 12 9)" />   
                    <polyline points="9 14.2 9 21 12 19 15 21 15 14.2" transform="rotate(30 12 9)" />
                </>
            )
        case "backspace": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M20 6a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-11l-5 -5a1.5 1.5 0 0 1 0 -2l5 -5Z" />   
                    <path d="M12 10l4 4m0 -4l-4 4" />
                </>
            )
        case "ball-basketball": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="5.65" y1="5.65" x2="18.35" y2="18.35" />   
                    <line x1="5.65" y1="18.35" x2="18.35" y2="5.65" />   
                    <path d="M12 3a9 9 0 0 0 9 9" />   
                    <path d="M3 12a9 9 0 0 1 9 9" />
                </>
            )
        case "ball-bowling": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="11" y1="9" x2="11" y2="9.01" />   
                    <line x1="15" y1="8" x2="15" y2="8.01" />   
                    <line x1="14" y1="12" x2="14" y2="12.01" />
                </>
            )
        case "ball-tennis": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />      <path d="M6 5.3a9 9 0 0 1 0 13.4" />      <path d="M6 5.3a9 9 0 0 1 0 13.4" transform="rotate(180 12 12)" />   
                    </>)
        case "ball-volleyball": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />      <path d="M12 12a8 8 0 0 0 8 4M7.5 13.5a12 12 0 0 0 8.5 6.5" />      <path d="M12 12a8 8 0 0 0 8 4M7.5 13.5a12 12 0 0 0 8.5 6.5" transform="rotate(120 12 12)" />      <path d="M12 12a8 8 0 0 0 8 4M7.5 13.5a12 12 0 0 0 8.5 6.5" transform="rotate(240 12 12)" />   
                    </>)
        case "ban": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="5.7" y1="5.7" x2="18.3" y2="18.3" />
                </>
            )
        case "bandage": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="14" y1="12" x2="14" y2="12.01" />   
                    <line x1="10" y1="12" x2="10" y2="12.01" />   
                    <line x1="12" y1="10" x2="12" y2="10.01" />   
                    <line x1="12" y1="14" x2="12" y2="14.01" />   
                    <path d="M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7" />
                </>
            )
        case "barcode": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 7v-1a2 2 0 0 1 2 -2h2" />   
                    <path d="M4 17v1a2 2 0 0 0 2 2h2" />   
                    <path d="M16 4h2a2 2 0 0 1 2 2v1" />   
                    <path d="M16 20h2a2 2 0 0 0 2 -2v-1" />   
                    <rect x="5" y="11" width="1" height="2" />   
                    <line x1="10" y1="11" x2="10" y2="13" />   
                    <rect x="14" y="11" width="1" height="2" />   
                    <line x1="19" y1="11" x2="19" y2="13" />
                </>
            )
        case "basket": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="7 10 12 4 17 10" />   
                    <path d="M21 10l-2 8a2 2.5 0 0 1 -2 2h-10a2 2.5 0 0 1 -2 -2l-2 -8Z" />   
                    <circle cx="12" cy="15" r="2" />
                </>
            )
        case "battery-1": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 7h11a2 2 0 0 1 2 2v.5a.5 .5 0 0 0 .5 .5a.5 .5 0 0 1 .5 .5v3a.5 .5 0 0 1 -.5 .5a.5 .5 0 0 0 -.5 .5v.5a2 2 0 0 1 -2 2h-11a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2" />   
                    <line x1="7" y1="10" x2="7" y2="14" />
                </>
            )
        case "battery-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 7h11a2 2 0 0 1 2 2v.5a.5 .5 0 0 0 .5 .5a.5 .5 0 0 1 .5 .5v3a.5 .5 0 0 1 -.5 .5a.5 .5 0 0 0 -.5 .5v.5a2 2 0 0 1 -2 2h-11a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2" />   
                    <line x1="7" y1="10" x2="7" y2="14" />   
                    <line x1="10" y1="10" x2="10" y2="14" />
                </>
            )
        case "battery-3": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 7h11a2 2 0 0 1 2 2v.5a.5 .5 0 0 0 .5 .5a.5 .5 0 0 1 .5 .5v3a.5 .5 0 0 1 -.5 .5a.5 .5 0 0 0 -.5 .5v.5a2 2 0 0 1 -2 2h-11a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2" />   
                    <line x1="7" y1="10" x2="7" y2="14" />   
                    <line x1="10" y1="10" x2="10" y2="14" />   
                    <line x1="13" y1="10" x2="13" y2="14" />
                </>
            )
        case "battery-4": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 7h11a2 2 0 0 1 2 2v.5a.5 .5 0 0 0 .5 .5a.5 .5 0 0 1 .5 .5v3a.5 .5 0 0 1 -.5 .5a.5 .5 0 0 0 -.5 .5v.5a2 2 0 0 1 -2 2h-11a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2" />   
                    <line x1="7" y1="10" x2="7" y2="14" />   
                    <line x1="10" y1="10" x2="10" y2="14" />   
                    <line x1="13" y1="10" x2="13" y2="14" />   
                    <line x1="16" y1="10" x2="16" y2="14" />
                </>
            )
        case "battery-charging": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16 7h1a2 2 0 0 1 2 2v.5a.5 .5 0 0 0 .5 .5a.5 .5 0 0 1 .5 .5v3a.5 .5 0 0 1 -.5 .5a.5 .5 0 0 0 -.5 .5v.5a2 2 0 0 1 -2 2h-2" />   
                    <path d="M8 7H6a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h1" />   
                    <polyline points="12 8 10 12 13 12 11 16" />
                </>
            )
        case "battery": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 7h11a2 2 0 0 1 2 2v.5a.5 .5 0 0 0 .5 .5a.5 .5 0 0 1 .5 .5v3a.5 .5 0 0 1 -.5 .5a.5 .5 0 0 0 -.5 .5v.5a2 2 0 0 1 -2 2h-11a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2" />
                </>
            )
        case "bed": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6" />   
                    <circle cx="7" cy="10" r="1" />
                </>
            )
        case "bell": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />   
                    <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                </>
            )
        case "bike": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="5" cy="18" r="3" />   
                    <circle cx="19" cy="18" r="3" />   
                    <polyline points="12 19 12 15 9 12 14 8 16 11 19 11" />   
                    <circle cx="17" cy="5" r="1" />
                </>
            )
        case "bluetooth": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="6 8 18 16 12 20 12 4 18 8 6 16" />
                </>
            )
        case "bold": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 5h6a3.5 3.5 0 0 1 0 7h-6z" />   
                    <path d="M13 12h1a3.5 3.5 0 0 1 0 7h-7v-7" />
                </>
            )
        case "bolt": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="13 3 13 10 19 10 11 21 11 14 5 14 13 3" />
                </>
            )
        case "book": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />   
                    <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />   
                    <line x1="3" y1="6" x2="3" y2="19" />   
                    <line x1="12" y1="6" x2="12" y2="19" />   
                    <line x1="21" y1="6" x2="21" y2="19" />
                </>
            )
        case "bookmark": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 4h6a2 2 0 0 1 2 2v14l-5-3l-5 3v-14a2 2 0 0 1 2 -2" />
                </>
            )
        case "border-all": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <line x1="4" y1="12" x2="20" y2="12" />   
                    <line x1="12" y1="4" x2="12" y2="20" />
                </>
            )
        case "border-bottom": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="20" y1="20" x2="4" y2="20" />   
                    <g stroke-dasharray=".001 4">   
                    <line x1="4" y1="4" x2="20" y2="4" />   
                    <line x1="20" y1="4" x2="20" y2="20" />   
                    <line x1="4" y1="20" x2="4" y2="4" />   
                    <line x1="4" y1="12" x2="20" y2="12" />   
                    <line x1="12" y1="4" x2="12" y2="20" />   
                    </g></>)
        case "border-horizontal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="12" x2="20" y2="12" />   
                    <g stroke-dasharray=".001 4">   
                    <line x1="4" y1="4" x2="20" y2="4" />   
                    <line x1="4" y1="20" x2="4" y2="4" />   
                    <line x1="20" y1="20" x2="4" y2="20" />   
                    <line x1="20" y1="4" x2="20" y2="20" />   
                    <line x1="12" y1="4" x2="12" y2="20" />   
                    </g></>)
        case "border-inner": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="12" x2="20" y2="12" />   
                    <line x1="12" y1="4" x2="12" y2="20" />   
                    <g stroke-dasharray=".001 4">   
                    <line x1="4" y1="4" x2="20" y2="4" />   
                    <line x1="4" y1="20" x2="4" y2="4" />   
                    <line x1="20" y1="20" x2="4" y2="20" />   
                    <line x1="20" y1="4" x2="20" y2="20" />   
                    </g></>)
        case "border-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="20" x2="4" y2="4" />   
                    <g stroke-dasharray=".001 4">   
                    <line x1="4" y1="4" x2="20" y2="4" />   
                    <line x1="20" y1="4" x2="20" y2="20" />   
                    <line x1="20" y1="20" x2="4" y2="20" />   
                    <line x1="4" y1="12" x2="20" y2="12" />   
                    <line x1="12" y1="4" x2="12" y2="20" />   
                    </g></>)
        case "border-none": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <g stroke-dasharray=".001 4">   
                    <line x1="4" y1="4" x2="20" y2="4" />   
                    <line x1="20" y1="4" x2="20" y2="20" />   
                    <line x1="20" y1="20" x2="4" y2="20" />   
                    <line x1="4" y1="20" x2="4" y2="4" />   
                    <line x1="4" y1="12" x2="20" y2="12" />   
                    <line x1="12" y1="4" x2="12" y2="20" />   
                    </g></>)
        case "border-outer": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <g stroke-dasharray=".001 4">   
                    <line x1="4" y1="12" x2="20" y2="12" />   
                    <line x1="12" y1="4" x2="12" y2="20" />   
                    </g></>)
        case "border-radius": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16.01 4h4v16h-16v-4.01" stroke-dasharray=".001 4" />   
                    <path d="M4 12v-4a4 4 0 0 1 4 -4h4" />
                </>
            )
        case "border-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="20" y1="4" x2="20" y2="20" />   
                    <g stroke-dasharray=".001 4">   
                    <line x1="4" y1="4" x2="20" y2="4" />   
                    <line x1="20" y1="20" x2="4" y2="20" />   
                    <line x1="4" y1="20" x2="4" y2="4" />   
                    <line x1="4" y1="12" x2="20" y2="12" />   
                    <line x1="12" y1="4" x2="12" y2="20" />   
                    </g></>)
        case "border-top": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="4" x2="20" y2="4" />   
                    <g stroke-dasharray=".001 4">   
                    <line x1="20" y1="4" x2="20" y2="20" />   
                    <line x1="20" y1="20" x2="4" y2="20" />   
                    <line x1="4" y1="20" x2="4" y2="4" />   
                    <line x1="4" y1="12" x2="20" y2="12" />   
                    <line x1="12" y1="4" x2="12" y2="20" />   
                    </g></>)
        case "border-vertical": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="4" x2="12" y2="20" />   
                    <g stroke-dasharray=".001 4">   
                    <line x1="4" y1="12" x2="20" y2="12" />   
                    <line x1="4" y1="4" x2="20" y2="4" />   
                    <line x1="4" y1="20" x2="4" y2="4" />   
                    <line x1="20" y1="20" x2="4" y2="20" />   
                    <line x1="20" y1="4" x2="20" y2="20" />   
                    </g></>)
        case "box": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" />   
                    <line x1="12" y1="12" x2="20" y2="7.5" />   
                    <line x1="12" y1="12" x2="12" y2="21" />   
                    <line x1="12" y1="12" x2="4" y2="7.5" />
                </>
            )
        case "braces": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 4a2 2 0 0 0 -2 2v3a2 3 0 0 1 -2 3a2 3 0 0 1 2 3v3a2 2 0 0 0 2 2" />   
                    <path d="M17 4a2 2 0 0 1 2 2v3a2 3 0 0 0 2 3a2 3 0 0 0 -2 3v3a2 2 0 0 1 -2 2" />
                </>
            )
        case "brackets": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M8 4h-3v16h3" />   
                    <path d="M16 4h3v16h-3" />
                </>
            )
        case "brand-android": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="10" x2="3" y2="16" />   
                    <line x1="21" y1="10" x2="21" y2="16" />   
                    <path d="M7 9h10v8a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-8a5 5 0 0 1 10 0" />   
                    <line x1="8" y1="3" x2="9" y2="5" />   
                    <line x1="16" y1="3" x2="15" y2="5" />   
                    <line x1="9" y1="18" x2="9" y2="21" />   
                    <line x1="15" y1="18" x2="15" y2="21" />
                </>
            )
        case "brand-apple": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 7c-3 0-4 3-4 5.5 0 3 2 7.5 4 7.5 1.088-.046 1.679-.5 3-.5 1.312 0 1.5.5 3 .5s4-3 4-5c-.028-.01-2.472-.403-2.5-3-.019-2.17 2.416-2.954 2.5-3-1.023-1.492-2.951-1.963-3.5-2-1.433-.111-2.83 1-3.5 1-.68 0-1.9-1-3-1z" />   
                    <path d="M12 4a2 2 0 0 0 2 -2a2 2 0 0 0 -2 2" />
                </>
            )
        case "brand-chrome": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <circle cx="12" cy="12" r="3" />   
                    <line x1="12" y1="9" x2="20.4" y2="9" />   
                    <line x1="12" y1="9" x2="20.4" y2="9" transform="rotate(120 12 12)" />   
                    <line x1="12" y1="9" x2="20.4" y2="9" transform="rotate(240 12 12)" />
                </>
            )
        case "brand-dribbble": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <path d="M9 3.6c5 6 7 10.5 7.5 16.2" />   
                    <path d="M6.4 19c3.5-3.5 6-6.5 14.5-6.4" />   
                    <path d="M3.1 10.75c5 0 9.814-.38 15.314-5" />
                </>
            )
        case "brand-facebook": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                </>
            )
        case "brand-framer": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 15h12l-12 -12h12v6h-12v6l6 6v-6" />
                </>
            )
        case "brand-github": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 19c-4.286 1.35-4.286-2.55-6-3m12 5v-3.5c0-1 .099-1.405-.5-2 2.791-.3 5.5-1.366 5.5-6.04a4.567 4.567 0 0 0 -1.333 -3.21 4.192 4.192 0 00-.08-3.227s-1.05-.3-3.476 1.267a12.334 12.334 0 0 0 -6.222 0C6.462 2.723 5.413 3.023 5.413 3.023a4.192 4.192 0 0 0 -.08 3.227A4.566 4.566 0 004 9.486c0 4.64 2.709 5.68 5.5 6.014-.591.589-.56 1.183-.5 2V21" />
                </>
            )
        case "brand-gitlab": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M21 14l-9 7-9-7L6 3l3 7h6l3-7z" />
                </>
            )
        case "brand-google-drive": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12 10L6 20l-3-5L9 5z" />   
                    <path d="M9 15h12l-3 5h-12" />   
                    <path d="M15 15L9 5h6l6 10z" />
                </>
            )
        case "brand-google": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M17.788 5.108A9 9 0 1021 12h-8" />
                </>
            )
        case "brand-instagram": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="4" />   
                    <circle cx="12" cy="12" r="3" />   
                    <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                </>
            )
        case "brand-opera": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <ellipse cx="12" cy="12" rx="3" ry="5" />
                </>
            )
        case "brand-paypal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M10 13l2.538-.003c2.46 0 4.962-2.497 4.962-4.997 0-3-1.89-5-4.962-5H7c-.5 0-1 .5-1 1L4 18c0 .5.5 1 1 1h2.846L9 14c.089-.564.43-1 1-1zm7.467-5.837C19.204 8.153 20 10 20 12c0 2.467-2.54 4.505-5 4.505h.021-2.629l-.576 3.65a.998.998 0 01-.988.844l-2.712-.002a.5.5 0 01-.494-.578L7.846 19" />
                </>
            )
        case "brand-safari": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="8 16 10 10 16 8 14 14 8 16" />   
                    <circle cx="12" cy="12" r="9" />
                </>
            )
        case "brand-sketch": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3.262 10.878l7.991 8.789c.4.44 1.091.44 1.491 0l7.993-8.79c.313-.344.349-.859.087-1.243L17.287 4.44a.996.996 0 00-.823-.436H7.538a.996.996 0 00-.823.436 2713782426.663 2713782426.663 0 01-3.54 5.192c-.263.385-.227.901.087 1.246z" />
                </>
            )
        case "brand-snapchat": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16.882 7.842a4.882 4.882 0 0 0 -9.764 0c0 4.273-.213 6.409-4.118 8.118 2 .882 2 .882 3 3 3 0 4 2 6 2s3-2 6-2c1-2.118 1-2.118 3-3-3.906-1.709-4.118-3.845-4.118-8.118h0zM3 15.961c4-2.118 4-4.118 1-7.118m17 7.118c-4-2.118-4-4.118-1-7.118" />
                </>
            )
        case "brand-telegram": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" />
                </>
            )
        case "brand-twitter": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497 -3.753C20.18 7.773 21.692 5.25 22 4.009z" />
                </>
            )
        case "briefcase": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="7" width="18" height="13" rx="2" />   
                    <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />   
                    <line x1="12" y1="12" x2="12" y2="12.01" />   
                    <path d="M3 13a20 20 0 0 0 18 0" />
                </>
            )
        case "brightness-down": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="3" />   
                    <line x1="12" y1="5" x2="12" y2="5.01" />   
                    <line x1="17" y1="7" x2="17" y2="7.01" />   
                    <line x1="19" y1="12" x2="19" y2="12.01" />   
                    <line x1="17" y1="17" x2="17" y2="17.01" />   
                    <line x1="12" y1="19" x2="12" y2="19.01" />   
                    <line x1="7" y1="17" x2="7" y2="17.01" />   
                    <line x1="5" y1="12" x2="5" y2="12.01" />   
                    <line x1="7" y1="7" x2="7" y2="7.01" />
                </>
            )
        case "brightness-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="3" />   
                    <line x1="12" y1="5" x2="12" y2="3" />   
                    <line x1="17" y1="7" x2="18.4" y2="5.6" />   
                    <line x1="19" y1="12" x2="21" y2="12" />   
                    <line x1="17" y1="17" x2="18.4" y2="18.4" />   
                    <line x1="12" y1="19" x2="12" y2="21" />   
                    <line x1="7" y1="17" x2="5.6" y2="18.4" />   
                    <line x1="6" y1="12" x2="4" y2="12" />   
                    <line x1="7" y1="7" x2="5.6" y2="5.6" />
                </>
            )
        case "brightness": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="12" y1="3" x2="12" y2="21" />   
                    <path d="M12 9l4.65 -4.65" />   
                    <path d="M12 14.3l7.37 -7.37" />   
                    <path d="M12 19.6l8.85 -8.85" />
                </>
            )
        case "browser": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="1" />   
                    <line x1="4" y1="8" x2="20" y2="8" />   
                    <line x1="8" y1="4" x2="8" y2="8" />
                </>
            )
        case "brush": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 21v-4a4 4 0 1 1 4 4h-4" />   
                    <path d="M21 3a16 16 0 0 0 -12.8 10.2" />   
                    <path d="M21 3a16 16 0 0 1 -10.2 12.8" />   
                    <path d="M10.6 9a9 9 0 0 1 4.4 4.4" />
                </>
            )
        case "bucket": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M19 9l-2 9a2 2.5 0 0 1 -2 2h-6a2 2.5 0 0 1 -2 -2l-2 -9Z" />   
                    <path d="M7 9a5 5 0 0 1 10 0" />
                </>
            )
        case "bug": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 9v-1a3 3 0 0 1 6 0v1" />   
                    <path d="M8 9h8a6 6 0 0 1 1 3v3a5 5 0 0 1 -10 0v-3a6 6 0 0 1 1 -3" />   
                    <line x1="3" y1="13" x2="7" y2="13" />   
                    <line x1="17" y1="13" x2="21" y2="13" />   
                    <line x1="12" y1="20" x2="12" y2="14" />   
                    <line x1="4" y1="19" x2="7.35" y2="17" />   
                    <line x1="20" y1="19" x2="16.65" y2="17" />   
                    <line x1="4" y1="7" x2="7.75" y2="9.4" />   
                    <line x1="20" y1="7" x2="16.25" y2="9.4" />
                </>
            )
        case "building-arch": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="21" x2="21" y2="21" />   
                    <path d="M4 21v-15a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v15" />   
                    <path d="M9 21v-8a3 3 0 0 1 6 0v8" />
                </>
            )
        case "building-bank": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="21" x2="21" y2="21" />   
                    <line x1="3" y1="10" x2="21" y2="10" />   
                    <polyline points="5 6 12 3 19 6" />   
                    <line x1="4" y1="10" x2="4" y2="21" />   
                    <line x1="20" y1="10" x2="20" y2="21" />   
                    <line x1="8" y1="14" x2="8" y2="17" />   
                    <line x1="12" y1="14" x2="12" y2="17" />   
                    <line x1="16" y1="14" x2="16" y2="17" />
                </>
            )
        case "building-bridge-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 7h12a2 2 0 0 1 2 2v9a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a4 4 0 0 0 -8 0v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-9a2 2 0 0 1 2 -2" />
                </>
            )
        case "building-bridge": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="6" y1="5" x2="6" y2="19" />   
                    <line x1="18" y1="5" x2="18" y2="19" />   
                    <line x1="2" y1="15" x2="22" y2="15" />   
                    <path d="M3 8a7.5 7.5 0 0 0 3 -2a6.5 6.5 0 0 0 12 0a7.5 7.5 0 0 0 3 2" />   
                    <line x1="12" y1="10" x2="12" y2="15" />
                </>
            )
        case "building-church": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="21" x2="21" y2="21" />   
                    <path d="M10 21v-4a2 2 0 0 1 4 0v4" />   
                    <line x1="10" y1="5" x2="14" y2="5" />   
                    <line x1="12" y1="3" x2="12" y2="8" />   
                    <path d="M6 21v-7m-2 2l8 -8l8 8m-2 -2v7" />
                </>
            )
        case "building-community": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8" />   
                    <line x1="13" y1="7" x2="13" y2="7.01" />   
                    <line x1="17" y1="7" x2="17" y2="7.01" />   
                    <line x1="17" y1="11" x2="17" y2="11.01" />   
                    <line x1="17" y1="15" x2="17" y2="15.01" />
                </>
            )
        case "building-hospital": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="21" x2="21" y2="21" />   
                    <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />   
                    <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />   
                    <line x1="10" y1="9" x2="14" y2="9" />   
                    <line x1="12" y1="7" x2="12" y2="11" />
                </>
            )
        case "building-pavilon": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 21h7v-3a2 2 0 0 1 4 0v3h7" />   
                    <line x1="6" y1="21" x2="6" y2="12" />   
                    <line x1="18" y1="21" x2="18" y2="12" />   
                    <path d="M6 12h12a3 3 0 0 0 3 -3a9 8 0 0 1 -9 -6a9 8 0 0 1 -9 6a3 3 0 0 0 3 3" />
                </>
            )
        case "building-skyscrappers": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="21" x2="21" y2="21" />   
                    <path d="M5 21v-14l8 -4v18" />   
                    <path d="M19 21v-10l-6 -4" />   
                    <line x1="9" y1="9" x2="9" y2="9.01" />   
                    <line x1="9" y1="12" x2="9" y2="12.01" />   
                    <line x1="9" y1="15" x2="9" y2="15.01" />   
                    <line x1="9" y1="18" x2="9" y2="18.01" />
                </>
            )
        case "building-store": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="21" x2="21" y2="21" />   
                    <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />   
                    <path d="M5 21v-10.15" />   
                    <path d="M19 21v-10.15" />   
                    <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                </>
            )
        case "building-warehouse": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 21v-13l9-4l9 4v13" />   
                    <path d="M13 13h4v8h-10v-6h6" />   
                    <path d="M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3" />
                </>
            )
        case "building": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="21" x2="21" y2="21" />   
                    <line x1="9" y1="8" x2="10" y2="8" />   
                    <line x1="9" y1="12" x2="10" y2="12" />   
                    <line x1="9" y1="16" x2="10" y2="16" />   
                    <line x1="14" y1="8" x2="15" y2="8" />   
                    <line x1="14" y1="12" x2="15" y2="12" />   
                    <line x1="14" y1="16" x2="15" y2="16" />   
                    <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
                </>
            )
        case "bulb-off": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" />   
                    <line x1="9.7" y1="17" x2="14.3" y2="17" />
                </>
            )
        case "bulb": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 12h1M12 3v1M20 12h1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7" />   
                    <path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" />   
                    <line x1="9.7" y1="17" x2="14.3" y2="17" />
                </>
            )
        case "bus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="6" cy="17" r="2" />   
                    <circle cx="18" cy="17" r="2" />   
                    <path d="M4 17h-2v-11a1 1 0 0 1 1 -1h14a5 7 0 0 1 5 7v5h-2m-4 0h-8" />   
                    <polyline points="16 5 17.5 12 22 12" />   
                    <line x1="2" y1="10" x2="17" y2="10" />   
                    <line x1="7" y1="5" x2="7" y2="10" />   
                    <line x1="12" y1="5" x2="12" y2="10" />
                </>
            )
        case "calculator": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="3" width="16" height="18" rx="2" />   
                    <rect x="8" y="7" width="8" height="3" rx="1" />   
                    <line x1="8" y1="14" x2="8" y2="14.01" />   
                    <line x1="12" y1="14" x2="12" y2="14.01" />   
                    <line x1="16" y1="14" x2="16" y2="14.01" />   
                    <line x1="8" y1="17" x2="8" y2="17.01" />   
                    <line x1="12" y1="17" x2="12" y2="17.01" />   
                    <line x1="16" y1="17" x2="16" y2="17.01" />
                </>
            )
        case "calendar-event": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="5" width="16" height="16" rx="2" />   
                    <line x1="16" y1="3" x2="16" y2="7" />   
                    <line x1="8" y1="3" x2="8" y2="7" />   
                    <line x1="4" y1="11" x2="20" y2="11" />   
                    <rect x="8" y="15" width="2" height="2" />
                </>
            )
        case "calendar-minus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="5" width="16" height="16" rx="2" />   
                    <line x1="16" y1="3" x2="16" y2="7" />   
                    <line x1="8" y1="3" x2="8" y2="7" />   
                    <line x1="4" y1="11" x2="20" y2="11" />   
                    <line x1="10" y1="16" x2="14" y2="16" />
                </>
            )
        case "calendar-plus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="5" width="16" height="16" rx="2" />   
                    <line x1="16" y1="3" x2="16" y2="7" />   
                    <line x1="8" y1="3" x2="8" y2="7" />   
                    <line x1="4" y1="11" x2="20" y2="11" />   
                    <line x1="10" y1="16" x2="14" y2="16" />   
                    <line x1="12" y1="14" x2="12" y2="18" />
                </>
            )
        case "calendar": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="5" width="16" height="16" rx="2" />   
                    <line x1="16" y1="3" x2="16" y2="7" />   
                    <line x1="8" y1="3" x2="8" y2="7" />   
                    <line x1="4" y1="11" x2="20" y2="11" />   
                    <line x1="11" y1="15" x2="12" y2="15" />   
                    <line x1="12" y1="15" x2="12" y2="18" />
                </>
            )
        case "camera": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />   
                    <circle cx="12" cy="13" r="3" />
                </>
            )
        case "car": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="7" cy="17" r="2" />   
                    <circle cx="17" cy="17" r="2" />   
                    <path d="M5 17h-2v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" />
                </>
            )
        case "caret-down": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18 15l-6-6l-6 6h12" transform="rotate(180 12 12)" />
                </>
            )
        case "caret-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18 15l-6-6l-6 6h12" transform="rotate(270 12 12)" />
                </>
            )
        case "caret-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18 15l-6-6l-6 6h12" transform="rotate(90 12 12)" />
                </>
            )
        case "caret-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18 15l-6-6l-6 6h12" />
                </>
            )
        case "cash": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="7" y="9" width="14" height="10" rx="2" />   
                    <circle cx="14" cy="14" r="2" />   
                    <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" />
                </>
            )
        case "cast": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="19" x2="3.01" y2="19" />   
                    <path d="M7 19a4 4 0 0 0 -4 -4" />   
                    <path d="M11 19a8 8 0 0 0 -8 -8" />   
                    <path d="M15 19h3a3 3 0 0 0 3 -3v-8a3 3 0 0 0 -3 -3h-12a3 3 0 0 0 -2.8 2" />
                </>
            )
        case "chart-area-line": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="4 19 8 13 12 15 16 10 20 14 20 19 4 19" />   
                    <polyline points="4 12 7 8 11 10 16 4 20 8" />
                </>
            )
        case "chart-area": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="19" x2="20" y2="19" />   
                    <polyline points="4 15 8 9 12 11 16 6 20 10 20 15 4 15" />
                </>
            )
        case "chart-bar": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="12" width="6" height="8" rx="1" />   
                    <rect x="9" y="8" width="6" height="12" rx="1" />   
                    <rect x="15" y="4" width="6" height="16" rx="1" />   
                    <line x1="4" y1="20" x2="18" y2="20" />
                </>
            )
        case "chart-candle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="6" width="4" height="5" rx="1" />   
                    <line x1="6" y1="4" x2="6" y2="6" />   
                    <line x1="6" y1="11" x2="6" y2="20" />   
                    <rect x="10" y="14" width="4" height="5" rx="1" />   
                    <line x1="12" y1="4" x2="12" y2="14" />   
                    <line x1="12" y1="19" x2="12" y2="20" />   
                    <rect x="16" y="5" width="4" height="6" rx="1" />   
                    <line x1="18" y1="4" x2="18" y2="5" />   
                    <line x1="18" y1="11" x2="18" y2="20" />
                </>
            )
        case "chart-donut": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M10 3.2a9 9 0 1 0 10.8 10.8a1 1 0 0 0 -1 -1h-3.8a4.1 4.1 0 1 1 -5 -5v-4a.9 .9 0 0 0 -1 -.8" />   
                    <path d="M15 3.5a9 9 0 0 1 5.5 5.5h-4.5a9 9 0 0 0 -1 -1v-4.5" />
                </>
            )
        case "chart-line": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="19" x2="20" y2="19" />   
                    <polyline points="4 15 8 9 12 11 16 6 20 10" />
                </>
            )
        case "chart-pie": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M10 3.2a9 9 0 1 0 10.8 10.8a1 1 0 0 0 -1 -1h-6.8a2 2 0 0 1 -2 -2v-7a.9 .9 0 0 0 -1 -.8" />   
                    <path d="M15 3.5a9 9 0 0 1 5.5 5.5h-4.5a1 1 0 0 1 -1 -1v-4.5" />
                </>
            )
        case "check": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 12l5 5l10 -10" />
                </>
            )
        case "checkbox": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="9 11 12 14 20 6" />   
                    <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
                </>
            )
        case "checks": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 12l5 5l10 -10" />   
                    <path d="M2 12l5 5m5 -5l5 -5" />
                </>
            )
        case "chevron-down": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="6 9 12 15 18 9" />
                </>
            )
        case "chevron-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="15 6 9 12 15 18" />
                </>
            )
        case "chevron-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="9 6 15 12 9 18" />
                </>
            )
        case "chevron-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="6 15 12 9 18 15" />
                </>
            )
        case "chevrons-down": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="7 7 12 12 17 7" />   
                    <polyline points="7 13 12 18 17 13" />
                </>
            )
        case "chevrons-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="11 7 6 12 11 17" />   
                    <polyline points="17 7 12 12 17 17" />
                </>
            )
        case "chevrons-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="7 7 12 12 7 17" />   
                    <polyline points="13 7 18 12 13 17" />
                </>
            )
        case "chevrons-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="7 11 12 6 17 11" />   
                    <polyline points="7 17 12 12 17 17" />
                </>
            )
        case "circle-check": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <path d="M9 12l2 2l4 -4" />
                </>
            )
        case "circle-minus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="12" x2="15" y2="12" />
                </>
            )
        case "circle-plus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="12" x2="15" y2="12" />   
                    <line x1="12" y1="9" x2="12" y2="15" />
                </>
            )
        case "circle-x": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <path d="M10 10l4 4m0 -4l-4 4" />
                </>
            )
        case "circle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />
                </>
            )
        case "clear-formatting": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M17 15l4 4m0 -4l-4 4" />   
                    <path d="M7 6v-1h11v1" />   
                    <line x1="7" y1="19" x2="11" y2="19" />   
                    <line x1="13" y1="5" x2="9" y2="19" />
                </>
            )
        case "click": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="12" x2="6" y2="12" />   
                    <line x1="12" y1="3" x2="12" y2="6" />   
                    <line x1="7.8" y1="7.8" x2="5.6" y2="5.6" />   
                    <line x1="16.2" y1="7.8" x2="18.4" y2="5.6" />   
                    <line x1="7.8" y1="16.2" x2="5.6" y2="18.4" />   
                    <path d="M12 12l9 3l-4 2l-2 4l-3 -9" />
                </>
            )
        case "clipboard-check": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />   
                    <rect x="9" y="3" width="6" height="4" rx="2" />   
                    <path d="M9 14l2 2l4 -4" />
                </>
            )
        case "clipboard-list": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />   
                    <rect x="9" y="3" width="6" height="4" rx="2" />   
                    <line x1="9" y1="12" x2="9.01" y2="12" />   
                    <line x1="13" y1="12" x2="15" y2="12" />   
                    <line x1="9" y1="16" x2="9.01" y2="16" />   
                    <line x1="13" y1="16" x2="15" y2="16" />
                </>
            )
        case "clipboard-x": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />   
                    <rect x="9" y="3" width="6" height="4" rx="2" />   
                    <path d="M10 12l4 4m0 -4l-4 4" />
                </>
            )
        case "clipboard": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 5H7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2V7a2 2 0 0 0 -2 -2h-2" />   
                    <rect x="9" y="3" width="6" height="4" rx="2" />
                </>
            )
        case "clock": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <polyline points="12 7 12 12 15 15" />
                </>
            )
        case "cloud-download": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4" />   
                    <line x1="12" y1="13" x2="12" y2="22" />   
                    <polyline points="9 19 12 22 15 19" />
                </>
            )
        case "cloud-rain": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 18a4.6 4.4 0 0 1 0 -9h0a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7" />   
                    <path d="M11 13v2m0 3v2m4 -5v2m0 3v2" />
                </>
            )
        case "cloud-snow": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 18a4.6 4.4 0 0 1 0 -9h0a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7" />   
                    <path d="M11 15v.01m0 3v.01m0 3v.01m4 -4v.01m0 3v.01" />
                </>
            )
        case "cloud-storm": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 18a4.6 4.4 0 0 1 0 -9h0a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />   
                    <polyline points="13 14 11 18 14 18 12 22" />
                </>
            )
        case "cloud-upload": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 18a4.6 4.4 0 0 1 0 -9h0a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />   
                    <polyline points="9 15 12 12 15 15" />   
                    <line x1="12" y1="12" x2="12" y2="21" />
                </>
            )
        case "cloud": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 18a4.6 4.4 0 0 1 0 -9h0a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-12" />
                </>
            )
        case "code": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="7 8 3 12 7 16" />   
                    <polyline points="17 8 21 12 17 16" />   
                    <line x1="14" y1="4" x2="10" y2="20" />
                </>
            )
        case "coin": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1" />   
                    <path d="M12 6v2m0 8v2" />
                </>
            )
        case "color-picker": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="11" y1="7" x2="17" y2="13" />   
                    <path d="M5 19v-4l9.7 -9.7a1 1 0 0 1 1.4 0l2.6 2.6a1 1 0 0 1 0 1.4l-9.7 9.7h-4" />
                </>
            )
        case "color-swatch": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M19 3h-4a2 2 0 0 0 -2 2v12a4 4 0 0 0 8 0v-12a2 2 0 0 0 -2 -2" />   
                    <path d="M13 7.35l-2 -2a2 2 0 0 0 -2.828 0l-2.828 2.828a2 2 0 0 0 0 2.828l 9 9" />   
                    <path d="M7.3 13h-2.3a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h12" />   
                    <line x1="17" y1="17" x2="17" y2="17.01" />
                </>
            )
        case "columns": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="6" x2="9.5" y2="6" />   
                    <line x1="4" y1="10" x2="9.5" y2="10" />   
                    <line x1="4" y1="14" x2="9.5" y2="14" />   
                    <line x1="4" y1="18" x2="9.5" y2="18" />   
                    <line x1="14.5" y1="6" x2="20" y2="6" />   
                    <line x1="14.5" y1="10" x2="20" y2="10" />   
                    <line x1="14.5" y1="14" x2="20" y2="14" />   
                    <line x1="14.5" y1="18" x2="20" y2="18" />
                </>
            )
        case "command": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 9a2 2 0 1 1 2 -2v10a2 2 0 1 1 -2 -2h10a2 2 0 1 1 -2 2v-10a2 2 0 1 1 2 2h-10" />
                </>
            )
        case "compass": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="8 16 10 10 16 8 14 14 8 16" />   
                    <circle cx="12" cy="12" r="9" />
                </>
            )
        case "copy": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="8" y="8" width="12" height="12" rx="2" />   
                    <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                </>
            )
        case "copyright": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <path d="M14.5 9a3.5 4 0 1 0 0 6" />
                </>
            )
        case "corner-down-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18 6v6a3 3 0 0 1 -3 3h-10l5 -5m0 10l-5 -5" />
                </>
            )
        case "corner-down-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 6v6a3 3 0 0 0 3 3h10l-5 -5m0 10l5 -5" />
                </>
            )
        case "corner-left-down": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18 6h-6a3 3 0 0 0 -3 3v10l-5 -5m10 0l-5 5" />
                </>
            )
        case "corner-left-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18 18h-6a3 3 0 0 1 -3 -3v-10l-5 5m10 0l-5 -5" />
                </>
            )
        case "corner-right-down": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 6h6a3 3 0 0 1 3 3v10l-5 -5m10 0l-5 5" />
                </>
            )
        case "corner-right-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 18h6a3 3 0 0 0 3 -3v-10l-5 5m10 0l-5 -5" />
                </>
            )
        case "corner-up-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18 18v-6a3 3 0 0 0 -3 -3h-10l5 -5m0 10l-5 -5" />
                </>
            )
        case "corner-up-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 18v-6a3 3 0 0 1 3 -3h10l-5 -5m0 10l5 -5" />
                </>
            )
        case "credit-card": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="5" width="18" height="14" rx="3" />   
                    <line x1="3" y1="10" x2="21" y2="10" />   
                    <line x1="7" y1="15" x2="7.01" y2="15" />   
                    <line x1="11" y1="15" x2="13" y2="15" />
                </>
            )
        case "crop": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M8 5v10a1 1 0 0 0 1 1h10" />   
                    <path d="M5 8h10a1 1 0 0 1 1 1v10" />
                </>
            )
        case "currency-bitcoin": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 6h8a3 3 0 0 1 0 6a3 3 0 0 1 0 6h-8" />   
                    <line x1="8" y1="6" x2="8" y2="18" />   
                    <line x1="8" y1="12" x2="14" y2="12" />   
                    <line x1="9" y1="3" x2="9" y2="6" />   
                    <line x1="13" y1="3" x2="13" y2="6" />   
                    <line x1="9" y1="18" x2="9" y2="21" />   
                    <line x1="13" y1="18" x2="13" y2="21" />
                </>
            )
        case "currency-dollar": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2" />   
                    <path d="M12 3v3m0 12v3" />
                </>
            )
        case "currency-euro": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M17.2 7a6 7 0 1 0 0 10" />   
                    <path d="M13 10h-8m0 4h8" />
                </>
            )
        case "currency-pound": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M17 18.5a6 6 0 0 1 -5 0a6 6 0 0 0 -5 .5a3 3 0 0 0 2 -2.5v-7.5a4 4 0 0 1 7.45 -2m-2.55 6h-7" />
                </>
            )
        case "currency-rupee": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18 6h-11h3a4 4 0 0 1 0 8h-3l6 6" />   
                    <line x1="7" y1="10" x2="18" y2="10" />
                </>
            )
        case "currency-yen": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12 19v-7l-5 -7m10 0l-5 7" />   
                    <line x1="8" y1="17" x2="16" y2="17" />   
                    <line x1="8" y1="13" x2="16" y2="13" />
                </>
            )
        case "cut": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="7" cy="17" r="3" />   
                    <circle cx="17" cy="17" r="3" />   
                    <line x1="9.15" y1="14.85" x2="18" y2="4" />   
                    <line x1="6" y1="4" x2="14.85" y2="14.85" />
                </>
            )
        case "dashboard": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="13" r="2" />   
                    <line x1="13.45" y1="11.55" x2="15.5" y2="9.5" />   
                    <path d="M6.4 20a9 9 0 1 1 11.2 0Z" />
                </>
            )
        case "database": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <ellipse cx="12" cy="6" rx="8" ry="3"></ellipse>   
                    <path d="M4 6v6a8 3 0 0 0 16 0v-6" />   
                    <path d="M4 12v6a8 3 0 0 0 16 0v-6" />
                </>
            )
        case "device-desktop": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="4" width="18" height="12" rx="1" />   
                    <line x1="7" y1="20" x2="17" y2="20" />   
                    <line x1="9" y1="16" x2="9" y2="20" />   
                    <line x1="15" y1="16" x2="15" y2="20" />
                </>
            )
        case "device-floppy": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />   
                    <circle cx="12" cy="14" r="2" />   
                    <polyline points="14 4 14 8 8 8 8 4" />
                </>
            )
        case "device-gamepad": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="2" y="6" width="20" height="12" rx="2" />   
                    <path d="M6 12h4m-2 -2v4" />   
                    <line x1="15" y1="11" x2="15" y2="11.01" />   
                    <line x1="18" y1="13" x2="18" y2="13.01" />
                </>
            )
        case "device-laptop": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="19" x2="21" y2="19" />   
                    <rect x="5" y="6" width="14" height="10" rx="1" />
                </>
            )
        case "device-mobile-vibration": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <g transform="rotate(27 12 12)">   
                    <rect x="7" y="4" width="10" height="16" rx="1" />   
                    <line x1="11" y1="5" x2="13" y2="5" />   
                    <line x1="12" y1="17" x2="12" y2="17.01" />   
                    </g>   
                    <path d="M6 4l-2 1v2.5l-2 1v2.5" />   
                    <path d="M22 13v2.5l-2 1v2.5l-2 1" />
                </>
            )
        case "device-mobile": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="7" y="4" width="10" height="16" rx="1" />   
                    <line x1="11" y1="5" x2="13" y2="5" />   
                    <line x1="12" y1="17" x2="12" y2="17.01" />
                </>
            )
        case "device-speaker": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="5" y="3" width="14" height="18" rx="2" />   
                    <circle cx="12" cy="14" r="3" />   
                    <line x1="12" y1="7" x2="12" y2="7.01" />
                </>
            )
        case "device-tablet": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="5" y="3" width="14" height="18" rx="1" />   
                    <circle cx="12" cy="17" r="1" />
                </>
            )
        case "device-tv": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="7" width="18" height="13" rx="2" />   
                    <polyline points="16 3 12 7 8 3" />
                </>
            )
        case "device-watch": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="6" y="6" width="12" height="12" rx="3" />   
                    <path d="M9 18v3h6v-3" />   
                    <path d="M9 6v-3h6v3" />
                </>
            )
        case "devices": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="13" y="8" width="8" height="12" rx="1" />   
                    <path d="M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9" />   
                    <line x1="16" y1="9" x2="18" y2="9" />
                </>
            )
        case "diamond": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 5h12l3 5l-8.5 9.5a.7 .7 0 0 1 -1 0l-8.5 -9.5l3 -5" />   
                    <path d="M10 12l-2 -2.2l.6 -1" />
                </>
            )
        case "dice": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <circle cx="8.5" cy="8.5" r=".5" fill="currentColor" />   
                    <circle cx="15.5" cy="8.5" r=".5" fill="currentColor" />   
                    <circle cx="15.5" cy="15.5" r=".5" fill="currentColor" />   
                    <circle cx="8.5" cy="15.5" r=".5" fill="currentColor" />
                </>
            )
        case "direction-horizontal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M10 9l-3 3l3 3" />   
                    <path d="M14 9l3 3l-3 3" />
                </>
            )
        case "direction": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 10l3 -3l3 3" />   
                    <path d="M9 14l3 3l3 -3" />
                </>
            )
        case "directions": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 5h10l2 2l-2 2h-10a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1" />   
                    <path d="M13 13h-7l-2 2l2 2h7a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1" />   
                    <line x1="12" y1="22" x2="12" y2="17" />   
                    <line x1="12" y1="13" x2="12" y2="9" />   
                    <line x1="12" y1="5" x2="12" y2="3" />
                </>
            )
        case "disabled-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="17" cy="6" r="2" />   
                    <path d="M9 11a4.97 4.97 0 1 0 3.95 7.95" />   
                    <path d="M19 20l-4 -5h-4l3 -5l-4 -3l-4 1" />
                </>
            )
        case "disabled": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="11" cy="5" r="2" />   
                    <polyline points="11 7 11 15 15 15 19 20" />   
                    <line x1="11" y1="11" x2="16" y2="11" />   
                    <path d="M7 11.5a4.97 4.97 0 1 0 6 7.5" />
                </>
            )
        case "disc": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <circle cx="12" cy="12" r="1" />   
                    <path d="M7 12a5 5 0 0 1 5 -5" />   
                    <path d="M12 17a5 5 0 0 0 5 -5" />
                </>
            )
        case "discount": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="9" y1="15" x2="15" y2="9" />   
                    <circle cx="9.5" cy="9.5" r=".5" fill="currentColor" />   
                    <circle cx="14.5" cy="14.5" r=".5" fill="currentColor" />   
                    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55 v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55 v-1" />
                </>
            )
        case "dots-circle-horizontal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="8" y1="12" x2="8" y2="12.01" />   
                    <line x1="12" y1="12" x2="12" y2="12.01" />   
                    <line x1="16" y1="12" x2="16" y2="12.01" />
                </>
            )
        case "dots-diagonal-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="7" cy="7" r="1" />   
                    <circle cx="12" cy="12" r="1" />   
                    <circle cx="17" cy="17" r="1" />
                </>
            )
        case "dots-diagonal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="7" cy="17" r="1" />   
                    <circle cx="12" cy="12" r="1" />   
                    <circle cx="17" cy="7" r="1" />
                </>
            )
        case "dots-vertical": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="1" />   
                    <circle cx="12" cy="19" r="1" />   
                    <circle cx="12" cy="5" r="1" />
                </>
            )
        case "dots": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="5" cy="12" r="1" />   
                    <circle cx="12" cy="12" r="1" />   
                    <circle cx="19" cy="12" r="1" />
                </>
            )
        case "download": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />   
                    <polyline points="7 11 12 16 17 11" />   
                    <line x1="12" y1="4" x2="12" y2="16" />
                </>
            )
        case "drag-drop-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16 8v-4h-12v12.01h4" stroke-dasharray=".001 4" />   
                    <rect x="8" y="8" width="12" height="12" rx="2" />
                </>
            )
        case "drag-drop": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 7v-4h-12v12.01h4" stroke-dasharray=".001 4" />   
                    <path d="M19 11v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />   
                    <path d="M13 13l9 3l-4 2l-2 4l-3 -9" />
                </>
            )
        case "droplet": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12 3l5 5a7 7 0 1 1 -10 0l5 -5" />
                </>
            )
        case "ear": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 10a7 7 0 1 1 13 3.6a10 10 0 0 1 -2 2a8 8 0 0 0 -2 3  a4.5 4.5 0 0 1 -6.8 1.4" />   
                    <path d="M10 10a3 3 0 1 1 5 2.2" />
                </>
            )
        case "edit": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />   
                    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />   
                    <line x1="16" y1="5" x2="19" y2="8" />
                </>
            )
        case "egg": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 14a6 6 0 0 0 12 0a12 12 0 0 0 -3 -8.5a3.7 3.7 0 0 0 -6 0a12 12 0 0 0 -3 8.5" />
                </>
            )
        case "emphasis": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16 5 h-8v10h8m-1 -5h-7" />   
                    <line x1="6" y1="20" x2="6" y2="20.01" />   
                    <line x1="10" y1="20" x2="10" y2="20.01" />   
                    <line x1="14" y1="20" x2="14" y2="20.01" />   
                    <line x1="18" y1="20" x2="18" y2="20.01" />
                </>
            )
        case "eraser": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M19 19h-11l-4 -4a1 1 0 0 1 0 -1.41l10 -10a1 1 0 0 1 1.41 0l5 5a1 1 0 0 1 0 1.41l-9 9" />   
                    <path d="M18 12.3l-6.3 -6.3" />
                </>
            )
        case "exchange": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="5" cy="18" r="2" />   
                    <circle cx="19" cy="6" r="2" />   
                    <path d="M19 8v5a5 5 0 0 1 -5 5h-3l3 -3m0 6l-3 -3" />   
                    <path d="M5 16v-5a5 5 0 0 1 5 -5h3l-3 -3m0 6l3 -3" />
                </>
            )
        case "exposure": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <line x1="4.6" y1="19.4" x2="19.4" y2="4.6" />   
                    <path d="M7 9h4m-2 -2v4" />   
                    <path d="M13 16h4" />
                </>
            )
        case "external-link": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />   
                    <line x1="10" y1="14" x2="20" y2="4" />   
                    <polyline points="15 4 20 4 20 9" />
                </>
            )
        case "eye": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="2" />   
                    <path d="M2 12l1.5 2a11 11 0 0 0 17 0l1.5 -2" />   
                    <path d="M2 12l1.5 -2a11 11 0 0 1 17 0l1.5 2" />
                </>
            )
        case "face-id": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />   
                    <path d="M4 16v2a2 2 0 0 0 2 2h2" />   
                    <path d="M16 4h2a2 2 0 0 1 2 2v2" />   
                    <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />   
                    <line x1="9" y1="10" x2="9.01" y2="10" />   
                    <line x1="15" y1="10" x2="15.01" y2="10" />   
                    <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
                </>
            )
        case "file-check": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />   
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />   
                    <path d="M9 15l2 2l4 -4" />
                </>
            )
        case "file-code": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />   
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />   
                    <path d="M10 13l-1 2l1 2" />   
                    <path d="M14 13l1 2l-1 2" />
                </>
            )
        case "file-download": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />   
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />   
                    <line x1="12" y1="11" x2="12" y2="17" />   
                    <polyline points="9 14 12 17 15 14" />
                </>
            )
        case "file-horizontal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16 5v4a1 1 0 0 0 1 1h4" />   
                    <path d="M3 7v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2 -2v-7l-5 -5h-11a2 2 0 0 0 -2 2z" />
                </>
            )
        case "file-invoice": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />   
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />   
                    <line x1="9" y1="7" x2="10" y2="7" />   
                    <line x1="9" y1="13" x2="15" y2="13" />   
                    <line x1="13" y1="17" x2="15" y2="17" />
                </>
            )
        case "file-minus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />   
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />   
                    <line x1="9" y1="14" x2="15" y2="14" />
                </>
            )
        case "file-music": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />   
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />   
                    <circle cx="11" cy="16" r="1" />   
                    <polyline points="12 16 12 11 14 12" />
                </>
            )
        case "file-plus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />   
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />   
                    <line x1="12" y1="11" x2="12" y2="17" />   
                    <line x1="9" y1="14" x2="15" y2="14" />
                </>
            )
        case "file-shredder": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />   
                    <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />   
                    <line x1="3" y1="12" x2="21" y2="12" />   
                    <line x1="6" y1="16" x2="6" y2="18" />   
                    <line x1="10" y1="16" x2="10" y2="22" />   
                    <line x1="14" y1="16" x2="14" y2="18" />   
                    <line x1="18" y1="16" x2="18" y2="20" />
                </>
            )
        case "file-text": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />   
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />   
                    <line x1="9" y1="9" x2="10" y2="9" />   
                    <line x1="9" y1="13" x2="15" y2="13" />   
                    <line x1="9" y1="17" x2="15" y2="17" />
                </>
            )
        case "file-x": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />   
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />   
                    <path d="M10 12l4 4m0 -4l-4 4" />
                </>
            )
        case "file": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />   
                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                </>
            )
        case "filter": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5.5 5h13a1 1 0 0 1 0.5 1.5L14 12L14 19L10 16L10 12L5 6.5a1 1 0 0 1 0.5 -1.5" />
                </>
            )
        case "fingerprint": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18.9 7a8 8 0 0 1 1.1 5v1a6 6 0 0 0 .8 3" />   
                    <path d="M8 11a4 4 0 0 1 8 0v1a10 10 0 0 0 2 6" />   
                    <path d="M12 11v2a14 14 0 0 0 2.5 8" />   
                    <path d="M8 15a18 18 0 0 0 1.8 6" />   
                    <path d="M4.9 19a22 22 0 0 1 -.9 -7v-1a8 8 0 0 1 12 -6.95" />
                </>
            )
        case "firetruck": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="5" cy="17" r="2" />   
                    <circle cx="17" cy="17" r="2" />   
                    <path d="M7 18h8m4 0h2v-6a5 5 0 0 0 -5 -5h-1l1.5 5h4.5" />   
                    <path d="M12 18v-11h3" />   
                    <polyline points="3 17 3 12 12 12" />   
                    <line x1="3" y1="9" x2="21" y2="3" />   
                    <line x1="6" y1="12" x2="6" y2="8" />
                </>
            )
        case "flag": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="5" y1="5" x2="5" y2="21" />   
                    <line x1="19" y1="5" x2="19" y2="14" />   
                    <path d="M5 5a5 5 0 0 1 7 0a5 5 0 0 0 7 0" />   
                    <path d="M5 14a5 5 0 0 1 7 0a5 5 0 0 0 7 0" />
                </>
            )
        case "flame": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18 15a6 6 0 1 1 -10.853 -3.529c1.926-2.338 4.763-3.327 3.848-8.47 2.355 1.761 5.84 5.38 2.022 9.406-1.136 1.091-.244 2.767 1.221 2.593.882-.105 2.023-.966 3.23-2.3.532.68.532 1.717.532 2.3z" />
                </>
            )
        case "flask": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="9" y1="3" x2="15" y2="3" />   
                    <line x1="10" y1="9" x2="14" y2="9" />   
                    <path d="M10 3v6l-4 11a.7 .7 0 0 0 .5 1h11a.7 .7 0 0 0 .5 -1l-4 -11v-6" />
                </>
            )
        case "flip-horizontal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="12" x2="21" y2="12" />   
                    <polyline points="7 16 17 16 7 21 7 16" />   
                    <polyline points="7 8 17 8 7 3 7 8" />
                </>
            )
        case "flip-vertical": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="3" x2="12" y2="21" />   
                    <polyline points="16 7 16 17 21 17 16 7" />   
                    <polyline points="8 7 8 17 3 17 8 7" />
                </>
            )
        case "float-center": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect width="6" height="6" x="9" y="5" rx="1" />   
                    <line x1="4" y1="7" x2="5" y2="7" />   
                    <line x1="4" y1="11" x2="5" y2="11" />   
                    <line x1="19" y1="7" x2="20" y2="7" />   
                    <line x1="19" y1="11" x2="20" y2="11" />   
                    <line x1="4" y1="15" x2="20" y2="15" />   
                    <line x1="4" y1="19" x2="20" y2="19" />
                </>
            )
        case "float-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect width="6" height="6" x="4" y="5" rx="1" />   
                    <line x1="14" y1="7" x2="20" y2="7" />   
                    <line x1="14" y1="11" x2="20" y2="11" />   
                    <line x1="4" y1="15" x2="20" y2="15" />   
                    <line x1="4" y1="19" x2="20" y2="19" />
                </>
            )
        case "float-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect width="6" height="6" x="14" y="5" rx="1" />   
                    <line x1="4" y1="7" x2="10" y2="7" />   
                    <line x1="4" y1="11" x2="10" y2="11" />   
                    <line x1="4" y1="15" x2="20" y2="15" />   
                    <line x1="4" y1="19" x2="20" y2="19" />
                </>
            )
        case "floppy-disk": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18 20h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9l5 5v9a2 2 0 0 1 -2 2" />   
                    <circle cx="12" cy="13" r="2" />   
                    <polyline points="4 8 10 8 10 4" />
                </>
            )
        case "focus-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r=".5" fill="currentColor" />   
                    <circle cx="12" cy="12" r="7" />   
                    <line x1="12" y1="3" x2="12" y2="5" />   
                    <line x1="3" y1="12" x2="5" y2="12" />   
                    <line x1="12" y1="19" x2="12" y2="21" />   
                    <line x1="19" y1="12" x2="21" y2="12" />
                </>
            )
        case "focus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r=".5" fill="currentColor" />   
                    <circle cx="12" cy="12" r="9" />
                </>
            )
        case "folder-minus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />   
                    <line x1="9" y1="13" x2="15" y2="13" />
                </>
            )
        case "folder-plus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />   
                    <line x1="12" y1="10" x2="12" y2="16" />   
                    <line x1="9" y1="13" x2="15" y2="13" />
                </>
            )
        case "folder-x": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />   
                    <path d="M10 11l4 4m0 -4l-4 4" />
                </>
            )
        case "folder": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" />
                </>
            )
        case "folders": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 4h3l2 2h5a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />   
                    <path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h2" />
                </>
            )
        case "forbid-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="15" x2="15" y2="9" />
                </>
            )
        case "forbid": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="9" x2="15" y2="15" />
                </>
            )
        case "forklift": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="5" cy="17" r="2" />   
                    <circle cx="14" cy="17" r="2" />   
                    <line x1="7" y1="17" x2="12" y2="17" />   
                    <path d="M3 17v-6h13v6" />   
                    <path d="M5 11v-4h4" />   
                    <path d="M9 11v-6h4l3 6" />   
                    <path d="M22 15h-3v-10" />   
                    <line x1="16" y1="13" x2="19" y2="13" />
                </>
            )
        case "frame": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="7" x2="20" y2="7" />   
                    <line x1="4" y1="17" x2="20" y2="17" />   
                    <line x1="7" y1="4" x2="7" y2="20" />   
                    <line x1="17" y1="4" x2="17" y2="20" />
                </>
            )
        case "friends": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="7" cy="5" r="2" />   
                    <path d="M5 22v-5l-1-1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />   
                    <circle cx="17" cy="5" r="2" />   
                    <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
                </>
            )
        case "gauge": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <circle cx="12" cy="12" r="1" />   
                    <line x1="13.41" y1="10.59" x2="16" y2="8" />   
                    <path d="M7 12a5 5 0 0 1 5 -5" />
                </>
            )
        case "ghost": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7" />   
                    <line x1="10" y1="10" x2="10.01" y2="10" />   
                    <line x1="14" y1="10" x2="14.01" y2="10" />   
                    <path d="M10 14a3.5 3.5 0 0 0 4 0" />
                </>
            )
        case "gift": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="8" width="18" height="4" rx="1" />   
                    <line x1="12" y1="8" x2="12" y2="21" />   
                    <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />   
                    <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5" />
                </>
            )
        case "git-branch": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="7" cy="18" r="2" />   
                    <circle cx="7" cy="6" r="2" />   
                    <circle cx="17" cy="6" r="2" />   
                    <line x1="7" y1="8" x2="7" y2="16" />   
                    <path d="M9 18h6a2 2 0 0 0 2 -2v-5" />   
                    <polyline points="14 14 17 11 20 14" />
                </>
            )
        case "git-commit": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="3" />   
                    <line x1="12" y1="3" x2="12" y2="9" />   
                    <line x1="12" y1="15" x2="12" y2="21" />
                </>
            )
        case "git-compare": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="6" cy="6" r="2" />   
                    <circle cx="18" cy="18" r="2" />   
                    <path d="M11 6h5a2 2 0 0 1 2 2v8" />   
                    <polyline points="14 9 11 6 14 3" />   
                    <path d="M13 18h-5a2 2 0 0 1 -2 -2v-8" />   
                    <polyline points="10 15 13 18 10 21" />
                </>
            )
        case "git-fork": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="18" r="2" />   
                    <circle cx="7" cy="6" r="2" />   
                    <circle cx="17" cy="6" r="2" />   
                    <path d="M7 8v2a2 2 0 0 0 2 2h6a2 2 0 0 0 2 -2v-2" />   
                    <line x1="12" y1="12" x2="12" y2="16" />
                </>
            )
        case "git-merge": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="7" cy="18" r="2" />   
                    <circle cx="7" cy="6" r="2" />   
                    <circle cx="17" cy="12" r="2" />   
                    <line x1="7" y1="8" x2="7" y2="16" />   
                    <path d="M7 8a4 4 0 0 0 4 4h4" />
                </>
            )
        case "git-pull-request": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="6" cy="18" r="2" />   
                    <circle cx="6" cy="6" r="2" />   
                    <circle cx="18" cy="18" r="2" />   
                    <line x1="6" y1="8" x2="6" y2="16" />   
                    <path d="M11 6h5a2 2 0 0 1 2 2v8" />   
                    <polyline points="14 9 11 6 14 3" />
                </>
            )
        case "glass-full": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="8" y1="21" x2="16" y2="21" />   
                    <line x1="12" y1="15" x2="12" y2="21" />   
                    <path d="M16 4l1 6a5 5 0 0 1 -10 0l1 -6z" />   
                    <path d="M7 10a4 4 0 0 1 5 0a4 4 0 0 0 5 0" />
                </>
            )
        case "glass": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="8" y1="21" x2="16" y2="21" />   
                    <line x1="12" y1="15" x2="12" y2="21" />   
                    <path d="M16 4l1 6a5 5 0 0 1 -10 0l1 -6z" />
                </>
            )
        case "globe": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="10" r="4" />   
                    <path d="M6.75 16a8.015 8.015 0 1 0 9.25 -13" />   
                    <line x1="12" y1="18" x2="12" y2="22" />   
                    <line x1="8" y1="22" x2="16" y2="22" />
                </>
            )
        case "grid-dots": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="5" cy="5" r="1" />   
                    <circle cx="12" cy="5" r="1" />   
                    <circle cx="19" cy="5" r="1" />   
                    <circle cx="5" cy="12" r="1" />   
                    <circle cx="12" cy="12" r="1" />   
                    <circle cx="19" cy="12" r="1" />   
                    <circle cx="5" cy="19" r="1" />   
                    <circle cx="12" cy="19" r="1" />   
                    <circle cx="19" cy="19" r="1" />
                </>
            )
        case "grid": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="6" height="6" rx="1" />   
                    <rect x="14" y="4" width="6" height="6" rx="1" />   
                    <rect x="4" y="14" width="6" height="6" rx="1" />   
                    <rect x="14" y="14" width="6" height="6" rx="1" />
                </>
            )
        case "grip-horizontal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="5" cy="9" r="1" />   
                    <circle cx="5" cy="15" r="1" />   
                    <circle cx="12" cy="9" r="1" />   
                    <circle cx="12" cy="15" r="1" />   
                    <circle cx="19" cy="9" r="1" />   
                    <circle cx="19" cy="15" r="1" />
                </>
            )
        case "grip-vertical": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="9" cy="5" r="1" />   
                    <circle cx="9" cy="12" r="1" />   
                    <circle cx="9" cy="19" r="1" />   
                    <circle cx="15" cy="5" r="1" />   
                    <circle cx="15" cy="12" r="1" />   
                    <circle cx="15" cy="19" r="1" />
                </>
            )
        case "hand-middle-finger": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M8 13.5v-4a1.5 1.5 0 0 1 3 0v2.5m0 -2.5v-6a1.5 1.5 0 0 1 3 0v8.5m0 -2.5a1.5 1.5 0 0 1 3 0v2.5m0 -1.5a1.5 1.5 0 0 1 3 0v5.5a6 6 0 0 1 -6 6h-2a7 6 0 0 1 -5 -3l-2.7 -5.25a1.4 1.4 0 0 1 2.75 -2l.9 1.75" />
                </>
            )
        case "hand-stop": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M8 13.5v-8a1.5 1.5 0 0 1 3 0v6.5m0 -6.5v-2a1.5 1.5 0 0 1 3 0v8.5m0 -6.5a1.5 1.5 0 0 1 3 0v6.5m0 -4.5a1.5 1.5 0 0 1 3 0v8.5a6 6 0 0 1 -6 6h-2a7 6 0 0 1 -5 -3l-2.7 -5.25a1.4 1.4 0 0 1 2.75 -2l.9 1.75" />
                </>
            )
        case "hash": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="5" y1="9" x2="19" y2="9" />   
                    <line x1="5" y1="15" x2="19" y2="15" />   
                    <line x1="11" y1="4" x2="7" y2="20" />   
                    <line x1="17" y1="4" x2="13" y2="20" />
                </>
            )
        case "headphones": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="13" rx="2" width="5" height="7" />   
                    <rect x="15" y="13" rx="2" width="5" height="7" />   
                    <path d="M4 15v-3a8 8 0 0 1 16 0v3" />
                </>
            )
        case "headset": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="13" rx="2" width="4" height="6" />   
                    <rect x="16" y="13" rx="2" width="4" height="6" />   
                    <path d="M4 15v-3a8 8 0 0 1 16 0v3" />   
                    <path d="M18 19a6 3 0 0 1 -6 3" />
                </>
            )
        case "heart": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
                </>
            )
        case "help": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="12" y1="17" x2="12" y2="17.01" />   
                    <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
                </>
            )
        case "hexagon": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12.971 3.54l6 3.333A2 2 0 0120 8.62v6.536a2 2 0 0 1 -1.029 1.748l-6 3.333a2 2 0 0 1 -1.942 0l-6-3.333A2 2 0 014 15.157V8.62a2 2 0 0 1 1.029 -1.748l6-3.333a2 2 0 0 1 1.942 0z" />
                </>
            )
        case "history": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="12 8 12 12 14 14" />   
                    <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5" />
                </>
            )
        case "home-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="5 12 3 12 12 3 21 12 19 12" />   
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />   
                    <rect x="10" y="12" width="4" height="4" />
                </>
            )
        case "home": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="5 12 3 12 12 3 21 12 19 12" />   
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />   
                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                </>
            )
        case "ice-cream": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="20" x2="8" y2="16" />   
                    <line x1="5" y1="13" x2="11" y2="19" />   
                    <path d="M11 19l7 -7a4 4 0 0 0 -6 -6l-7 7" />
                </>
            )
        case "id": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="4" width="18" height="16" rx="3" />   
                    <circle cx="9" cy="10" r="2" />   
                    <line x1="15" y1="8" x2="17" y2="8" />   
                    <line x1="15" y1="12" x2="17" y2="12" />   
                    <line x1="7" y1="16" x2="17" y2="16" />
                </>
            )
        case "inbox": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <path d="M4 13h3l3 3h4l3 -3h3" />
                </>
            )
        case "indent-decrease": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="20" y1="6" x2="13" y2="6" />   
                    <line x1="20" y1="12" x2="11" y2="12" />   
                    <line x1="20" y1="18" x2="13" y2="18" />   
                    <path d="M8 8l-4 4l4 4" />
                </>
            )
        case "indent-increase": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="20" y1="6" x2="9" y2="6" />   
                    <line x1="20" y1="12" x2="13" y2="12" />   
                    <line x1="20" y1="18" x2="9" y2="18" />   
                    <path d="M4 8l4 4l-4 4" />
                </>
            )
        case "infinity": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9.828 9.172a4 4 0 1 0 0 5.656 a10 10 0 0 0 2.172 -2.828a10 10 0 0 1 2.172 -2.828 a4 4 0 1 1 0 5.656a10 10 0 0 1 -2.172 -2.828a10 10 0 0 0 -2.172 -2.828" />
                </>
            )
        case "info-circle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="12" y1="8" x2="12.01" y2="8" />   
                    <polyline points="11 12 12 12 12 16 13 16" />
                </>
            )
        case "info-square": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="8" x2="12.01" y2="8" />   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <polyline points="11 12 12 12 12 16 13 16" />
                </>
            )
        case "italic": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="11" y1="5" x2="17" y2="5" />   
                    <line x1="7" y1="19" x2="13" y2="19" />   
                    <line x1="14" y1="5" x2="10" y2="19" />
                </>
            )
        case "key": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="8" cy="15" r="4" />   
                    <line x1="10.85" y1="12.15" x2="19" y2="4" />   
                    <line x1="18" y1="5" x2="20" y2="7" />   
                    <line x1="15" y1="8" x2="17" y2="10" />
                </>
            )
        case "keyboard": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="4" width="18" height="16" rx="2" />   
                    <line x1="7" y1="8" x2="7" y2="8.01" />   
                    <line x1="12" y1="8" x2="12" y2="8.01" />   
                    <line x1="17" y1="8" x2="17" y2="8.01" />   
                    <line x1="7" y1="12" x2="7" y2="12.01" />   
                    <line x1="12" y1="12" x2="12" y2="12.01" />   
                    <line x1="17" y1="12" x2="17" y2="12.01" />   
                    <line x1="7" y1="16" x2="17" y2="16" />
                </>
            )
        case "language": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 7h7m-2 -2v2a5 8 0 0 1 -5 8m1 -4a7 4 0 0 0 6.7 4" />   
                    <path d="M11 19l4 -9l4 9m-.9 -2h-6.2" />
                </>
            )
        case "layers-difference": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2v-2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-2" />   
                    <polyline points="8 8 16 8 16 16 8 16 8 8" stroke-dasharray="4 4" stroke-dashoffset="2" />
                </>
            )
        case "layers-intersect": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="8" y="4" width="12" height="12" rx="2" />   
                    <rect x="4" y="8" width="12" height="12" rx="2" />
                </>
            )
        case "layers-subtract": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="8" y="4" width="12" height="12" rx="2" />   
                    <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2" />
                </>
            )
        case "layers-union": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16 16v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h2v-2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-2" />
                </>
            )
        case "layout-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="6" height="5" rx="2" />   
                    <rect x="4" y="13" width="6" height="7" rx="2" />   
                    <rect x="14" y="4" width="6" height="7" rx="2" />   
                    <rect x="14" y="15" width="6" height="5" rx="2" />
                </>
            )
        case "layout-align-bottom": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="20" x2="20" y2="20" />   
                    <rect x="9" y="4" width="6" height="12" rx="2" />
                </>
            )
        case "layout-align-center": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="4" x2="12" y2="9" />   
                    <line x1="12" y1="15" x2="12" y2="20" />   
                    <rect x="6" y="9" width="12" height="6" rx="2" />
                </>
            )
        case "layout-align-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="4" x2="4" y2="20" />   
                    <rect x="8" y="9" width="12" height="6" rx="2" />
                </>
            )
        case "layout-align-middle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="12" x2="9" y2="12" />   
                    <line x1="15" y1="12" x2="20" y2="12" />   
                    <rect x="9" y="6" width="6" height="12" rx="2" />
                </>
            )
        case "layout-align-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="20" y1="4" x2="20" y2="20" />   
                    <rect x="4" y="9" width="12" height="6" rx="2" />
                </>
            )
        case "layout-align-top": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="4" x2="20" y2="4" />   
                    <rect x="9" y="8" width="6" height="12" rx="2" />
                </>
            )
        case "layout-bottombar": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <line x1="4" y1="15" x2="20" y2="15" />
                </>
            )
        case "layout-cards": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="6" height="16" rx="2" />   
                    <rect x="14" y="4" width="6" height="10" rx="2" />
                </>
            )
        case "layout-columns": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <line x1="12" y1="4" x2="12" y2="20" />
                </>
            )
        case "layout-distribute-horizontal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="4" x2="20" y2="4" />   
                    <line x1="4" y1="20" x2="20" y2="20" />   
                    <rect x="6" y="9" width="12" height="6" rx="2" />
                </>
            )
        case "layout-distribute-vertical": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="4" x2="4" y2="20" />   
                    <line x1="20" y1="4" x2="20" y2="20" />   
                    <rect x="9" y="6" width="6" height="12" rx="2" />
                </>
            )
        case "layout-list": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="6" rx="2" />   
                    <rect x="4" y="14" width="16" height="6" rx="2" />
                </>
            )
        case "layout-navbar": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <line x1="4" y1="9" x2="20" y2="9" />
                </>
            )
        case "layout-rows": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <line x1="4" y1="12" x2="20" y2="12" />
                </>
            )
        case "layout-sidebar-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <line x1="15" y1="4" x2="15" y2="20" />
                </>
            )
        case "layout-sidebar": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <line x1="9" y1="4" x2="9" y2="20" />
                </>
            )
        case "layout": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="6" height="5" rx="2" />   
                    <rect x="4" y="13" width="6" height="7" rx="2" />   
                    <rect x="14" y="4" width="6" height="16" rx="2" />
                </>
            )
        case "lego": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="9.5" y1="11" x2="9.51" y2="11" />   
                    <line x1="14.5" y1="11" x2="14.51" y2="11" />   
                    <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />   
                    <path d="M7 5h1v-2h8v2h1a3 3 0 0 1 3 3v9a3 3 0 0 1 -3 3v1h-10v-1a3 3 0 0 1 -3 -3v-9a3 3 0 0 1 3 -3" />
                </>
            )
        case "letter-case": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="18" cy="16" r="3" />   
                    <line x1="21" y1="13" x2="21" y2="19" />   
                    <path d="M3 19l5 -13l5 13" />   
                    <line x1="5" y1="14" x2="11" y2="14" />
                </>
            )
        case "license": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 21h-9a3 3 0 0 1 -3 -3v-1h10v2a2 2 0 0 0 4 0v-14a2 2 0 1 1 2 2h-2m2 -4h-11a3 3 0 0 0 -3 3v11" />   
                    <line x1="9" y1="7" x2="13" y2="7" />   
                    <line x1="9" y1="11" x2="13" y2="11" />
                </>
            )
        case "lifebuoy": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="4" />   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="15" y1="15" x2="18.35" y2="18.35" />   
                    <line x1="9" y1="15" x2="5.65" y2="18.35" />   
                    <line x1="5.65" y1="5.65" x2="9" y2="9" />   
                    <line x1="18.35" y1="5.65" x2="15" y2="9" />
                </>
            )
        case "line-height": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="3 8 6 5 9 8" />   
                    <polyline points="3 16 6 19 9 16" />   
                    <line x1="6" y1="5" x2="6" y2="19" />   
                    <line x1="13" y1="6" x2="20" y2="6" />   
                    <line x1="13" y1="12" x2="20" y2="12" />   
                    <line x1="13" y1="18" x2="20" y2="18" />
                </>
            )
        case "link": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />   
                    <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
                </>
            )
        case "list-check": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3.5 5.5l1.5 1.5l2.5 -2.5" />   
                    <path d="M3.5 11.5l1.5 1.5l2.5 -2.5" />   
                    <path d="M3.5 17.5l1.5 1.5l2.5 -2.5" />   
                    <line x1="11" y1="6" x2="20" y2="6" />   
                    <line x1="11" y1="12" x2="20" y2="12" />   
                    <line x1="11" y1="18" x2="20" y2="18" />
                </>
            )
        case "list": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="9" y1="6" x2="20" y2="6" />   
                    <line x1="9" y1="12" x2="20" y2="12" />   
                    <line x1="9" y1="18" x2="20" y2="18" />   
                    <line x1="5" y1="6" x2="5" y2="6.01" />   
                    <line x1="5" y1="12" x2="5" y2="12.01" />   
                    <line x1="5" y1="18" x2="5" y2="18.01" />
                </>
            )
        case "live-photo": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="1" />   
                    <circle cx="12" cy="12" r="5" />   
                    <circle cx="12" cy="12" r="9" stroke-dasharray=".001 4.03" />
                </>
            )
        case "location": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M21 3L14.5 21a.55 .55 0 0 1 -1 0L10 14L3 10.5a.55 .55 0 0 1 0 -1L21 3" />
                </>
            )
        case "lock-open": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="5" y="11" width="14" height="10" rx="2" />   
                    <circle cx="12" cy="16" r="1" />   
                    <path d="M8 11v-5a4 4 0 0 1 8 0" />
                </>
            )
        case "lock": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="5" y="11" width="14" height="10" rx="2" />   
                    <circle cx="12" cy="16" r="1" />   
                    <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                </>
            )
        case "login": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />   
                    <path d="M20 12h-13l3 -3m0 6l-3 -3" />
                </>
            )
        case "logout": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />   
                    <path d="M7 12h14l-3 -3m0 6l3 -3" />
                </>
            )
        case "magnet": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 13v-8a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v8a2 2 0 0 0 6 0v-8a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v8a8 8 0 0 1 -16 0" />   
                    <line x1="4" y1="8" x2="9" y2="8" />   
                    <line x1="15" y1="8" x2="19" y2="8" />
                </>
            )
        case "mail-opened": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="3 9 12 15 21 9 12 3 3 9" />   
                    <path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" />   
                    <line x1="3" y1="19" x2="9" y2="13" />   
                    <line x1="15" y1="13" x2="21" y2="19" />
                </>
            )
        case "mail": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="5" width="18" height="14" rx="2" />   
                    <polyline points="3 7 12 13 21 7" />
                </>
            )
        case "man": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="5" r="2" />   
                    <path d="M10 22v-5l-1-1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
                </>
            )
        case "map-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="18" y1="6" x2="18" y2="6.01" />   
                    <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />   
                    <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />   
                    <line x1="9" y1="4" x2="9" y2="17" />   
                    <line x1="15" y1="15" x2="15" y2="20" />
                </>
            )
        case "map-pin": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="11" r="3" />   
                    <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 0 1 -2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0z" />
                </>
            )
        case "map": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7" />   
                    <line x1="9" y1="4" x2="9" y2="17" />   
                    <line x1="15" y1="7" x2="15" y2="20" />
                </>
            )
        case "math": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16 13l4 4m0 -4l-4 4" />   
                    <path d="M20 5h-7l-4 14l-3 -6h-2" />
                </>
            )
        case "maximize": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />   
                    <path d="M4 16v2a2 2 0 0 0 2 2h2" />   
                    <path d="M16 4h2a2 2 0 0 1 2 2v2" />   
                    <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
                </>
            )
        case "medical-cross": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M13 3a1 1 0 0 1 1 1v4.535l3.928 -2.267a1 1 0 0 1 1.366 .366l1 1.732a1 1 0 0 1 -.366 1.366L16.001 12l3.927 2.269a1 1 0 0 1 .366 1.366l-1 1.732a1 1 0 0 1 -1.366 .366L14 15.464V20a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-4.536l-3.928 2.268a1 1 0 0 1 -1.366 -.366l-1-1.732a1 1 0 0 1 .366 -1.366L7.999 12 4.072 9.732a1 1 0 0 1 -.366 -1.366l1-1.732a1 1 0 0 1 1.366 -.366L10 8.535V4a1 1 0 0 1 1 -1h2z" />
                </>
            )
        case "menu": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="8" x2="20" y2="8" />   
                    <line x1="4" y1="16" x2="20" y2="16" />
                </>
            )
        case "message-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12 20l-3 -3h-2a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-2l-3 3" />   
                    <line x1="8" y1="9" x2="16" y2="9" />   
                    <line x1="8" y1="13" x2="14" y2="13" />
                </>
            )
        case "message-circle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />   
                    <line x1="12" y1="12" x2="12" y2="12.01" />   
                    <line x1="8" y1="12" x2="8" y2="12.01" />   
                    <line x1="16" y1="12" x2="16" y2="12.01" />
                </>
            )
        case "message-dots": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />   
                    <line x1="12" y1="11" x2="12" y2="11.01" />   
                    <line x1="8" y1="11" x2="8" y2="11.01" />   
                    <line x1="16" y1="11" x2="16" y2="11.01" />
                </>
            )
        case "message": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />   
                    <line x1="8" y1="9" x2="16" y2="9" />   
                    <line x1="8" y1="13" x2="14" y2="13" />
                </>
            )
        case "messages": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />   
                    <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
                </>
            )
        case "microphone": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="9" y="2" width="6" height="11" rx="3" />   
                    <path d="M5 10a7 7 0 0 0 14 0" />   
                    <line x1="8" y1="21" x2="16" y2="21" />   
                    <line x1="12" y1="17" x2="12" y2="21" />
                </>
            )
        case "minimize": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 19v-2a2 2 0 0 1 2 -2h2" />   
                    <path d="M15 5v2a2 2 0 0 0 2 2h2" />   
                    <path d="M5 15h2a2 2 0 0 1 2 2v2" />   
                    <path d="M5 9h2a2 2 0 0 0 2 -2v-2" />
                </>
            )
        case "minus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="5" y1="12" x2="19" y2="12" />
                </>
            )
        case "mist": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 5h3m4 0h9" />   
                    <path d="M3 10h11m4 0h1" />   
                    <path d="M5 15h5m4 0h7" />   
                    <path d="M3 20h9m4 0h3" />
                </>
            )
        case "mood-confuzed": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="10" x2="9.01" y2="10" />   
                    <line x1="15" y1="10" x2="15.01" y2="10" />   
                    <path d="M9.5 16a10 10 0 0 1 6 -1.5" />
                </>
            )
        case "mood-happy": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="9" x2="9.01" y2="9" />   
                    <line x1="15" y1="9" x2="15.01" y2="9" />   
                    <path d="M8 13a4 4 0 1 0 8 0m0 0H8" />
                </>
            )
        case "mood-kid": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="10" x2="9.01" y2="10" />   
                    <line x1="15" y1="10" x2="15.01" y2="10" />   
                    <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />   
                    <path d="M12 3a2 2 0 0 0 0 4" />
                </>
            )
        case "mood-neutral": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="10" x2="9.01" y2="10" />   
                    <line x1="15" y1="10" x2="15.01" y2="10" />   
                    <line x1="9" y1="15" x2="15" y2="15" />
                </>
            )
        case "mood-sad": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="10" x2="9.01" y2="10" />   
                    <line x1="15" y1="10" x2="15.01" y2="10" />   
                    <path d="M9.5 15.25a3.5 3.5 0 0 1 5 0" />
                </>
            )
        case "mood-smile": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="10" x2="9.01" y2="10" />   
                    <line x1="15" y1="10" x2="15.01" y2="10" />   
                    <path d="M9.5 15a3.5 3.5 0 0 0 5 0" />
                </>
            )
        case "mood-suprised": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="9" x2="9.01" y2="9" />   
                    <line x1="15" y1="9" x2="15.01" y2="9" />   
                    <circle cx="12" cy="15" r="2" />
                </>
            )
        case "mood-tongue": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="9" y1="10" x2="9.01" y2="10" />   
                    <line x1="15" y1="10" x2="15.01" y2="10" />   
                    <path d="M10 14v2a2 2 0 0 0 4 0v-2m1.5 0h-7" />
                </>
            )
        case "moon": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16.2 4a9.03 9.03 0 1 0 3.9 12a6.5 6.5 0 1 1 -3.9 -12" />
                </>
            )
        case "mouse": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="7" y="4" width="10" height="16" rx="4" />   
                    <line x1="12" y1="8" x2="12" y2="11" />
                </>
            )
        case "movie": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <line x1="8" y1="4" x2="8" y2="20" />   
                    <line x1="16" y1="4" x2="16" y2="20" />   
                    <line x1="4" y1="8" x2="8" y2="8" />   
                    <line x1="4" y1="16" x2="8" y2="16" />   
                    <line x1="4" y1="12" x2="20" y2="12" />   
                    <line x1="16" y1="8" x2="20" y2="8" />   
                    <line x1="16" y1="16" x2="20" y2="16" />
                </>
            )
        case "mug": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 6h10a1 1 0 0 1 1 1v8a4 4 0 0 1 -4 4h-4a4 4 0 0 1 -4 -4v-8a1 1 0 0 1 1 -1" />   
                    <path d="M16 9h2a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-2" />
                </>
            )
        case "music": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="6" cy="17" r="3" />   
                    <circle cx="16" cy="17" r="3" />   
                    <polyline points="9 17 9 4 19 4 19 17" />   
                    <line x1="9" y1="8" x2="19" y2="8" />
                </>
            )
        case "new-section": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="9" y1="12" x2="15" y2="12" />   
                    <line x1="12" y1="9" x2="12" y2="15" />   
                    <path d="M4 6v-1a1 1 0 0 1 1 -1h1m5 0h2m5 0h1a1 1 0 0 1 1 1v1m0 5v2m0 5v1a1 1 0 0 1 -1 1h-1m-5 0h-2m-5 0h-1a1 1 0 0 1 -1 -1v-1m0 -5v-2m0 -5" />
                </>
            )
        case "news": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />   
                    <line x1="8" y1="8" x2="12" y2="8" />   
                    <line x1="8" y1="12" x2="12" y2="12" />   
                    <line x1="8" y1="16" x2="12" y2="16" />
                </>
            )
        case "note": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="13" y1="20" x2="20" y2="13" />   
                    <path d="M13 20v-6a1 1 0 0 1 1 -1h6v-7a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7" />
                </>
            )
        case "notebook": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />   
                    <line x1="13" y1="8" x2="15" y2="8" />   
                    <line x1="13" y1="12" x2="15" y2="12" />
                </>
            )
        case "notes": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="5" y="3" width="14" height="18" rx="2" />   
                    <line x1="9" y1="7" x2="15" y2="7" />   
                    <line x1="9" y1="11" x2="15" y2="11" />   
                    <line x1="9" y1="15" x2="13" y2="15" />
                </>
            )
        case "notification": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />   
                    <circle cx="16" cy="8" r="3" />
                </>
            )
        case "omega": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 19h5v-1a7.35 7.35 0 1 1 6 0v1h5" />
                </>
            )
        case "outlet": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <circle cx="9" cy="12" r=".5" fill="currentColor" />   
                    <circle cx="15" cy="12" r=".5" fill="currentColor" />
                </>
            )
        case "package": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" />   
                    <line x1="12" y1="12" x2="20" y2="7.5" />   
                    <line x1="12" y1="12" x2="12" y2="21" />   
                    <line x1="12" y1="12" x2="4" y2="7.5" />   
                    <line x1="16" y1="5.25" x2="8" y2="9.75" />
                </>
            )
        case "paint": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="5" y="3" width="14" height="6" rx="2" />   
                    <path d="M19 6h1a2 2 0 0 1 2 2a5 5 0 0 1 -5 5l-5 0v2" />   
                    <rect x="10" y="15" width="4" height="6" rx="1" />
                </>
            )
        case "palette": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12 21a9 9 0 1 1 0 -18a9 8 0 0 1 9 8a4.5 4 0 0 1 -4.5 4h-2.5a2 2 0 0 0 -1 3.75a1.3 1.3 0 0 1 -1 2.25" />   
                    <circle cx="7.5" cy="10.5" r=".5" fill="currentColor" />   
                    <circle cx="12" cy="7.5" r=".5" fill="currentColor" />   
                    <circle cx="16.5" cy="10.5" r=".5" fill="currentColor" />
                </>
            )
        case "paperclip": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9 l6.5 -6.5" />
                </>
            )
        case "parentheses": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 4a12.25 12.25 0 0 0 0 16" />   
                    <path d="M17 4a12.25 12.25 0 0 1 0 16" />
                </>
            )
        case "parking": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <path d="M9 16v-8h4a2 2 0 0 1 0 4h-4" />
                </>
            )
        case "pencil": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />   
                    <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                </>
            )
        case "phone-call": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />   
                    <path d="M15 7a2 2 0 0 1 2 2" />   
                    <path d="M15 3a6 6 0 0 1 6 6" />
                </>
            )
        case "phone-check": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />   
                    <path d="M15 6l2 2l4 -4" />
                </>
            )
        case "phone-incoming": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />   
                    <line x1="15" y1="9" x2="20" y2="4" />   
                    <polyline points="15 5 15 9 19 9" />
                </>
            )
        case "phone-outgoing": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />   
                    <line x1="15" y1="9" x2="20" y2="4" />   
                    <polyline points="16 4 20 4 20 8" />
                </>
            )
        case "phone-pause": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />   
                    <line x1="20" y1="3" x2="20" y2="7" />   
                    <line x1="16" y1="3" x2="16" y2="7" />
                </>
            )
        case "phone-plus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />   
                    <path d="M15 6h6m-3 -3v6" />
                </>
            )
        case "phone-x": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />   
                    <path d="M16 4l4 4m0 -4l-4 4" />
                </>
            )
        case "phone": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                </>
            )
        case "photo": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="15" y1="8" x2="15.01" y2="8" />   
                    <rect x="4" y="4" width="16" height="16" rx="3" />   
                    <path d="M4 15l4 -4a3 5 0 0 1 3 0l 5 5" />   
                    <path d="M14 14l1 -1a3 5 0 0 1 3 0l 2 2" />
                </>
            )
        case "pillow": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7" />   
                    <path d="M8.5 8.5l7 7" />
                </>
            )
        case "plane-arrival": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 12h5a2 2 0 0 1 0 4h-15l-3 -6h3l2 2h3l-2 -7h3z" transform="rotate(15 12 12) translate(0 -1)" />   
                    <line x1="3" y1="21" x2="21" y2="21" />
                </>
            )
        case "plane-departure": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 12h5a2 2 0 0 1 0 4h-15l-3 -6h3l2 2h3l-2 -7h3z" transform="rotate(-15 12 12) translate(0 -1)" />   
                    <line x1="3" y1="21" x2="21" y2="21" />
                </>
            )
        case "plane": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16 10h4a2 2 0 0 1 0 4h-4l-4 7h-3l2 -7h-4l-2 2h-3l2-4l-2 -4h3l2 2h4l-2 -7h3z" />
                </>
            )
        case "planet": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M17.905 13.057c2.208 1.756 3.436 3.308 3.012 4.035-.67 1.146-5.204-.204-10.129-3.016-4.924-2.812-8.374-6.022-7.705-7.168.418-.716 2.347-.458 4.936.524" />   
                    <circle cx="12" cy="12" r="6" />
                </>
            )
        case "plug": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 7h10v6a3 3 0 0 1 -3 3h-4a3 3 0 0 1 -3 -3v-6" />   
                    <line x1="9" y1="3" x2="9" y2="7" />   
                    <line x1="15" y1="3" x2="15" y2="7" />   
                    <path d="M12 16v2a2 2 0 0 0 2 2h3" />
                </>
            )
        case "plus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="5" x2="12" y2="19" />   
                    <line x1="5" y1="12" x2="19" y2="12" />
                </>
            )
        case "point": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="4" />
                </>
            )
        case "power": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 6a7.75 7.75 0 1 0 10 0" />   
                    <line x1="12" y1="4" x2="12" y2="12" />
                </>
            )
        case "presentation": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="4" x2="21" y2="4" />   
                    <path d="M4 4v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-10" />   
                    <line x1="12" y1="16" x2="12" y2="20" />   
                    <line x1="9" y1="20" x2="15" y2="20" />   
                    <path d="M8 12l3 -3l2 2l3 -3" />
                </>
            )
        case "printer": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />   
                    <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />   
                    <rect x="7" y="13" width="10" height="8" rx="2" />
                </>
            )
        case "prompt": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="5 7 10 12 5 17" />   
                    <line x1="13" y1="17" x2="19" y2="17" />
                </>
            )
        case "puzzle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1" />
                </>
            )
        case "qrcode": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="6" height="6" rx="1" />   
                    <line x1="7" y1="17" x2="7" y2="17.01" />   
                    <rect x="14" y="4" width="6" height="6" rx="1" />   
                    <line x1="7" y1="7" x2="7" y2="7.01" />   
                    <rect x="4" y="14" width="6" height="6" rx="1" />   
                    <line x1="17" y1="7" x2="17" y2="7.01" />   
                    <line x1="14" y1="14" x2="17" y2="14" />   
                    <line x1="20" y1="14" x2="20" y2="14.01" />   
                    <line x1="14" y1="14" x2="14" y2="17" />   
                    <line x1="14" y1="20" x2="17" y2="20" />   
                    <line x1="17" y1="17" x2="20" y2="17" />   
                    <line x1="20" y1="17" x2="20" y2="20" />
                </>
            )
        case "record-mail": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="7" cy="12" r="3" />   
                    <circle cx="17" cy="12" r="3" />   
                    <line x1="7" y1="15" x2="17" y2="15" />
                </>
            )
        case "recycle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12 17l-2 2l2 2m-2 -2h9a2 2 0 0 0 1.75 -2.75l-.55 -1" />   
                    <path d="M12 17l-2 2l2 2m-2 -2h9a2 2 0 0 0 1.75 -2.75l-.55 -1" transform="rotate(120 12 13)" />   
                    <path d="M12 17l-2 2l2 2m-2 -2h9a2 2 0 0 0 1.75 -2.75l-.55 -1" transform="rotate(240 12 13)" />
                </>
            )
        case "refresh": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -5v5h5" />   
                    <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 5v-5h-5" />
                </>
            )
        case "registered": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <path d="M9 16v-8h4a2 2 0 0 1 0 4h-4m3 0l3 4" />
                </>
            )
        case "repeat-once": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 12v-3a3 3 0 0 1 3 -3h13m-3 -3l3 3l-3 3" />   
                    <path d="M20 12v3a3 3 0 0 1 -3 3h-13m3 3l-3-3l3-3" />   
                    <path d="M11 11l1 -1v4" />
                </>
            )
        case "repeat": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 12v-3a3 3 0 0 1 3 -3h13m-3 -3l3 3l-3 3" />   
                    <path d="M20 12v3a3 3 0 0 1 -3 3h-13m3 3l-3-3l3-3" />
                </>
            )
        case "replace": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="3" width="6" height="6" rx="1" />   
                    <rect x="15" y="15" width="6" height="6" rx="1" />   
                    <path d="M21 11v-3a2 2 0 0 0 -2 -2h-6l3 3m0 -6l-3 3" />   
                    <path d="M3 13v3a2 2 0 0 0 2 2h6l-3 -3m0 6l3 -3" />
                </>
            )
        case "rotate-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 4.55a8 8 0 0 0 -6 14.9m0 -4.45v5h-5" />   
                    <path d="M13 19.95a8 8 0 0 0 5.3 -12.8" stroke-dasharray=".001 4.13" />
                </>
            )
        case "rotate-clockwise-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />   
                    <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" />
                </>
            )
        case "rotate-clockwise": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4.05 11a8 8 0 1 1 .5 4m-.5 5v-5h5" />
                </>
            )
        case "rotate-rectangle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16.3 5h.7a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h5l-2.82 -2.82m0 5.64l2.82 -2.82" transform="rotate(-45 12 12)" />
                </>
            )
        case "rotate": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M19.95 11a8 8 0 1 0 -.5 4m.5 5v-5h-5" />
                </>
            )
        case "route": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="6" cy="19" r="2" />   
                    <circle cx="18" cy="5" r="2" />   
                    <path d="M12 19h4.5a3.5 3.5 0 0 0 0 -7h-8a3.5 3.5 0 0 1 0 -7h3.5" />
                </>
            )
        case "router": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="13" width="18" height="8" rx="2" />   
                    <line x1="17" y1="17" x2="17" y2="17.01" />   
                    <line x1="13" y1="17" x2="13" y2="17.01" />   
                    <line x1="15" y1="13" x2="15" y2="11" />   
                    <path d="M11.75 8.75a4 4 0 0 1 6.5 0" />   
                    <path d="M8.5 6.5a8 8 0 0 1 13 0" />
                </>
            )
        case "rss": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="5" cy="19" r="1" />   
                    <path d="M4 4a16 16 0 0 1 16 16" />   
                    <path d="M4 11a9 9 0 0 1 9 9" />
                </>
            )
        case "ruler": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 4h14a1 1 0 0 1 1 1v5a1 1 0 0 1 -1 1h-7a1 1 0 0 0 -1 1v7a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1" />   
                    <line x1="4" y1="8" x2="6" y2="8" />   
                    <line x1="4" y1="12" x2="7" y2="12" />   
                    <line x1="4" y1="16" x2="6" y2="16" />   
                    <line x1="8" y1="4" x2="8" y2="6" />   
                    <polyline points="12 4 12 7 " />   
                    <polyline points="16 4 16 6 " />
                </>
            )
        case "scale": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="3" x2="12" y2="21" />   
                    <line x1="9" y1="21" x2="15" y2="21" />   
                    <polyline points="3 6 6 7 12 5 18 7 21 6" />   
                    <path d="M6 7l-3 9a5 5 0 0 0 6 0l-3 -9" />   
                    <path d="M18 7l-3 9a5 5 0 0 0 6 0l-3 -9" />
                </>
            )
        case "scan": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 7v-1a2 2 0 0 1 2 -2h2" />   
                    <path d="M4 17v1a2 2 0 0 0 2 2h2" />   
                    <path d="M16 4h2a2 2 0 0 1 2 2v1" />   
                    <path d="M16 20h2a2 2 0 0 0 2 -2v-1" />   
                    <line x1="5" y1="12" x2="19" y2="12" />
                </>
            )
        case "scissors": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="6" cy="7" r="3" />   
                    <circle cx="6" cy="17" r="3" />   
                    <line x1="8.6" y1="8.6" x2="19" y2="19" />   
                    <line x1="8.6" y1="15.4" x2="19" y2="5" />
                </>
            )
        case "search": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="10" cy="10" r="7" />   
                    <line x1="21" y1="21" x2="15" y2="15" />
                </>
            )
        case "selector": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="8 9 12 5 16 9" />   
                    <polyline points="16 15 12 19 8 15" />
                </>
            )
        case "send": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="10" y1="14" x2="21" y2="3" />   
                    <path d="M21 3L14.5 21a.55 .55 0 0 1 -1 0L10 14L3 10.5a.55 .55 0 0 1 0 -1L21 3" />
                </>
            )
        case "separator": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="3" y1="12" x2="3" y2="12.01" />   
                    <line x1="7" y1="12" x2="17" y2="12" />   
                    <line x1="21" y1="12" x2="21" y2="12.01" />
                </>
            )
        case "server": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="4" width="18" height="8" rx="3" />   
                    <rect x="3" y="12" width="18" height="8" rx="3" />   
                    <line x1="7" y1="8" x2="7" y2="8.01" />   
                    <line x1="7" y1="16" x2="7" y2="16.01" />
                </>
            )
        case "servicemark": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 9h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" />   
                    <path d="M13 15v-6l3 4l3 -4v6" />
                </>
            )
        case "settings": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />   
                    <circle cx="12" cy="12" r="3" />
                </>
            )
        case "shape": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="6" cy="6" r="2" />   
                    <circle cx="18" cy="6" r="2" />   
                    <circle cx="6" cy="18" r="2" />   
                    <circle cx="18" cy="18" r="2" />   
                    <line x1="6" y1="8" x2="6" y2="16" />   
                    <line x1="8" y1="6" x2="16" y2="6" />   
                    <line x1="8" y1="18" x2="16" y2="18" />   
                    <line x1="18" y1="8" x2="18" y2="16" />
                </>
            )
        case "share": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="6" cy="12" r="3" />   
                    <circle cx="18" cy="6" r="3" />   
                    <circle cx="18" cy="18" r="3" />   
                    <line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />   
                    <line x1="8.7" y1="13.3" x2="15.3" y2="16.7" />
                </>
            )
        case "shield-check": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M9 12l2 2l4 -4" />   
                    <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
                </>
            )
        case "shield-x": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />   
                    <path d="M10 10l4 4m0 -4l-4 4" />
                </>
            )
        case "shield": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
                </>
            )
        case "shirt": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 4l6 2v5h-3v8a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1v-8h-3v-5l6 -2a3 3 0 0 0 6 0" />
                </>
            )
        case "shopping-cart": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="9" cy="19" r="2" />   
                    <circle cx="17" cy="19" r="2" />   
                    <path d="M3 3h2l2 12a3 3 0 0 0 3 2h7a3 3 0 0 0 3 -2l1 -7h-15.2" />
                </>
            )
        case "sitemap": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="3" y="15" width="6" height="6" rx="2" />   
                    <rect x="15" y="15" width="6" height="6" rx="2" />   
                    <rect x="9" y="3" width="6" height="6" rx="2" />   
                    <path d="M6 15v-1a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v1" />   
                    <line x1="12" y1="9" x2="12" y2="12" />
                </>
            )
        case "slice": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 19l15 -15l3 3l-6 6l2 2a14 14 0 0 1 -14 4" />
                </>
            )
        case "slideshow": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="15" y1="6" x2="15.01" y2="6" />   
                    <rect x="3" y="3" width="18" height="14" rx="3" />   
                    <path d="M3 13l4 -4a3 5 0 0 1 3 0l 4 4" />   
                    <path d="M13 12l2 -2a3 5 0 0 1 3 0l 3 3" />   
                    <line x1="8" y1="21" x2="8.01" y2="21" />   
                    <line x1="12" y1="21" x2="12.01" y2="21" />   
                    <line x1="16" y1="21" x2="16.01" y2="21" />
                </>
            )
        case "snowflake": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>      <path d="M10 4l2 1l2 -1m-2 -2v6.5l3 1.72" />      <path d="M10 4l2 1l2 -1m-2 -2v6.5l3 1.72" transform="rotate(60 12 12)" />      <path d="M10 4l2 1l2 -1m-2 -2v6.5l3 1.72" transform="rotate(120 12 12)" />      <path d="M10 4l2 1l2 -1m-2 -2v6.5l3 1.72" transform="rotate(180 12 12)" />      <path d="M10 4l2 1l2 -1m-2 -2v6.5l3 1.72" transform="rotate(240 12 12)" />      <path d="M10 4l2 1l2 -1m-2 -2v6.5l3 1.72" transform="rotate(300 12 12)" />   
                    </>)
        case "social": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="5" r="2" />   
                    <circle cx="5" cy="19" r="2" />   
                    <circle cx="19" cy="19" r="2" />   
                    <circle cx="12" cy="14" r="3" />   
                    <line x1="12" y1="7" x2="12" y2="11" />   
                    <path d="M6.7 17.8l2.8 -2" />   
                    <path d="M17.3 17.8l-2.8 -2" />
                </>
            )
        case "sort-ascending": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="6" x2="11" y2="6" />   
                    <line x1="4" y1="12" x2="11" y2="12" />   
                    <line x1="4" y1="18" x2="13" y2="18" />   
                    <polyline points="15 9 18 6 21 9" />   
                    <line x1="18" y1="6" x2="18" y2="18" />
                </>
            )
        case "sort-descending": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="6" x2="13" y2="6" />   
                    <line x1="4" y1="12" x2="11" y2="12" />   
                    <line x1="4" y1="18" x2="11" y2="18" />   
                    <polyline points="15 15 18 18 21 15" />   
                    <line x1="18" y1="6" x2="18" y2="18" />
                </>
            )
        case "space": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 10v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1 -1v-3" />
                </>
            )
        case "square-check": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <path d="M9 12l2 2l4 -4" />
                </>
            )
        case "square-minus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <line x1="9" y1="12" x2="15" y2="12" />
                </>
            )
        case "square-plus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <line x1="9" y1="12" x2="15" y2="12" />   
                    <line x1="12" y1="9" x2="12" y2="15" />
                </>
            )
        case "square-x": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <path d="M10 10l4 4m0 -4l-4 4" />
                </>
            )
        case "square": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />
                </>
            )
        case "stack": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="12 4 4 8 12 12 20 8 12 4" />   
                    <polyline points="4 12 12 16 20 12" />   
                    <polyline points="4 16 12 20 20 16" />
                </>
            )
        case "star": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M12 17.75l-6.172 3.245 1.179-6.873-4.993-4.867 6.9-1.002L12 2l3.086 6.253 6.9 1.002-4.993 4.867 1.179 6.873z" />
                </>
            )
        case "sticker": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M20 12l-2 .5a6 6 0 0 1 -6.5 -6.5l.5 -2l8 8" />   
                    <path d="M20 12a8 8 0 1 1 -8 -8" />
                </>
            )
        case "strikethrough": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="5" y1="12" x2="19" y2="12" />   
                    <path d="M16 6.5a4 2 0 0 0 -4 -1.5h-1a3.5 3.5 0 0 0 0 7" />   
                    <path d="M16.5 16a3.5 3.5 0 0 1 -3.5 3h-1.5a4 2 0 0 1 -4 -1.5" />
                </>
            )
        case "subscript": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 7l8 10m-8 0l8 -10" />   
                    <path d="M21 20h-4l3.5 -4a1.73 1.73 0 0 0 -3.5 -2" />
                </>
            )
        case "sum": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M18 16v2a1 1 0 0 1 -1 1h-11l6-7l-6-7h11a1 1 0 0 1 1 1v2" />
                </>
            )
        case "sun": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="4" />   
                    <path d="M3 12h1M12 3v1M20 12h1M12 20v1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7M17.7 17.7l.7 .7M6.3 17.7l-.7 .7" />
                </>
            )
        case "sunset": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />   
                    <line x1="3" y1="21" x2="21" y2="21" />   
                    <path d="M12 9v-6l3 3m-6 0l3 -3" />
                </>
            )
        case "sunshine": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />   
                    <line x1="3" y1="21" x2="21" y2="21" />   
                    <path d="M12 3v6l3 -3m-6 0l3 3" />
                </>
            )
        case "superscript": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 7l8 10m-8 0l8 -10" />   
                    <path d="M21 11h-4l3.5 -4a1.73 1.73 0 0 0 -3.5 -2" />
                </>
            )
        case "switch-horizontal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="16 3 20 7 16 11" />   
                    <line x1="10" y1="7" x2="20" y2="7" />   
                    <polyline points="8 13 4 17 8 21" />   
                    <line x1="4" y1="17" x2="13" y2="17" />
                </>
            )
        case "switch-vertical": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="3 8 7 4 11 8" />   
                    <line x1="7" y1="4" x2="7" y2="13" />   
                    <polyline points="13 16 17 20 21 16" />   
                    <line x1="17" y1="10" x2="17" y2="20" />
                </>
            )
        case "switch": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="15 4 19 4 19 8" />   
                    <line x1="14.75" y1="9.25" x2="19" y2="4" />   
                    <line x1="5" y1="19" x2="9" y2="15" />   
                    <polyline points="15 19 19 19 19 15" />   
                    <line x1="5" y1="5" x2="19" y2="19" />
                </>
            )
        case "table": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="16" rx="2" />   
                    <line x1="4" y1="10" x2="20" y2="10" />   
                    <line x1="10" y1="4" x2="10" y2="20" />
                </>
            )
        case "tag": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M11 3L20 12a1.5 1.5 0 0 1 0 2L14 20a1.5 1.5 0 0 1 -2 0L3 11v-4a4 4 0 0 1 4 -4h4" />   
                    <circle cx="9" cy="9" r="2" />
                </>
            )
        case "target": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="1" />   
                    <circle cx="12" cy="12" r="5" />   
                    <circle cx="12" cy="12" r="9" />
                </>
            )
        case "tax": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="9" y1="14" x2="15" y2="8" />   
                    <circle cx="9.5" cy="8.5" r=".5" fill="currentColor" />   
                    <circle cx="14.5" cy="13.5" r=".5" fill="currentColor" />   
                    <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2" />
                </>
            )
        case "temperature-celsius": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="6" cy="8" r="2" />   
                    <path d="M20 9a3 3 0 0 0 -3 -3h-1a3 3 0 0 0 -3 3v6a3 3 0 0 0 3 3h1a3 3 0 0 0 3 -3" />
                </>
            )
        case "temperature-fahrenheit": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="6" cy="8" r="2" />   
                    <line x1="13" y1="12" x2="18" y2="12" />   
                    <path d="M20 6h-6a1 1 0 0 0 -1 1v11" />
                </>
            )
        case "temperature-minus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M8 13.5a4 4 0 1 0 4 0v-8.5a2 2 0 0 0 -4 0v8.5" />   
                    <line x1="8" y1="9" x2="12" y2="9" />   
                    <line x1="16" y1="9" x2="22" y2="9" />
                </>
            )
        case "temperature-plus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M8 13.5a4 4 0 1 0 4 0v-8.5a2 2 0 0 0 -4 0v8.5" />   
                    <line x1="8" y1="9" x2="12" y2="9" />   
                    <line x1="16" y1="9" x2="22" y2="9" />   
                    <line x1="19" y1="6" x2="19" y2="12" />
                </>
            )
        case "temperature": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M10 13.5a4 4 0 1 0 4 0v-8.5a2 2 0 0 0 -4 0v8.5" />   
                    <line x1="10" y1="9" x2="14" y2="9" />
                </>
            )
        case "template": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="4" width="16" height="4" rx="1" />   
                    <rect x="4" y="12" width="6" height="8" rx="1" />   
                    <line x1="14" y1="12" x2="20" y2="12" />   
                    <line x1="14" y1="16" x2="20" y2="16" />   
                    <line x1="14" y1="20" x2="20" y2="20" />
                </>
            )
        case "terminal-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M8 9l3 3l-3 3" />   
                    <line x1="13" y1="15" x2="16" y2="15" />   
                    <rect x="3" y="4" width="18" height="16" rx="2" />
                </>
            )
        case "terminal": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 7l5 5l-5 5" />   
                    <line x1="12" y1="19" x2="19" y2="19" />
                </>
            )
        case "test-pipe": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M16 4l3 3l-12.35 12.35a1.5 1.5 0 0 1 -3 -3l12.35 -12.35" />   
                    <line x1="10" y1="10" x2="16" y2="10" />   
                    <path d="M19 15l1.5 1.6a2 2 0 1 1 -3 0l1.5 -1.6" />
                </>
            )
        case "test": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <image href="https://static.tildacdn.com/tild3432-3932-4036-b734-633738333636/Myicons__part_2line_.png"  width="111" x="-43.5" y="-27.75" opacity=".3"></image>   
                    <path d="M8 13.5v-8a1.5 1.5 0 0 1 3 0v6.5m0 -6.5v-2a1.5 1.5 0 0 1 3 0v8.5m0 -6.5a1.5 1.5 0 0 1 3 0v6.5m0 -4.5a1.5 1.5 0 0 1 3 0v8.5a6 6 0 0 1 -6 6h-2a7 6 0 0 1 -5 -3l-3 -4.5a1.4 1.4 0 0 1 2.5 -2.5l1.5 1.5" />
                </>
            )
        case "text-wrap": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="6" x2="20" y2="6" />   
                    <line x1="4" y1="18" x2="9" y2="18" />   
                    <path d="M4 12h13a3 3 0 0 1 0 6h-4l2 -2m0 4l-2 -2" />
                </>
            )
        case "thumb-down": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 13v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h3a4 4 0 0 1 4 4v 1a2 2 0 0 0 4 0v-5h3a2 2 0 0 0 2 -2l-1 -5a2 3 0 0 0 -2 -2h-7a3 3 0 0 0 -3 3" />
                </>
            )
        case "thumb-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 11v 8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
                </>
            )
        case "ticket": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="15" y1="5" x2="15" y2="7" />   
                    <line x1="15" y1="11" x2="15" y2="13" />   
                    <line x1="15" y1="17" x2="15" y2="19" />   
                    <path d="M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2" />
                </>
            )
        case "tir": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="5" cy="17" r="2" />   
                    <circle cx="17" cy="17" r="2" />   
                    <path d="M7 18h8m4 0h2v-6a5 7 0 0 0 -5 -7h-1l1.5 7h4.5" />   
                    <path d="M12 18v-13h3" />   
                    <polyline points="3 17 3 12 12 12" />
                </>
            )
        case "toggle-left": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="8" cy="12" r="2" />   
                    <rect x="2" y="6" width="20" height="12" rx="6" />
                </>
            )
        case "toggle-right": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="16" cy="12" r="2" />   
                    <rect x="2" y="6" width="20" height="12" rx="6" />
                </>
            )
        case "tool": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6-6a6 6 0 0 1 -8 -8l3.5 3.5" />
                </>
            )
        case "tools": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 21h4l13 -13a1.5 1.5 0 0 0 -4 -4l-13 13v4" />   
                    <line x1="14.5" y1="5.5" x2="18.5" y2="9.5" />   
                    <polyline points="12 8 7 3 3 7 8 12" />   
                    <line x1="7" y1="8" x2="5.5" y2="9.5" />   
                    <polyline points="16 12 21 17 17 21 12 16" />   
                    <line x1="16" y1="17" x2="14.5" y2="18.5" />
                </>
            )
        case "tractor": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="7" cy="15" r="4" />   
                    <line x1="7" y1="15" x2="7" y2="15.01" />   
                    <circle cx="19" cy="17" r="2" />   
                    <path d="M10.5 17h6.5" />   
                    <path d="M20 15.2v-4.2a1 1 0 0 0 -1 -1h-6l-2 -5h-6v6.5" />   
                    <path d="M18 5h-1a1 1 0 0 0 -1 1v4" />
                </>
            )
        case "trademark": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4.5 9 h5m-2.5 0v6" />   
                    <path d="M13 15v-6l3 4l3 -4v6" />
                </>
            )
        case "traffic-cone": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="20" x2="20" y2="20" />   
                    <line x1="9.4" y1="10" x2="14.6" y2="10" />   
                    <line x1="7.8" y1="15" x2="16.2" y2="15" />   
                    <path d="M6 20l5 -15h2l5 15" />
                </>
            )
        case "trash": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="7" x2="20" y2="7" />   
                    <line x1="10" y1="11" x2="10" y2="17" />   
                    <line x1="14" y1="11" x2="14" y2="17" />   
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />   
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                </>
            )
        case "trees": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <rect x="4" y="3" width="8" height="14" rx="4" />   
                    <rect x="12" y="7" width="8" height="10" rx="3" />   
                    <line x1="8" y1="21" x2="8" y2="13" />   
                    <line x1="16" y1="21" x2="16" y2="14" />
                </>
            )
        case "trending-down": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="3 7 9 13 13 9 21 17" />   
                    <polyline points="21 10 21 17 14 17" />
                </>
            )
        case "trending-up": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="3 17 9 11 13 15 21 7" />   
                    <polyline points="14 7 21 7 21 14" />
                </>
            )
        case "triangle": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5.07 19H19a2 2 0 0 0 1.75 -2.75L13.75 4a2 2 0 0 0 -3.5 0L3.25 16.25a2 2 0 0 0 1.75 2.75" />
                </>
            )
        case "trophy": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="8" y1="21" x2="16" y2="21" />   
                    <line x1="12" y1="17" x2="12" y2="21" />   
                    <line x1="7" y1="4" x2="17" y2="4" />   
                    <path d="M17 4v8a5 5 0 0 1 -10 0v-8" />   
                    <circle cx="5" cy="9" r="2" />   
                    <circle cx="19" cy="9" r="2" />
                </>
            )
        case "truck": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="7" cy="17" r="2" />   
                    <circle cx="17" cy="17" r="2" />   
                    <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" />
                </>
            )
        case "typography": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="4" y1="20" x2="7" y2="20" />   
                    <line x1="14" y1="20" x2="21" y2="20" />   
                    <line x1="6.9" y1="15" x2="13.8" y2="15" />   
                    <line x1="10.2" y1="6.3" x2="16" y2="20" />   
                    <polyline points="5 20 11 4 13 4 20 20" />
                </>
            )
        case "umbrella": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 12a8 8 0 0 1 16 0Z" />   
                    <path d="M12 12v6a2 2 0 0 0 4 0" />
                </>
            )
        case "underline": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="6" y1="20" x2="18" y2="20" />   
                    <path d="M8 5v6a4 4 0 0 0 8 0v-6" />
                </>
            )
        case "unlink": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />   
                    <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />   
                    <line x1="16" y1="21" x2="16" y2="19" />   
                    <line x1="19" y1="16" x2="21" y2="16" />   
                    <line x1="3" y1="8" x2="5" y2="8" />   
                    <line x1="8" y1="3" x2="8" y2="5" />
                </>
            )
        case "upload": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />   
                    <polyline points="7 9 12 4 17 9" />   
                    <line x1="12" y1="4" x2="12" y2="16" />
                </>
            )
        case "urgent": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M8 16v-4a4 4 0 0 1 8 0v4" />   
                    <path d="M3 12h1M12 3v1M20 12h1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7" />   
                    <rect x="6" y="16" width="12" height="4" rx="1" />
                </>
            )
        case "user-check": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="9" cy="7" r="4" />   
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />   
                    <path d="M16 11l2 2l4 -4" />
                </>
            )
        case "user-exclamation": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="9" cy="7" r="4" />   
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />   
                    <line x1="19" y1="7" x2="19" y2="10" />   
                    <line x1="19" y1="14" x2="19" y2="14.01" />
                </>
            )
        case "user-minus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="9" cy="7" r="4" />   
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />   
                    <line x1="16" y1="11" x2="22" y2="11" />
                </>
            )
        case "user-plus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="9" cy="7" r="4" />   
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />   
                    <path d="M16 11h6m-3 -3v6" />
                </>
            )
        case "user-x": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="9" cy="7" r="4" />   
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />   
                    <path d="M17 9l4 4m0 -4l-4 4" />
                </>
            )
        case "user": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="7" r="4" />   
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </>
            )
        case "users": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="9" cy="7" r="4" />   
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />   
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />   
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                </>
            )
        case "viewfinder": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="12" y1="3" x2="12" y2="7" />   
                    <line x1="12" y1="21" x2="12" y2="18" />   
                    <line x1="3" y1="12" x2="7" y2="12" />   
                    <line x1="21" y1="12" x2="18" y2="12" />   
                    <line x1="12" y1="12" x2="12" y2="12.01" />
                </>
            )
        case "viewport-narrow": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M3 12h7l-3 -3m0 6l3 -3" />   
                    <path d="M21 12h-7l3 -3m0 6l-3 -3" />   
                    <path d="M9 6v-3h6v3" />   
                    <path d="M9 18v3h6v-3" />
                </>
            )
        case "viewport-wide": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M10 12h-7l3 -3m0 6l-3 -3" />   
                    <path d="M14 12h7l-3 -3m0 6l3 -3" />   
                    <path d="M3 6v-3h18v3" />   
                    <path d="M3 18v3h18v-3" />
                </>
            )
        case "virus": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="5" />   
                    <path d="M12 7v-4m-1 0h2" />   
                    <path d="M12 7v-4m-1 0h2" transform="rotate(45 12 12)" />   
                    <path d="M12 7v-4m-1 0h2" transform="rotate(90 12 12)" />   
                    <path d="M12 7v-4m-1 0h2" transform="rotate(135 12 12)" />   
                    <path d="M12 7v-4m-1 0h2" transform="rotate(180 12 12)" />   
                    <path d="M12 7v-4m-1 0h2" transform="rotate(225 12 12)" />   
                    <path d="M12 7v-4m-1 0h2" transform="rotate(270 12 12)" />   
                    <path d="M12 7v-4m-1 0h2" transform="rotate(315 12 12)" />
                </>
            )
        case "volume-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 8a5 5 0 0 1 0 8" />   
                    <path d="M6 15 h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a.8 .8 0 0 1 1.5 .5v14a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5" />
                </>
            )
        case "volume-3": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M6 15 h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a.8 .8 0 0 1 1.5 .5v14a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5" />   
                    <path d="M16 10l4 4m0 -4l-4 4" />
                </>
            )
        case "volume": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M15 8a5 5 0 0 1 0 8" />   
                    <path d="M17.7 5a9 9 0 0 1 0 14" />   
                    <path d="M6 15 h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a.8 .8 0 0 1 1.5 .5v14a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5" />
                </>
            )
        case "wallet": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />   
                    <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
                </>
            )
        case "wand": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <polyline points="6 21 21 6 18 3 3 18 6 21" />   
                    <line x1="15" y1="6" x2="18" y2="9" />   
                    <path d="M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />   
                    <path d="M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
                </>
            )
        case "wifi-0": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="12" y1="18" x2="12.01" y2="18" />
                </>
            )
        case "wifi-1": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <g transform="rotate(-45 12 18)">   
                    <line x1="12" y1="18" x2="12.01" y2="18" />   
                    <path d="M12 14a4 4 0 0 1 4 4" />   
                    </g></>)
        case "wifi-2": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <g transform="rotate(-45 12 18)">   
                    <line x1="12" y1="18" x2="12.01" y2="18" />   
                    <path d="M12 14a4 4 0 0 1 4 4" />   
                    <path d="M12 10a8 8 0 0 1 8 8" />   
                    </g></>)
        case "wifi": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <g transform="rotate(-45 12 18)">   
                    <line x1="12" y1="18" x2="12.01" y2="18" />   
                    <path d="M12 14a4 4 0 0 1 4 4" />   
                    <path d="M12 10a8 8 0 0 1 8 8" />   
                    <path d="M12 6a12 12 0 0 1 12 12" />   
                    </g></>)
        case "wind": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <path d="M5 8h8.5a2.5 2.5 0 1 0 -2.34 -3.24" />   
                    <path d="M3 12h15.5a2.5 2.5 0 1 1 -2.34 3.24" />   
                    <path d="M4 16h5.5a2.5 2.5 0 1 1 -2.34 3.24" />
                </>
            )
        case "woman": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="5" r="2" />   
                    <path d="M10 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
                </>
            )
        case "world": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <line x1="3.6" y1="9" x2="20.4" y2="9" />   
                    <line x1="3.6" y1="15" x2="20.4" y2="15" />   
                    <path d="M11.5 3a17 17 0 0 0 0 18" />   
                    <path d="M12.5 3a17 17 0 0 1 0 18" />
                </>
            )
        case "x": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <line x1="18" y1="6" x2="6" y2="18" />   
                    <line x1="6" y1="6" x2="18" y2="18" />
                </>
            )
        case "yin-yang": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="12" cy="12" r="9" />   
                    <path d="M12 3a4.5 4.5 0 0 0 0 9a4.5 4.5 0 0 1 0 9" />   
                    <circle cx="12" cy="7.5" r=".5" fill="currentColor" />   
                    <circle cx="12" cy="16.5" r=".5" fill="currentColor" />
                </>
            )
        case "zoom-in": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="10" cy="10" r="7" />   
                    <line x1="7" y1="10" x2="13" y2="10" />   
                    <line x1="10" y1="7" x2="10" y2="13" />   
                    <line x1="21" y1="21" x2="15" y2="15" />
                </>
            )
        case "zoom-out": 
            return ( 
                <>
                    <path stroke="none" d="M0 0h24v24H0z"/>   
                    <circle cx="10" cy="10" r="7" />   
                    <line x1="7" y1="10" x2="13" y2="10" />   
                    <line x1="21" y1="21" x2="15" y2="15" />
                </>
            )
        
}
}
