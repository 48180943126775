import debounce from "lodash.debounce";
import { gql, useMutation, useState } from "../../imports";
const UpdateItemQuantityMutation = gql`
  mutation MyMutation($amount: Int!, $rowId: Int!, $subordererKey: String) {
    updateProductAmount(
      input: {
        amount: $amount
        clientMutationId: ""
        rowId: $rowId
        subordererKey: $subordererKey
      }
    ) {
      cart {
        lists {
          name
          total
          orderers {
            products {
              amount
              rowId
              total
              product {
                parent {
                  name
                  coolbox {
                    coolboxActivated
                    coolboxWeight
                  }
                }
                name
                price
                weight
                variationId
                id
              }
            }
            name
            total
          }
        }
        total
        coolbox
        shipping
        subtotalWithTax
        minimumOrderWeight
        totalWeight
      }
    }
  }
`;

const GetExtCartItems = gql`
  query getExtCartItems($subordererKey: String!) {
    extCart(subordererKey: $subordererKey) {
      lists {
        name
        total
        orderers {
          products {
            amount
            rowId
            total
            product {
              parent {
                name
                coolbox {
                  coolboxActivated
                  coolboxWeight
                }
              }
              name
              price
              weight
              variationId
              id
            }
          }
          name
          total
        }
      }
      total
      coolbox
      shipping
      subtotalWithTax
      minimumOrderWeight
      totalWeight
    }
  }
`;
export function useAmountInput() {
  const [UpdateProductAmount, { loading: loadingUpdateAmount }] = useMutation(
    UpdateItemQuantityMutation,
    {
      refetchQueries: ["getExtCartItems"],
      update: (cache, mutationResult) => {
        cache.writeQuery({
          query: GetExtCartItems,
          data: { extCart: mutationResult.cart },
        });
      },
    }
  );
  const [data234, setData] = useState();

  const debouncedInputHandle = debounce((product, input, subordererKey) => {
    if (parseInt(input) > 0) {
      UpdateProductAmount({
        variables: {
          amount: parseInt(input),
          rowId: product.rowId,
          subordererKey: subordererKey,
        },
      })
        .then((response) => {
          // setData(response?.data.updateProductAmount.cart.lists)
        })
        .catch((e) => {
          alert(e.toString());
        });
    } else {
      alert("Dies ist keine ganze Zahl!");
    }
  }, 50);

  return { debouncedInputHandle, data234, loadingUpdateAmount };
}

export default useAmountInput;
