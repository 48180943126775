import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import HeadingTwo from "./HeadingTwo";

// Props:
// - mainText: String (required)
// -- big golden text in quote marks
// - description: String (required)
// -- small blue description text under mainText
// . colors: String
// -- one of "DEFAULT" or "LIGHT"

// Example: <Quote mainText="blos ned hudla" description="gut ding braucht weile" color="LIGHT/>

const PhilosophieText = ({ heading, description }) => {
  return (
    <Wrapper>
      <HeadingTwo title={heading} color="DEFAULT" />
      <Paragraph>{description}</Paragraph>
    </Wrapper>
  );
};

PhilosophieText.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  text-align: left;
  display: inline-block;
  margin-right: 8%;

  @media (max-width: 1500px){
    max-width: 100%;
  }
`;

const Paragraph = styled.p`
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: #4d4d4d;

  @media (min-width: 1600px) {
    font-size: 17px;
  }
`;

export default PhilosophieText;
