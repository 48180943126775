// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   31.07.2020
// Last edited:     02.08.2020

import React from "react";
import styled from "styled-components";
import styl from "../styl";
import PropTypes from "prop-types";
import colors from "./../constants/colors";
import constants from "./../constants/constants";

// Props:
// - fontColor: String (required)
// -- Sets the link items' text color.
// -- If no fontColor is provided, the fontColor will be #4D4D4D by default.

// Example: <Footer fontColor={"white"} />

const Footer = ({ fontColor, showFooter }) => {
  return (
    <StyledFooter prop_showFooter={showFooter}>
      <Positioner>
        <StyledFooterItem href={"/agb"} prop_fontColor={fontColor}>
          AGB
        </StyledFooterItem>
        <StyledFooterItem href={"/impressum"} prop_fontColor={fontColor}>
          Impressum
        </StyledFooterItem>
        <StyledFooterItem href={"/datenschutz"} prop_fontColor={fontColor}>
          Datenschutz
        </StyledFooterItem>
      </Positioner>
    </StyledFooter>
  );
};

Footer.propTypes = {
  fontColor: PropTypes.string.isRequired,
};

Footer.defaultProps = {
  fontColor: colors.SolidGreyText,
};

const StyledFooter = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  width: 100%;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    position: relative;
    background: transparent;
    height: 38px;
  }

  @media (max-width: 770px) {
    background: #e7e6e2;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-self: center;
    padding-bottom: 25px;
    padding-top: 10px;

    display: ${(props) => (props.prop_showFooter == false ? "none" : "flex")};
  }
`;

const StyledFooterItem = styled.a`
  ${styl.clear};
  ${styl.clear};
  ${styl.defaultFontFamily};
  ${styl.transition};
  font-weight: 400;
  padding: 5px 10px;
  font-size: 11px;
  cursor: pointer;
  color: ${(props) => props.prop_fontColor};
  text-transform: uppercase;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    color: ${colors.SolidGreyText};
  }
`;

const Positioner = styled.div`
  display: flex;
  /* width: 100%; */
  flex-direction: row-reverse;
  align-items: flex-end;
  @media (max-width: 1000px) {
  }
`;

export default Footer;
