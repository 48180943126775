// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   02.08.2020
// Last edited:     02.08.2020

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import constants from '../../constants/constants';

// Props: 
// - navState: Boolean (required)
// -- Sets the link items' text color.
// -- If no fontColor is provided, the fontColor will be #4D4D4D by default.
// - onClick: Function (required)
// -- Toggles the state of the Button (3 bars in a column or cross) and the navigation (open/close)

// Example: <BurgerButton navState={navState} onClick={() => setNavState(prevNavState => !prevNavState)} />

const BurgerButton = ({ navState, onClick }) => {
    return (
        <Button className={navState == true ? "ham hamburger hamburger--squeeze is-active" : "ham hamburger hamburger--squeeze"} type="button" onClick={onClick}>
            <span className="hamburger-box">
                <span className="hamburger-inner"></span>
            </span>
        </Button>
    )
};

BurgerButton.propTypes = {
    navState: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};

const Button = styled.button`
    display: none;

    &:hover, &:active, &:focus {
        outline: none;
        border: none;
    }

    @media (max-width: ${constants.nexusPortraitWidth}px) {
        display: block;
        padding: 11px;
        transform: scale(0.7);
    }
`;

export default BurgerButton;