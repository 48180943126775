import colors from './constants/colors'

let shadow = (properties) => {
    return `
    -webkit-box-shadow: ${properties};
    -moz-box-shadow: ${properties};
    box-shadow: ${properties};
    `
  }
  
  let styl = {
    clear: `margin: 0px; padding: 0px; background: transparent; border: none; text-decoration: none;`,
    flexRow: `display: flex; flex-flow: row;`,
    transition: `transition: .3s ease-in-out`,
    flexColumn: `display: flex; flex-flow: column;`,
    flexCenter: `justify-content: center; align-items: center;`,
    mobile: `1000px`,
    scrollbarWidth: `scrollbar-width: thin;`,
    defaultFontFamily: `font-family: 'Montserrat', sans-serif;`,
    lightDefaultFont: `font-family: 'Montserrat', sans-serif; font-weight: 400;`,
    boldDefaultFont: `font-family: 'Montserrat', sans-serif; font-weight: 600;`,
    shadow: shadow,
    dashedBorder: `border: 2px dashed ${colors.defaultPrimaryColor};`,
    dashedBorderBottom: `border-bottom: 2px dashed ${colors.defaultPrimaryColor};`,

    Card: `background: white; ${shadow('0px 0px 5px 0px rgba(0,0,0,0.15)')};`,
  }
  
  export default styl;
  

