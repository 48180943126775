import { React, styled, constants, colors } from "../../imports";

const DisplayListComp = ({ list }) => {
  return (
    <>
      <tr style={{ lineHeight: 0 }}>
        <PlainData>
          <SmallHeading>{list?.name || list?.title}</SmallHeading>
        </PlainData>
      </tr>
        {list?.suborderers.map((sub) => (
          <InputWrapper>
            <PlainData width="60%">
              <FirstLastName style={{paddingRight: "5px"}}>
                {sub?.firstName}
              </FirstLastName>
              <FirstLastName>
                {sub?.lastName}
              </FirstLastName>
            </PlainData>
            <PlainData width="60%">
              {sub?.email}
            </PlainData>
          </InputWrapper>
        ))}
        <tr style={{height: '20px'}}> </tr>
    </>
  );
};

const SmallHeading = styled.p`
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 400;
  color: #4d4d4d;
  letter-spacing: 0.4px;
  text-align: left;
`;
const InputWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  @media (max-width: 1330px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    border-bottom: 1px ${colors.MercuryGreyInputfields} solid;
	}
`
const PlainData = styled.div`
  color: #808080;
  font-family: "Montserrat";
  font-weight: 300;
  letter-spacing: 0.4px;
  font-size: 16px;
  margin: 5px 0;
  width: ${(props) => props.width};
  @media (max-width: 900px) {
    width: 100%;
	}
`
const FirstLastName = styled.div`
  display: inline-flex;
  word-break: break-word;
`

export default DisplayListComp;
