// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   08.08.2020
// Last edited:     09.08.2020

import styled, { css } from 'styled-components';
import colors from '../constants/colors';
import styl from '../styl';

export const cssInputContainer = css`
    ${styl.flexColumn};
    justify-content: flex-start;
    width: 100%;
    padding: 7px 0;
    @media (max-width: 1600px){
        padding-top: 0;
        padding-bottom: 4px;
    }
`;

export const InputTitle = styled.p`
    ${styl.clear};
    ${styl.textFont};
    font-size: 14px;
    padding-bottom: 3px;
`;

export const cssInputField = css`
    ${styl.clear};
    ${styl.transition};
    width: 100%;
    height: 32px;
    background: ${colors.MercuryGreyInputfields};
    ${styl.textFont};
    ${styl.lightDefaultFont};
    color: ${colors.Gray};
    padding: 0 10px;
    box-sizing: border-box;
`;

export const Textarea = styled.textarea`
${cssInputField};
padding: 10px;
min-height: 100px;
`;
