// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   08.08.2020
// Last edited:     09.08.2020
import { gql, useMutation, useQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import queryString from "query-string";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import styled from "styled-components";
import { GetCurrentPage, GetUserContext } from "../localStorage/Store";
import ArrowImage from "./../assets/images/arrow.png";
import colors from "./../constants/colors";
import CircleWithText from "./CircleWithText";
import DeleteUserAlert from "./DeleteUserAlert";
import InfoComponent from "./InfoComponent";
import MultipleSelect from "./MultipleSelect";
import RefInput from "./RefInput";
import StateInput from "./StateInput";
import StyledButton from "./StyledButton";

var IBAN = require("iban");
const UpdateCustomerMutation = gql`
  mutation updateCustomer(
    $id: ID
    $iban: String!
    $bank: String
    $lastNameAll: String!
    $firstNameAll: String!
    $emailAll: String!
    $addressBilling: String!
    $cityBilling: String!
    $firstNameBilling: String!
    $lastNameBilling: String!
    $postcodeBilling: String!
    $phoneBilling: String!
    $stateBilling: String!
    $addressShipping: String!
    $cityShipping: String!
    $firstNameShipping: String!
    $lastNameShipping: String!
    $postcodeShipping: String!
    $stateShipping: String!
    $countryBilling: CountriesEnum
    $countryShipping: CountriesEnum
  ) {
    __typename
    updateCustomer(
      input: {
        clientMutationId: "UpdateCustomer"
        iban: $iban
        bank: $bank
        id: $id
        lastName: $lastNameAll
        firstName: $firstNameAll
        email: $emailAll
        billing: {
          address1: $addressBilling
          city: $cityBilling
          country: $countryBilling
          firstName: $firstNameBilling
          lastName: $lastNameBilling
          postcode: $postcodeBilling
          phone: $phoneBilling
          state: $stateBilling
          email: $emailAll
        }
        shipping: {
          address1: $addressShipping
          city: $cityShipping
          country: $countryShipping
          state: $stateShipping
          firstName: $firstNameShipping
          lastName: $lastNameShipping
          postcode: $postcodeShipping
          email: $emailAll
        }
      }
    ) {
      customer {
        billing {
          firstName
          address1
          city
          country
          email
          lastName
          phone
          postcode
          state
        }
        shipping {
          address1
          city
          postcode
          state
          country
          firstName
          lastName
          email
          phone
        }
        hasCoupon
        orderCount
        lastName
        firstName
        email
        customerId
        id
        username
        iban
        bank
        showName
        showPrice
      }
    }
  }
`;

const setPriceNameMutation = gql`
  mutation updateCustomer($id: ID, $showName: Boolean, $showPrice: Boolean) {
    __typename
    updateCustomer(
      input: {
        clientMutationId: "UpdateCustomer"
        showName: $showName
        showPrice: $showPrice
        id: $id
      }
    ) {
      customer {
        billing {
          firstName
          address1
          city
          country
          email
          lastName
          phone
          postcode
          state
        }
        shipping {
          address1
          city
          postcode
          state
          country
          firstName
          lastName
          email
          phone
        }
        hasCoupon
        orderCount
        lastName
        firstName
        email
        customerId
        id
        username
        iban
        bank
        showName
        showPrice
      }
    }
  }
`;

const subCheckoutMutation = gql`
  mutation MyMutation($key: String!) {
    __typename

    subCheckout(input: { clientMutationId: "", subordererKey: $key }) {
      success
    }
  }
`;
const GetExtCart = gql`
  query getExtCart {
    __typename
    extCart {
      addProductsUntil
      total
    }
  }
`;
const EmptyExtCart = gql`
  mutation emptyExtCart($subordererKey: String) {
    __typename
    emptyExtCart(
      input: { clientMutationId: "emptyCart", subordererKey: $subordererKey }
    ) {
      clientMutationId
      success
    }
  }
`;

const UpdatePassword = gql`
  mutation updatePassword($passwordOld: String!, $passwordNew: String!) {
    __typename
    updatePassword(
      input: {
        clientMutationId: "updatePassword"
        newPassword: $passwordNew
        oldPassword: $passwordOld
      }
    ) {
      resetted
    }
  }
`;

/**
 * @author Sandro Mennel
 * @param {string} parentPage if the component is called from the MultipleBuyerPage
 * @param {function} activeView if clicked on the button the given function is called(required)
 * @param {boolean} multipleBuyer if this page is called from the multipleBuyerPage
 * @param {string} subordererKey the key of the order where Suborderer can join and add to cart
 * @param {object} productList state of custom hook, list is transfered between components
 * @example <UserData activeView={() => handleView()}></UserData>
 */

const UserData = ({
  parentPage,
  location,
  activeView,
  multipleBuyer,
  subordererKey,
  productList,
}) => {
  const [passwordValue, setPasswordValue] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      password1: "",
      password2: "",
      passwordOld: "",
      passwordError: "",
    }
  );
  const [user, setUser] = useContext(GetUserContext);
  const [edit, setEdit] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [password2Validation, setPassword2Validation] = useState(false);
  const [passwordOldValidation, setPasswordOldValidation] = useState(false);
  const [ibanState, setIbanState] = useState();
  const [bankState, setBankState] = useState();
  const telRef = useRef();
  const firstNameAllRef = useRef();
  const lastNameAllRef = useRef();
  const emailAllRef = useRef();
  const isBillingSameShipping = useRef();

  const [enableSelect, setEnableSelection] = useState(false);

  const [emailValidation, setEmailValidation] = useState(false);
  const [town2Validation, setTown2Validation] = useState(false);
  const [adress2Validation, setAdress2Validation] = useState(false);
  const [firstNameValidation, setFirstNameValidation] = useState(false);
  const [firstNameAllValidation, setFirstNameAllValidation] = useState(false);
  const [firstName2Validation, setFirstName2Validation] = useState(false);
  const [lastNameValidation, setLastNameValidation] = useState(false);
  const [lastNameAllValidation, setLastNameAllValidation] = useState(false);
  const [lastName2Validation, setLastName2Validation] = useState(false);
  const [plzValidation, setPlzValidation] = useState(false);
  const [plz2Validation, setPlz2Validation] = useState(false);
  const [adressValidation, setAdressValidation] = useState(false);
  const [townValidation, setTownValidation] = useState(false);
  const [telValdiation, setTelValidation] = useState(false);
  const [IBANValidation, setIBANValidation] = useState(false);
  const [countryValidation, setCountryValidation] = useState(false);
  const [country2Validation, setCountry2Validation] = useState(false);
  const [shippingSameBilling, setShippingSameBilling] = useState(false);

  const [oldOption, setOldOptions] = useState();
  const [oldOptionShippingBilling, setoldOptionShippingBilling] = useState();

  const [shipping, setShipping] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      plz2: user.shipping?.postcode || "",
      adress2: user.shipping?.address1 || "",
      lastName2: user.shipping?.lastName || "",
      firstName2: user.shipping?.firstName || "",
      country2: user.shipping?.country || "",
      town2: user.shipping?.city || "",
    }
  );
  const [billing, setBilling] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      plz: user.billing?.postcode || "",
      address: user.billing?.address1 || "",
      lastName: user.billing?.lastName || "",
      firstName: user.billing?.firstName || "",
      country: user.billing?.country || "",
      town: user.billing?.city || "",
    }
  );

  // const duration = useRef("");
  // const [durationBackend, setDurationBackend] = useState(10)
  const { duration, setDuration, durationFromBackend, setDurationFromBackend } =
    useContext(GetCurrentPage);
  const [changePassword, setChangePassword] = useState(false);

  const [reload, setReload] = useState();

  const [updateCustomer, { loading: mutationLoading, error: mutationError }] =
    useMutation(UpdateCustomerMutation);

  const [
    setPriceNameMutation2,
    { loading: mutationLoading2, error: mutationError2 },
  ] = useMutation(setPriceNameMutation);

  const [
    SubCheckout,
    { loading: subCheckoutLoading, error: subCheckoutError },
  ] = useMutation(subCheckoutMutation);
  const [emptyExtCart, { loading: mutationLoading3, error: mutationError3 }] =
    useMutation(EmptyExtCart);
  const [
    updatePasswordMutation,
    { loading: mutationLoading5, error: mutationError8 },
  ] = useMutation(UpdatePassword);

  const {
    loading1,
    error1,
    data: content,
  } = useQuery(GetExtCart, {
    onCompleted: (response) => {
      // if (
      //   response.extCart?.addProductsUntil !== 0 &&
      //   response.extCart?.addProductsUntil !== null
      // ) {
      //   let date = new Date(response.extCart?.addProductsUntil * 1000);
      //   let currentDate = new Date();
      //   // let day = parseInt(Math.round(date.getTime() - currentDate.getTime())/ (1000 * 60 * 60 * 24))|| 0
      //   let tmpTime = date.getTime() - currentDate.getTime();
      //   var tmpDays = tmpTime / (1000 * 3600 * 24);
      //   setDurationState(Math.round(tmpDays));
      // } else {
      //   setDurationState(10);
      // }
    },
  });

  useEffect(() => {
    setEnableSelection(false);
    if (user.showName && user.showPrice) {
      setOldOptions([
        {
          id: 1,
          title: "Preis auf Etikett drucken",
        },
        {
          id: 2,
          title: "Name auf Etikett drucken",
        },
      ]);
    } else if (user.showName) {
      setOldOptions([
        {
          id: 2,
          title: "Name auf Etikett drucken",
        },
      ]);
    } else if (user.showPrice) {
      setOldOptions([
        {
          id: 1,
          title: "Preis auf Etikett drucken",
        },
      ]);
    } else {
      setOldOptions([]);
    }

    setEnableSelection(true);
  }, [reload]);

  useEffect(() => {
    setBankState(user.bank);
  }, [edit]);
  const saveUser = async () => {
    setEmailValidation(false);
    setTown2Validation(false);
    setAdress2Validation(false);
    setLastNameValidation(false);
    setLastName2Validation(false);
    setFirstName2Validation(false);
    setFirstNameValidation(false);
    setPlzValidation(false);
    setPlz2Validation(false);
    setAdressValidation(false);
    setTownValidation(false);
    setTelValidation(false);
    setIBANValidation(false);
    setCountry2Validation(false);
    setCountryValidation(false);
    setFirstNameAllValidation(false);
    setLastNameAllValidation(false);
    if (
      billing.firstName &&
      billing.lastName &&
      shipping.firstName2 &&
      shipping.lastName2 &&
      billing.plz &&
      shipping.plz2 &&
      billing.address &&
      shipping.adress2 &&
      billing.town &&
      shipping.town2 &&
      emailAllRef.current.value &&
      firstNameAllRef.current.value &&
      lastNameAllRef.current.value &&
      shipping.country2 &&
      billing.country
    ) {
      if (
        (await checkZIPCode(billing.plz, false)) &&
        (await checkZIPCode(shipping.plz2, true)) &&
        checkPhone(telRef.current.value) &&
        CheckEmail(emailAllRef.current.value) &&
        (ibanState ? IBAN.isValid(ibanState) : true) &&
        checkCountry(billing.country, shipping.country2)
      ) {
        if (shippingSameBilling) {
          updateCustomer({
            variables: {
              id: user.id,
              iban: ibanState || "",
              bank: bankState,
              emailAll: emailAllRef.current.value,
              firstNameAll: firstNameAllRef.current.value,
              lastNameAll: lastNameAllRef.current.value,
              addressBilling: billing.address,
              cityBilling: billing.town,
              firstNameBilling: billing.firstName,
              lastNameBilling: billing.lastName,
              postcodeBilling: billing.plz,
              phoneBilling: telRef.current.value,
              stateBilling: "Vorarlberg",
              countryBilling: billing.country,
              addressShipping: billing.address,
              cityShipping: billing.town,
              firstNameShipping: billing.firstName,
              lastNameShipping: billing.lastName,
              postcodeShipping: billing.plz,
              stateShipping: "Vorarlberg",
              countryShipping: billing.country.toUpperCase(),
            },
          })
            .then((response) => {
              setUser(response.data.updateCustomer.customer);
              let tmpBilling = {
                adress1: response.data.updateCustomer.customer.billing.address1,
                city: response.data.updateCustomer.customer.billing.city,
                plz: response.data.updateCustomer.customer.billing.postcode,
                state: response.data.updateCustomer.customer.billing.state,
                country: response.data.updateCustomer.customer.billing.country,
              };
              let tmpShipping = {
                adress1:
                  response.data.updateCustomer.customer.shipping.address1,
                city: response.data.updateCustomer.customer.shipping.city,
                plz: response.data.updateCustomer.customer.shipping.postcode,
                state: response.data.updateCustomer.customer.shipping.state,
                country: response.data.updateCustomer.customer.shipping.country,
              };
              if (JSON.stringify(tmpBilling) == JSON.stringify(tmpShipping)) {
                setoldOptionShippingBilling([
                  {
                    id: 1,
                    title: "Lieferadresse ist gleich wie Rechnungsadresse",
                  },
                ]);
                setShippingSameBilling(true);
              }
              setEdit(false);
              setReload(true);
            })
            .catch((error) => {
              alert(error.toString());
            });
        } else {
          updateCustomer({
            variables: {
              id: user.id,
              iban: ibanState || "",
              bank: bankState,
              emailAll: emailAllRef.current.value,
              firstNameAll: firstNameAllRef.current.value,
              lastNameAll: lastNameAllRef.current.value,
              addressBilling: billing.address,
              cityBilling: billing.town,
              firstNameBilling: billing.firstName,
              lastNameBilling: billing.lastName,
              postcodeBilling: billing.plz,
              phoneBilling: telRef.current.value,
              stateBilling: "Vorarlberg",
              countryBilling: billing.country.toUpperCase(),
              addressShipping: shipping.adress2,
              cityShipping: shipping.town2,
              firstNameShipping: shipping.firstName2,
              lastNameShipping: shipping.lastName2,
              postcodeShipping: shipping.plz2,
              stateShipping: "Vorarlberg",
              countryShipping: shipping.country2.toUpperCase(),
            },
          })
            .then((response) => {
              setUser(response.data.updateCustomer.customer);
              let tmpBilling = {
                adress1: response.data.updateCustomer.customer.billing.address1,
                city: response.data.updateCustomer.customer.billing.city,
                plz: response.data.updateCustomer.customer.billing.postcode,
                state: response.data.updateCustomer.customer.billing.state,
                country: response.data.updateCustomer.customer.billing.country,
              };
              let tmpShipping = {
                adress1:
                  response.data.updateCustomer.customer.shipping.address1,
                city: response.data.updateCustomer.customer.shipping.city,
                plz: response.data.updateCustomer.customer.shipping.postcode,
                state: response.data.updateCustomer.customer.shipping.state,
                country: response.data.updateCustomer.customer.shipping.country,
              };
              if (JSON.stringify(tmpBilling) == JSON.stringify(tmpShipping)) {
                setoldOptionShippingBilling([
                  {
                    id: 1,
                    title: "Lieferadresse ist gleich wie Rechnungsadresse",
                  },
                ]);
                setShippingSameBilling(true);
              }
              setEdit(false);
              setReload(true);
            })
            .catch((error) => {
              alert(error.toString());
            });
        }
      } else {
        if (ibanState && !IBAN.isValid(ibanState))
          setIBANValidation("Gib einen gültigen IBAN an.");
      }
    } else {
      if (!firstNameAllRef.current.value) {
        setFirstNameAllValidation("Gib einen Vornamen an.");
      }
      if (!lastNameAllRef.current.value) {
        setLastNameAllValidation("Gib einen Nachnamen an.");
      }
      if (!emailAllRef.current.value) {
        setEmailValidation("Gib eine E-Mail Adresse an.");
      }
      if (!shipping.firstName2) {
        setFirstName2Validation("Gib einen Vornamen an.");
      }
      if (ibanState) {
        if (!IBAN.isValid(ibanState)) {
          setIBANValidation("Gib einen gültigen IBAN an.");
        }
      }
      if (!billing.firstName) {
        setFirstNameValidation("Gib einen Vornamen an.");
      }
      if (!shipping.lastName2) {
        setLastName2Validation("Gib einen Nachnamen an.");
      }
      if (!billing.lastName) {
        setLastNameValidation("Gib einen Nachnamen an.");
      }
      if (!billing.address) {
        setAdressValidation("Gib eine Adresse an.");
      }
      if (!shipping.adress2) {
        setAdress2Validation("Gib eine Adresse an.");
      }
      if (!billing.plz) {
        setPlzValidation("Gib eine PLZ an.");
      }
      if (!shipping.plz2) {
        setPlz2Validation("Gib eine PLZ an.");
      }
      if (!billing.town) {
        setTownValidation("Gib einen Ort an.");
      }
      if (!shipping.town2) {
        setTown2Validation("Gib einen Ort an.");
      }
      if (!billing.country) {
        setCountryValidation("Gib einen Länderkürzel an.");
      }
      if (!shipping.country2) {
        setCountry2Validation("Gib einen Länderkürzel an.");
      }
    }
  };

  const H = window.H;
  var platform = new H.service.Platform({
    apikey: "hzdcbr05flQK6n8sOrnmm_3kROdUdeMN9QiYD2nl-Qc",
  });
  // async function checkZIPCode(plz, item) {
  //   let valid = false;
  //   var service = platform.getSearchService();

  //   await service.geocode(
  //     {
  //       // Search query

  //       in: "countryCode:AUT,DEU",
  //       qq: `postalCode=${plz}`,
  //       // Center of the search context
  //       at: "47.4345111,9.8987025,17",
  //     },
  //     (result) => {
  //       if (result.items.length > 0) {
  //         valid = true;
  //       } else {
  //         if (item === 1) {
  //           setPlzValidation(
  //             "PLZ wurde nicht in Deutschland oder Österreich gefunden."
  //           );
  //         } else {
  //           setPlz2Validation(
  //             "PLZ wurde nicht in Deutschland oder Österreich gefunden."
  //           );
  //         }
  //       }
  //     }
  //   );
  //   return valid;
  // }
  async function checkZIPCode(plz3, showShipping) {
    let valid = false;
    var service = platform.getSearchService();

    await service.geocode(
      {
        // Search query

        in: "countryCode:AUT,DEU",
        qq: `postalCode=${plz3}`,
        // Center of the search context
        at: "47.4345111,9.8987025,17",
      },
      (result) => {
        if (result.items.length > 0) {
          if (result.items[0].address.countryCode == "AUT") {
            if (showShipping) {
              setPlz2Validation(false);
              setShipping({ country2: "AT" });
            } else {
              setPlzValidation(false);
              setBilling({ country: "AT" });
              if (shippingSameBilling) {
                setShipping({ country2: "AT" });
              }
            }
          } else if (result.items[0].address.countryCode == "DEU") {
            if (showShipping) {
              setPlz2Validation(false);
              setShipping({ country2: "DE" });
            } else {
              setPlzValidation(false);
              setBilling({ country: "DE" });
              if (shippingSameBilling) {
                setShipping({ country2: "DE" });
              }
            }
          }
          if (result.items[0].address.city) {
            if (showShipping) {
              setShipping({ town2: result.items[0].address.city });
            } else {
              setBilling({ town: result.items[0].address.city });
              if (shippingSameBilling) {
                setShipping({ town2: result.items[0].address.city });
              }
            }
          }

          valid = true;
        } else {
          if (showShipping) {
            setPlz2Validation(
              "PLZ wurde nicht in Deutschland oder Österreich gefunden."
            );
          } else {
            setPlzValidation(
              "PLZ wurde nicht in Deutschland oder Österreich gefunden."
            );
          }
        }
      }
    );

    return valid;
  }

  function checkPhone(phone) {
    var phoneFormat = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

    if (phone && !phone.match(phoneFormat)) {
      setTelValidation("Gib eine gültige Telefonnummer an.");
      return false;
    } else {
      return true;
    }
  }

  function checkCountry(countryBilling, countryShipping) {
    if (
      countryBilling.toUpperCase() === "AT" ||
      countryBilling.toUpperCase() === "DE"
    ) {
      if (
        countryShipping.toUpperCase() === "AT" ||
        countryShipping.toUpperCase() === "DE"
      ) {
        return true;
      } else {
        setCountry2Validation("Nur AT oder DE möglich");
        return false;
      }
    } else {
      setCountryValidation("Nur AT oder DE möglich");
      return false;
    }
  }
  const handleEditMode = () => {
    setEmailValidation(false);
    setTown2Validation(false);
    setAdress2Validation(false);
    setLastNameValidation(false);
    setLastName2Validation(false);
    setFirstName2Validation(false);
    setFirstNameValidation(false);
    setPlzValidation(false);
    setPlz2Validation(false);
    setAdressValidation(false);
    setTownValidation(false);
    setTelValidation(false);
    setIBANValidation(false);
    setCountry2Validation(false);
    setCountryValidation(false);

    let tmpBilling = {
      adress1: user.billing?.address1,
      city: user.billing?.city,
      plz: user.billing?.postcode,
      state: user.billing?.state,
      country: user.billing?.country,
    };
    let tmpShipping = {
      adress1: user.shipping?.address1,
      city: user.shipping?.city,
      plz: user.shipping?.postcode,
      state: user.shipping?.state,
      country: user.shipping?.country,
    };
    if (JSON.stringify(tmpBilling) == JSON.stringify(tmpShipping)) {
      setoldOptionShippingBilling([
        {
          id: 1,
          title: "Lieferadresse ist gleich wie Rechnungsadresse",
        },
      ]);
      setShippingSameBilling(true);
    }
    let editTemp = edit;
    setEdit(!editTemp);
  };
  const handleContinue = () => {
    // if (durationState === 10) {
    //   var d = new Date();
    //   d.setDate(d.getDate() + parseInt(10));

    //   let unix = parseInt((d.getTime() / 1000).toFixed(0));
    //   setDurationOrder({ variables: { duration: unix } }).then((response) => {
    //     setDurationState(10);
    //   });
    // }
    if (
      user.billing?.city &&
      user.billing?.postcode &&
      user.billing?.address1 &&
      user.billing?.country &&
      user.shipping?.city &&
      user.shipping?.postcode &&
      user.shipping?.address1 &&
      user.shipping?.country
    ) {
      location === "SingleBuyerPage" && user.orderCount > 0
        ? activeView("AddProduct")
        : user.orderCount == 0 && location !== "MultipleBuyerPage"
        ? activeView("AddProduct")
        : activeView("Invite");
    } else {
      alert("Noch nicht alle Daten vorhanden");
      handleEditMode();
    }
  };

  const handleFinish = () => {
    let newArray = [];
    productList.map((tmp) => {
      let tmpArray = { variationId: tmp.id, amount: tmp.count };
      newArray = [...newArray, tmpArray];
    });

    emptyExtCart({ variables: { subordererKey: subordererKey } })
      .then((response) => {
        AddItemsToCart(newArray);
      })
      .catch((error) => {
        alert(error.toString());
      });
  };

  async function AddItemsToCart(newArray) {
    let params = queryString.parse(window.location.search);
    const key = decodeURIComponent(params.id);
    try {
      SubCheckout({ variables: { key } })
        .then((response) => {
          activeView("OrderComplete");
        })
        .catch((e) => {
          alert("5", e.toString());
        });
    } catch {}
  }
  function checkPassword(password) {
    var counter = 0;

    if (/[a-z]/.test(password)) {
      ++counter;
    }
    if (/[A-Z]/.test(password)) {
      ++counter;
    }
    if (/[0-9]/.test(password)) {
      ++counter;
    }
    if (/[^A-Za-z0-9]/.test(password)) {
      ++counter;
    }

    if (counter >= 2 && password.length >= 8 && password.length <= 50) {
      return true;
    } else {
      return false;
    }
  }
  function CheckEmail(email) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      return true;
    } else {
      setEmailValidation("Ungültige E-Mail-Adresse.");
      return false;
    }
  }
  const handleShippingBilling = (value) => {
    if (value.length !== 0) {
      fillReducer("plz2", billing.plz);
      fillReducer("adress2", billing.address);
      fillReducer("firstName2", billing.firstName);
      fillReducer("lastName2", billing.lastName);
      fillReducer("town2", billing.town);
      fillReducer("country2", billing.country);
      setShippingSameBilling(true);
    } else {
      setShippingSameBilling(false);
    }
  };

  const saveShowNamePrice = (e) => {
    let showNameEtikett = false;
    let showPriceEtikett = false;
    if (e.length == 2) {
      showNameEtikett = true;
      showPriceEtikett = true;
    } else if (e[0]?.id == 1) {
      showPriceEtikett = true;
    } else if (e[0]?.id == 2) {
      showNameEtikett = true;
    } else {
    }

    setPriceNameMutation2({
      variables: {
        id: user.id,
        showPrice: showPriceEtikett,
        showName: showNameEtikett,
      },
    })
      .then((response) => {
        setUser(response.data.updateCustomer.customer);

        setReload(true);
        setEdit(false);
      })
      .catch((error) => {
        alert(error.toString());
      });
  };

  useEffect(() => {
    if (!duration) {
      setDuration(5);
    }
  }, []);
  const handleDuration = () => {
    const e = document.getElementById("data");
    const index = e.options[e.selectedIndex].value;
    const tmpDate = days[index];
    setDuration(tmpDate);
  };
  // const handleDuration = () => {
  //   const e = document.getElementById("data");
  //   const index = e.options[e.selectedIndex].value;

  //   const tmpDate = days[index];
  //   if (tmpDate >= 2 && tmpDate <= 14) {
  //     var d = new Date();
  //     d.setDate(d.getDate() + parseInt(tmpDate));
  //     setDurationState(tmpDate);

  //     let unix = parseInt((d.getTime() / 1000).toFixed(0));
  //     setDurationOrder({ variables: { duration: unix } }).then((response) => {
  //       if (tmpDate === 0) {
  //         setDurationState(10);
  //       } else {
  //         // setDurationState(tmpDate);
  //       }
  //     });
  //   } else if (typeof tmpDate === "undefined") {
  //     return;
  //   }
  // };
  let days = [2, 3, 4, 5, 6, 7, 8, 9, 10];
  const fillReducer = async (name, value) => {
    await setShipping({ [name]: value });
  };

  const handleShipping = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setShipping({ [name]: newValue });
  };

  const setVisible = () => {
    setIsVisible(!isVisible);
  };

  // const handlePassword = (e) =>{
  //   setPasswordState(e.target.value)
  // }

  const handleEditPassword = () => {
    let tmp = changePassword;
    setChangePassword(!tmp);
  };
  const handlePassword2 = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setPasswordValue({ [name]: newValue });
  };
  const handleResetPassword = () => {
    passwordValue.passwordError = "";
    setPassword2Validation();
    if (
      checkPassword(passwordValue.password1) &&
      passwordValue.password1 === passwordValue.password2 &&
      passwordValue.passwordOld
    ) {
      updatePasswordMutation({
        variables: {
          passwordOld: passwordValue.passwordOld,
          passwordNew: passwordValue.password1,
        },
      })
        .then((response) => {
          setChangePassword(false);
        })
        .catch((e) => {
          passwordValue.passwordError = e.toString();
          setPassword2Validation(true);
        });
    } else {
      if (passwordValue.password2 !== passwordValue.password1) {
        passwordValue.passwordError = "Passwörter stimmen nicht überein!";
        setPassword2Validation(true);
        setPasswordValidation(true);
      } else {
        passwordValue.passwordError = "Neues Passwort erfüllt nicht die Regeln";
      }
      setPassword2Validation(true);
      setPasswordValidation(true);
    }
  };

  const handleDebounceZip = debounce((input, enabled) => {
    checkZIPCode(input, enabled);
  }, 50);
  return (
    <DataContainer>
      <Wrapper>
        {edit && !changePassword ? (
          <React.Fragment>
            {/* <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}> */}
            {/* <WrapperEdit> */}
            <ContentContainer>
              <SmallHeading>Meine Daten</SmallHeading>
              <InputWrapper>
                <RefInput
                  forwardedRef={firstNameAllRef}
                  type={"text"}
                  originValue={user.firstName}
                  error={firstNameAllValidation}
                  placeholder={"Vorname"}
                  maxLength="40"
                ></RefInput>
              </InputWrapper>
              <InputWrapper>
                <RefInput
                  forwardedRef={lastNameAllRef}
                  type={"text"}
                  placeholder={"Nachname"}
                  error={lastNameAllValidation}
                  originValue={user.lastName}
                  maxLength="40"
                ></RefInput>
              </InputWrapper>
              <InputWrapper>
                <RefInput
                  forwardedRef={emailAllRef}
                  type={"text"}
                  placeholder={"Email"}
                  error={emailValidation}
                  originValue={user.email}
                  maxLength="100"
                ></RefInput>
              </InputWrapper>
              <InputWrapper>
                <RefInput
                  forwardedRef={telRef}
                  type={"text"}
                  placeholder={"Telefon"}
                  error={telValdiation}
                  originValue={user.billing?.phone}
                  maxLength="20"
                ></RefInput>
              </InputWrapper>
              {parentPage === "MultipleBuyerPage" || location === "Profile" ? (
                <div>
                  <InputWrapperWithInfo>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ width: "100%" }}>
                        <StateInput
                          name={"IBAN"}
                          forwardedRef={ibanState}
                          onChange={(e) => {
                            setIbanState(e.target.value);
                            if (!bankState) {
                              setBankState(
                                firstNameAllRef.current.value +
                                  " " +
                                  lastNameAllRef.current.value
                              );
                            }
                          }}
                          error={IBANValidation}
                          type={"text"}
                          placeholder={"IBAN"}
                          originValue={user.iban}
                          maxLength="40"
                        ></StateInput>
                      </div>
                      <InfoComponent text="Bitte gib deine Kontodaten an um Zahlungen aus deiner Community direkt auf dein Konto zu erhalten." />
                    </div>
                  </InputWrapperWithInfo>
                  <InputWrapper>
                    {/* <RefInput
                      forwardedRef={bankRef}
                      type={"text"}
                      placeholder={"Kontowortlaut"}
                      // error={IBANValidation}
                      originValue={user.bank}
                    ></RefInput> */}
                    <StateInput
                      name={"Kontowortlaut"}
                      forwardedRef={bankState}
                      onChange={(e) => {
                        setBankState(e.target.value);
                      }}
                      type={"text"}
                      placeholder={"Kontowortlaut"}
                      originValue={user.bank}
                      maxLength="40"
                    ></StateInput>
                  </InputWrapper>
                </div>
              ) : (
                <div style={{ height: "10vh" }} />
              )}
              <InputWrapperTwo>
                <DataParagraphMargin>
                  Sie haben die AGB sowie DSGVO akzeptiert
                </DataParagraphMargin>
              </InputWrapperTwo>
            </ContentContainer>
            <ContentContainer>
              <SmallHeading>
                {shippingSameBilling
                  ? "Rechnungs- & Lieferadresse"
                  : "Rechnungsadresse"}
              </SmallHeading>
              <InputWrapper>
                <StateInput
                  forwardedRef={billing.firstName}
                  type={"text"}
                  originValue={user.billing?.firstName}
                  error={firstNameValidation}
                  placeholder={"Vorname"}
                  maxLength="40"
                  onChange={(e) => {
                    setBilling({ firstName: e.target.value });

                    if (shippingSameBilling) {
                      fillReducer("firstName2", e.target.value);
                    }
                  }}
                ></StateInput>
              </InputWrapper>
              <InputWrapper>
                <StateInput
                  forwardedRef={billing.lastName}
                  type={"text"}
                  placeholder={"Nachname"}
                  error={lastNameValidation}
                  originValue={user.billing?.lastName}
                  maxLength="40"
                  onChange={(e) => {
                    setBilling({ lastName: e.target.value });

                    if (shippingSameBilling) {
                      fillReducer("lastName2", e.target.value);
                    }
                  }}
                ></StateInput>
              </InputWrapper>
              <InputWrapper>
                <StateInput
                  forwardedRef={billing.address}
                  type={"text"}
                  placeholder={"Adresse"}
                  error={adressValidation}
                  originValue={user.billing?.address1}
                  maxLength="110"
                  onChange={(e) => {
                    setBilling({ address: e.target.value });
                    if (shippingSameBilling) {
                      fillReducer("adress2", e.target.value);
                    }
                  }}
                ></StateInput>
              </InputWrapper>
              <InputWrapper>
                <StateInput
                  forwardedRef={billing.plz}
                  type={"text"}
                  placeholder={"PLZ"}
                  error={plzValidation}
                  originValue={user.billing?.postcode}
                  maxLength="10"
                  onChange={(e) => {
                    setBilling({ plz: e.target.value });

                    if (shippingSameBilling) {
                      fillReducer("plz2", e.target.value);
                    }
                    handleDebounceZip(e.target.value, false);
                  }}
                ></StateInput>
              </InputWrapper>
              <InputWrapper>
                <StateInput
                  forwardedRef={billing.town}
                  type={"text"}
                  placeholder={"Ort"}
                  error={townValidation}
                  originValue={user.billing?.city}
                  maxLength="100"
                  onChange={(e) => {
                    setBilling({ town: e.target.value });

                    if (shippingSameBilling) {
                      fillReducer("town2", e.target.value);
                    }
                  }}
                ></StateInput>
              </InputWrapper>

              <InputWrapper>
                <StateInput
                  forwardedRef={billing.country}
                  type={"text"}
                  error={countryValidation}
                  placeholder={"Länderkürzel"}
                  originValue={user.billing?.country}
                  maxLength="2"
                  onChange={(e) => {
                    setBilling({ country: e.target.value });

                    if (shippingSameBilling) {
                      fillReducer("country2", e.target.value);
                    }
                  }}
                ></StateInput>
              </InputWrapper>
              <InputWrapperTwo>
                <MultipleSelect
                  alignment="RIGHT"
                  info="test"
                  options={[
                    {
                      id: 1,
                      title: "Lieferadresse ist gleich wie Rechnungsadresse",
                    },
                  ]}
                  optionTitleName={"title"}
                  forwardedRef={isBillingSameShipping}
                  compareValue={"id"}
                  onChange={(value) => handleShippingBilling(value)}
                  oldOptions={oldOptionShippingBilling}
                />
              </InputWrapperTwo>
            </ContentContainer>
            {!shippingSameBilling && (
              <ContentContainer>
                <SmallHeading>Lieferadresse</SmallHeading>
                <InputWrapper>
                  <StateInput
                    name={"firstName2"}
                    forwardedRef={shipping.firstName2}
                    onChange={(e) => handleShipping(e)}
                    error={firstName2Validation}
                    type={"text"}
                    placeholder={"Vorname"}
                    maxLength="40"
                  ></StateInput>
                </InputWrapper>
                <InputWrapper>
                  <StateInput
                    name={"lastName2"}
                    forwardedRef={shipping.lastName2}
                    onChange={(e) => handleShipping(e)}
                    error={lastName2Validation}
                    type={"text"}
                    placeholder={"Nachname"}
                    maxLength="40"
                  ></StateInput>
                </InputWrapper>
                <InputWrapper>
                  <StateInput
                    name={"adress2"}
                    forwardedRef={shipping.adress2}
                    onChange={(e) => handleShipping(e)}
                    error={adress2Validation}
                    type={"text"}
                    placeholder={"Adresse"}
                    maxLength="110"
                  ></StateInput>
                </InputWrapper>
                <InputWrapper>
                  <StateInput
                    name={"plz2"}
                    forwardedRef={shipping.plz2}
                    onChange={(e) => {
                      handleShipping(e);

                      handleDebounceZip(e.target.value, true);
                    }}
                    error={plz2Validation}
                    type={"text"}
                    placeholder={"PLZ"}
                    maxLength="10"
                  ></StateInput>
                </InputWrapper>
                <InputWrapper>
                  <StateInput
                    name={"town2"}
                    forwardedRef={shipping.town2}
                    onChange={(e) => handleShipping(e)}
                    error={town2Validation}
                    type={"text"}
                    placeholder={"Stadt"}
                    maxLength="100"
                  ></StateInput>
                </InputWrapper>

                <InputWrapper>
                  <StateInput
                    name={"country2"}
                    forwardedRef={shipping.country2}
                    onChange={(e) => handleShipping(e)}
                    type={"text"}
                    error={country2Validation}
                    placeholder={"Länderkürzel"}
                    maxLength="2"
                  ></StateInput>
                </InputWrapper>
              </ContentContainer>
            )}
            {/* </WrapperEdit> */}
            {/* </div>
            </div> */}
          </React.Fragment>
        ) : changePassword ? (
          <LoginContainer>
            <SmallHeading>Passwort ändern</SmallHeading>
            <RefInput
              name={"passwordOld"}
              forwardedRef={passwordValue.passwordOld}
              onChange={(e) => handlePassword2(e)}
              error={passwordOldValidation}
              type={"password"}
              placeholder={" Altes Passwort"}
            ></RefInput>

            <RefInput
              name={"password1"}
              forwardedRef={passwordValue.password1}
              onChange={(e) => handlePassword2(e)}
              error={passwordValidation}
              type={"password"}
              placeholder={"Neues Passwort"}
            ></RefInput>

            <PasswordStrengthBar
              password={passwordValue.password1}
              scoreWordStyle={{
                fontFamily: "Montserrat",
                fontSize: 13,
                color:
                  passwordValue.password1.length < 8
                    ? colors.ErrorRed
                    : colors.Gray,
              }}
              minLength={8}
              shortScoreWord="zu kurz"
              scoreWords={["schwach", "schwach", "okay", "gut", "stark"]}
            />

            <RefInput
              name={"password2"}
              forwardedRef={passwordValue.password2}
              onChange={(e) => handlePassword2(e)}
              type={"password"}
              error={passwordValue.passwordError}
              placeholder={"Neues Passwort wiederholen"}
            ></RefInput>

            <ButtonBox>
              <StyledButton
                onClick={() => handleResetPassword()}
                scale={"ORDER"}
                title={"Speichern"}
                color={colors.YellowBrigthButton}
                fontSize={"20px"}
                loading={mutationLoading5}
              />
            </ButtonBox>
            <ButtonBox>
              <StyledButton
                onClick={() => {
                  handleEditPassword();
                }}
                scale={"ORDER"}
                title="Zurück"
                color="#B2982A"
                fontSize="20px"
              />
            </ButtonBox>
          </LoginContainer>
        ) : (
          <React.Fragment>
            <WrapperContent>
              <ContentContainer>
                <SmallHeading>Meine Daten</SmallHeading>
                <DataParagraph>
                  {user.firstName} {user.lastName}
                </DataParagraph>
                <DataParagraph>{user.email}</DataParagraph>
                <DataParagraph>{user.billing?.phone}</DataParagraph>
                {(parentPage === "MultipleBuyerPage" ||
                  location === "Profile") && (
                  <div>
                    <DataParagraph>{user.bank}</DataParagraph>
                    <DataParagraph>{user.iban}</DataParagraph>
                  </div>
                )}
              </ContentContainer>
              <ContentContainer>
                <SmallHeading>Rechnungsadresse</SmallHeading>
                <DataParagraph>
                  {user.billing?.firstName} {user.billing?.lastName}
                </DataParagraph>
                <DataParagraph>{user.billing?.address1}</DataParagraph>
                <DataParagraph>
                  {user.billing?.postcode} {user.billing?.city}
                </DataParagraph>
                <DataParagraph>{user.billing?.country}</DataParagraph>
              </ContentContainer>
              <ContentContainer>
                <SmallHeading>Lieferadresse</SmallHeading>
                <DataParagraph>
                  {user.shipping?.firstName} {user.shipping?.lastName}
                </DataParagraph>
                <DataParagraph>{user.shipping?.address1}</DataParagraph>
                <DataParagraph>
                  {user.shipping?.postcode} {user.shipping?.city}
                </DataParagraph>
                <DataParagraph>{user.shipping?.country}</DataParagraph>
              </ContentContainer>
            </WrapperContent>
          </React.Fragment>
        )}
      </Wrapper>
      {!changePassword && (
        <WrapperButton>
          <ContentContainer>
            <ButtonBox>
              {edit && (
                <StyledButton
                  onClick={() => saveUser()}
                  scale={"ORDER"}
                  loading={mutationLoading}
                  title={"Daten speichern"}
                  color={colors.YellowBrigthButton}
                  fontSize={"20px"}
                />
              )}
            </ButtonBox>
            <ButtonBox>
              <StyledButton
                onClick={() => handleEditMode()}
                scale={"ORDER"}
                title={edit ? "Zurück" : "Daten ändern"}
                color={edit ? "#B2982A" : "#CCB242"}
                fontSize={"20px"}
              />
            </ButtonBox>
            {!edit && (
              <ButtonBox>
                <StyledButton
                  onClick={() => {
                    handleEditPassword();
                  }}
                  scale={"ORDER"}
                  title="Passwort ändern"
                  color="#B2982A"
                  fontSize="20px"
                />
              </ButtonBox>
            )}
            <ButtonBox>
              {!edit && location != "Profile" && (
                <StyledButton
                  onClick={() => handleContinue()}
                  scale={"ORDER"}
                  title={
                    parentPage === "DataInputSubbesteller" ? "zurück" : "weiter"
                  }
                  color="#8D7833"
                  fontSize={"20px"}
                />
              )}
            </ButtonBox>
            <ButtonBox></ButtonBox>
            <ButtonBox>
              {parentPage === "DataInputSubbesteller" && (
                <StyledButton
                  onClick={() => handleFinish()}
                  scale={"ORDER"}
                  title={"Bestellung abschließen"}
                  color={colors.YellowBrigthButton}
                  fontSize={"20px"}
                />
              )}
            </ButtonBox>
          </ContentContainer>
          {!edit && (
            <ContentContainer>
              {enableSelect && (
                <SelectContainer>
                  {parentPage === "SingleBuyerPage" ? (
                    <MultipleSelect
                      alignment="RIGHT"
                      info=""
                      options={[
                        {
                          id: 1,
                          title: "Preis auf Etikett drucken",
                        },
                      ]}
                      optionTitleName={"title"}
                      forwardedRef={isBillingSameShipping}
                      compareValue={"id"}
                      onChange={(value) => saveShowNamePrice(value)}
                      oldOptions={oldOption}
                    />
                  ) : (
                    <MultipleSelect
                      alignment="RIGHT"
                      info=""
                      options={[
                        {
                          id: 1,
                          title: "Preis auf Etikett drucken",
                        },
                        {
                          id: 2,
                          title: "Name auf Etikett drucken",
                        },
                      ]}
                      optionTitleName={"title"}
                      forwardedRef={isBillingSameShipping}
                      compareValue={"id"}
                      onChange={(value) => saveShowNamePrice(value)}
                      oldOptions={oldOption}
                    />
                  )}
                  {location === "MultipleBuyerPage" && (
                    <InfoWrapper>
                      <div style={{ height: "26px" }} />
                      <InfoComponent text="Name auf Etikett drucken:	Vor- und Nachname werden auf dem Etikett angeführt. Erleichtert die Verteilung der bestellten Produkte." />
                    </InfoWrapper>
                  )}
                </SelectContainer>
              )}
              {location === "MultipleBuyerPage" && (
                <>
                  <OrderRange>
                    {!durationFromBackend ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5px",
                        }}
                      >
                        <RangeCaptionWrapper>
                          <OrderRangeCaption>
                            Dauer der Bestellung:
                          </OrderRangeCaption>
                        </RangeCaptionWrapper>
                        <OrderRangeSelect
                          name="data"
                          id="data"
                          value={duration - 2}
                          onChange={(e) => handleDuration(e)}
                        >
                          {days.map((item, index) => (
                            <option
                              value={index}
                              key={index}
                              style={{ fontFamily: "Montserrat" }}
                            >
                              {item} Tage
                            </option>
                          ))}
                        </OrderRangeSelect>{" "}
                      </div>
                    ) : duration.toString().includes("Bestelldauer") ? (
                      <Paragraph>
                        <strong>{duration}</strong>
                      </Paragraph>
                    ) : (
                      <Paragraph style={{ width: "100%" }}>
                        Dauer der Bestellung noch{" "}
                        {duration.toString().includes("Uhr") ? (
                          <strong> bis {duration}</strong>
                        ) : (
                          <strong>{duration} Tage</strong>
                        )}
                      </Paragraph>
                    )}
                    <InfoComponent text="Dauer der Bestellung:	In diesem Zeitraum können sich die eingeladenen Personen auf deiner Bestellliste eintragen. " />
                  </OrderRange>
                </>
              )}
            </ContentContainer>
          )}
          {!edit && parentPage === "MultipleBuyerPage" ? (
            <CircleWrapper onClick={() => handleContinue()}>
              <CircleWithText size="170" font="22">
                Mit Freunden <br /> bestellen
              </CircleWithText>
            </CircleWrapper>
          ) : (
            parentPage === "SingleBuyerPage" && (
              <CircleWrapper
                onClick={() =>
                  (window.location.href = "/gemeinsam?location=single")
                }
              >
                <CircleWithText size="170" font="22">
                  Mit Freunden <br /> bestellen
                </CircleWithText>
              </CircleWrapper>
            )
          )}
        </WrapperButton>
      )}
      {!edit && !changePassword && (
        <Wrapper>
          <ContentContainerDelete>
            <DeleteP onClick={() => setVisible()}>
              <a>Profil löschen</a>
            </DeleteP>
          </ContentContainerDelete>
        </Wrapper>
      )}
      <DeleteUserAlert
        isVisibel={isVisible}
        setIsVisible={setVisible}
        DataParagraph={DataParagraph}
      />
      {/* <Alert isVisible={isVisible}>Hallo</Alert> */}
    </DataContainer>
  );
};

const SmallHeading = styled.h4`
  color: #4d4d4d;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 400;
  letter-spacing: 0.4px;
  margin-top: 0;
`;

const DataParagraph = styled.p`
  color: #808080;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 300;
  letter-spacing: 0.4px;
  margin: 0;
  padding: 0;
  line-height: 22px;
`;

const DataParagraphMargin = styled.p`
  color: #808080;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 300;
  letter-spacing: 0.4px;
  margin: 0;
  padding: 0;
  line-height: 22px;
  margin: 5px 0;
`;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const ButtonBox = styled.div`
  margin-bottom: 5px;
  width: 20vw;
`;

const DataContainer = styled.div`
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 63vh;
  @media (max-width: 1250px) {
    height: unset;
  }
  @media (max-width: 1700px) {
    height: unset;
    /* height: 65vh; */
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2vh;
  width: 70vw;
  @media (max-width: 1250px) {
    flex-direction: column;
    flex-direction: column-reverse;
  }
`;
const LoginContainer = styled.div`
  padding: 20px 0;
  width: 241px;
`;
const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5vh;
  width: 70vw;
  /* @media (max-width: 1600px) {
      margin-top: -5vh;
  } */
  @media (max-width: 1250px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 1000px) {
    margin-top: 20px;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 290px;
  min-width: 290px;
  /* @media (max-width: 1250px) {
    width: 30vw;
  }
  @media (max-width: 770px) {
    width: 40vw;
  }
  @media (max-width: 570px) {
    width: 60vw;
  }
  @media (max-width: 370px) {
    margin: 0;
    width: 90vw;
  } */
`;

const InfoWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20vw;
  @media (min-width: 2000px) {
    max-width: 400px;
  }
  @media (max-width: 1250px) {
    width: 50vw;
  }
  @media (max-width: 1000px) {
    margin-top: 20px;
  }
  @media (max-width: 750px) {
    width: 90vw;
  }
`;

const OrderRange = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(290px);
  min-width: 290px;
  /* @media (max-width: 1250px) {
    width: calc(50vw * 0.98);
    margin-bottom: 5vh;
  }
  @media (max-width: 750px) {
    width: calc(90vw);
  } */
`;

const RangeCaptionWrapper = styled.div`
  display: flex;
  height: 33px;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
`;

const OrderRangeCaption = styled.p`
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 300;
  color: #808080;
`;

const OrderRangeSelect = styled.select`
  height: 33px;
  width: 80px;
  border: none;
  background-color: #e1e1e1;
  color: #808080;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: 0;
  background: url(${ArrowImage}) 96% / 15% no-repeat #eee;
  font-family: "Montserrat";
  padding: 3px;
  option {
    font-family: "Montserrat";
    color: #808080;
  }
`;

const Option = styled.span`
  font-family: "Montserrat";
  font-weight: 300;
  color: #808080;
`;

const DeleteP = styled.p`
  font-family: "Montserrat";
  font-weight: 300;
  text-decoration: underline;
  color: #4d4d4d;
  font-size: 16px;
  letter-spacing: 0.4px;
  cursor: pointer;
  width: fit-content;
`;

const WrapperEdit = styled.div`
  display: "flex";
  flex-direction: "row";
  /* @media(max-width: 900){
     flex-direction: column;
 } */
`;

const ContentContainerDelete = styled.div`
  display: flex;
  flex-direction: column;
  width: 20vw;
  height: 18vh;
  justify-content: flex-end;

  @media (max-width: 1600px) {
    height: 10vh;
  }
  @media (max-width: 1000px) {
    height: 8vh;
    width: 90vw;
  }
`;

const InputWrapper = styled.div`
  width: 80%;
`;
const InputWrapperWithInfo = styled.div`
  width: calc(80% + 29px);
`;

const InputWrapperTwo = styled.div`
  width: 80%;
  margin-top: 1.5vh;
`;

const CircleWrapper = styled.div`
  position: absolute;
  top: 17vh;
  right: 2vw;
  cursor: pointer;

  @media (min-width: 1700px) {
    top: 180px;
    left: 1500px;
    right: unset;
  }
  @media (max-width: 1270px) {
    display: none;
  }
`;

const Paragraph = styled.p`
  margin-top: 6px;
  font-family: "Montserrat";
  font-size: 14px;
  color: #808080;
`;

export default UserData;
