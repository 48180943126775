import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import colors from "./../constants/colors";
import styl from "./../styl";
import HeaderText from "./HeaderText";
import Button from "./StyledButton";
/**
 * @author Sandro Mennel
 * @param {string} text text of the component (required)
 * @param {string} picture picture link of the component (required)
 * @param {string} header the text of the headertwo (required)
 * @param {string} buttonColor the color of the button (required)
 * @param {string} productID the ID of the Product (required)
 * @example <ProductOverviewComp header={"Der Milde"} productID={"absdf"} buttonColor={colors.LuckyYellowHomepageSquare} picture={Kaese} text={"Lorem uam erat volut"}></ProductOverviewComp>
 */
export default function ProductOverviewComp({
  text,
  picture,
  header,
  buttonColor,
  productID,
  priceTable,
}) {
  const handleClick = () => {
    window.location.href = "./produktDetail?id=" + productID;
  };

  return (
    <Main>
      <Content>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <ImageWrapper>
            <Picture src={picture}></Picture>
          </ImageWrapper>
          <div style={{ height: "20px" }} />
          <HeaderTextDiv>
            <HeaderText header={header} text={text}></HeaderText>
          </HeaderTextDiv>
        </div>
        <div style={{ flex: 1 }}></div>
        <table style={{ maxWidth: "50%" }}>
          <tbody>
            {priceTable.map((item) => {
              return (
                <tr>
                  <Td>€</Td>
                  <Td style={item.quantity && { textAlign: "right" }}>
                    {item.price}
                  </Td>
                  {item.quantity && (
                    <>
                      <Td>|</Td>
                      <Td>{item.quantity} kg</Td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ marginTop: "15px", marginBottom: "30px" }}>
          <Button
            title={"mehr erfahren"}
            color={buttonColor}
            onClick={handleClick}
            scale={"DEFAULT"}
            fontSize={"21px"}
          ></Button>
        </div>
      </Content>
    </Main>
  );
}
ProductOverviewComp.propTypes = {
  text: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
};

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    flex: 1;
  }

  /* padding: 50px; */
  @media (max-width: 1470px) {
    margin-top: 30px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding-right: 10px;
  padding-left: 10px;
`;
const HeaderTextDiv = styled.div`
  width: 230px;
  max-width: 100%;
`;
const Picture = styled.img`
  width: auto;
  height: 100%;
`;

const Td = styled.td`
  text-align: left;
  font-size: 14px;
  ${styl.lightDefaultFont};
  color: ${colors.SolidBlackText};
  line-height: 20px;
  font-weight: 300;
  max-width: 580px;
  white-space: nowrap;
`;
const ImageWrapper = styled.div`
  width: 230px;
  height: 150px;
  min-width: 200px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
