import Loader from "react-loader-spinner";
import OrderContainer from "../../components/OrderContainer";
import {
  colors,
  dateFormat,
  GetUserContext,
  gql,
  React,
  styled,
  useContext,
  useQuery,
  useState,
} from "../../imports.js";

const GetOrderHistoryQuery = gql`
  query getOrderHistory {
    __typename
    customer {
      orders(first: 1000) {
        nodes {
          orderId
          total
          date
          totalWeight
          customer {
            shipping {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

const OrderHistory = ({ history }) => {
  const [selected, setSelected] = useState([]);

  const {
    loading,
    error,
    data: content,
    refetch,
  } = useQuery(GetOrderHistoryQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: (order) => {
      let OrdersArray = [];
      order.customer.orders.nodes.map((item) => {
        OrdersArray.push(item);
      });
      setSelected(OrdersArray);
    },
  });
  const [user, setUser] = useContext(GetUserContext);

  const Selected = (
    { select, index } //returns the component
  ) => (
    <React.Fragment>
      <TableData
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push("/bestellhistorie/detail?orderId=" + select.orderId);
        }}
      >
        {dateFormat(select.date, "dd.mm.yyyy")}
      </TableData>
      <TableData
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push("/bestellhistorie/detail?orderId=" + select.orderId);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <span>
            <p style={{ textAlign: "right", width: "35px" }}>
              {select.totalWeight}
            </p>
          </span>
          <span>
            <p style={{ width: "35px", marginLeft: "5px" }}> kg</p>
          </span>
        </div>
      </TableData>
      <TableData style={{ textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <span>
            <p style={{ width: "20px", textAlign: "right" }}>€ </p>
          </span>
          <span>
            <p style={{ textAlign: "right", width: "75px" }}>{select.total}</p>
          </span>
        </div>
      </TableData>
    </React.Fragment>
  );

  const navigation = [
    { actualName: ["Bestellhistorie"] },
    { codeName: ["History"] },
  ];

  if (loading)
    return (
      <OrderContainer
        currentPage={"History"}
        navigation={navigation}
        clickable={false}
      >
        <Wrapper>
          <Content>
            <Loader
              height={280}
              width={200}
              type="ThreeDots"
              color={colors.YellowBrigthButton}
            />
          </Content>
        </Wrapper>
      </OrderContainer>
    );
  if (error) return `Error! ${error.message}`;

  return (
    <OrderContainer
      currentPage={"History"}
      navigation={navigation}
      clickable={false}
    >
      <Wrapper>
        <Content>
          <div style={{ width: "100%" }}>
            <ContentWrapper>
              {selected.length > 0 ? (
                <Table>
                  <TableRow hideUnderline>
                    <TableHead>Datum</TableHead>
                    <TableHead style={{ textAlign: "center" }}>
                      Gewicht
                    </TableHead>
                    <TableHead style={{ textAlign: "center" }}>
                      Betrag
                    </TableHead>
                  </TableRow>
                  {selected.map((select, index) => (
                    <TableRow>
                      <Selected key={index} index={index} select={select} />
                    </TableRow>
                  ))}
                </Table>
              ) : (
                <p>
                  Noch keine Bestellungen getätigt.{" "}
                  <a href="./bestellen">Hier bestellen</a>
                </p>
              )}
              {/* {content.cart.total} */}
            </ContentWrapper>
          </div>
        </Content>
      </Wrapper>
    </OrderContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

const ContentWrapper = styled.div`
  background-color: white;
  width: 100%;
`;

const CheeseList = styled.div`
  width: 60vw;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const DataParagraph = styled.td``;

const Table = styled.table`
  width: 70%;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const TableRow = styled.tr`
  font-family: "Montserrat";
  font-weight: 300;
  color: #808080;
  font-size: 16px;
  letter-spacing: 0.4px;
  width: 90%;
  line-height: 35px;
  &:hover {
    text-decoration: ${(props) => (props.hideUnderline ? null : "underline")};
    color: #4d4d4d;
  }
`;

const TableData = styled.td`
  width: 30%;
`;

const TableHead = styled.th`
  text-align: left;
  font-family: "Montserrat";
  font-weight: 400;
  color: #4d4d4d;
  font-size: 16px;
  letter-spacing: 0.4px;
`;

export default OrderHistory;
