// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   08.08.2020
// Last edited:     09.08.2020

import React from "react";
import styled from "styled-components";
import colors from "../constants/colors";
import styl from "../styl";
import { cssInputContainer, cssInputField, Textarea } from "./InputPartials";

/**
 *
 * @param {ref} props.forwardedRef - The reference is used to get the input value. A reference is created by using the createRef() hook from react.
 * @param {string} props.originValue - The initial value of the input. E.g. Hans
 * @param {string} props.placeholder - Tells the user what to write into the input. E.g.: Firstname
 * @param {string} props.type - The type of the input. Recommended types: email, password, text (one line), textarea (multiple lines), url, date
 * @param {bool} props.error - The input field will be surrounded with a red shadow if this value is true.
 * @param {number} props.min - If the input is type number then there is the option to give a minValue-prop.
 * @param {number} props.max - If the input is type number then there is the option to give a maxValue-prop.
 * @param {any} props.onKeyDown
 * @example <RefInput forwardedRef={mailRef} type={"text"} placeholder={"E-Mail Adresse"} originValue={"example@zewas-digital.com"}></RefInput>
 */

const RefInput = (props) => {
  return (
    <div>
      <InputContainer type={props.type}>
        {props.type == "textarea" ? (
          <Textarea
            ref={props.forwardedRef}
            defaultValue={props.originValue}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
            maxLength={props.maxLength}
          />
        ) : props.type !== "password" ? (
          <InputField
            type={props.type}
            ref={props.forwardedRef}
            defaultValue={props.originValue}
            placeholder={props.placeholder}
            min={props.min}
            max={props.max}
            onKeyDown={props.onKeyDown}
            maxLength={props.maxLength}
          />
        ) : (
          <InputField
            type={props.type}
            value={props.forwardedRef}
            onChange={(e) => props.onChange(e)}
            name={props.name}
            defaultValue={props.originValue}
            placeholder={props.placeholder}
            min={props.min}
            max={props.max}
            onKeyDown={props.onKeyDown}
          />
        )}
        {props.error && <ErrorText>{props.error}</ErrorText>}
      </InputContainer>
    </div>
  );
};

const InputContainer = styled.div`
  ${cssInputContainer};
  width: ${(props) => (props.type == "date" ? `150px` : null)};

  .error {
    ${styl.shadow("0px 0px 6px 1px rgba(255,0,0,1)")}
  }
`;

const InputField = styled.input`
  ${cssInputField};
  width: ${(props) => (props.type == "date" ? `150px` : null)};
  font-size: 16px;
`;
const ErrorText = styled.p`
  color: ${colors.ErrorRed};
  font-size: 11px;
  font-family: "Montserrat";
  font-weight: 300;
  margin: 0;
  padding: 0;
  text-align: right;
  white-space: pre-wrap;
`;

export default RefInput;
