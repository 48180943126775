import Brand from "../../assets/images/Brand.jpg";
import { React, styled } from "../../imports";
import Alert from "../Alert";
import StyledButton from "../StyledButton";
const NotifyAlert = ({ setShowAlert, loading, email }) => {

  return (
    <Alert isVisible={true}>
      {email ? <ParagraphCenter>Bitte schließe deine Registrierung mit dem erhaltenen E-Mail ab.</ParagraphCenter>:<ParagraphCenter>Einladungen erfolgreich gesendet</ParagraphCenter>}
      <ImageWrapper>
        <ImageLogo alt={"test"} src={Brand}></ImageLogo>
        <div style={{ height: "20px" }} />
      </ImageWrapper>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <StyledButton
          onClick={() => {
            setShowAlert();
          }}
          loading={loading}
          scale={"ORDER"}
          title={"OK"}
          color="#CCB242"
          fontSize={"20px"}
        />
      </div>
    </Alert>

  );
};



const ParagraphCenter = styled.p`
  color: #808080;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-family: "Montserrat";
  font-weight: 300;
  padding: 0;
  text-align: center;
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const ImageLogo = styled.img`
  width: 312px;
  height: auto;
  justify-content: center;
  @media (max-width: 770px){
    width: 240px;
    height: 20%;
  }
`;
export default NotifyAlert;
