import { FaPen } from "react-icons/fa";
import { React, styled, useRef, useState } from "../../imports";
import Alert from "../Alert";
import MultipleSelect from "../MultipleSelect";
import StyledButton from "../StyledButton";

const UserSavedLists = ({
  list,
  removeList,
  editList,
  oldOptions,
  parentPage,
  handleSelectInviteList,
  editMode,
}) => {
  const selectedListRef = useRef();

  const [alert, setShowAlert] = useState(false);

  function checkDisabled(id) {
    let tmpTest = oldOptions.filter((item) => item.id === id);
    if (tmpTest.length != 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <TableRow>
        <td style={{ padding: 0 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 0,
              justifyContent: "flexStart",
            }}
          >
            <MultipleSelect
              paddingZero="FALSE"
              min="220px"
              options={[
                {
                  id: list.id,
                  title: list.name,
                  disabled: checkDisabled(list.id),
                  suborderers: list.suborderers,
                },
              ]}
              optionTitleName={"title"}
              forwardedRef={selectedListRef}
              compareValue={"id"}
              oldOptions={oldOptions}
              onChange={(value) => {
                value.filter((list2) => list2.disabled == false).length === 0
                  ? handleSelectInviteList([
                      {
                        id: list.id,
                        title: false,
                        disabled: false,
                      },
                    ])
                  : handleSelectInviteList(value);
              }}
            />

            {!editMode && (
              <>
                <IconWrapper style={{ marginRight: "5px" }}>
                  <FaPen
                    style={{
                      color: "#B2B2B2",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (oldOptions.some((item) => item.id === list.id)) {
                        setShowAlert(true);
                      } else {
                        editList(list.id);
                      }
                    }}
                  />
                </IconWrapper>
                {/* <IconWrapper>
                <FaTrashAlt
                  style={{
                    color: "#B2B2B2",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    removeList(list.id);
                  }}
                />
              </IconWrapper> */}
              </>
            )}
          </div>
        </td>
      </TableRow>
      {alert && (
        <Alert isVisible={true}>
          <Paragraph>
            Das Ändern bzw. Hinzufügen von Personen hat keinen Einfluss auf
            deine Bestellung
          </Paragraph>
          <StyledButton
            onClick={() => {
              setShowAlert(false);
              editList(list.id);
            }}
            scale={"ORDER"}
            title={"Ok"}
            color="#CCB242"
            fontSize={"20px"}
          />
        </Alert>
      )}
    </>
  );
};

const TableRow = styled.tr`
  padding: 0;
  line-height: 18px;
`;

const IconWrapper = styled.div`
  height: 23px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Paragraph = styled.p`
  color: #808080;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-family: "Montserrat";
  font-weight: 300;
  padding: 0;
  text-align: right;
  line-height: 1.2;
`;

export default UserSavedLists;
