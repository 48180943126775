// Created by:      Julius Lässer
// Copyright:       © 2020 Zewas OG

import { gql, useMutation } from "@apollo/client";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { GetUserContext } from "../localStorage/Store";
import Alert from "./Alert";
import RefInput from "./RefInput";
import StyledButton from "./StyledButton";

const deleteUserMutation = gql`
  mutation MyMutation($password: String!) {
    __typename
    deleteCustomer(input: { clientMutationId: "", password: $password }) {
      deleted
    }
  }
`;
const cookies = new Cookies();
export default function DeleteUserAlert({
  isVisibel,
  setIsVisible: setVisible,
  DataParagraph,
}) {
  const [deleteCustomer, { loading, error }] = useMutation(deleteUserMutation);

  const [errorMessage, setErrorMessage] = useState("");

  const [passwordState, setPasswordState] = useState();
  const [user, setUser] = useContext(GetUserContext);
  const removeUser = () => {
    if (passwordState) {
      setErrorMessage("");
      deleteCustomer({ variables: { password: passwordState } })
        .then((response) => {
          setUser(null);
          cookies.remove("AuthToken", { path: "/" });
          cookies.remove("RefreshToken", { path: "/" });
          window.location.href = "./home";
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    } else {
      setErrorMessage("Bitte bestätige mit deinem Passwort");
    }
  };

  const handlePassword = (e) => {
    setPasswordState(e.target.value);
  };
  return (
    <Alert isVisible={isVisibel}>
      <DataParagraph style={{ textAlign: "right", color: "#333333" }}>
        Dadurch wird dein Profil und alle damit verbundenen Informationen
        gelöscht.
      </DataParagraph>
      <DataParagraph style={{ textAlign: "right", color: "#B2982A" }}>
        Willst du dein Profil wirklich löschen?
      </DataParagraph>
      <RefInput
        forwardedRef={passwordState}
        onChange={(e) => {
          handlePassword(e);
        }}
        type={"password"}
        error="Bitte bestätige mit deinem Passwort"
        placeholder={"Passwort"}
        // onKeyDown={(e) => {
        //   if (e.key === 'Enter') {
        //     handleLogin()
        //   }
        // }}
      ></RefInput>
      <ButtonWrapper>
        <StyledButton
          title="abbrechen"
          color="#CCB242"
          scale="DEFAULT"
          onClick={() => setVisible()}
        />
        <div style={{ width: "5%" }} />
        <StyledButton
          title="löschen"
          loading={loading}
          color="#B2982A"
          scale="DEFAULT"
          onClick={() => {
            removeUser();
          }}
        />
      </ButtonWrapper>
    </Alert>
  );
}

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 770px) {
    margin-top: 10px;
    flex-direction: column;
    height: 85px;
  }
`;
