// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   08.08.2020
// Last edited:     09.08.2020

import React from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';
import styl from '../styl';

/**
 * 
 * @param {String} props.children - Conatains the text to display within the circle.
 * 
 * @example <CircleWithText>Hier gleich loslegen</CircleWithText>
 */

const CircleWithText = ({ children, size, font }) => {
    return (
        <Circle prop_Size={size} prop_Font={font}>
            {children}
        </Circle>
    )
};

const Circle = styled.p`
    ${styl.clear};
    height: ${(props) => props.prop_Size !== undefined ? props.prop_Size + "px" : '205px'};
    width: ${(props) => props.prop_Size !== undefined ? props.prop_Size + "px" : '205px'};;
    border-radius: 50%;
    background: ${colors.OldgoldYellowHomepageSquare};
    font-family: 'Sorts Mill Goudy', serif;
    font-size: ${(props) => props.prop_Font !== undefined ? props.prop_Font + "px" : '25px'};
    color: white;
    ${styl.flexColumn};
    ${styl.flexCenter};
    text-align: center;
    hyphens: auto;
`;

export default CircleWithText;