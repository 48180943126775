import ArrowImage from "../../assets/images/arrow.png";
import { React, styled, useContext, useState } from "../../imports";
import { GetCurrentPage } from "../../localStorage/Store";
import Alert from "../Alert";
import CircleWithText from "../CircleWithText";
import InfoComponent from "../InfoComponent";
import StyledButton from "../StyledButton";

const DurationAlert = ({
  setShowAlert,
  generateLink,
  saveDuration,
  inviteSuborderers,
  type,
}) => {
  const { duration, setDuration, durationFromBackend, setDurationFromBackend } =
    useContext(GetCurrentPage);
  let days = [2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [tmpDuration, setTmpDuration] = useState(duration);
  const [loading, setLoading] = useState(false);

  const handleDuration = () => {
    const e = document.getElementById("data");
    const index = e.options[e.selectedIndex].value;
    const tmpDate = days[index];
    if (tmpDate >= 2 && tmpDate <= 10) {
      setTmpDuration(tmpDate);
    } else if (typeof tmpDate === "undefined") {
      return;
    }
  };

  const handleContinue = () => {
    setLoading(true);
    var d = new Date();
    d.setDate(d.getDate() + parseInt(tmpDuration));
    // setDuration(tmpDate);

    let unix = parseInt((d.getTime() / 1000).toFixed(0));
    if (saveDuration(unix, tmpDuration)) {
      setLoading(false);
    }
  };

  return (
    <Alert isVisible={true}>
      <ParagraphRight>
        Bitte bestätige den Zeitraum, in dem sich eingeladene Personen ab sofort
        auf deiner Bestellliste eintragen können.
      </ParagraphRight>
      <LinkWrapper>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <InfoComponent text="Dauer der Bestellung:	In diesem Zeitraum können sich die eingeladenen Personen auf deiner Bestellliste eintragen. " />

          <div style={{ width: "20px", height: "10px" }} />
          {!durationFromBackend ? (
            <OrderRangeSelect
              name="data"
              id="data"
              value={tmpDuration - 2}
              onChange={() => handleDuration()}
            >
              {days.map((item, index) => (
                <option
                  value={index}
                  key={index}
                  style={{ fontFamily: "Montserrat" }}
                >
                  {item} Tage
                </option>
              ))}
            </OrderRangeSelect>
          ) : //  <CircleWrapper>
          duration.toString().includes("Dauer") ? (
            <CircleWithText>{duration}</CircleWithText>
          ) : (
            <CircleWithText size="70" font="14">
              <br />
              {duration.toString().includes("Uhr") ? (
                <strong> bis {duration}</strong>
              ) : (
                <strong>{duration} Tage</strong>
              )}
              <br />
            </CircleWithText>
          )}
        </div>
        <ButtonBox>
          {!duration.toString().includes("abgelaufen") && (
            <StyledButton
              onClick={() => {
                setShowAlert();
                handleContinue();
              }}
              scale={"ORDER"}
              loading={loading}
              title={"Bestätigen"}
              color="#CCB242"
              fontSize={"20px"}
            />
          )}
          <div style={{ width: "20px", height: "5px" }} />
          <StyledButton
            onClick={() => {
              setShowAlert();
            }}
            scale={"ORDER"}
            title={"abbrechen"}
            color="#8D7833"
            fontSize={"20px"}
          />
        </ButtonBox>
      </LinkWrapper>
    </Alert>
  );
};

const ParagraphRight = styled.p`
  color: #808080;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-family: "Montserrat";
  font-weight: 300;
  padding: 0;
  text-align: right;
  line-height: 1.2;
`;
const OrderRangeSelect = styled.select`
  height: 33px;
  width: 80px;
  border: none;
  background-color: #e1e1e1;
  color: #808080;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: 0;
  background: url(${ArrowImage}) 96% / 15% no-repeat #eee;
  font-family: "Montserrat";
  padding: 3px;
  option {
    font-family: "Montserrat";
    color: #808080;
  }
`;

const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;

  @media (max-width: 770px) {
    flex-direction: column;
    /* height: 300px; */
  }
`;
const ButtonBox = styled.div`
  /* margin-top: 20px; */
  @media (max-width: 770px) {
    margin-top: 20px;
  }
`;
export default DurationAlert;
