import React from "react";
import styled from "styled-components";
import HeadingOne from "../../components/HeadingOne";
import HeadingTwo from "../../components/HeadingTwo";

const AGB = () => {
  return (
    <Wrapper>
      <HeadingOne title="Allgemeine Geschäftsbedingungen" />
      <Paragraph>
        <Text>Melchior Simma</Text>
        <Text>gut gereift im Bregenzerwald</Text>
        <Text>Wieshalde 381</Text>
        <Text>6863 Egg</Text>
        <Text>Bezirksgericht Bezau</Text>
        <Text>UID ATU 75761604</Text>
        <Text>Tel: +43 (0)664 414 28 27</Text>
        <Text>
          E-Mail:{" "}
          <Link href="mailto:info@gutgereift.at">info@gutgereift.at</Link>
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo title="1. Geltungsbereich" underline="false" />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Diese Allgemeinen Geschäftsbedingungen (AGB) liegen allen Verträgen
          zugrunde, die Sie („Kunde“) durch eine Bestellung über{" "}
          <Link href="https://www.gutgereift.at/bestellen">
            www.gutgereift.at/bestellen
          </Link>{" "}
          mit SIMMA Melchior, gut gereift im Bregenzerwald (in der Folge kurz
          „GUTGEREIFT“ genannt) abschließen. Diese AGB gelten daher für alle
          aufgrund derartiger Bestellungen ausgeführte Lieferungen und
          Leistungen von GUTGEREIFT nach Österreich und Deutschland. Durch Ihre
          Registrierung als Kunde oder durch Abgabe einer Bestellung über{" "}
          <Link href="https://www.gutgereift.at/bestellen">
            www.gutgereift.at/bestellen
          </Link>{" "}
          anerkennen Sie die AGB in der jeweils gültigen Version und erklären
          sich mit diesen einverstanden. Die AGB gelten sowohl gegenüber
          Verbrauchern als auch gegenüber Unternehmern, es sei denn, in der
          jeweiligen Klausel wird eine Differenzierung vorgenommen. Die AGB
          gelten ausschließlich, das heißt abweichende oder widersprechende AGB
          werden von GUTGEREIFT nicht akzeptiert. GUTGEREIFT ist berechtigt, die
          AGB jederzeit abzuändern. Für Ihre Bestellung über{" "}
          <Link href="https://www.gutgereift.at/bestellen">
            www.gutgereift.at/bestellen
          </Link>{" "}
          gelten die jeweils zum Zeitpunkt der Bestellung aktuellen AGB.
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo
          title="2. Angebote und Vertragsabschluss"
          underline="false"
        />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Vertrags- und Geschäftssprache ist ausschließlich Deutsch. Das auf{" "}
          <Link href="https://www.gutgereift.at/produkte">
            www.gutgereift.at/produkte
          </Link>{" "}
          dargestellte Sortiment von GUTGEREIFT ist hinsichtlich der
          Verfügbarkeit der Ware freibleibend und unverbindlich. Abbildungen
          stellen lediglich Symbolfotos dar. Lieferbaren
          Produktionsspezifikationen entnehmen Sie bitte der jeweiligen
          Produktdetailseite auf{" "}
          <Link href="https://www.gutgereift.at/produkte">
            www.gutgereift.at/produkte
          </Link>
          . Die Produkte auf{" "}
          <Link href="https://www.gutgereift.at">www.gutgereift.at</Link>{" "}
          stellen noch kein Angebot von GUTGEREIFT dar. Erst durch die Abgabe
          einer elektronischen Bestellung (per Internet) geben Sie ein
          verbindliches Angebot an GUTGEREIFT ab. GUTGEREIFT ist nicht
          verpflichtet, dieses anzunehmen.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          Mit der Verwendung des Features “Gruppenbestellung” können
          Bestellungen in einer Community auf GUTGEREIFT organisiert werden. Die
          Plausibilisierung der Bestellungen hat durch den jeweiligen Besteller
          zu erfolgen – vor allem die Bestellungen der Subbesteller.{" "}
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          Unmittelbar nach Erhalt Ihrer Bestellung, welche ein verbindliches
          Vertragsanbot darstellt, erhalten Sie auf elektronischem Wege eine
          automatisierte Bestellbestätigung. Diese besagt lediglich, dass wir
          Ihre Bestellung erhalten haben. Diese Bestellbestätigung gilt noch
          nicht als Annahme Ihrer Bestellung durch GUTGEREIFT und ein
          verbindlicher Vertrag kommt dadurch jedenfalls noch nicht zustande.
          Ein Vertrag kommt erst mit der Annahme Ihrer Bestellung durch
          GUTGEREIFT und dem Zahlungseingang auf dem Konto IBAN AT22 2060 3000
          0208 3681 lauten auf Melchior Simma – gut gereift im Bregenzerwald -
          zustande.
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo title="3. Preise" underline="false" />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Die angebotenen Preise verstehen sich in Euro inklusive der
          gesetzlichen Mehrwertsteuer und beinhalten sämtliche gesetzliche
          Abgaben. In den angegebenen Preisen sind die allfälligen
          Transportkosten (Zustellgebühren) für Österreich enthalten. Bei
          Versand nach Deutschland sind auf der Rechnung die zusätzlichen
          Transportkosten ersichtlich. Die Mindestbestellmenge beträgt 8 kg. Auf
          Gutscheine gelangen die Bedingungen des jeweiligen Gutscheines zur
          Anwendung.
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo title="4. Zahlungsmodalitäten" underline="false" />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Die Zahlung ist via Überweisung auf das Konto mit dem IBAN AT22 2060
          3000 0208 3681 lauten auf Melchior Simma – gut gereift im
          Bregenzerwald zu erfolgen. Durch den Zahlungseingang erfolgt der
          Vertragsabschluss – siehe Punkt 2.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          <strong>Kauf auf offene Rechnung (Unternehmer)</strong>
        </Text>
        <Text>
          Unternehmen bieten wir die Möglichkeit des Kaufs auf offene Rechnung
          an. Hierbei erhält der Unternehmer gemeinsam mit der Lieferung eine
          Rechnung, deren Rechnungsbetrag binnen 10 Tagen auf das in der
          Rechnung angeführte Konto zu überweisen ist. Voraussetzung für den
          Kauf auf offene Rechnung ist die Angabe der UID-Nummer.
          Eigentumsvorbehalt / Verzugsfolgen: Die Ware bleibt im Eigentum von
          GUTGEREIFT bis zur vollständigen Begleichung aller im Zusammenhang mit
          der jeweiligen Bestellung entstehenden Forderungen von GUTGEREIFT
          durch Sie.
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo title="5. Lieferbedingungen" underline="false" />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Die Lieferfrist von 10 Tagen ab Vertragsabschluss stellt einen
          unverbindlichen Zielwert dar, aus denen der Kunde keinerlei Ansprüche
          ableiten kann. Wird die Lieferung oder die Einhaltung einer
          vereinbarten Lieferzeit durch von GUTGEREIFT nicht zu vertretende und
          unvorhersehbare Ereignisse unmöglich, so wird GUTGEREIFT Sie hierüber
          informieren. Schadenersatzansprüche sind für diesen Fall
          ausgeschlossen. Für den Fall, dass GUTGEREIFT nicht rechtzeitig
          liefert, haben Sie das Recht unter Setzung einer angemessenen
          Nachfrist vom Vertrag zurückzutreten.
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo title="6. Rücktrittsrecht" underline="false" />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Sie haben das Recht von geschlossenen Kaufverträgen binnen vierzehn
          Kalendertagen, gerechnet ab dem Tag, an dem Ihnen die Ware übergeben
          oder von Ihnen abgeholt wurde, ohne Angabe von Gründen zurückzutreten
          – Voraussetzung Ware wurde ordnungsgemäß gelagert. Kein Widerrufsrecht
          besteht jedoch wenn die Produkte, die versiegelt geliefert werden und
          aus Gründen des Gesundheitsschutzes oder aus Hygienegründen nicht zur
          Rückgabe geeignet sind, sofern deren Versiegelung nach der Lieferung
          entfernt wurde (zB geöffnete Vakuumverpackung). Um Ihr Widerrufsrecht
          auszuüben, müssen Sie GUTGEREIFT mittels einer eindeutigen Erklärung
          (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren
          Entschluss, den Vertrag zu widerrufen, informieren.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>per Post an:</Text>
        <Text>Melchior Simma</Text>
        <Text>gut gereift im Bregenzerwald</Text>
        <Text>Wieshalde 381</Text>
        <Text>6863 Egg</Text>
      </Paragraph>
      <Paragraph>
        <Text>per E-Mail an: info@gutgereift.at</Text>
        <Text>per Telefon an: +43 (0)664 414 28 27</Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo title="7. Folgen des Rücktritts" underline="false" />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Rechte und Pflichten von GUTGEREIFT. Wenn Sie den Vertrag mit
          GUTGEREIFT widerrufen, haben wir Ihnen alle Zahlungen, die wir von
          Ihnen erhalten haben, unverzüglich und spätestens binnen vierzehn
          Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung Ihres Widerrufs
          dieses Vertrags bei uns eingegangen ist.
        </Text>
        <Text>
          Für die Rückzahlung verwendet GUTGEREIFT dasselbe Zahlungsmittel, das
          Sie bei der ursprünglichen Transaktion eingesetzt haben.
        </Text>
        <Text>
          Rechte und Pflichten des Kunden. Sie haben die Waren unverzüglich und
          in jedem Fall spätestens binnen 14 Tagen ab dem Tag, an dem Sie uns
          über den Widerruf des Vertrages unterrichten, an GUTGEREIFT
          zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die
          Waren vor Ablauf der Frist von 14 Tagen absenden.
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo title="8. Gewährleistung" underline="false" />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Es bestehen die gesetzlichen Mängelhaftungsrechte. Als Verbraucher
          werden Sie gebeten, die Sache bei Lieferung umgehend auf
          Vollständigkeit, offensichtliche Mängel und Transportschäden zu
          überprüfen und uns sowie dem Spediteur Beanstandungen schnellstmöglich
          mitzuteilen. Kommen Sie dem nicht nach, hat dies keine Auswirkung auf
          Ihre gesetzlichen Gewährleistungsansprüche.
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo title="9. Datenschutz" underline="false" />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Informationen zur Datenverarbeitung durch GUTGEREIFT sowie zu Ihren
          Rechten finden Sie im separaten Punkt{" "}
          <Link href="https://gutgereift.at/datenschutz">Datenschutz</Link>.
        </Text>
      </Paragraph>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
`;

const Paragraph = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50vw;
  @media (max-width: 800px) {
    width: 80vw;
  }
`;

const ParagraphHeading = styled.div`
  margin-top: 40px;
  margin-bottom: 0;
  width: 50vw;
  @media (max-width: 800px) {
    width: 80vw;
  }
`;


const Text = styled.div`
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #333333;
  margin-top: 0;
  text-align: left;
`;

const Link = styled.a`
  color: #b2982a;
  text-decoration: none;
`;

export default AGB;
