import React from "react";
import styled from "styled-components";
import PropTypes from 'prop-types'
import constants from "../constants/constants";
import colors from "../constants/colors";
import styl from "../styl";
import HeadingOne from "./HeadingOne";


// Props: title: String (required)

// Example: <HeadingOne title="Example" />

const CommunityComponent = ({ src, text }) => {
  return (
    <Wrapper>
       <Picture src={src}></Picture>
       <Text>{text}</Text> 
    </Wrapper>
  );
};


const Wrapper = styled.div`
  text-align: left;
  display: inline-block;
  text-align: center;
  max-width: 400px;
  @media (max-width: 1200px){
    margin-bottom: 20px;
  }
  @media (max-width: 1800px){
    max-width: 350px;
  }
  @media (max-width: 1500px){
    max-width: 300px;
  }
`;

CommunityComponent.propTypes = {
    src: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  };
  

  const Picture = styled.img`
  width: auto;
  height: 200px;
  margin-left: ${props => props.format === "row-reverse" ? "0px" : "50px" }; 
  margin-right: ${props => props.format === "row-reverse" ? "50px" : "0px" };
  /* min-width: 300px;
  min-height: 200px; */
  align-content: center;

  @media (min-width: 1700px){
    height: 280px;
  }

  @media (max-width:768px) and (max-height: 1024px){
    height: auto;
    width: 300px;
     
  }
  @media (max-width:800px){
    height: auto;
    max-width: 380px;
    width: 80%;
    margin-left: 0;
    /* margin-bottom: 20px; */
  }
  @media (max-width:${constants.iPhonePortraitWidth}){
    width: 300px;
    /* max-height: 200px; */
  }
`

const Text = styled.p`
    font-size: 15px;
    color: ${colors.CongressBlueHeader};
    text-transform: uppercase;
    text-align: center;
    @media(max-width: 1000px){
      margin-bottom: 40px;
    }
`
  
export default CommunityComponent;
