import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { ApolloLink, fromPromise } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import "./App.css";
import constants from "./constants/constants";
import { getToken } from "./constants/util";
import Store from "./localStorage/Store";
import Router from "./Router";
import DefaultWindow from "./components/DefaultWindow";
import { colors } from "./imports";
import Maintenance from "./pages/maintenance/Maintenance";

const cookies = new Cookies();

let client;

const httpLink = createHttpLink({
  uri: constants.backendUrl,
});

const RefreshTokenMutation = `
mutation RefreshJWTAuthToken( $input: String! ){
    refreshJwtAuthToken( input:{jwtRefreshToken: $input, clientMutationId: "RegisterUser"} ) {
      authToken
    }
  }
`;

async function refreshToken() {
  const token = await cookies.get("RefreshToken");
  return fetch(constants.backendUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query: RefreshTokenMutation,
      variables: {
        input: token,
      },
    }),
  })
    .then((r) => r.json())
    .then((data) => {
      return data.data.refreshJwtAuthToken.authToken;
    });
}

const authLink = setContext(async (req, { headers }) => {
  const token = await getToken();
  return {
    ...headers,
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.message) {
          case "Internal server error":
            cookies.remove("AuthToken", { path: "/bestellhistorie" });
            cookies.remove("RefreshToken", { path: "/bestellhistorie" });
            console.error(err);
            return fromPromise(
              refreshToken().catch((error) => {
                // Handle token refresh errors e.g clear stored tokens, redirect to login
                cookies.remove("AuthToken", { path: "/" });
                cookies.remove("RefreshToken", { path: "/" });
                window.location.href = "./login";
                return;
              })
            )
              .filter((value) => Boolean(value))
              .flatMap((authToken) => {
                const oldHeaders = operation.getContext().headers;
                cookies.set("AuthToken", authToken, { path: "/" });
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${authToken}`,
                  },
                });

                // retry the request, returning the new observable
                return forward(operation);
              });

          default:
            return;
        }
      }
    }
  }
);
const maintenance = false;

// const client = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache: new InMemoryCache(),

// });
client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Store>
        {maintenance ? (
          <DefaultWindow
            showHeader={false}
            footerFontColor={colors.SolidWhiteBackground}
            background={colors.Cararra}
            exact
            path=""
          >
            <Maintenance />
          </DefaultWindow>
        ) : (
          <Router />
        )}
      </Store>
    </ApolloProvider>
  );
}

export default App;
