import React from "react";
import styled from "styled-components";
import Alert from "./Alert";
import { useState } from "react";
import StyledButton from "./StyledButton";
import colors from "../constants/colors";
import constants from "../constants/constants";

const GoodyBanner = ({ goody }) => {
  const [showGoodyAlert, setShowGoodyAlert] = useState(false);
  return (
    <>
      <GoodyBannerComp onClick={() => setShowGoodyAlert(true)}>
        <GoodyContentWrapper>
          {goody?.goodyImage && (
            <GoodyImage src={goody?.goodyImage?.sourceUrl} />
          )}
          <GoodyText>{goody?.goodyText}</GoodyText>
        </GoodyContentWrapper>
      </GoodyBannerComp>
      {showGoodyAlert && (
        <Alert isVisible={true}>
          <AlertContainer>
            <Paragraph>
              <strong>
                <u>Goody</u>
              </strong>
            </Paragraph>
            {goody?.goodyImage && (
              <GoodyImage
                className="alert"
                src={goody?.goodyImage?.sourceUrl}
              />
            )}
            {goody?.goodyText && (
              <Paragraph style={{ textAlign: "center" }}>
                {goody?.goodyText}
              </Paragraph>
            )}
            <StyledButton
              // loading={mutationLoading}
              scale={"ORDER"}
              title={"Schließen"}
              color={colors.YellowBrigthButton}
              fontSize={"20px"}
              onClick={() => {
                // handleCheckAGB();
                setShowGoodyAlert(false);
              }}
            />
          </AlertContainer>
        </Alert>
      )}
    </>
  );
};
const GoodyBannerComp = styled.div`
  width: 100%;
  padding: 8px;
  background: #ccb242;
  position: absolute;
  top: 0;
  /* height: 40px; */
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  @media (max-width: 770px) {
    position: fixed;
    top: 174px;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    padding: 8px 15px;
  }
`;
const GoodyImage = styled.img`
  width: auto;
  height: 100%;
  object-fit: cover;
  max-height: 30px;
  &.alert {
    width: 300px;
    height: auto;
    max-height: unset;
  }
`;

const GoodyContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  height: 100%;
  @media (max-width: 770px) {
    justify-content: space-around;
  }
  @media (max-width: ${constants.iPhonePortraitWidth}px) {
    gap: 5px;
  }
`;

const AlertContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const Paragraph = styled.p`
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #333333;
  margin-top: 0;
  text-align: left;
`;
const GoodyText = styled(Paragraph)`
  margin: auto;
  color: white;
  text-align: center;
  hyphens: auto;
  color: ${colors.SolidGreyText};
  text-decoration: underline;
  font-weight: 500;
`;

export default GoodyBanner;
