// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   13.11.2020
// Last edited:     13.11.2020

import React from 'react';
import InviteList from '../../components/inviteList/InviteList';
import OrderContainer from "../../components/OrderContainer";

const InvitePage = () => {
    const navigation = [{actualName: ["Community verwalten"]}, {codeName: ["Invite2"]}]
    return (
      <OrderContainer
          currentPage={"Invite2"}
          navigation={navigation}
          clickable={false}
      >
        <InviteList parentPage="einladen"></InviteList>
      </OrderContainer>
    )
};

export default InvitePage;