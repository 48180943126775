import React, { useEffect } from "react";
import styled from "styled-components";
import Martin from "../../assets/images/martin_dorner.jpg";
import Milchkannen from "../../assets/images/milchkannen.jpg";
import Muehle from "../../assets/images/muehle_egg.jpg";
import Richard from "../../assets/images/Richard_natter.jpg";
import Falz from "../../assets/images/untere_falz.jpg";
import Wolfgang from "../../assets/images/wolfgang_simma.jpg";
import HeadingOne from "../../components/HeadingOne";
import HeadingTwo from "../../components/HeadingTwo";
import PictureText from "../../components/PictureText";
import Quote from "../../components/Quote";

// Import Images

export default function AttitudePage() {
  useEffect(() => {
    document.title = "Partner";
  }, []);

  return (
    <Main>
      <ContentContainer>
        <TopWrapper>
          <WrapperWithPicture>
            <HeadingOne title="Unsere Partner" />
          </WrapperWithPicture>
          <QuoteDiv>
            <Quote
              mainText={["Anônd im", <br />, "Wort schtông"]}
              description={["sein Wort halten"]}
            />
          </QuoteDiv>
        </TopWrapper>
        <PictureTextDiv>
          <HeadingBox>
            <HeadingTwo title="Wolfgang Simma" color="DEFAULT" />
          </HeadingBox>
          <DesktopWrapper>
            <PictureText
              text="
                Melchiors Bruder Wolfgang bewirtschaftet als Ältester von 6 Kindern den elterlichen Hof. Mit seiner Frau Herlinde hat er drei Töchter und zwei Söhne. 30 Kühe, 20 Rinder, Hühner und drei Schweine füllen das Tagwerk der Familie. Wenn Zeit übrig ist, hilft Melchior an und wann auf dem Hof seines Bruders aus – am liebsten im Sommer beim Heuen in Handarbeit. Im Gegenzug hilft Wolfgang mit seinem Fuhrpark und handwerklichen Geschick oftmals spontan aus. Der in der dritten Generation geführte landwirtschaftliche Betrieb baut die Brücke zu weiteren Kooperationspartnern. In der Sennerei Mühle-Hub auf dem Vorsäß Eggats-/Hammeratsberg und der Alpe Untere Falz wird die Milch der Kühe zu Käse versennt.
              "
              subtitle={[<strong>Wolfgang Simma</strong>, ", Landwirt"]}
              picture={Wolfgang}
              format={"row"}
            ></PictureText>
          </DesktopWrapper>
          <HeadingBox>
            <HeadingTwo title="Dorfsennereien" color="DEFAULT" />
          </HeadingBox>
          <DesktopWrapper>
            <PictureText
              text="
              Im Bregenzerwald gibt es nur noch einzelne Dorfsennereien, die klein strukturiert sind und in traditioneller Manier handgesennten Bergkäse herstellen. Dabei wird die Milch in holzbefeuerten Kupferkesseln erhitzt, die eingelabte und gestockte Käsemasse von Hand mit dem Käsetuch ausgenommen und in Formen gepresst. In den Wintermonaten wird in den Dorfsennereien die rar gewordene Sennsuppe ausgegeben. Die heiße Molke wird im Anschluss zur Reinigung des Sennlokals verwendet. Diese ist fettlösend und antibakteriell. Die Hände der Senninnen und Sennen geben das Maß der zu verarbeitenden Milchmenge in diesen Sennereien vor.              
              "
              subtitle={[<strong>Sennereigebäude</strong>, ", Baujahr 1910"]}
              picture={Muehle}
              format={"row"}
            ></PictureText>
          </DesktopWrapper>
          <HeadingBox>
            <HeadingTwo title="Alpe Untere Falz" color="DEFAULT" />
          </HeadingBox>
          <DesktopWrapper>
            <PictureText
              text="
                Die Alpe Untere Falz wird in den Sommermonaten auf einer Alpfläche von rund 58 ha mit knapp 100 Kühen bewirtschaftet. Die Milch der etwas höher gelegenen Nachbaralpe Isewart, als Gemeinschaftsalpe gehört sie zur Unteren Falz dazu, wird zweimal täglich mit der Seilbahn heruntergeführt und ebenfalls in der Sennküche der Alpe Untere Falz verarbeitet.              
              "
              subtitle={["Alpgebäude ", <strong>Untere Falz</strong>]}
              picture={Falz}
              format={"row"}
            ></PictureText>
          </DesktopWrapper>
          <HeadingBox>
            <HeadingTwo title="Vorsäß Eggats-/Hammeratsberg" color="DEFAULT" />
          </HeadingBox>
          <DesktopWrapper>
            <PictureText
              text="
                Das Vorsäß Eggats-/Hammeratsberg liegt wie die Alpe Untere Falz ebenfalls im Gemeindegebiet Egg und wird im Frühjahr und Herbst mit ca. 70 Kühen bewirtschaftet. Das Vorsäß erwacht in dieser Zeit zum kleinen Dorf. Dreh- und Angelpunkt ist das Sennhaus. Der Strukturwandel in der Landwirtschaft hat dazu geführt, dass heute nur noch wenige Hüttenbesitzer auch Bauern sind. Das stellt die agrarstrukturierte Gemeinschaft vor neue Herausforderungen, nicht nur als Landschaftspfleger. Auf dem Vorsäß Eggats-Hammeratsberg wird die Milch nach wie vor in Gebsen (Gefäß aus Fichtenholz) zu Käse versennt – eine alt überlieferte Herstellungsweise.
              "
              subtitle={[<strong>Martin Dorner</strong>, ", Senn"]}
              picture={Martin}
              format={"row"}
            ></PictureText>
          </DesktopWrapper>
          <HeadingBox>
            <HeadingTwo
              title="Ausgewählte Alpen im Bregenzerwald"
              color="DEFAULT"
            />
          </HeadingBox>
          <DesktopWrapper>
            <PictureText
              text="
                Talent und viel Gespür. Das bringen die Sennen dieser Alpen mit. Sie verbringen den ganzen Sommer mit den Kühen und ihrer Familie auf diesen Höhenlagen und wissen worauf es ankommt: Zusammenhalt und genaues Hinschauen auf das Tier, die Milch, den Käse. Jeden Tag.
              "
              subtitle={[<strong>Richard Natter</strong>, ", Senn"]}
              picture={Richard}
              format={"row"}
            ></PictureText>
          </DesktopWrapper>
          <HeadingBox>
            <HeadingTwo
              title="Käsereibetriebe im Bregenzerwald"
              color="DEFAULT"
            />
          </HeadingBox>
          <DesktopWrapper>
            <PictureText
              text="
                In diesen Talbetrieben wird das ganze Jahr über gesennt. Aufgrund der im Bregenzerwald gelebten Stufenlandwirtschaft ist flexibles Handeln gefordert, da die die zu verarbeitenden Milchmengen im Laufe eines Jahres variieren. Von diesen Betrieben beziehen wir den Schnittkäse in konstant guter Qualität.
              "
              picture={Milchkannen}
              format={"row"}
            ></PictureText>
          </DesktopWrapper>
          <HeadingBox>
            <HeadingTwo title="Stimmen unserer Kunden" color="DEFAULT" />
          </HeadingBox>
          <CustomerWrapper>
            <Customer>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "10%",
                }}
              >
                {/* <img
                  src={Placeholder}
                  style={{ width: "56px", height: "56px" }}
                /> */}
              </div>
              <CustomerHeading>Bernhard Koppler, Linz</CustomerHeading>
              <CustomerParagraph>
                „Mittlerweile bestellen Familie, Kolleginnen und Kollegen mit
                mir. Der Bestellkreis wächst von Woche zu Woche. Die Abwicklung
                ist unkompliziert und der Käse einfach klasse! Das Wissen durch
                meine Sammelbestellung klein strukturiertes, lokales Handwerk zu
                unterstützen, steigert den Genuss nochmals.“
              </CustomerParagraph>
            </Customer>
            <Customer>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "10%",
                }}
              >
                {/* <img
                  src={Placeholder}
                  style={{ width: "56px", height: "56px" }}
                /> */}
              </div>
              <CustomerHeading>Belinda Schoder, Feldkirch</CustomerHeading>
              <CustomerParagraph>
                „Ich liebe den feinen Käse! Alle Sorten sind köstlich. Die
                Produktion von regionalen Produkten gehört gestärkt und ist
                wichtiger denn je. Das finden auch viele meiner Bekannten, die
                mittlerweile gerne eine Sammelbestellung aufgeben, welche rasch
                und unkompliziert abgewickelt wird.“
              </CustomerParagraph>
            </Customer>
            <Customer>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "10%",
                }}
              >
                {/* <img
                  src={Placeholder}
                  style={{ width: "56px", height: "56px" }}
                /> */}
              </div>
              <CustomerHeading>
                Christian Eggenhofer, Schönkirchen
              </CustomerHeading>
              <CustomerParagraph>
                „Unseren Käse beziehen wir schon seit längerer Zeit aus dem
                Bregenzerwald. Die hohe Qualität und der gute Geschmack zu einem
                wirklich fairen Preis haben sich auch schon bei FreundInnen und
                KollegInnen herumgesprochen. Die Bestelllisten werden somit
                immer länger. Wir sind sehr zufrieden und freuen uns darüber,
                ein kleines Familienunternehmen unterstützen zu können.“
              </CustomerParagraph>
            </Customer>
          </CustomerWrapper>
        </PictureTextDiv>
      </ContentContainer>
    </Main>
  );
}

const Main = styled.div`
  /* height: 100vh; */
  padding-bottom: 25px;
  width: 100vw;
  max-width: 100%;
  @media (max-width: 1980px) and (max-height: 1080px) {
    display: flex;
    justify-content: center;
  }
`;
const QuoteDiv = styled.div`
  display: flex;
  /* justify-content: flex-end; */
  margin-bottom: 40px;
  max-width: 70vw;
  @media (max-width: 950px) {
    display: none;
  }
`;

const DesktopWrapper = styled.div`
  /* display: block; */
  /* width: 80vw; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 960px;
  @media (max-width: 1600px) {
    flex-direction: column;
    width: 70vw;
  }
  @media (max-width: 1100px) {
    margin-bottom: 7vh;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 960px;
  width: 960px;
  /* margin-left: 6vw; */
  @media (max-width: 1700px) {
    /* margin-left: 2vw; */
  }
  @media (max-width: 1100px) {
    width: 80vw;
    margin-left: 0;
  }
`;

const WrapperWithPicture = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  @media (max-width: 1000px) {
    margin-bottom: 50px;
  }
  @media (max-width: 770px) {
    margin-left: 10%;
  }
`;

const PictureTextDiv = styled.div`
  width: 60vw;
  max-width: 100vw;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (max-width: 1300px) {
    width: 90vw;
    margin-top: 0;
  }
  @media (max-width: 1100px) {
    width: 100vw;
    margin-top: 0;
    margin-left: 0;
  }
`;
const ContentContainer = styled.div`
  width: 90vw;
  margin-left: 28vw;
  display: flex;
  flex-direction: column;
  @media (max-width: 1980px) and (max-height: 1080px) {
    margin-top: 75px;
  }
  @media (max-width: 1700px) {
    margin-left: 20vw;
  }
  @media (max-width: 1400px) {
    width: 90vw;
    margin-left: 5vw;
  }
  @media (max-width: 1100px) {
    width: 100vw;
    margin-left: 0;
  }
  @media (max-width: 770px) {
    margin-top: 20px;
  }
`;

const HeadingBox = styled.div`
  margin-bottom: 20px;
  @media (max-width: 1100px) {
    height: auto;
    /* max-height: 380px; */
    width: 80vw;
    margin-left: 10vw;
    margin-bottom: 20px;
  }
`;

const CustomerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
  margin-top: 50px;
  @media (max-width: 1100px) {
    height: auto;
    /* max-height: 380px; */
    width: 80vw;
    margin-left: 10vw;
    margin-bottom: 20px;
  }
  @media (max-width: 940px) {
    flex-direction: column;
  }
`;

const Customer = styled.div`
  width: 30%;
  background-color: white;
  display: flex;
  flex-direction: column;
  @media (max-width: 940px) {
    margin-bottom: 3vh;
    width: 95%;
  }
`;

const CustomerHeading = styled.h4`
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  padding: 0 15%;
`;

const CustomerParagraph = styled.p`
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 16px;
  color: #333333;
  text-align: center;
  padding: 0 15%;
  padding-bottom: 10%;
`;
