import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "../constants/colors"
import constants from "../constants/constants"
import styl from "../styl";

// Props: 
// - mainText: String (required)
// -- big golden text in quote marks
// - description: String (required)
// -- small blue description text under mainText
// . colors: String
// -- one of "DEFAULT" or "LIGHT"

// Example: <Quote mainText="blos ned hudla" description="gut ding braucht weile" color="LIGHT/>

const Quote = ({ mainText, description, color, size, width, align }) => {
  return (
    <Wrapper>
      <H1 prop_Align={align} prop_Color={color} prop_Size={size}><q>{mainText}</q></H1>
      <H2 prop_Align={align} prop_Color={color} prop_Size={size} prop_Width={width}>{description}</H2>
    </Wrapper>
  );
};

Quote.propTypes = {
  mainText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["DEFAULT", "LIGHT"]),
  size: PropTypes.oneOf(["DEFAULT", "SMALL"]),
  width: PropTypes.string,
  align: PropTypes.oneOf(['LEFT, RIGHT'])
};

Quote.defaultProps = {
  color: "DEFAULT",
  size: "DEFAULT",
  align: 'RIGHT'
};

const Wrapper = styled.div`
  text-align: left;
  display: inline-block;
  margin: 30px;
`;

const H1 = styled.p`
  color: ${props => props.prop_Color === "LIGHT" ? colors.QuoteLightBrown : colors.PestoYellowHomepageSquare};
  font-size: ${props => props.prop_Size === "SMALL" ? '40px' : '50px'};
  font-family: 'Sorts Mill Goudy', serif;
  font-style: italic;
  font-weight: 100;
  line-height: ${props => props.prop_Size === "SMALL" ? '50px' : '65px'};
  margin: 0;
  text-align: ${props => props.prop_Align === "RIGHT" ? 'right' : 'left'};
  max-width: 100%;

  @media (max-width: ${constants.nexusLandscapeWidth}px) {
    font-size: ${props => props.prop_Size === "SMALL" ? '25px' : '40px'};
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    font-size: 76px;
  }
  @media (max-width: ${constants.iPhonePortraitWidth}px) {
    font-size: 41px;
  }
`;

const H2 = styled.p`
  color:${props => props.prop_Color === "LIGHT" ? colors.QuoteLightBrown : colors.SolidGreyText};
  font-size: ${props => props.prop_Size === "SMALL" ? '15px' : '20px'};
  ${styl.lightDefaultFont};
  text-transform: uppercase; 
  line-height: 24px;
  text-align: ${props => props.prop_Align === "RIGHT" ? 'right' : 'left'};
  margin: 0;
  font-weight: 400;
  max-width: ${props => props.prop_Width ? props.prop_Width+"%" : '100%'};
`;


export default Quote;