import React, { useEffect } from "react";
import styled from "styled-components";
import communitiesImage from "../../assets/images/philosophie/communities.jpg";
import gutesEntdeckenImage from "../../assets/images/philosophie/gutes_entdecken.jpg";
import philosophieImage from "../../assets/images/philosophie/philosophieimage.jpg";
import HeadingOne from "./../../components/HeadingOne";
import PhilosophieText from "./../../components/PhilosophieText";
import StyledButton from "./../../components/StyledButton";
import constants from "./../../constants/constants";


const PhilosophiePage = () => {
  useEffect(() => {
    document.title = "Philosophie";
  }, []);
  return (
    <Wrapper>
      <Box>
        <LeftBox>
          <HeadingOneWrapper>
            <HeadingOne title="Philosophie" />
          </HeadingOneWrapper>

          <Content>
            <ContentBox>
              <ShortImageHaltung />
              <PhilosophieText
                heading="Haltung"
                description="Wir erhalten die kleinen Strukturen auf der Produktionsseite und kreieren neue auf der Kundenseite. Kooperation und Netzwerken, die wesentlichen Grundpfeiler der traditionellen Alp- und Sennereigenossenschaften, sind uns vertraut. Die Gründung von Bestellgemeinschaften ist eine Stärkung dieses sozialen und nachhaltigen Gedankens. Ganz wie im Handwerk Usus sind wir persönlich ansprechbar und greifbar. "
              />
              <ButtonBox>
                <StyledButton
                  title="mehr erfahren"
                  onClick={() => {
                    window.location.href = "/haltung";
                  }}
                  color="#CCB242"
                />
              </ButtonBox>
            </ContentBox>
            <ContentBoxImg>
              <Img />
            </ContentBoxImg>
            <ContentBox>
              <ShortImageCommunities />
              <PhilosophieText
                heading="Communities"
                description="Digital und sozial heißt die Devise, wenn wir das Gemeinschaftliche mit digitalen Möglichkeiten in einer direkten Vermarktung verbinden. Über den Aufbau neuer Communities erzeugen wir eigene Kreisläufe innerhalb von Familien, Vereinen und Stammtischen, Firmen und Freundeskreisen. Der/die BestellinitiatorIn sammelt über gutgereift.at die Einzelbestellungen aus einer Gruppe und schickt sie ab. Einzeln beschriftet und verpackt, in einem Paket gesammelt, erhält der/die BestellinitiatorIn die Produkte zur Verteilung. Auch eine Einzelperson kann bestellen, wenn die Mindestgesamtmenge 8 kg beträgt. "
              />
              <ButtonBox>
                <StyledButton
                  title="so funktioniert's"
                  onClick={() => {
                    window.location.href = "/community";
                  }}
                  color="#B2982A"
                />
              </ButtonBox>
            </ContentBox>
            <ContentBox>
              <ShortImageGutesEntdecken />
              <PhilosophieText
                heading="Gutes entdecken"
                description="Hier aufgewachsen kennt Hilda die Menschen, guten Orte und Plätze im Bregenzerwald. Die beschaulichen Dörfer und Häuser, die Kultur und das Handwerk sind ihr vertraut. Wer den Bregenzerwald in seiner Vielfalt erleben möchte, bekommt von ihr die passenden Tipps."
              />
              <ButtonBox>
                <StyledButton
                  title="jetzt entdecken"
                  onClick={() => {
                    window.location.href = "/entdecken";
                  }}
                  color="#8D7833"
                />
              </ButtonBox>
            </ContentBox>
          </Content>
        </LeftBox>
        <RightBox>
          <Image />
        </RightBox>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-bottom: 40px;
  }
  @media (max-width: 770px){
    margin-top: 20px;
  }
`;

const Box = styled.div`
  width: 91%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1500px) {
    flex-direction: column;
  }

  @media (min-width: 1920px) {
    width: 1745px;
  }
`;

const Content = styled.div`
  /* width: 100%; */
  /* display: flex; */
  flex-direction: row;
  margin-top: 40px;
  display: grid;
  grid-template-columns: auto auto auto;

  @media (max-width: 1500px) {
    width: 100%;
    margin-top: 20px;
    grid-template-columns: auto auto;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    flex-direction: column;
    padding-top: 10px;
    grid-template-columns: auto;
  }
`;

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 300px;

  @media (max-width: 1500px) {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    flex-direction: column;
    margin-top: 5px;
    margin-left: 20px;
  }
`;

const ContentBoxImg = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  display: none;

  @media (max-width: 1500px) {
    display: block;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    flex-direction: column;
    margin-top: 5px;
  }
`;

const ButtonBox = styled.div`
  margin-top: 20px;
`;

const LeftBox = styled.div`
  width: 60%;

  @media (max-width: 1500px) {
    width: 100%;
  }
`;
const RightBox = styled.div`
  width: 40%;

  @media (max-width: 1500px) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    flex-direction: column;
  }
`;

const Image = styled.div`
  height: 70%;
  width: 100%;
  background-image: url(${philosophieImage});
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  @media (max-width: 1500px) {
    display: none;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    width: 97%;
  }
`;

const Img = styled.div`
  height: 90%;
  width: 95%;
  background-image: url(${philosophieImage});
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
`;

const ShortImageHaltung = styled.div`
  display: none;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    display: block;
    background-image: url(${philosophieImage});
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    width: 100%;
    height: 250px;
    margin: 20px 0 20px 0;
  }
`;

const ShortImageCommunities = styled.div`
  display: none;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    display: block;
    background-image: url(${communitiesImage});
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    width: 100%;
    height: 250px;
    margin: 20px 0 20px 0;
  }
`;

const ShortImageGutesEntdecken = styled.div`
  display: none;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    display: block;
    background-image: url(${gutesEntdeckenImage});
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    width: 100%;
    height: 250px;
    margin: 20px 0 20px 0;
  }
`;

const HeadingOneWrapper = styled.div`
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin: 0;
    margin-left: 20px;
  }
`;

export default PhilosophiePage;
