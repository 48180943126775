import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'
import HeaderTwo from "./HeadingTwo";
import styl from "../styl";
import colors from "../constants/colors"
/**
 * @author Sandro Mennel
 * @param {string} text text of the component (required)
 * @param {string} header the text of the HeaderTwo (required)
 * @example  <HeaderText header={header} text={text}></HeaderText>
 */
export default function HeaderText({header, text}) {

  
  return (
      <Main>
        <Content>
            <HeaderTwo title={header}></HeaderTwo>
            <Text >{text}</Text>
        </Content>
        
      </Main>
  );
}
HeaderText.propTypes = {
  text: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};


const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;
const Text = styled.p`
  text-align: left;
  font-size: 14px;
  ${styl.lightDefaultFont};
  color: ${colors.SolidBlackText};
  line-height: 20px;
  font-weight: 300;
  max-width: 580px;
`;
