import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeadingOne from "../../components/HeadingOne";
import HeadingTwo from "../../components/HeadingTwo";
import { constants } from "../../imports";

const Impressum = () => {
  const [imprint, setImprint] = useState(null);

  useEffect(() => {
    (async () => {
      await fetch(constants.imprintUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: constants.imprintBearer,
        },
      })
        .then((r) => r.json())
        .then((data) => {
          setImprint(data.data.attributes);
        });
    })();
  }, []);

  return (
    <Wrapper>
      <HeadingOne title="Impressum" />
      <Paragraph>
        <Text>
          Informationspflicht laut §5 E-Commerce Gesetz, §14
          Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht
          laut §25 Mediengesetz.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          <strong>gut gereift im Bregenzerwald</strong>
        </Text>
        <Text>Melchior Simma</Text>
        <Text>Wieshalde 381</Text>
        <Text>6863 Egg</Text>
        <Text>Austria</Text>
      </Paragraph>
      <Paragraph>
        <Text>Unternehmensgegenstand: Handel mit Agrarprodukten</Text>
        <Text>UID-Nummer: ATU 75761604</Text>
        <Text>Tel.: +43 664 414 28 27</Text>
        <Text>
          E-Mail:{" "}
          <Link href="mailto:info@gutgereift.at">info@gutgereift.at</Link>
        </Text>
        <Text>
          Aufsichtsbehörde/Gewerbebehörde: Bezirkshauptmannschaft Bregenz
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          <strong>Konzeption und Grafik</strong>
        </Text>
        <Text>Melchior Simma</Text>
        <Text>Hilda Simma</Text>
        <Text>Renate Breuss (Inhaltliche Konzeption)</Text>
        <Text>Jodok Simma (Technische Konzeption)</Text>
      </Paragraph>
      <Paragraph>
        <Text>
          <strong>Grafik</strong>
        </Text>
        <Text>
          Sonja Matthees –
          <Link href="https://www.sonzilla.com/"> Sonzilla</Link>
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          <strong>Fotografie</strong>
        </Text>
        <Text>
          Die Bilder, Fotos und Grafiken auf dieser Webseite sind
          urheberrechtlich geschützt.
        </Text>
        <Text>
          Die Bildrechte liegen bei den folgenden Fotografen und Unternehmen:
        </Text>
        <Text>Martin Winkler</Text>
        <Text>
          Thomas Steinlechner{" "}
          <Link href="https://www.thomas-steinlechner.com/">
            www.thomas-steinlechner.com
          </Link>
        </Text>
      </Paragraph>
      {imprint && (
        <Paragraph>
          <Text>
            <strong>{imprint.heading}</strong>
          </Text>
          <Text>
            {imprint.name.data.attributes.name}{" "}
            <Link href={imprint.website.data.attributes.link}>
              www.{imprint.website.data.attributes.link.split("https://")}
            </Link>
          </Text>
          <Text>{imprint.address.data.attributes.name}</Text>
        </Paragraph>
      )}
      <ParagraphHeading>
        <HeadingTwo
          title="Haftung für Inhalte dieser Webseite"
          underline="false"
        />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen
          uns korrekte und aktuelle Informationen bereitzustellen. Leider können
          wir keine Haftung für die Korrektheit aller Inhalte auf dieser
          Webseite übernehmen, speziell für jene die seitens Dritter
          bereitgestellt wurden.
        </Text>
        <Text>
          Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen,
          bitten wir Sie uns umgehend zu kontaktieren, Sie finden die
          Kontaktdaten im Impressum.
        </Text>
        <Text>Haftung für Links auf dieser Webseite</Text>
        <Text>
          Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt
          wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht
          laut{" "}
          <Link href="https://www.ris.bka.gv.at/Dokument.wxe?Abfrage=Bundesnormen&Dokumentnummer=NOR40025813">
            § 17 ECG
          </Link>{" "}
          für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten
          und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen
          sind und wir Links sofort entfernen würden, wenn uns
          Rechtswidrigkeiten bekannt werden.
        </Text>
        <Text>
          Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten
          wir Sie uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo title="Urheberrechtshinweis" underline="false" />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
          unterliegen dem Urheberrecht. Falls notwendig, werden wir die
          unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich
          verfolgen. Sollten Sie auf dieser Webseite Inhalte finden, die das
          Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <Text>
          <HeadingTwo title="EU-Streitschlichtung" underline="false" />
        </Text>
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Gemäß Verordnung über Online-Streitbeilegung in
          Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die
          Online-Streitbeilegungsplattform (OS-Plattform) informieren.
        </Text>
        <Text>
          Verbraucher haben die Möglichkeit, Beschwerden an die Online
          Streitbeilegungsplattform der Europäischen Kommission unter
          <Link href="http://ec.europa.eu/odr?tid=121437256">
            http://ec.europa.eu/odr?tid=121437256
          </Link>{" "}
          zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in
          unserem Impressum.
        </Text>
        <Text>
          Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder
          verpflichtet sind, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </Text>
      </Paragraph>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
`;

const Paragraph = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50vw;
  @media (max-width: 800px) {
    width: 80vw;
  }
`;

const ParagraphHeading = styled.div`
  margin-top: 40px;
  margin-bottom: 0;
  width: 50vw;
  @media (max-width: 800px) {
    width: 80vw;
  }
`;

const Text = styled.div`
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #333333;
  margin-top: 0;
  text-align: left;
`;

const Link = styled.a`
  color: #b2982a;
  text-decoration: none;
`;

export default Impressum;
