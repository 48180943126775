// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   08.08.2020
// Last edited:     09.08.2020

import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import einzelbestellung from "../../assets/images/Einzelbestellung.png";
import gruppenbestellung from "../../assets/images/Gruppenbestellung.png";
import Alert from "../../components/Alert";
import CircleWithText from "../../components/CircleWithText";
import HeadingOne from "../../components/HeadingOne";
import Login from "../../components/Login";
import StyledButton from "../../components/StyledButton";
import colors from "../../constants/colors";
import constants from "../../constants/constants";
import { GetFallbackURL, GetUserContext } from "../../localStorage/Store";
import styl from "../../styl";
import GoodyBanner from "../../components/GoodyBanner";

const GetExtCartQuery = gql`
  query getExtCart {
    __typename
    extCart {
      addProductsUntil
    }
  }
`;
const GetGoodyOptions = gql`
  query getGoodyOptions {
    generalOptions {
      goody {
        goodyText
        goodyValidUntil
        goodyImage {
          uri
          link
          altText
          sourceUrl
        }
      }
    }
  }
`;
const SwitchToSingleBuyerMutation = gql`
  mutation MyMutation {
    __typename
    switchToIndividualOrder(input: { clientMutationId: "switch" }) {
      cart {
        total
      }
    }
  }
`;
const StartOrderPage = () => {
  const [user, setUser] = useContext(GetUserContext);
  const [url, setURL] = useContext(GetFallbackURL);
  const [alert, setAlert] = useState(true);
  const [goodyUntil, setGoodyUntil] = useState();

  const [showRemoveOrderAlert, setShowRemoveOrderAlert] = useState(false);
  const [SwitchToIndividualOrder, { loading: loading3, error: error12 }] =
    useMutation(SwitchToSingleBuyerMutation);

  const { data: extCartData } = useQuery(GetExtCartQuery);
  const { data: goodyData } = useQuery(GetGoodyOptions);

  useEffect(() => {
    document.title = "bestellen";
  }, []);

  useEffect(() => {
    if (extCartData) {
      if (
        extCartData.extCart &&
        extCartData.extCart.addProductsUntil &&
        extCartData.extCart.addProductsUntil !== 0 &&
        extCartData.extCart.addProductsUntil !== null
      ) {
        let date = new Date(extCartData.extCart?.addProductsUntil * 1000);
        let currentDate = new Date();

        // let day = parseInt(Math.round(date.getTime() - currentDate.getTime())/ (1000 * 60 * 60 * 24))|| 0
        let tmpTime = date.getTime() - currentDate.getTime();
        var tmpDays = tmpTime / (1000 * 3600 * 24);
        if (tmpDays < -5) {
          setShowRemoveOrderAlert(true);
        }
      }
    }
  }, [extCartData]);

  useEffect(() => {
    if (goodyData?.generalOptions?.goody?.goodyValidUntil) {
      const goodyDate =
        goodyData?.generalOptions?.goody?.goodyValidUntil?.split(".");
      if (goodyDate)
        setGoodyUntil(
          new Date(`${goodyDate[2]}-${goodyDate[1]}-${goodyDate[0]}`)
        );
    }
  }, [goodyData]);

  return (
    <SplitScreen>
      {new Date().setHours(0, 0, 0, 0) <= goodyUntil && (
        <GoodyBanner goody={goodyData?.generalOptions?.goody} />
      )}

      <SplitScreenItem
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.location.href = "./einzeln";
        }}
      >
        <Layer1Colum>
          <HeadingOne title="Einzelbestellung" />
          <Layer2Row>
            <Image url={einzelbestellung}></Image>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "50%",
                justifyContent: "center",
              }}
            >
              <LeftCircle>
                <CircleWithText size="150">Hier gleich loslegen</CircleWithText>
              </LeftCircle>
            </div>
          </Layer2Row>
        </Layer1Colum>
      </SplitScreenItem>
      <SplitScreenItem
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.location.href = "./gemeinsam";
        }}
      >
        <Layer1Colum>
          <span style={{ cursor: "pointer" }}>
            <HeadingOne title="Gruppenbestellung" />
          </span>
          <Layer2Row className="gemeinsam">
            <Mobile>
              <RightCircle>
                <CircleWithText size="150">Hier gleich loslegen</CircleWithText>
              </RightCircle>
            </Mobile>
            <Desktop>
              <RightCircle
                data-value="child"
                onClick={(e) => {
                  e.stopPropagation();

                  window.location.href = "./community";
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "flex-end",
                  // marginBottom: '25px'
                }}
              >
                <CircleWithText size="150" font="25">
                  So <br /> funktioniert's{" "}
                  {[
                    <p
                      style={{
                        lineHeight: "1px",
                        height: "1px",
                        fontSize: "15px",
                      }}
                    />,
                  ]}
                </CircleWithText>
              </RightCircle>
            </Desktop>
          </Layer2Row>
          <RightWrapper>
            <Image2 url={gruppenbestellung}></Image2>
            <Mobile>
              <LeftCircle
                onClick={(e) => {
                  e.stopPropagation();

                  window.location.href = "./community";
                }}
                data-value="child"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  marginBottom: "25px",
                }}
              >
                <CircleWithText size="150" font="25">
                  So <br /> funktioniert's{" "}
                  {[
                    <p
                      style={{
                        lineHeight: "1px",
                        height: "1px",
                        fontSize: "15px",
                      }}
                    />,
                  ]}
                </CircleWithText>
              </LeftCircle>
            </Mobile>
            <Desktop>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <RightCircle>
                  <CircleWithText size="150">
                    Hier gleich loslegen
                  </CircleWithText>
                </RightCircle>
              </div>
            </Desktop>
          </RightWrapper>
        </Layer1Colum>
      </SplitScreenItem>
      {showRemoveOrderAlert && (
        <Alert isVisible={true}>
          <Paragraph>
            Bestelldauer ist abgelaufen. Soll diese abgebrochen werden?
          </Paragraph>
          <StyledButton
            // loading={mutationLoading}
            scale={"ORDER"}
            title={"Bestätigen"}
            color={colors.YellowBrigthButton}
            fontSize={"20px"}
            onClick={() => {
              SwitchToIndividualOrder().then((response) => {
                setShowRemoveOrderAlert(false);
              });
            }}
          />
          <StyledButton
            // loading={mutationLoading}
            scale={"ORDER"}
            title={"Abbrechen"}
            color={colors.YellowBrigthButton}
            fontSize={"20px"}
            onClick={() => {
              // handleCheckAGB();
              setShowRemoveOrderAlert(false);
            }}
          />
        </Alert>
      )}
    </SplitScreen>
  );
};

const Layer1Colum = styled.div`
  ${styl.flexColumn};
  justify-content: space-between;
  height: 100%;
`;

const Layer2Row = styled.div`
  ${styl.flexRow};
  justify-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
  margin-top: 20px;
  &.gemeinsam {
    align-items: flex-start;
    cursor: pointer;
    @media (max-width: 1200px) {
      align-items: flex-end;
      padding-top: 38px;
      /* justify-content: space-between; */
      /* max-height: 1000px; */
    }
  }
  @media (max-width: 1200px) {
    ${styl.flexColumn};
    flex-direction: column-reverse;
    margin-top: 5px;
    align-items: flex-end;
    /* justify-content: space-between; */
    /* max-height: 1000px; */
  }

  /* @media (max-width: ${constants.nexusLandscapeWidth}px) {
    ${styl.flexRow};
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    ${styl.flexColumn};
    flex-direction: column-reverse;
    ${styl.flexCenter};
    /* max-height: 1000px; */
  /* } */
`;

const Layer3Column = styled.div`
  ${styl.flexColumn};
  margin: 0;
`;

const LeftCircle = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: right;
`;

const RightCircle = styled.div`
  cursor: pointer;
  @media (max-width: 1700px) {
    right: 2vw;
  }
  @media (max-width: 1200px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: ${constants.nexusLandscapeWidth}px) {
    width: auto;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    width: 100%;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1020px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

const SplitScreen = styled.div`
  ${styl.flexRow};
  ${styl.flexCenter};
  justify-content: space-around;
  flex: 1;
  /* max-height: 85vh; */
  /* height: 80vh; */
  margin: auto 0;
  margin-bottom: 25px;
  overflow: hidden;
  position: relative;
  /* height: 80vh; */

  @media (max-width: ${constants.nexusLandscapeWidth}px) {
    ${styl.flexColumn};
    flex-direction: column-reverse;
  }
  @media (min-width: 2000px) {
    justify-content: space-around;
  }
`;

const SplitScreenItem = styled.div`
  background-color: #f3f3f1;
  max-width: 800px;
  width: 50%;
  max-height: 83vh;
  height: 800px;
  box-sizing: border-box;
  margin: 20px;
  padding: 30px;
  margin-top: 60px;
  /* cursor: pointer; */

  &:last-child {
    margin-left: 10px;
  }

  &:first-child {
    margin-right: 10px;
  }

  @media (max-width: ${constants.nexusLandscapeWidth}px) {
    width: 92%;
    padding: 6%;
    height: unset;
    max-height: unset;

    &:last-child,
    &:first-child {
      margin: 4%;
    }
    &:last-child {
      margin-top: calc(4% + 40px);
    }
  }
  @media (max-width: 500px) {
    height: unset;
    max-height: unset;
  }
`;

const Image = styled.div`
  background: url("${(props) => props.url}");
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: left;
  background-size: contain;
  position: unset;
  height: 100%;
  width: 100%;
  min-width: 400px;
  align-self: left;
  margin: 0, 20px;
  min-height: 330px;
  @media (max-width: 1200px) {
    height: 100%;
    min-height: unset;
    background-position-x: center;
  }
  @media (max-width: 1024px) {
    height: 350px;
  }
  @media (max-width: 400px) {
    height: 200px;
  }
`;

const Image2 = styled.div`
  background: url("${(props) => props.url}");
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: left;
  background-size: contain;
  position: relative;
  top: -20vh;
  height: 150%;
  width: 80%;
  min-width: 395px;
  align-self: left;
  margin: 0, 20px;
  cursor: pointer;
  min-height: 330px;
  @media (max-width: 1480px) {
    background-size: contain;
    /* top: 0; */
  }
  @media (max-width: 1200px) {
    /* background-size: contain; */
    height: 100%;
    position: unset;
    min-width: 300px;
  }
`;

const Paragraph = styled.p`
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #333333;
  margin-top: 0;
  text-align: left;
`;

const Mobile = styled.div`
  @media (min-width: 770px) {
    display: none;
  }
`;

const Desktop = styled.div`
  @media (max-width: 770px) {
    display: none;
  }
`;

export default StartOrderPage;
