import {
  colors, React,
  styled, useState
} from "../../imports";
import StateInput from "../StateInput";
import StyledButton from "../StyledButton";


const CreateList = ({ activeView, createNewList, parentPage, creationLoading }) => {
  const [newListName, setNewListName] = useState("");
  const [listNameValidation, setListNameValidation] = useState("");

  const createList = () => {
    if (newListName.trim() == "") {
      setListNameValidation("Gib der Liste einen Namen.");
      return;
    }
    createNewList(newListName);
  };

  return (
    <Padding>
      <SmallHeading>Neue Liste erstellen</SmallHeading>
      <InputWrapper>
        <StateInput
          name={"Listenname"}
          forwardedRef={newListName}
          onChange={(e) => setNewListName(e.target.value)}
          error={listNameValidation}
          type={"text"}
          style={{ width: "80%" }}
          placeholder={"Listen Name"}
          maxLength="20"
        ></StateInput>
      </InputWrapper>

      <StyledButton
        onClick={() => {
          createList();
        }}
        loading={creationLoading}
        scale={"ORDER"}
        title={"Liste erstellen"}
        color={colors.YellowBrigthButton}
        fontSize={"20px"}
      />
      {parentPage != "einladen" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "4px",
            width: "241px",
            height: "32px"
          }}
        >
        <div style={{ width: '118px', height: "32px" }}>
          <StyledButton
            onClick={() => activeView("UserData")}
            scale={"O"}
            title={"zurück"}
            color="#8D7833"
            fontSize={"20px"}
          />
        </div>
        <div style={{ width: "5px" }} />
        <div style={{ width: '118px', height: "32px" }}>
          <StyledButton
            onClick={() => activeView("AddProduct")}
            scale={"s"}
            title={"weiter"}
            color="#8D7833"
            fontSize={"20px"}
            style={{lineHeight: "32px"}}
          /></div>
        </div>
  )
}
    </Padding >
  );
};

const SmallHeading = styled.p`
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 400;
  color: #4d4d4d;
  letter-spacing: 0.4px;
  text-align: left;
  @media (max-height: 676px){
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;
const Padding = styled.div`
  /* padding: 20px 0; */
  @media (max-width: 770px){
    margin-bottom: 20px;
  }
`;

const InputWrapper = styled.div`
  width: 241px;
`;

export default CreateList;
