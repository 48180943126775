import { FaAngleDown, FaTrashAlt } from "react-icons/fa";
import {
  colors,
  constants,
  React,
  styl,
  styled,
  useEffect,
  useState,
} from "../../imports";
import Alert from "../Alert";
import useAmountInput from "../hooks/useAmountInput";
import useRemoveFromCart from "../hooks/useRemoveFromCart";
import useWindowSize from "../hooks/useWindowSize";
import StyledButton from "../StyledButton";
import "./Accordion.css";
import CoolBoxInfo from "../CoolBoxInfo";
import { AmountInput } from "./AccordionSingleBuyer";

function AccordionInitiator({ data, list, index, orderId, parentPage }) {
  const [dropDown3Index, setDropDown3Index] = useState(-1);
  const [inputState, setInputState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState(false);
  const { debouncedInputHandle, loadingUpdateAmount } = useAmountInput();
  const { removeSelected } = useRemoveFromCart();
  const size = useWindowSize();

  useEffect(() => {
    if (data.length == 1) {
      handleInputState(data[0].orderers[0].products);
    }
  }, []);
  const handleInitiator = (channel, index, products) => {
    //Ausklappen der Hauptkategorie
    setInputState([]);

    if (dropDown3Index == index) {
      setDropDown3Index(-1);
    } else {
      setDropDown3Index(index);
      handleInputState(products);
    }
  };

  const handleInputState = (products) => {
    let tmp = [];
    products.map((item) => {
      tmp.push(item.amount);
    });
    setInputState(tmp);
  };

  const removeItemFromList = async (product, i2) => {
    setLoadingRemove(true);

    let tmp = inputState;
    tmp.splice(i2, 1);
    setInputState(tmp);
    await removeSelected(product.rowId, "");

    setLoadingRemove(false);
  };
  return (
    <Wrapper key={"Hallo"}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <ContentContainer3 className={parentPage}>
          <NameContainer>
            <FaAngleDown
              onClick={() => {
                handleInitiator(
                  list.orderers[0].name,
                  0,
                  list.orderers[0].products
                );
              }}
              color="#707070"
              style={dropDown3Index == 0 ? { transform: "rotate(180deg)" } : {}}
            />
            Meine Bestellung
          </NameContainer>
          <NameContainer>
            €{" "}
            {list.orderers[0].total.toLocaleString("DE-DE", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </NameContainer>
        </ContentContainer3>

        {/* <input type="checkBox"></input> */}
      </div>
      <DropdownContainer
        style={dropDown3Index == 0 ? { display: "block" } : { display: "none" }}
        className={parentPage}
      >
        {!loadingRemove && !loadingUpdateAmount ? (
          list.orderers[0].products.map((product, i2) => {
            return (
              <ProductContainer key={i2} className={i2} style={{ opacity: 1 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <SubCheckboxLabel>
                    {/* <select name="cars" id="cars">
                      <option value="volvo">Volvo</option>
                      <option value="saab">Saab</option>
                      <option value="opel">Opel</option>
                      <option value="audi">Audi</option>
                    </select> */}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          maxHeight: "36px",
                          alignItems: "center",
                        }}
                      >
                        {product.rowId && parentPage !== "OrderOverview" ? (
                          <AmountInput
                            onChange={async (e) => {
                              setLoading(true);
                              if (e.target.value != "") {
                                let tmp = inputState.slice();
                                if (e.target.value == "0") {
                                  tmp[i2] = "1";
                                } else {
                                  tmp[i2] = e.target.value;
                                }
                                setInputState(tmp);
                                await debouncedInputHandle(product, tmp[i2]);
                                setLoading(false);
                              }
                            }}
                            value={inputState[i2]}
                            readOnly={
                              parentPage == "OrderOverview" ? true : false
                            }
                            type={"number"}
                          ></AmountInput>
                        ) : (
                          <Paragraph>{product.amount}x</Paragraph>
                        )}
                        {/* <SubCheckboxInput id={product.product.name} type="checkbox" onChange={() => { }} checked={false} /> */}
                        {/* <SubCheckboxButton className="fa fa-check" /> */}
                        <ProductName>{product.product.name}</ProductName>
                        {product.product.parent?.coolbox?.coolboxActivated && (
                          <CoolBoxInfo />
                        )}
                      </div>

                      {!loading && (
                        <ProductName>
                          €{" "}
                          {product.total.toLocaleString("DE-DE", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}
                        </ProductName>
                      )}
                    </div>
                    {size.width < constants.nexusPortraitWidth &&
                      !orderId &&
                      parentPage != "OrderOverview" && (
                        <IconText
                          onClick={async () => {
                            setDeleteProduct({ product: product, i2: i2 });
                            setShowDeleteAlert(true);
                          }}
                        >
                          Produkt entfernen
                        </IconText>
                      )}
                  </SubCheckboxLabel>

                  {!orderId && parentPage != "OrderOverview" && (
                    <DeleteWrapper>
                      {size.width > constants.nexusPortraitWidth && (
                        <Item>
                          <FaTrashAlt
                            onClick={async () => {
                              setDeleteProduct({ product: product, i2: i2 });

                              setShowDeleteAlert(true);
                            }}
                            color="#E1E1E1"
                            height="16px"
                          />
                        </Item>
                      )}
                    </DeleteWrapper>
                  )}
                  {showDeleteAlert && deleteProduct && (
                    <Alert isVisible={true}>
                      <Paragraph>
                        Willst du dieses Produkt wirklich entfernen?
                      </Paragraph>
                      <ButtonBox>
                        <div style={{ width: "180px" }}>
                          <StyledButton
                            // loading={mutationLoading}
                            scale={"DEF"}
                            title={"Abbrechen"}
                            color={colors.YellowBrigthButton}
                            fontSize={"20px"}
                            onClick={() => {
                              setDeleteProduct(false);
                              // handleCheckAGB();
                              setShowDeleteAlert(false);
                            }}
                          />
                        </div>
                        <div style={{ width: "10px", height: "5px" }} />
                        <div style={{ width: "180px" }}>
                          <StyledButton
                            // loading={mutationLoading}
                            scale={"DEF"}
                            title={"entfernen"}
                            color={colors.YellowBrigthButton}
                            fontSize={"20px"}
                            onClick={() => {
                              // SwitchToIndividualOrder().then((response)=>{
                              //   setShowSwitchAlert(false)
                              // });
                              removeItemFromList(
                                deleteProduct.product,
                                deleteProduct.i2
                              );
                              setDeleteProduct(false);
                            }}
                          />
                        </div>
                      </ButtonBox>
                    </Alert>
                  )}
                </div>
              </ProductContainer>
            );
          })
        ) : (
          <p>Loading...</p>
        )}
      </DropdownContainer>
    </Wrapper>
  );
}

export default AccordionInitiator;

const Wrapper = styled.li`
  /* position: relative; */
  list-style: none;
`;
const ContentContainer = styled.div`
  width: 100%;
  max-height: 36px;
  color: black;
  margin-right: 50px;
  display: flex;
  justify-content: space-between;
  flex-flow: row;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  &.OrderOverview {
    margin: 0;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-right: 0;
  }
`;

const ContentContainer3 = styled(ContentContainer)``;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  /* z-index: 3; */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-family: "Montserrat";
  font-weight: 300;
  color: #333333;
  letter-spacing: 0.35px;
  height: 30px;
`;

const DropdownContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  /* max-width: 330px; */
  transition: height 0.3s ease;
  /* overflow-y: hidden; */
  z-index: 1;
  &.OrderOverviewWithId {
    padding-right: 50px;
    @media (max-width: ${constants.nexusPortraitWidth}px) {
      padding-right: 0;
    }
  }
`;

const ProductContainer = styled.li`
  list-style: none;
  padding: 0px;
  margin: 0px;
  transition: 0.3s ease;
  opacity: 0;
`;

const SubCheckboxLabel = styled.label`
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 36px;
  background-color: white;
  display: flex;
  flex-flow: column;
  justify-content: center;
  font-size: 14px;
  margin-left: 10px;
  border-bottom: 0.8px solid ${colors.MercuryGreyInputfields};
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    height: unset;
  }
`;

const ProductName = styled.h6`
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 300;
  color: #808080;
  padding: 0px;
  padding-left: 11px;
  margin: 0px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;
const IconText = styled.div`
  font-size: 12px;
  text-decoration: underline;
  color: ${colors.YellowBrigthButton};
  cursor: pointer;
  text-align: right;
  margin: 12px 0 5px 0;
  &:hover {
    opacity: 0.6;
  }
`;

const Paragraph = styled.p`
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.35;
  color: #808080;
  margin: 0;
`;

const Item = styled.div`
  width: 50px;
  ${styl.flexRow};
  align-items: center;
  justify-content: center;
  /* position: relative; */
  /* min-width: ${(props) => (props.min ? props.min : "240px")}; */
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  flex-flow: ${(props) => (props.alignment == "RIGHT" ? "row-reverse" : "row")};

  @media (max-width: 375px) {
    display: none;
  }
`;

const DeleteWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  @media (max-width: 770px) {
    flex-direction: column;
  }
`;
