import { gql, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import HeadingOne from "../../components/HeadingOne";
import ProductOverviewComp from "../../components/ProductOverviewComp";
import colors from "../../constants/colors";
import Error404 from "../error/Error404";
import CircleWithText from "./../../components/CircleWithText";

const GetProducts = gql`
  query getProducts {
    __typename
    products(first: 20, where: { orderby: { field: DATE, order: ASC } }) {
      nodes {
        e2_shortDescription {
          shortdescription
        }
        ... on VariableProduct {
          id
          productId
          name
          variations {
            nodes {
              price
              weight
            }
          }
          image {
            sourceUrl
          }
        }
      }
    }
  }
`;

export default function ProductOverViewPage() {
  const { loading, error, data } = useQuery(GetProducts);

  const colorsArray = [
    colors.LuckyYellowHomepageSquare,
    colors.DriftwoodYellowHomepageSquare,
    colors.PestoYellowHomepageSquare,
    colors.YellowBrigthButton,
    colors.DriftwoodYellowHomepageSquare,
  ];
  useEffect(() => {
    document.title = "Produkte";
  }, []);

  if (loading)
    return (
      <Main>
        <ContentContainer>
          <CircleWithTextContainer
            onClick={() => (window.location.href = "/bestellen")}
          >
            <CircleWithText size="170" font="30">
              Bestellen
            </CircleWithText>
          </CircleWithTextContainer>
          <HeadingOne title="Produkte" />
          <Main>
            <Loader
              height={400}
              width={200}
              type="ThreeDots"
              color={colors.YellowBrigthButton}
            />
          </Main>
        </ContentContainer>
      </Main>
    );
  if (error) return <Error404 />;

  // for (var i = data.products.nodes.length - 1; i >= 0; i--) {
  //   var newArray = [];
  //   for (var i = data.products.nodes.length - 1; i >= 0; i--) {
  //     newArray.push(data.products.nodes[i]);
  //   }
  // }
  return (
    <Main>
      <ContentContainer>
        <CircleWithTextContainer
          onClick={() => (window.location.href = "/bestellen")}
        >
          <CircleWithText size="170" font="30">
            Bestellen
          </CircleWithText>
        </CircleWithTextContainer>
        <HeadingOne title="Produkte" />
        <ProductDiv>
          {data?.products?.nodes?.map((item, i) => {
            return (
              <ProductOverviewComp
                header={item.name}
                productID={item.productId}
                buttonColor={colorsArray[i % colorsArray.length]}
                picture={item.image.sourceUrl}
                text={item.e2_shortDescription.shortdescription}
                priceTable={
                  item.priceTable || [
                    ...item.variations.nodes.map((element) => {
                      return {
                        price: element.price,
                        quantity: element.weight,
                      };
                    }),
                  ]
                }
              />
            );
          })}
          {/* <ProductOverviewComp header={"Der Würzige"} buttonColor={colors.DriftwoodYellowHomepageSquare} picture={Laib} text={"Lorem ipsum Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim. Lorem ipsum dolor sit amet, consectetuer"}></ProductOverviewComp>
          <ProductOverviewComp header={"Der Alpige"} buttonColor={colors.PestoYellowHomepageSquare} picture={Milde} text={"Lorem ipsum Lorem ipsum dolor sit amet, Lorem ipsum Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim. Lorem ipsum dolor sit amet, consectetuer consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim. Lorem ipsum dolor sit amet, consectetuer"}></ProductOverviewComp>
          <ProductOverviewComp header={"Der Schnittige"} buttonColor={colors.YellowBrigthButton} picture={Kaese} text={"Lorem ipsum Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim. Lorem ipsum dolor sit amet, consectetuer"}></ProductOverviewComp>
          <ProductOverviewComp header={"Der Besondere"} buttonColor={colors.DriftwoodYellowHomepageSquare} picture={Laib} text={"Lorem ipsum Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim. Lorem ipsum dolor sit amet, consectetuer"}></ProductOverviewComp> */}
        </ProductDiv>
      </ContentContainer>
    </Main>
  );
}

const CircleWithTextContainer = styled.div`
  position: absolute;
  top: 150px;
  right: 100px;
  /* margin: 10; */
  /* transform: scale(0.5) rotate(10deg); */
  cursor: pointer;
  @media (min-width: 2000px) {
    right: 30vw;
  }
  @media (min-width: 3000px) {
    right: 60vw;
  }
  @media (max-width: 1100px) {
    right: 20px;
  }
  @media (max-width: 600px) {
    display: none;
  }
  /* @media (min-width: 1800px) {
    top: -20px;
    right: 100px;
  } */
`;

const Main = styled.div`
  padding-bottom: 25px;
  margin-left: 5vw;

  /* display: flex;
    justify-content: center;
    position: relative; */
`;

const ContentContainer = styled.div`
  /* margin-left: 470px; */
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 70vw;
  margin-left: 20px;
  @media (max-width: 1980px) and (max-height: 1080px) {
    margin-left: 130px;
    /* max-width: calc(100% - 130px); */
  }
  @media (max-width: 1500px) {
    width: 80%;
    margin: 62px;
  }
  @media (max-width: 990px) {
    width: 60%;
    margin: 5%;
  }
  @media (max-width: 880px) {
    width: 70%;
  }
`;

const ProductDiv = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1300px;
  flex-wrap: wrap;
  /* justify-content: center; */
  /* @media (max-width:1000px){
    justify-content: center;
  } */

  @media (max-width: 1500px) {
    margin-top: 20px;
  }
`;
