import React, { useEffect } from "react";
import styled from "styled-components";
import styl from "../../styl";
import partner from "./../../assets/images/annalp.jpg";
import hilda from "./../../assets/images/hilda.jpg";
import melchior from "./../../assets/images/melchior.jpg";
import HeadingOne from "./../../components/HeadingOne";
import PhilosophieText from "./../../components/PhilosophieText";
import Quote from "./../../components/Quote";
import StyledButton from "./../../components/StyledButton";
import constants from "./../../constants/constants";
// import ProductOverviewComp from "./../../components/ProductOverviewComp";

const PhilosophiePage = () => {
  useEffect(() => {
    document.title = "Wer wir sind";
  }, []);

  return (
    <Wrapper>
      <Box>
        <LeftBox>
          <HeadingOneWrapper>
            <HeadingOne title="Wer wir sind" />
          </HeadingOneWrapper>
          <Content>
            <ContentBox>
              <ContentBoxImage source={melchior} />
              <TextWrapper>
                <PhilosophieText
                  heading="Melchior"
                  description="Mit den Rhythmen der Natur, dem Zusammenhalt in Familie und Verbänden bin ich aufgewachsen. Nach 20 Jahren, großteils in leitender Position, in einer Bank tätig, habe ich Ende 2019 den Entschluss gefasst, auf neue Pferde zu setzen. Das war der Startschuss für gut gereift im Bregenzerwald. "
                />

                <ButtonBox>
                  <StyledButton
                    title="mehr erfahren"
                    onClick={() => {
                      window.location.href = "/melchior";
                    }}
                    color="#CCB242"
                  />
                </ButtonBox>
              </TextWrapper>
            </ContentBox>
            <ContentBox>
              <ContentBoxImage source={hilda} />
              <TextWrapper>
                <PhilosophieText
                  heading="Hilda"
                  description="Ich habe mich Dingen verschrieben, die gut gemacht und auf das Wesentliche reduziert sind. Weil ich das Ursprüngliche schätze und das Urbane mag, ist der Bregenzerwald für mich und meine Familie ein guter Ort zum Leben."
                />
                <ButtonBox>
                  <StyledButton
                    title="mehr erfahren"
                    onClick={() => {
                      window.location.href = "/hilda";
                    }}
                    color="#A6944A"
                  />
                </ButtonBox>
              </TextWrapper>
            </ContentBox>
            <ContentBox>
              <ContentBoxImage source={partner} />
              <TextWrapper>
                <PhilosophieText
                  heading="Partner"
                  description="Der Umgang mit unseren Partnern läuft nie anonym. Von der Landwirtschaft des Bruders bis zum/zur BestellinitiatorIn in Wien, immer sind es die persönlichen Verbindungen, die unserem Produkt seinen Wert geben."
                />
                <ButtonBox>
                  <StyledButton
                    title="mehr erfahren"
                    onClick={() => {
                      window.location.href = "/partner";
                    }}
                    color="#84763B"
                  />
                </ButtonBox>
              </TextWrapper>
            </ContentBox>
          </Content>
        </LeftBox>
        <RightBox>
          <QuoteWrapper>
            <RowWrapper>
              <Quote
                mainText="mitan&#244;nd"
                description="Gemeinsam"
                width="100"
              />
            </RowWrapper>
          </QuoteWrapper>
        </RightBox>
      </Box>
    </Wrapper>
  );
};

const ContentBoxImage = styled.div`
  background-position: center;
  background-size: cover;
  height: 212px;
  width: 212px;
  background-image: url("${(props) => props.source}");
  margin-bottom: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: auto;
  @media (max-height: 800px)and (min-width: 1100px){
    margin-bottom: 100px;
  } 
  @media (max-width: 1100px) {
    height: unset;
    min-height: 110vh;
  }
  @media (max-width: 770px) {
    min-height: 100%;
  }
`;

const Box = styled.div`
  width: 100%;
  /* max-height: 10vh; */
  padding: 3.01vw;
  padding-top: 1%;
  display: flex;
  flex-direction: row;

  @media (max-width: 1500px) {
    flex-direction: column;
  }

  @media (min-width: 1700px) {
    width: 1745px;
  }
`;

const Content = styled.div`
  ${styl.flexRow}
  margin-top: 20px;

  @media (max-width: 1550px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    flex-direction: column;
    padding-top: 10px;
  }
`;

const ContentBox = styled.div`
  max-width: 230px;
  min-width: 212px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 3.01vw;
  justify-content: flex-start;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    max-width: 93%;
    flex-direction: column;
    margin-top: 5px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 287px;

  @media (max-width: 770px) {
    height: unset;
    margin-bottom: 10px;
  }
`;

const ButtonBox = styled.div`
  margin-top: auto;
`;

const LeftBox = styled.div`
  @media (max-width: 1500px) {
    width: 100%;
  }
`;
const RightBox = styled.div`
  width: 40%;
  ${styl.flexColumn};
  ${styl.flowCenter};

  @media (min-width: 1700px){
    margin-left: 20px;
  }
  @media (max-width: 1500px) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    flex-direction: column;
  }
`;

const QuoteWrapper = styled.div`
  @media (max-width: 1500px) {
    position: absolute;
    padding-top: 20px;
    top: 120px;
    right: 100px;
    padding-bottom: 30px;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Image = styled.div`
  height: 50%;
  width: 100%;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  @media (max-width: 1500px) {
    display: none;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    width: 97%;
  }
`;

const ShortImageHaltung = styled.div`
  display: none;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    display: block;
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    width: 100%;
    height: 250px;
    margin: 20px 0 20px 0;
  }
`;

const HeadingOneWrapper = styled.div`
  padding: 0 3.01vw;
  padding-top: 3vh;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin: 0;
  }
`;

export default PhilosophiePage;
