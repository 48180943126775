import React from 'react';
import styled from 'styled-components';
import Login from '../../components/Login';
import HeadingOne from './../../components/HeadingOne';
import constants from './../../constants/constants';

const LoginPage = () => {

	return (
		<Wrapper>
			<Box>
				<LeftBox>
					<HeadingOneWrapper>
						<HeadingOne title="Login" />
					</HeadingOneWrapper>
					<br />
					<Content>
						<ContentBox>
							
							<Login></Login>
                            
						</ContentBox>
						
					</Content>
				</LeftBox>
			</Box>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
    width: 100%;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
		margin-bottom: 40px;
	}
`;

const Box = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
    
	@media (max-width: 1500px) {
		flex-direction: column;
	}

  @media (min-width: 1920px) {
    width: 1745px;
  }
`;

const Content = styled.div`
	/* width: 100%; */
	display: flex;
	flex-direction: row;
  margin-top: 40px;

	@media (max-width: 1550px) {
		width: 100%;
		margin-top: 20px;
	}

	@media (max-width: ${constants.nexusPortraitWidth}px) {
		flex-direction: column;
		padding-top: 10px;
	}
`;

const ContentBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;

	@media (max-width: ${constants.nexusPortraitWidth}px) {
		flex-direction: column;
		margin-top: 5px;
	}
`;

const LeftBox = styled.div`
	width: 100%;
    /* background-color: red; */
	@media (max-width: 1500px) {
		width: 100%;
	}
`;

const HeadingOneWrapper = styled.div`
	@media (max-width: ${constants.nexusPortraitWidth}px) {
		margin: 0;
		margin-left: 20px;
	}
`;

export default LoginPage;
