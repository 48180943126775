import React, { useState } from 'react';

export function useProductList() {
	const [productList, setProductList] = useState([]);

	const setter = (list) => {
        setProductList(list)
    }

	return { productList, setter }
}