import { useState } from 'react';

export function useLocalState(localItem) {
    const [loc, setState] = useState(localStorage.getItem(localItem));

    async function setLoc(newItem) {
        let newItem2 = JSON.stringify(newItem)
        await localStorage.setItem(localItem, newItem2);
        setState(newItem2);
    }

    return [JSON.parse(loc), setLoc];
}