import React, { useEffect, useState } from "react";
import styled from "styled-components";
import philosophie from "../../assets/images/home/1_philosophie.jpg";
import products from "../../assets/images/home/2_produkte.jpg";
import community from "../../assets/images/home/3_community.jpg";
import bestellen from "../../assets/images/home/4_bestellen.jpg";
import whoweare from "../../assets/images/home/5_werwirsind.jpg";
import contact from "../../assets/images/home/6_kontakt.jpg";
import heroImg from "../../assets/images/home/headerbild-min.jpg";
import HeadingOne from "../../components/HeadingOne";
import HomepageSquare from "../../components/HomepageSquare";
import Header from "../../components/styledHeader/StyledHeader";
import TablerIcon from "../../components/TablerIcon";
import colors from "../../constants/colors";
import constants from "../../constants/constants";

export default function Homepage() {
  useEffect(() => {
    document.title = "Willkommen bei gut gereift";
  }, []);
  const [hover, setHover] = useState(false);
  const [hover2, setHover2] = useState(false);
  return (
    <Main>
      <GridContainer>
        <TopContainer>
          <Header />
          <HeroImg>
            <HeadingOnePositioner>
              <HeadingOne title="Hier ist's gut" color="WHITE" />
            </HeadingOnePositioner>
          </HeroImg>
        </TopContainer>
        <HomepageSquare
          color="#E6D793"
          bgImage={philosophie}
          href="/philosophie"
          title="philosophie"
          hoverText="Wir schauen auf das was uns nahe liegt und erhalten bewährte Strukturen."
        />
        <HomepageSquare
          color="#D7C061"
          bgImage={products}
          href="/produkte"
          title="produkte"
          hoverText="Was unseren Käse auszeichnet macht die Natur, der Mensch, die Hand und das Tier."
        />
        <HomepageSquare
          color="#CCB342"
          bgImage={community}
          href="/community"
          title="community"
          hoverText="Wir finden neue Wege und Formen in der Vermarktung, digital und sozial."
        />
        <HomepageSquare
          color="#B2982A"
          bgImage={bestellen}
          href="/bestellen"
          title="bestellen"
          hoverText="Einzeln oder in der Gruppe bestellen. Schnell und einfach."
        />
        <HomepageSquare
          color="#8D7833"
          bgImage={whoweare}
          href="/werwirsind"
          title="wer wir sind"
          hoverText="Die Kultur der Landschaft und die Verbindung mit den Menschen das macht uns aus, das ist unsere Identität."
        />
        <HomepageSquare
          color="#7D6C2D"
          bgImage={contact}
          href="/kontakt"
          title="kontakt"
          hoverText={[
            <Link href="tel:00436644142827">Tel. +43 664 414 28 27</Link>,
            <br />,
            <Link href="mailto:info@gutgereift.at">info@gutgereift.at</Link>,
            <br />,
            <br />,
            <Paragraph>REIFELAGER</Paragraph>,
            <Paragraph>MOOS 383, 6866 ANDELSBUCH</Paragraph>,
            <Paragraph>VERKÖSTIGUNGEN AUF ANFRAGE.</Paragraph>,

            <SpaceTop>
              <Link
                href="https://www.instagram.com/gutgereiftimbregenzerwald/"
                passHref
                target={"_blank"}
              >
                <span
                  style={{ marginLeft: "-3px" }}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <TablerIcon
                    strokeWidth={1}
                    size={35}
                    color={!hover ? "#023682" : "white"}
                    iconName={"brand-instagram"}
                  ></TablerIcon>
                </span>
              </Link>

              <Link
                href="https://www.facebook.com/gutgereiftimbregenzerwald/"
                passHref
                target={"_blank"}
              >
                <span
                  style={{ marginLeft: "5px" }}
                  onMouseEnter={() => setHover2(true)}
                  onMouseLeave={() => setHover2(false)}
                >
                  <TablerIcon
                    strokeWidth={1}
                    size={35}
                    color={!hover2 ? "#023682" : "white"}
                    iconName={"brand-facebook"}
                  ></TablerIcon>
                </span>
              </Link>
            </SpaceTop>,
          ]}
        />
      </GridContainer>
    </Main>
  );
}
const SpaceTop = styled.div`
  margin-top: 15px;
  @media (max-width: 1300px) {
    margin-top: 5px;
  }
`;
const Main = styled.div`
  height: 100vh;
  width: 100vw;
  @media (min-width: ${constants.nexusPortraitWidth}px) and (max-height: 500px) {
    height: 100%;
  }
  @media (max-width: 770px) {
    /* height: 100%; */
    height: unset;
  }
`;
const TopContainer = styled.div`
  grid-column: 1/7;
  flex: 1;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    grid-column: 1/4;
  }
  @media (max-width: ${constants.iPhonePortraitWidth}px) {
    grid-column: 1/3;
  }
`;

const HeroImg = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${heroImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center calc(65%);
  @media (min-width: ${constants.nexusPortraitWidth}px) and (max-height: 500px) {
    height: calc(100vh - (100vw / 3) * 2 - 244px);
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    height: calc(100vh - (100vw / 3) * 2 - 244px);
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) and (min-height: 960px) {
    height: calc(100vh - (100vw / 3) * 2 - 244px);
  }
  @media (max-width: 670px) and (min-height: 800px) {
    height: calc(100vh - (100vw / 3) * 2 - 244px);
  }
  @media (max-width: ${constants.iPhonePortraitWidth}px) {
    display: none;
  }
`;
const HeadingOnePositioner = styled.div`
  height: 45%;
  display: flex;
  justify-content: center;
  @media (min-height: 940px) {
    margin-top: 160px;
  }
`;
const GridContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto calc(100vw / 6);
  grid-template-columns: auto auto auto auto auto auto;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    grid-template-rows: auto calc(100vw / 3) calc(100vw / 3);
    grid-template-columns: auto auto auto;
  }
  @media (max-width: ${constants.iPhonePortraitWidth}px) {
    grid-template-rows: 175px calc(100vw / 2) calc(100vw / 2) calc(100vw / 2);
    grid-template-columns: auto auto;
    /* height: 100vh; */
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: #023682;
  cursor: pointer;
  &:hover {
    color: white;
  }
`;

const Paragraph = styled.p`
  text-decoration: none;
  color: #023682;
  margin: 0;
  padding: 0;
  font-size: 12px;
`;
