import React from "react";
import styled from "styled-components";
import HeadingOne from "../../components/HeadingOne";
import HeadingTwo from "../../components/HeadingTwo";

const Datenschutz = () => {
  return (
    <Wrapper>
      <HeadingOne title="Datenschutzerklärung" />
      <Paragraph>
        <Text>
          Soweit nachstehend keine anderen Angaben gemacht werden, ist die
          Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder
          vertraglich vorgeschrieben, noch für einen Vertragsabschluss
          erforderlich. Sie sind zur Bereitstellung der Daten nicht
          verpflichtet. Eine Nichtbereitstellung hat keine Folgen. Dies gilt nur
          soweit bei den nachfolgenden Verarbeitungsvorgängen keine anderweitige
          Angabe gemacht wird.
        </Text>
        <Text>
          „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
          identifizierte oder identifizierbare natürliche Person beziehen.
        </Text>
      </Paragraph>
      <Paragraph>
        <HeadingFour>Server-Logfiles</HeadingFour>
        <Text>
          Sie können unsere Websites besuchen, ohne Angaben zu Ihrer Person zu
          machen. Bei jedem Zugriff auf unsere Website werden an uns oder
          unseren Webhoster / IT-Dienstleister Nutzungsdaten durch Ihren
          Internet Browser übermittelt und in Protokolldaten (sog.
          Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten gehören
          z.B. der Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs,
          die IP-Adresse, die übertragene Datenmenge und der anfragende
          Provider. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1
          lit. f DSGVO aus unserem überwiegend berechtigten Interesse an der
          Gewährleistung eines störungsfreien Betriebs unserer Website sowie zur
          Verbesserung unseres Angebotes.
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo title="1.Registrierung / Kundenkonto" underline="false" />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Bei der Registrierung (Kundenkonto) erheben wir Ihre personenbezogenen
          Daten in dem dort angegebenen Umfang. Die Datenverarbeitung dient dem
          Zweck, Ihr Einkauf zu verbessern und die Bestellabwicklung zu
          vereinfachen. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1
          lit. a DSGVO mit Ihrer Einwilligung. Sie können Ihre Einwilligung
          jederzeit durch Mitteilung an uns widerrufen, ohne dass die
          Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
          erfolgten Verarbeitung berührt wird. Ihr Kundenkonto wird anschließend
          gelöscht.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          Erhebung, Verarbeitung und Weitergabe personenbezogener Daten bei
          Bestellungen Bei der Bestellung erheben und verarbeiten wir Ihre
          personenbezogenen Daten nur, soweit dies zur Erfüllung und Abwicklung
          Ihrer Bestellung sowie zur Bearbeitung Ihrer Anfragen erforderlich
          ist. Die Bereitstellung der Daten ist für den Vertragsabschluss
          erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein
          Vertrag abgeschlossen werden kann. Die Verarbeitung erfolgt auf
          Grundlage des Art. 6 Abs. 1 lit. b DSGVO und ist für die Erfüllung
          eines Vertrags mit Ihnen erforderlich. Eine Weitergabe Ihrer Daten
          erfolgt dabei beispielsweise an das Versandunternehmen. In allen
          Fällen beachten wir strikt die gesetzlichen Vorgaben. Der Umfang der
          Datenübermittlung beschränkt sich auf ein Mindestmaß.
        </Text>
      </Paragraph>
      <Paragraph>
        <HeadingFour>Versanddienstleiter</HeadingFour>
        <Text>
          Information über den Versandstatus. Wir geben Ihre E-Mail-Adresse und
          Rufnummer im Rahmen der Vertragsabwicklung an das Transportunternehmen
          weiter, sofern Sie dem ausdrücklich im Bestellvorgang zugestimmt
          haben. Die Weitergabe dient dem Zweck, Sie per E-Mail oder Rufnummer
          über den Versandstatus zu informieren. Die Verarbeitung erfolgt auf
          Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung. Sie
          können die Einwilligung jederzeit durch Mitteilung an uns oder das
          Transportunternehmen widerrufen, ohne dass die Rechtmäßigkeit der
          aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
          berührt wird.
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo title="2. Kontakt / Newsletter" underline="false" />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Bei der Nutzung des Kontaktformulars erheben wir Ihre
          personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in
          dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung
          dient dem Zweck der Kontaktaufnahme. Mit Absenden Ihrer Nachricht
          willigen Sie in die Verarbeitung der übermittelten Daten ein. Die
          Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit
          Ihrer Einwilligung.
        </Text>
        <Text>
          Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns
          widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung
          bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihre
          E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre
          Daten werden anschließend gelöscht, sofern Sie der weitergehenden
          Verarbeitung und Nutzung nicht zugestimmt haben.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          Verwendung der E-Mail-Adresse für die Zusendung von Newslettern
        </Text>
        <Text>
          Wir nutzen Ihre E-Mail-Adresse unabhängig von der Vertragsabwicklung
          ausschließlich für eigene Werbezwecke zum Newsletterversand, sofern
          Sie dem ausdrücklich zugestimmt haben. Die Verarbeitung erfolgt auf
          Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung. Sie
          können die Einwilligung jederzeit widerrufen, ohne dass die
          Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
          erfolgten Verarbeitung berührt wird. Sie können dazu den Newsletter
          jederzeit unter Nutzung des entsprechenden Links im Newsletter oder
          durch Mitteilung an uns abbestellen. Ihre E-Mail-Adresse wird danach
          aus dem Verteiler entfernt.
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo title="3. Cookies" underline="false" />
      </ParagraphHeading>
      <Paragraph>
        <Text>
          Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die
          im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem
          eines Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so
          kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden.
          Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine
          eindeutige Identifizierung des Browsers beim erneuten Aufrufen der
          Website ermöglicht.
        </Text>
        <Text>
          Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die
          volle Kontrolle über die Verwendung von Cookies. Durch die Auswahl
          entsprechender technischer Einstellungen in Ihrem Internetbrowser
          können Sie vor dem Setzen von Cookies benachrichtigt werden und über
          die Annahme einzeln entscheiden sowie die Speicherung der Cookies und
          Übermittlung der enthaltenen Daten verhindern. Bereits gespeicherte
          Cookies können jederzeit gelöscht werden. Wir weisen Sie jedoch darauf
          hin, dass Sie dann gegebenenfalls nicht sämtliche Funktionen dieser
          Website vollumfänglich nutzen werden können.
        </Text>
        <Text>
          Unter den nachstehenden Links können Sie sich informieren, wie Sie die
          Cookies bei den wichtigsten Browsern verwalten (u.a. auch
          deaktivieren) können:
        </Text>
        <Text>
          Chrome Browser:{" "}
          <Link href="https://support.google.com/accounts/answer/61416?hl=de">
            https://support.google.com/accounts/answer/61416?hl=de
          </Link>
        </Text>
        <Text>
          Internet Explorer:{" "}
          <Link href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">
            https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
          </Link>
        </Text>
        <Text>
          Mozilla Firefox:{" "}
          <Link href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">
            https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
          </Link>
        </Text>
        <Text>
          Safari:{" "}
          <Link href="https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
            https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac
          </Link>
        </Text>
      </Paragraph>
      <Paragraph>
        <HeadingFour>Technisch notwendige Cookies</HeadingFour>
        <Text>
          Soweit nachstehend in der Datenschutzerklärung keine anderen Angaben
          gemacht werden, setzen wir nur diese technisch notwendigen Cookies zu
          dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver und
          sicherer zu machen. Des Weiteren ermöglichen Cookies unseren Systemen,
          Ihren Browser auch nach einem Seitenwechsel zu erkennen und Ihnen
          Services anzubieten. Einige Funktionen unserer Internetseite können
          ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es
          erforderlich, dass der Browser auch nach einem Seitenwechsel
          wiedererkannt wird.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO
          aus unserem überwiegenden berechtigten Interesse an der Gewährleistung
          der optimalen Funktionalität der Website sowie einer
          nutzerfreundlichen und effektiven Gestaltung unseres Angebots. Sie
          haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation
          ergeben, jederzeit diesen auf Art. 6 Abs. 1 lit. f DSGVO beruhenden
          Verarbeitungen Sie betreffender personenbezogener Daten zu
          widersprechen.
        </Text>
      </Paragraph>
      <Paragraph>
        <HeadingFour>Verwendung von Google Analytics</HeadingFour>
        <Text>
          Wir verwenden auf unserer Website den Webanalysedienst Google
          Analytics der Google LLC. (1600 Amphitheatre Parkway, Mountain View,
          CA 94043, USA; „Google“). Soweit Sie Ihren gewöhnlichen Aufenthalt im
          Europäischen Wirtschaftsraum oder der Schweiz haben, ist Google
          Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der
          für Ihre Daten zuständige Verantwortliche. Google Ireland Limited ist
          demnach das mit Google verbundene Unternehmen, welches für die
          Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren
          Datenschutzgesetze verantwortlich ist.
        </Text>
        <Text>
          Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und
          ihrer Besucher sowie Marketing- und Werbezwecken. Dazu wird Google im
          Auftrag des Betreibers dieser Website die gewonnenen Informationen
          benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere, mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen
          gegenüber dem Websitebetreiber zu erbringen. Dabei können u.a.
          folgende Informationen erhoben werden: IP-Adresse, Datum und Uhrzeit
          des Seitenaufrufs, Klickpfad, Informationen über den von Ihnen
          verwendeten Browser und das von Ihnen verwendete Device (Gerät),
          besuchte Seiten, Referrer-URL (Webseite, über die Sie unsere Webseite
          aufgerufen haben), Standortdaten, Kaufaktivitäten. Die im Rahmen von
          Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht
          mit anderen Daten von Google zusammengeführt.
        </Text>
        <Text>
          Google Analytics verwendet Technologien wie Cookies, Webspeicher im
          Browser und Zählpixel, die eine Analyse der Benutzung der Website
          durch Sie ermöglichen. Die dadurch erzeugten Informationen über Ihre
          Benutzung dieser Website werden in der Regel an einen Server von
          Google in den USA übertragen und dort gespeichert. Auf dieser Website
          ist die IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von
          Google innerhalb von Mitgliedstaaten der Europäischen Union oder in
          anderen Vertragsstaaten des Abkommens über den Europäischen
          Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
          IP-Adresse an einen Server von Google in den USA übertragen und dort
          gekürzt. Google hat sich nach dem US-EU-Datenschutzabkommen “Privacy
          Shield” zertifiziert und damit verpflichtet, die europäischen
          Datenschutzrichtlinien einzuhalten.
        </Text>
        <Text>
          Die Datenverarbeitung, insbesondere das Setzen von Cookies, erfolgt
          auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden
          berechtigten Interesse an der bedarfsgerechten und zielgerichteten
          Gestaltung der Website. Sie haben das Recht aus Gründen, die sich aus
          Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1
          lit. f DSGVO beruhenden Verarbeitungen Sie betreffender
          personenbezogener Daten zu widersprechen.
        </Text>
        <Text>
          Sie können die Erfassung der durch Google Analytics erzeugten und auf
          Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
          Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem Sie das unter dem folgenden Link verfügbare Browser-Plug-in
          herunterladen und installieren:{" "}
          <Link href="https://tools.google.com/dlpage/gaoptout?hl=de">
            https://tools.google.com/dlpage/gaoptout?hl=de
          </Link>
        </Text>
        <Text>
          Um die Datenerfassung und -speicherung durch Google Analytics
          geräteübergreifend zu verhindern, können Sie einen Opt-Out-Cookie
          setzen. Opt-Out-Cookies verhindern die zukünftige Erfassung Ihrer
          Daten beim Besuch dieser Website. Sie müssen das Opt-Out auf allen
          genutzten Systemen und Geräten durchführen, damit dies umfassend
          wirkt. Wenn Sie das Opt-out-Cookie löschen, werden wieder Anfragen an
          Google übermittelt. Wenn Sie hier klicken, wird das Opt-Out-Cookie
          gesetzt: Google Analytics deaktivieren.
        </Text>
        <Text>
          Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie
          unter{" "}
          <Link href="https://www.google.com/analytics/terms/de.html">
            https://www.google.com/analytics/terms/de.html
          </Link>{" "}
          bzw unter{" "}
          <Link href="https://www.google.de/intl/de/policies/">
            https://www.google.de/intl/de/policies/
          </Link>{" "}
          sowie unter{" "}
          <Link href="https://policies.google.com/technologies/cookies?hl=de">
            https://policies.google.com/technologies/cookies?hl=de
          </Link>
        </Text>
      </Paragraph>
      <Paragraph>
        <HeadingFour>Verwendung von Google Ads Conversion-Tracking</HeadingFour>
        <Text>
          Wir verwenden auf unserer Website das Online-Werbeprogramm „Google
          Ads“ und in diesem Rahmen Conversion-Tracking
          (Besuchsaktionsauswertung). Das Google Conversion Tracking ist ein
          Analysedienst der Google LLC (1600 Amphitheatre Parkway, Mountain
          View, CA 94043, USA; „Google“). Soweit Sie Ihren gewöhnlichen
          Aufenthalt im Europäischen Wirtschaftsraum oder der Schweiz haben, ist
          Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland)
          der für Ihre Daten zuständige Verantwortliche. Google Ireland Limited
          ist demnach das mit Google verbundene Unternehmen, welches für die
          Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren
          Datenschutzgesetze verantwortlich ist.
        </Text>
        <Text>
          Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein
          Cookie für das Conversion-Tracking auf Ihrem Rechner abgelegt. Diese
          Cookies haben eine begrenzte Gültigkeit, enthalten keine
          personenbezogenen Daten und dienen somit nicht der persönlichen
          Identifizierung. Wenn Sie bestimmte Seiten unserer Website besuchen
          und das Cookie noch nicht abgelaufen ist, können Google und wir
          erkennen, dass Sie auf die Anzeige geklickt haben und zu dieser Seite
          weitergeleitet wurden. Jeder Google Ads-Kunde erhält ein anderes
          Cookie. Somit besteht keine Möglichkeit, dass Cookies über die
          Websites von Ads-Kunden nachverfolgt werden können.
        </Text>
        <Text>
          Die Informationen, die mit Hilfe des Conversion-Cookie eingeholt
          werden, dienen dem Zweck, Conversion-Statistiken zu erstellen. Hierbei
          erfahren wir die Gesamtanzahl der Nutzer, die auf eine unserer
          Anzeigen geklickt haben und zu einer mit einem Conversion-Tracking-Tag
          versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine
          Informationen, mit denen sich Nutzer persönlich identifizieren lassen.
        </Text>
        <Text>
          Ihre Daten werden gegebenenfalls in die USA übermittelt. Google hat
          sich nach dem US-EU-Datenschutzabkommen „Privacy Shield“ zertifiziert
          und damit verpflichtet, die europäischen Datenschutzrichtlinien
          einzuhalten.
        </Text>
        <Text>
          Die Datenverarbeitung, insbesondere das Setzen von Cookies, erfolgt
          auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden
          berechtigten Interesse die Seitenbesucher zielgerichtet mit
          interessenbezogener Werbung anzusprechen. Sie haben das Recht aus
          Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit
          dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie
          betreffender personenbezogener Daten zu widersprechen.
        </Text>
        <Text>
          Sie können in den Einstellungen für Werbung bei Google für Sie
          personalisierte Werbung deaktivieren. Eine Anleitung dazu finden Sie
          unter{" "}
          <Link href="https://support.google.com/ads/answer/2662922?hl=de">
            https://support.google.com/ads/answer/2662922?hl=de
          </Link>
        </Text>
        <Text>
          Alternativ können Sie die Verwendung von Cookies durch Drittanbieter
          verhindern, indem Sie die Deaktivierungsseite der
          Netzwerkwerbeinitiative (Network Advertising Initiative) unter{" "}
          <Link href="https://www.networkadvertising.org/choices/">
            https://www.networkadvertising.org/choices/
          </Link>{" "}
          aufrufen und die dort genannten weiterführenden Information zum
          Opt-Out umsetzen. Sie werden sodann nicht in die Conversion-Tracking
          Statistiken aufgenommen.
        </Text>
        <Text>
          Nähere Informationen sowie die Datenschutzerklärung von Google finden
          Sie unter:{" "}
          <Link href="https://www.google.de/policies/privacy/">
            https://www.google.de/policies/privacy/
          </Link>
        </Text>
      </Paragraph>
      <ParagraphHeading>
        <HeadingTwo
          title="4. Betroffenenrechte und Speicherdauer"
          underline="false"
        />
      </ParagraphHeading>
      <Paragraph>
        <HeadingFour>Dauer der Speicherung</HeadingFour>
        <Text>
          Nach vollständiger Vertragsabwicklung werden die Daten zunächst für
          die Dauer der Gewährleistungsfrist, danach unter Berücksichtigung
          gesetzlicher, insbesondere steuer- und handelsrechtlicher
          Aufbewahrungsfristen gespeichert und dann nach Fristablauf gelöscht,
          sofern Sie der weitergehenden Verarbeitung und Nutzung nicht
          zugestimmt haben.
        </Text>
      </Paragraph>
      <Paragraph>
        <HeadingFour>Rechte der betroffenen Person</HeadingFour>
        <Text>
          Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende
          Rechte nach Artt. 15 bis 20 DSGVO zu: Recht auf Auskunft, auf
          Berichtigung, auf Löschung, auf Einschränkung der Verarbeitung, auf
          Datenübertragbarkeit.
        </Text>
        <Text>
          Außerdem steht Ihnen nach Art. 21 Abs. 1 DSGVO ein Widerspruchsrecht
          gegen die Verarbeitungen zu, die auf Art. 6 Abs. 1 f DSGVO beruhen,
          sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten finden Sie in
          unserem Impressum.
        </Text>
      </Paragraph>
      <Paragraph>
        <HeadingFour>Beschwerderecht bei der Aufsichtsbehörde</HeadingFour>
        <Text>
          Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde
          zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer
          personenbezogenen Daten nicht rechtmäßig erfolgt.
        </Text>
      </Paragraph>
      <Paragraph>
        <HeadingFour>Widerspruchsrecht</HeadingFour>
        <Text>
          Beruhen die hier aufgeführten personenbezogenen Datenverarbeitungen
          auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit.
          f DSGVO, haben Sie das Recht aus Gründen, die sich aus Ihrer
          besonderen Situation ergeben, jederzeit diesen Verarbeitungen mit
          Wirkung für die Zukunft zu widersprechen.
        </Text>
        <Text>
          Nach erfolgtem Widerspruch wird die Verarbeitung der betroffenen Daten
          beendet, es sei denn, wir können zwingende schutzwürdige Gründe für
          die Verarbeitung nachweisen, die Ihren Interessen, Rechten und
          Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen dient.
        </Text>
      </Paragraph>
      <Paragraph>
        <Text>
          Erfolgt die personenbezogene Datenverarbeitung zu Zwecken der
          Direktwerbung, können Sie dieser Verarbeitung jederzeit durch
          Mitteilung an uns widersprechen. Nach erfolgtem Widerspruch beenden
          wir die Verarbeitung der betroffenen Daten zum Zwecke der
          Direktwerbung.
        </Text>
      </Paragraph>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
`;

const Paragraph = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50vw;
  @media (max-width: 800px) {
    width: 80vw;
  }
`;

const ParagraphHeading = styled.div`
  margin-top: 40px;
  margin-bottom: 0;
  width: 50vw;
  @media (max-width: 800px) {
    width: 80vw;
  }
`;

const Text = styled.div`
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.4px;
  color: #333333;
  margin-top: 0;
  text-align: left;
`;

const Link = styled.a`
  color: #b2982a;
  text-decoration: none;
`;

const HeadingFour = styled.h4`
  color: #023682;
  font-family: "Sorts Mill Goudy";
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 400;
`;

export default Datenschutz;
