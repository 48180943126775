import PropTypes from "prop-types";
import React from "react";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import colors from "../constants/colors";

const StyledButton = ({ title, onClick, color, scale, fontSize, type, loading, error }) => {
    if (type === 'REMOVE') {
        return (
            <IconButton
                onClick={onClick}
                prop_Color={color}
                prop_Scale={scale}
                prop_fontSize={fontSize}
                disabled={loading}
            >
                {!loading ? title :
                    <Loader type="ThreeDots" color="white" height={scale === 'DEFAULT' ? '40px' :
                        scale === 'BIG' ? '65px' :
                            scale === 'ORDER' ? '32px' : '100%'} width={scale === 'DEFAULT' ? '80px' :
                                scale === 'BIG' ? '130px' :
                                    scale === 'ORDER' ? '64px' : '100%'} />
                }

            </IconButton>
        )
    } else {
        return (
            <div style={{ flexDirection: "column", display: "flex", }}>
                <Button
                    onClick={onClick}
                    prop_Color={color}
                    prop_Scale={scale}
                    prop_fontSize={fontSize}
                    disabled={loading}
                >
                    {!loading ? title :
                        <Loader type="ThreeDots" color="white" height={scale === 'DEFAULT' ? '40px' :
                            scale === 'BIG' ? '65px' :
                                scale === 'ORDER' ? '32px' : '100%'} width={scale === 'DEFAULT' ? '80px' :
                                    scale === 'BIG' ? '130px' :
                                        scale === 'ORDER' ? '64px' : '100%'} />
                    }

                </Button>
                {error && <ErrorText>{error}</ErrorText>}
            </div>
        );
    }
}

StyledButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  scale: PropTypes.oneOf(["DEFAULT", "BIG", "ORDER", "FIT"]),
  fontSize: PropTypes.string,
  type: PropTypes.string,
};

StyledButton.defaultProps = {
  scale: "DEFAULT",
};

const Button = styled.button`
  cursor: pointer;
  width: ${(props) =>
    props.prop_Scale === "DEFAULT"
      ? "216px"
      : props.prop_Scale === "BIG"
      ? "266px"
      : props.prop_Scale === "ORDER"
      ? "241px"
      : "100%"};
  height: ${(props) =>
    props.prop_Scale === "DEFAULT"
      ? "40px"
      : props.prop_Scale === "BIG"
      ? "65px"
      : props.prop_Scale === "ORDER"
      ? "32px"
      : "100%"};
  line-height: ${(props) =>
    props.prop_Scale === "DEFAULT"
      ? "43px"
      : props.prop_Scale === "BIG"
      ? "68px"
      : props.prop_Scale === "ORDER"
      ? "36px"
      : "32px"};
  font-size: ${(props) =>
    props.prop_fontSize
      ? props.prop_fontSize
      : props.prop_Scale === "DEFAULT"
      ? "21px"
      : props.prop_Scale === "BIG"
      ? "32px"
      : props.prop_Scale === "ORDER"
      ? "17px"
      : "17px"};
  font-family: "Sorts Mill Goudy";
  color: white;
  background-color: ${(props) => props.prop_Color};
  border-width: 0px;
  &:hover {
    ${props => props.prop_Color == colors.YellowBrigthButton ? "background-color: #DBC97A;" : "opacity: 60%;"}
    outline: none;
  }
  cursor: pointer;
  @media (max-width: 750) {
    width: unset;
  }
  display: flex;
  justify-content: center;
  &:focus{
    outline:none;
  }
`;
const IconButton = styled(Button)`
  background-color: ${colors.MercuryGreyInputfields};
  color: ${colors.Gray};
  width: 16px;
  height: 16px;
  padding: 0px;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  transition: 0.2s ease-in-out;
  &:hover {
    background-color: ${colors.YellowBrigthButton};
    color: ${colors.SolidWhiteBackground};
  }
`;

const ErrorText = styled.p`
  color: ${colors.ErrorRed};
  font-size: 11px;
  font-family: "Montserrat";
  font-weight: 300;
  margin: 0;
  padding: 0;
  text-align: right;
  white-space: pre-wrap;
  margin-top: 5px;
`;


export default StyledButton;
