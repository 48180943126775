import React, { useState } from 'react';
import { useLocalState } from './useLocalState';

export const NavigationTextDisplayContext = React.createContext('NavigationTextDisplayContext');
export const GetUserContext = React.createContext('GetUserContext');
export const GetFallbackURL = React.createContext('GetFallbackURL');
export const GetInviteLists = React.createContext('GetInviteLists');
export const GetCurrentPage = React.createContext('GetCurrentPage');


const Store = ({ children }) => {
    const [navigationTextDisplay, setNavigationTextDisplay] = useLocalState('NavigationTextDisplayState')
    const [getUser, setGetUser] = useLocalState('GetUserContext');
    const [getURL, setFallbackURL] = useLocalState('GetFallbackURL');
    const [getInviteLists, setInviteLists] = useLocalState('GetInviteLists');
    const [currentPage, setCurrentPage] = useState(false);
    const [order, setOrder] = useState();
    const [duration, setDuration] = useState(false);
    const [durationFromBackend,setDurationFromBackend ] = useState(false)
    const [selected, setSelected] = useState(false)
    // useEffect(()=>{
    //     setCurrentPage(false)
    // },[order])
    // const memoizedUser = useMemo(() => ({getUser,setGetUser}), [getUser, setGetUser]);
    // const memoizedNavigation = useMemo(() => ({navigationTextDisplay,setNavigationTextDisplay}), [navigationTextDisplay, setNavigationTextDisplay]);

    return (
        <NavigationTextDisplayContext.Provider value={[navigationTextDisplay, setNavigationTextDisplay]}>
            <GetUserContext.Provider value={[getUser, setGetUser]}>
                <GetFallbackURL.Provider value={[getURL, setFallbackURL]}>
                    <GetInviteLists.Provider value={[getInviteLists, setInviteLists]}>
                        <GetCurrentPage.Provider value={{ currentPage, setCurrentPage, order, setOrder, duration, setDuration, durationFromBackend,setDurationFromBackend, selected, setSelected }}>
                            {children}
                        </GetCurrentPage.Provider>
                    </GetInviteLists.Provider>
                </GetFallbackURL.Provider>
            </GetUserContext.Provider>
        </NavigationTextDisplayContext.Provider>
    )
}

export default Store;