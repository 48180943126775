import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import colors from "../constants/colors";
import constants from "../constants/constants";
import { GetCurrentPage } from "../localStorage/Store";
import styl from "../styl";
import AccordionComp from "./Accordion/Accordion";
import CircleWithText from "./CircleWithText";
import HeadingOne from "./HeadingOne";
import useAmountInput from "./hooks/useAmountInput";
import RefInput from "./RefInput";
import StateInput from "./StateInput";
import StyledButton from "./StyledButton";

const AddToExtCartMutation = gql`
  mutation AddToExtCart(
    $cartItems: [ExtCartItemInput]!
    $subordererKey: String!
  ) {
    __typename
    addToExtCart(
      input: {
        clientMutationId: "AddToCart"
        cartItems: $cartItems
        subordererKey: $subordererKey
      }
    ) {
      cart {
        lists {
          name
          total
          orderers {
            products {
              amount
              rowId
              total
              product {
                parent {
                  name
                  coolbox {
                    coolboxActivated
                    coolboxWeight
                  }
                }
                name
                price
                weight
                variationId
                id
              }
            }
            name
            total
          }
        }
        total
        coolbox
        shipping
        subtotalWithTax
        minimumOrderWeight
        totalWeight
        isNewCustomer
      }
    }
  }
`;
const AddSubordererToExtCart = gql`
  mutation addSubordererToExtCart(
    $cartItems: [ExtCartItemInput]!
    $subordererName: String!
    $subordererEmail: String
  ) {
    __typename
    addSubordererToExtCart(
      input: {
        clientMutationId: "addSubordererToExtCart"
        cartItems: $cartItems
        subordererName: $subordererName
        subordererEmail: $subordererEmail
      }
    ) {
      cart {
        lists {
          name
          total
          orderers {
            products {
              amount
              rowId
              total
              product {
                parent {
                  name
                  coolbox {
                    coolboxActivated
                    coolboxWeight
                  }
                }
                name
                price
                variationId
                weight
                id
              }
            }
            total
            name
          }
        }
        total
        coolbox
        shipping
        subtotalWithTax
        minimumOrderWeight
        totalWeight
        isNewCustomer
      }
    }
  }
`;
const subCheckoutMutation = gql`
  mutation MyMutation($key: String!) {
    __typename

    subCheckout(input: { clientMutationId: "", subordererKey: $key }) {
      success
    }
  }
`;
const GetExtCartItems = gql`
  query getExtCartItems($subordererKey: String!) {
    __typename
    extCart(subordererKey: $subordererKey) {
      addProductsUntil
      lists {
        name
        total
        orderers {
          products {
            amount
            rowId
            total
            product {
              parent {
                name
                coolbox {
                  coolboxActivated
                  coolboxWeight
                }
              }
              name
              price
              weight
              variationId
              id
            }
          }
          name
          total
        }
      }
      total
      coolbox
      shipping
      subtotalWithTax
      minimumOrderWeight
      totalWeight
      isNewCustomer
    }
  }
`;

const GetStandardProductQuery = gql`
  query MyQuery {
    __typename
    standardProduct {
      amount
      product {
        name
        variationId
        parent {
          name
          productId
          variations {
            nodes {
              name
              variationId
              weight
            }
          }
        }
      }
    }
  }
`;

const GetProductByID = gql`
  query getProduct($id: ID!) {
    __typename
    product(id: $id, idType: DATABASE_ID) {
      e2_shortDescription {
        shortdescription
      }
      ... on VariableProduct {
        name
        image {
          sourceUrl
        }
        variations {
          nodes {
            weight
            name
            variationId
          }
        }
      }
    }
  }
`;

const listBreak = 1190;
const detailBreak = 375;

/**
 * @author Sandro Mennel && Samuel Plankel
 * @param {boolean} data DataArray of the products
 * @param {function} activeView the function which is called when the back Button has been fired
 * @param {string} subordererKey the key of the order where Suborderer can join and add to cart
 * @param {string} parentPage if the component is called from the MultipleBuyerPage
 * @param {object} productList state of custom hook, list is transfered between components
 * @param {function} setter function to set the value of productList
 * @example <AddProduct data={data.products.nodes} saveOrder={()=>SaveOrder()}></AddProduct>
 */
const AddProduct = ({
  data,
  saveOrder,
  activeView,
  subordererKey,
  parentPage,
  productList,
  setter,
  accountType,
}) => {
  const [addToExtCart, { loading: loadingAddProduct }] =
    useMutation(AddToExtCartMutation);
  const [getProductById, { data: productData }] = useLazyQuery(GetProductByID);

  const { data: standardProductData } = useQuery(GetStandardProductQuery);
  const [addSubordererToExtCart] = useMutation(AddSubordererToExtCart);

  const [SubCheckout] = useMutation(subCheckoutMutation);
  const {
    loading: loadingExtCart,
    error,
    data: content,
    refetch,
  } = useQuery(GetExtCartItems, {
    // notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: { subordererKey },
  });
  // const amount = useRef('');
  useEffect(() => {
    if (content) {
      // setSelected([]);
      setCart(content.extCart);
      setSelected(content.extCart.lists);
      if (parentPage === "DataInputSubbesteller") setter(content.extCart.lists);
    }
  }, [content]);

  const firstname = useRef("");
  const lastname = useRef("");
  const email = useRef("");
  const { data234 } = useAmountInput();
  const [cart, setCart] = useState();
  const [selected, setSelected] = useState([]);
  // const amount = useRef('');
  const [amountState, setAmountState] = useState("");
  const [variations, setVariations] = useState([]);
  const [product, setProduct] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [variationButtonChecked, setVariationButton] = useState(0);
  const [selectedOption, setSelectedOption] = useState([
    data[0]?.name,
    0,
    data[0]?.productId,
  ]);
  const [lastSelectedOption, setLastSelectedOption] = useState([
    data[0]?.name,
    0,
    data[0]?.productId,
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [showAddSuborderer, setShowAddSuborderer] = useState(false);
  const { duration, setSelected: SetSelectedContext } =
    useContext(GetCurrentPage);
  const toggling = () => setIsOpen(!isOpen);
  const toggleShowAddSuborderer = () =>
    setShowAddSuborderer(!showAddSuborderer);

  const errorMessage1 =
    "Bitte geben Sie eine Zahl an! Keine Buchstaben oder Sonderzeichen";
  const errorMessage2 = "Bitte geben Sie eine Menge an!";
  const errorMessage3 = "Bitte fügen Sie zuerst Produkte der Liste hinzu!";
  const errorMessage4 =
    "Bitte wähle ein anderes Produkt und gib dann eine Menge an";
  const errorMessage5 = "Bitte geben Sie einen Vor- und Nachnamen ein!";

  useEffect(() => {
    if (standardProductData) {
      let tmpArray = [];
      let productId = "";
      const standardIndex = data.findIndex(
        (temp) =>
          temp.name == standardProductData.standardProduct.product.parent.name
      );
      setAmountState(standardProductData.standardProduct.amount);

      standardProductData.standardProduct.product.parent.variations.nodes.map(
        (item, i) => {
          if (
            item.variationId ==
            standardProductData.standardProduct.product.variationId
          ) {
            productId =
              standardProductData.standardProduct.product.parent.productId;

            setVariationButton(i);
            setLastSelectedOption([
              standardProductData.standardProduct.product.parent.name,
              standardIndex,
              standardProductData.standardProduct.product.parent.productId,
            ]);
            setSelectedOption([
              standardProductData.standardProduct.product.parent.name,
              standardIndex,
              standardProductData.standardProduct.product.parent.productId,
            ]);
          }
          tmpArray.push(item);
        }
      );
      getProductById({ variables: { id: productId } });
      // GetVariations()
      setVariations(tmpArray);
    }
  }, [standardProductData]);

  useEffect(() => {
    if (data234) {
      setSelected(data234);
    }
  }, [data234]);

  const addSelected = (value) => {
    //When clicked Produkt hinzufügen
    const e =
      variationButtonChecked >= variations.length
        ? 0
        : variationButtonChecked || 0;
    if (e === null) {
      setErrorMessage(errorMessage4);
    } else {
      let variation;
      variation = variations[e];

      if (value !== "") {
        let tmpCart = [
          {
            variationId: parseInt(variation.variationId),
            amount: parseInt(value),
          },
        ];

        addToExtCart({
          variables: { cartItems: tmpCart, subordererKey: subordererKey },
        })
          .then((response) => {
            setErrorMessage("");
            setSelected([]);
            setVariationButton(0);
            setCart(response.data.addToExtCart.cart);
            setSelected(response.data.addToExtCart.cart.lists);
          })
          .catch((error) => {
            alert(error.toString());
            // setErrorMessage(error);
          });
      } else {
        setErrorMessage(errorMessage2);
      }
    }
  };

  const addSubordererSelected = (value) => {
    //When clicked hinzufügen
    const e =
      variationButtonChecked >= variations.length
        ? 0
        : variationButtonChecked || 0;
    let variation = variations[e];

    if (
      e !== null &&
      value !== "" &&
      firstname.current.value !== "" &&
      lastname.current.value !== ""
    ) {
      let tmpCart = [
        {
          variationId: parseInt(variation.variationId),
          amount: parseInt(value),
        },
      ];
      let tmpSubordererName =
        firstname.current.value + " " + lastname.current.value;

      addSubordererToExtCart({
        variables: {
          cartItems: tmpCart,
          subordererName: tmpSubordererName,
          subordererEmail: email.current.value,
        },
      })
        .then((response) => {
          setErrorMessage("");
          setSelected([]);
          setVariationButton(0);
          setCart(response.data.addSubordererToExtCart.cart);
          setSelected(response.data.addSubordererToExtCart.cart.lists);
        })
        .catch((error) => {
          console.error(error.toString());
        });
    } else {
      if (e === null) {
        setErrorMessage(errorMessage4);
      }
      if (value === "") {
        setErrorMessage(errorMessage2);
      }
      if (firstname.current.value || lastname.current.value === "") {
        setErrorMessage(errorMessage5);
      }
    }
  };

  const GetVariations = () => {
    //When selected first dropdown
    const product = data[selectedOption[1]];
    let array = [];
    product.variations.nodes.map((item) => {
      array.push(item);
    });
    setProduct(product);
    setVariations(array);
  };

  const handleFinish = () => {
    let newArray = [];
    let params = queryString.parse(window.location.search);
    const key = decodeURIComponent(params.id);
    productList.map((tmp) => {
      let tmpArray = { variationId: tmp.id, amount: tmp.count };
      newArray = [...newArray, tmpArray];
    });

    SubCheckout({ variables: { key } })
      .then((response) => {
        activeView("OrderComplete");
      })
      .catch((e) => {
        console.error(e);
        alert("5", e.toString());
      });
  };

  const CheckList = () => {
    SetSelectedContext(false);
    if (selected.length == 0) {
      setErrorMessage(errorMessage3);
    } else {
      if (accountType === "noaccount" || accountType === "inviteLink") {
        handleFinish();
        activeView("ThanksScreen");
      } else {
        setErrorMessage("");
        parentPage === "DataInputSubbesteller"
          ? activeView("UserData")
          : activeView("OrderOverview");
      }
    }
  };

  const onOptionClicked = (value, index, productId) => {
    GetVariations();
    setSelectedOption([value, index, productId]);
    setLastSelectedOption([value, index, productId]);
    setIsOpen(false);
  };

  const handleHover = (value, index, productId) => {
    setSelectedOption([value, index, productId]);
    getProductById({ variables: { id: productId } });
    GetVariations();
  };

  return (
    <Main>
      <Wrapper>
        <LoginContainer>
          {parentPage != "SingleBuyerPage" && (
            <CircleWrapper>
              {duration.toString().includes("Bestelldauer") ? (
                <CircleWithText>{duration}</CircleWithText>
              ) : (
                <CircleWithText size="170" font="18">
                  Bestellmöglichkeit: <br />
                  {duration.toString().includes("Uhr") ? (
                    <strong style={{ fontWeight: 600 }}> bis {duration}</strong>
                  ) : (
                    <>
                      <strong style={{ fontWeight: 600 }}>
                        noch {duration} Tag(e)
                      </strong>{" "}
                      offen
                    </>
                  )}
                </CircleWithText>
              )}
            </CircleWrapper>
          )}

          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {showAddSuborderer && (
              <AddSuborderer>
                <ListHeader>Besteller</ListHeader>
                <RefInput
                  forwardedRef={firstname}
                  placeholder={"Vorname*"}
                  maxLength={40}
                />
                <RefInput
                  forwardedRef={lastname}
                  placeholder={"Nachname*"}
                  maxLength={40}
                />
                <RefInput
                  forwardedRef={email}
                  placeholder={"E-Mail (optional)"}
                  maxLength={100}
                />
              </AddSuborderer>
            )}
            <ListHeader>Produkte</ListHeader>
            {lastSelectedOption && lastSelectedOption.length != 0 && (
              <DropDownContainer>
                <DropDownHeader onClick={toggling}>
                  <div>{lastSelectedOption[0]}</div>
                  <ArrowDown />
                </DropDownHeader>
                {isOpen && (
                  <DropDownListContainer>
                    <DropDownList>
                      {data?.map((option, index) => {
                        if (lastSelectedOption[0] !== option.name)
                          return (
                            <ListItem
                              enable={index === 0 ? true : false}
                              onClick={() =>
                                onOptionClicked(
                                  option.name,
                                  index,
                                  option.productId
                                )
                              }
                              onMouseEnter={() =>
                                handleHover(
                                  option.name,
                                  index,
                                  option.productId
                                )
                              }
                              onMouseLeave={() =>
                                handleHover(
                                  lastSelectedOption[0],
                                  lastSelectedOption[1],
                                  lastSelectedOption[2]
                                )
                              }
                              key={index}
                              value={index}
                            >
                              {option.name}
                            </ListItem>
                          );
                      })}
                    </DropDownList>
                  </DropDownListContainer>
                )}
              </DropDownContainer>
            )}
            {variations.length !== 0 && variations[0].weight && (
              <SubSection>
                <ListHeader>Einheit</ListHeader>
                <div style={{ display: "flex", width: "100%" }}>
                  {variations.map((item, index) => (
                    <UnitButton
                      active={variationButtonChecked === index}
                      onClick={() => setVariationButton(index)}
                    >
                      {item.weight}
                    </UnitButton>
                  ))}
                </div>
              </SubSection>
            )}
          </div>
          <SubSection>
            <ListHeader style={{ marginBottom: "13px" }}>Menge</ListHeader>
            {variations.length !== 0 && (
              // <RefInput forwardedRef={amount} placeholder={'Menge'} />
              <StateInput
                id={"link"}
                forwardedRef={amountState}
                onChange={(e) => {
                  if (
                    /^\d*\d*$/.test(e.target.value) &&
                    e.target.value <= 100
                  ) {
                    setAmountState(e.target.value);
                  }
                }}
                type={"text"}
                placeholder={"Menge"}
                disabled
              />
            )}
          </SubSection>
          <SubSection style={{ gap: "5px" }}>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {!showAddSuborderer && (
              <StyledButton
                onClick={() => addSelected(amountState)}
                scale={"ORDER"}
                title={"Produkt hinzufügen"}
                color={colors.YellowBrigthButton}
                fontSize={"20px"}
                loading={loadingAddProduct}
              />
            )}
            {showAddSuborderer && (
              <ButtonWrapper>
                <StyledButton
                  onClick={() => {
                    addSubordererSelected(amountState);
                  }}
                  scale={"ORDER"}
                  title={"hinzufügen"}
                  color={colors.YellowBrigthButton}
                  fontSize={"20px"}
                />
              </ButtonWrapper>
            )}
            {parentPage === "MultipleBuyerPage" && (
              <StyledButton
                onClick={() => toggleShowAddSuborderer()}
                scale={"ORDER"}
                title={showAddSuborderer ? "zurück" : "Besteller hinzufügen"}
                color={colors.YellowBrigthButton}
                fontSize={"20px"}
              />
            )}

            {parentPage !== "DataInputSubbesteller" && !showAddSuborderer && (
              <StyledButton
                onClick={() =>
                  parentPage === "MultipleBuyerPage"
                    ? activeView("Invite")
                    : activeView("UserData")
                }
                scale={"ORDER"}
                title={"zurück"}
                color={colors.YellowBrigthButton}
                fontSize={"20px"}
              />
            )}
            {!showAddSuborderer && (
              <StyledButton
                onClick={() => CheckList()}
                scale={"ORDER"}
                title={
                  accountType === "noaccount" || accountType === "inviteLink"
                    ? "Bestellung abschließen"
                    : "weiter"
                }
                color={"#B2982A"}
                loading={loadingExtCart}
                fontSize={"20px"}
              />
            )}
          </SubSection>
          <MarginBot6 />
        </LoginContainer>
        <ProductDetailWrapper>
          {selectedOption && selectedOption.length != 0 && (
            <ProductDetailContent>
              <Image img={productData?.product.image.sourceUrl} />
              <HeadingOne title={selectedOption[0]} fontSize={30} />
              <Paragraph>
                {productData?.product.e2_shortDescription.shortdescription}
              </Paragraph>
              <table>
                <tbody style={{ display: "inline" }}>
                  {/* {data[selectedOption[1]].variations.nodes.map((vari) => ( */}
                  {data[selectedOption[1]].variations.nodes.map((item) => {
                    return (
                      <tr>
                        <Td>€</Td>
                        <Td style={item.weight && { textAlign: "right" }}>
                          {item.price.split("€")}
                        </Td>
                        {item.weight && (
                          <>
                            {" "}
                            <Td>|</Td>
                            <Td>{item.weight} kg</Td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                  {/* ))} */}
                </tbody>
              </table>
              <LinkWrapper
                onClick={() =>
                  window.open("/produktDetail?id=" + selectedOption[2])
                }
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  Zur Produktseite
                </a>
              </LinkWrapper>
            </ProductDetailContent>
          )}
        </ProductDetailWrapper>
        {!loadingExtCart ? (
          <ListWrapper>
            {/* <ListHeader>
            {selected?.length == 0 && <p>Noch keine Artikel im Warenkorb</p>}
          </ListHeader> */}
            <AccordionComp
              data={selected}
              parentPage={parentPage}
              cart={cart}
            />
            {parentPage != "SingleBuyerPage" && (
              <OrderOption>
                {duration.toString().includes("Bestelldauer") ? (
                  <strong>{duration}</strong>
                ) : (
                  <>
                    Bestellmöglichkeit:&nbsp;
                    {duration.toString().includes("Uhr") ? (
                      <strong> bis {duration}</strong>
                    ) : (
                      <strong>noch {duration} Tag(e)&nbsp;</strong>
                    )}{" "}
                    offen.
                  </>
                )}
              </OrderOption>
            )}
          </ListWrapper>
        ) : (
          <ListWrapper>
            <Loader
              height={400}
              width={200}
              type="ThreeDots"
              color={colors.YellowBrigthButton}
            />
          </ListWrapper>
        )}
      </Wrapper>
    </Main>
  );
};

AddProduct.propTypes = {
  data: PropTypes.array.isRequired,
};

const Main = styled.div`
  background-color: ${colors.SolidWhiteBackground};
  max-height: 900px;
  ${styl.defaultFontFamily}
  font-size: 14px;
  font-weight: 200;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    max-height: unset;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4.8vw;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    justify-content: center;
  }
`;
const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 241px;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    align-items: center;
    margin-right: 0;
  }
`;
const DropDownContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 15px;
`;
const DropDownHeader = styled.div`
  ${styl.transition};
  ${styl.textFont};
  ${styl.lightDefaultFont};
  background: ${colors.MercuryGreyInputfields};
  color: ${colors.Gray};
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`;
const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 12px solid ${colors.Gray};
`;
const DropDownListContainer = styled.div`
  ${styl.textFont};
  ${styl.lightDefaultFont};
  color: ${colors.Gray};
  width: 100%;
  position: absolute;
  top: 32px;
`;
const DropDownList = styled.ul`
  background: ${colors.MercuryGreyInputfields};
  padding: 0;
  margin: 0;
`;
const ListItem = styled.li`
  list-style: none;
  height: 32px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border-top: ${(props) =>
    props.enable ? `1px solid ${colors.Gray}` : "unset"};
  border-bottom: 1px solid ${colors.Gray};
`;
const SubSection = styled.div`
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const OrderOption = styled.p`
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.35;
  color: #4d4d4d;
  /* text-align: right; */
  line-height: 1.2;
  display: none;
  @media (max-width: 1270px) {
    display: flex;
  }
`;
const UnitButton = styled.button`
  ${styl.clear};
  ${styl.transition};
  ${styl.textFont};
  ${styl.lightDefaultFont};
  background-color: ${(props) =>
    props.active ? colors.YellowBrigthButton : colors.MercuryGreyInputfields};
  color: ${(props) =>
    props.active ? colors.SolidWhiteBackground : colors.Gray};
  height: 32px;
  width: 70px;
  margin-right: 8px;
  border: none;
  &:hover {
    opacity: 0.7;
  }
`;
const ProductDetailWrapper = styled.div`
  flex: 2;
  display: flex;
  max-width: 350px;
  background-color: ${colors.Cararra};
  min-width: 280px;
  @media (max-width: 1300px) {
    flex: 1;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-right: 0;
  }
`;
const ProductDetailContent = styled.div`
  margin: 5px auto 25px;
  width: 85%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    /* align-items: center; */
  }
`;
const Image = styled.div`
  width: 100%;
  height: 150px;
  min-height: 150px;
  background-image: url(${(props) => props.img});
  background-size: contain;
  background-position: center;
  box-sizing: border-box;
  background-repeat: no-repeat;
  @media (max-width: 770px) {
    margin-top: 10px;
  }
`;
const Paragraph = styled.p`
  margin-top: 20px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;

  @media (min-width: 1600px) {
    font-size: 17px;
  }
`;
const Td = styled.td`
  text-align: left;
  font-size: 14px;
  ${styl.lightDefaultFont};
  color: ${colors.SolidBlackText};
  line-height: 20px;
  font-weight: 200;
  max-width: 580px;
`;
const LinkWrapper = styled.div`
  margin-top: 20px;
  a {
    color: ${colors.SolidBlackText};
    text-decoration: underline;
  }
`;
const ErrorMessage = styled.p`
  color: red;
`;

const AddSuborderer = styled.div`
  margin-bottom: 20px;
`;
const ButtonWrapper = styled.div`
  height: 32px;
  margin-top: 5px;
`;
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  min-width: 420px;
  @media (max-width: ${listBreak}px) {
    margin-top: 2.8vw;
  }
  @media (max-width: 770px) {
    min-width: 380px;
    /* margin-bottom: 20px; */
  }
  @media (max-width: ${detailBreak}px) {
    width: 88%;
    min-width: 260px;
  }
`;
const ListHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  /* margin-bottom: 15px; */
  font-size: 15px;
  font-weight: 300;
  @media (max-width: ${detailBreak}px) {
    width: 100%;
  }
`;

const CircleWrapper = styled.div`
  position: absolute;
  top: 17vh;
  right: 1vw;
  cursor: pointer;

  @media (min-width: 1700px) {
    top: 150px;
    left: 1500px;
    right: unset;
  }
  @media (max-width: 1270px) {
    display: none;
  }
`;

const MarginBot6 = styled.div`
  height: 10px;
`;

export default AddProduct;
