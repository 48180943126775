import React, { useEffect } from "react";
import styled from "styled-components";
import Hilda1 from "../../assets/images/hilda1.jpg";
import Hilda2 from "../../assets/images/hilda2.jpg";
import HildaSmall from "../../assets/images/hilda_sw.jpg";
import CircelWithText from "../../components/CircleWithText";
import HeadingOne from "../../components/HeadingOne";
import PictureText from "../../components/PictureText";
import Quote from "../../components/Quote";

// Import Images

export default function AttitudePage() {
  useEffect(() => {
    document.title = "Hilda";
  }, []);

  return (
    <Main>
      <ContentContainer>
        <TopWrapper>
          <WrapperWithPicture>
            <HeadingOne title="Hilda" />
            <SmallImg src={HildaSmall} />
          </WrapperWithPicture>
          <QuoteDiv>
            <Quote
              mainText={["gär z’nëtt", <br />, "vôrschprëngt’s gen."]}
              description={["Alles mit Maß und Ziel"]}
            />
          </QuoteDiv>
        </TopWrapper>
        <PictureTextDiv>
          <DesktopWrapper>
            <PictureText
              text={
                ["Aufgewachsen bin ich auf einer kleinen Landwirtschaft im hinteren Bregenzerwald, mit meinem Bruder. Der respektvolle Umgang mit Natur, Tier und Mensch war für meine Eltern ein unausgesprochener Lebensgrundsatz. Kleine Strukturen waren für sie überschaubar und bildeten das Fundament für ein gutes Leben.", <br />, "Ich schätze Menschen, die erkennen, dass es neben dem Du auch ein Wir braucht. Daher sind wir Mitglied beim Werkraum Bregenzerwald. Einer über die Grenzen hinaus bekannten Kooperative von Handwerkerinnen und Handwerkern aus der Region. "]
              }
              picture={Hilda1}
              format={"row"}
            ></PictureText>
            <CircleDiv></CircleDiv>
          </DesktopWrapper>
          <DesktopWrapper>
            <PictureText
              text={
                ["Ich bringe mich auf der inhaltlichen Ebene von gut gereift ein. Das Handwerkszeug dazu durfte ich von Renate Breuss erlernen, der ehemaligen Geschäftsführerin des Werkraum Bregenzerwald und Autorin von „das Maß im Kochen“. Im Gründungsprozess hat sie uns geholfen, unsere Haltung und Gedanken auf Papier zu bringen und uns ermutigt, unsere Visionen zu verfolgen.", <br />, "Das Aufwachsen unserer Kinder schärft meinen Blick in vielen Lebensbereichen. Perfektion ist für mich nicht erstrebenswert. Vielmehr die Konzentration aufs Wesentliche. Das heißt für mich auch zu wissen, wann es genug ist. Daraus hat sich der Mut entwickelt, gemeinsam als Familie selbstbestimmt unseren Weg zu gehen. Darauf blickend mit wem und was wir unsere Zeit verbringen."]
              }
              picture={Hilda2}
              format={"row-reverse"}
            ></PictureText>
          </DesktopWrapper>
        </PictureTextDiv>
        <CircleDiv
          onClick={() => {
            window.location.href = "/entdecken";
          }}

        >
          <CircelWithText>Gutes entdecken</CircelWithText>
        </CircleDiv>
      </ContentContainer>
    </Main>
  );
}

const Main = styled.div`
  /* height: 100vh; */
  padding-bottom: 100px;
  width: 100vw;
  max-width: 100%;
  @media (max-width: 1980px) and (max-height: 1080px) {
    display: flex;
    justify-content: center;
  }
`;
const QuoteDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  max-width: 70vw;
  @media (max-width: 950px) {
    display: none;
  }
`;

const DesktopWrapper = styled.div`
  display: block;
  /* width: 80vw; */
  display: flex;
  flex-direction: row;
  @media (max-width: 1600px) {
    flex-direction: column;
    /* width: 70vw; */
  }
`;

const CircleDiv = styled.div`
  position: absolute;
  right: 200px;
  top: 800px;
  cursor: pointer;
  @media (max-width: 1920px){
    right: 150px;
  }
  @media (max-width: 1840px){
    right: 50px;
  }
  @media (max-width: 1400px){
    right: 25px;
  }
  @media (max-width: 1300px){
    position: relative;
    top: unset;
    right: unset;
    margin-left: 100px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 960px;
  @media (max-width: 1100px) {
    width: 80vw;
    margin-left: 10vw;
  }
`;

const WrapperWithPicture = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
`;

const SmallImg = styled.img`
  width: 150px;
  height: 150px;
  margin-bottom: 30px;
  margin-top: 20px;

  @media (max-width: 770px) {
    margin-top: 15px;
  }
`;

const PictureTextDiv = styled.div`
  width: 60vw;
  max-width: 960px;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (max-width: 1300px) {
    width: 90vw;
    margin-top: 0;
  }
  @media (max-width: 1100px) {
    width: 90vw;
    margin-top: 0;
    margin-left: 0;
  }
`;
const ContentContainer = styled.div`
  width: 90vw;
  margin-left: 20vw;
  display: flex;
  flex-direction: column;
  margin-left: 28vw;
  @media (max-width: 1980px) and (max-height: 1080px) {
    margin-top: 20px;
  }
  @media (max-width: 1700px) {
    margin-left: 20vw;
  }
  @media (max-width: 1300px) {
    width: 90vw;
    margin-left: 5vw;
  }
  @media (max-width: 1100px) {
    width: 100%;
    margin-left: 0;
  }
`;
