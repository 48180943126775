// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   02.08.2020
// Last edited:     02.08.2020

import React, { useContext } from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import LandscapeBrand from "../../assets/images/gutgereift_logo.svg";
import PortraitBrand from "../../assets/images/PortraitBrand.jpeg";
import LoginIcon from "../../assets/svgs/profil_icon.svg";
import colors from "../../constants/colors";
import constants from "../../constants/constants";
import { GetUserContext } from "../../localStorage/Store";
import styl from "../../styl";
import Login from "./../Login";
import { DropDownButton, DropDownContainer, ExpandButton } from "./DropDown";
import LoginButton from "./LoginButton";
import Navigation from "./Navigation";
import NavigationItem from "./NavigationItem";
const cookies = new Cookies();

const Header = () => {
  const [user, setUser] = useContext(GetUserContext);
  const logout = () => {
    cookies.remove("AuthToken", { path: "/" });
    cookies.remove("RefreshToken", { path: "/" });
    //remove all cookies from all paths
    var cookies2 = document.cookie.split("; ");
    for (var c = 0; c < cookies2.length; c++) {
      var d = window.location.hostname.split(".");
      while (d.length > 0) {
        var cookieBase =
          encodeURIComponent(cookies2[c].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          d.join(".") +
          " ;path=";
        var p = window.location.pathname.split("/");
        document.cookie = cookieBase + "/";
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/");
          p.pop();
        }
        d.shift();
      }
    }
    setUser(null);
  };
  const [showDropDown, setShowDropDown] = React.useState(false);

  return (
    <>
      <DropDownContainer
        isVisible={showDropDown}
        showBehindHeader={true}
        onCloseIconClick={() =>
          setShowDropDown((value) => {
            return !value;
          })
        }
      >
        {!user ? (
          <Login />
        ) : (
          <>
            <div style={{ width: 10, height: 15 }}></div>
            <div style={{ width: 10, height: 15 }}></div>
            <DropDownItem>
              <NavigationItem
                href={"/profile"}
                text={"Profil"}
                alignment="right"
              />
            </DropDownItem>
            <DropDownItem>
              <NavigationItem
                href={"/bestellhistorie"}
                text={"Bestellhistorie"}
                alignment="right"
              />
            </DropDownItem>
            <DropDownItem>
              <NavigationItem
                href={"/einladen"}
                text={"Community verwalten"}
                alignment="right"
              />
            </DropDownItem>
            <DropDownItem>
              <div
                onClick={() => logout()}
                style={{
                  width: "300px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <NavigationItem
                  href={"/home"}
                  text={user ? <strong>Abmelden</strong> : null}
                  alignment="right"
                />
              </div>
            </DropDownItem>
          </>
        )}
      </DropDownContainer>
      <StyledHeader>
        <HeaderContainer href="/home">
          <HeaderBrand />
        </HeaderContainer>
        <Navigation />
        <HeaderRight>
          <DropDownButton>
            {!user ? (
              <LoginButton
                text={"Log in"}
                fontWeight={"bold"}
                icon={LoginIcon}
                onClick={() =>
                  setShowDropDown((value) => {
                    return !value;
                  })
                }
              />
            ) : (
              <ExpandButton
                onClick={() => {
                  setShowDropDown((value) => {
                    return !value;
                  });
                }}
              />
            )}
          </DropDownButton>
        </HeaderRight>
      </StyledHeader>
    </>
  );
};

const HeaderBrand = styled.div`
  background: url(${LandscapeBrand});
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    background: url(${PortraitBrand});
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const HeaderContainer = styled.a`
  height: 120px;
  width: 18vw;
  max-width: 320px;
  margin-right: 20px;
  /* margin: auto 0; */
  display: block;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    width: 200px;
    height: 130px;
    margin: 0;
  }
`;

const StyledHeader = styled.div`
  position: sticky;
  top: 0;
  max-height: 120px;
  min-height: 76px;
  height: ${(window.innerHeight / 100) * 12}px;
  background: ${colors.SolidWhiteBackground};
  box-sizing: border-box;
  padding: 15px 33.33px;
  padding-right: 0;
  ${styl.flexRow};
  justify-content: space-between;
  align-items: center;
  z-index: 100000;

  @media (max-width: ${constants.nexusLandscapeWidth}px) {
    height: 76px;
    padding: 16px;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    ${styl.flexColumn};
    ${styl.flexCenter};
    padding: 32px 0;
    max-height: 1000px;
    height: auto;
  }
`;

const HeaderRight = styled.div`
  ${styl.clear};
  ${styl.flexRow};
  align-items: center;
  box-sizing: border-box;
  ${styl.transition};
  margin: 0px;
  cursor: pointer;
  height: 100%;
  margin-left: 10px;

  &.sel {
    p {
      font-weight: 600;
    }
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    position: absolute;
    right: 0;
    top: 0;
    padding: 16px 2px;
    height: auto;
    width: auto;
    margin: 0px;
  }
`;

const DropDownItem = styled.div`
  height: 50px;
  border-bottom: 1px solid #e1e1e1;
  width: 300px;
  padding-left: 50px;
  padding-right: 10px;
  text-align: right !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 440px) {
    width: 250px;
  }
  @media (max-width: 370px) {
    width: 200px;
  }
  @media (max-width: 330px) {
    padding-left: 0;
  }
`;

export default Header;
