// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   02.08.2020
// Last edited:     02.08.2020

import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import LoginButton from "../../assets/svgs/profil_icon.svg";
import constants from "../../constants/constants";
import styl from "../../styl";
import Alert from "../Alert";

// Props:
// - navState: Boolean (required)
// -- Sets the link items' text color.
// -- If no fontColor is provided, the fontColor will be #4D4D4D by default.
// - onClick: Function (required)
// -- Toggles the state of the Button (3 bars in a column or cross) and the navigation (open/close)

// Example: <BurgerButton navState={navState} onClick={() => setNavState(prevNavState => !prevNavState)} />

const BurgerButton = ({ navState, onClick, children }) => {
  return (
    <DropDownButton>
      <ExpandButton></ExpandButton>
      <DropDownContent>{children}</DropDownContent>
    </DropDownButton>
  );
};

export const ExpandButton = (props) => {
  return (
    <Button onClick={() => props.onClick()}>
      {/* <TablerIcon
                iconName={"chevron-left"}
                size={26}
                color={colors.SolidGreyText}
                stroke={colors.SolidGreyText}
                className={"icon"}
            >
            </TablerIcon> */}
      <ImageLogo
        src={LoginButton}
        width="40px"
        height="40px"
        // style={{ marginRight: "33.33px" }}
      />
    </Button>
  );
};

BurgerButton.propTypes = {
  navState: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Button = styled.button`
${styl.clear};
  height: calc(100%);
  width: calc(100%);
  ${styl.flexColumn};
  ${styl.flexCenter};
  min-height: 40px;
  padding: 0 10px;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const DropDownButton = styled.div`
  height: 100%;
  position: relative;
  
  /* width: 40vw; */
  .icon {
    ${styl.transition};
  }

  .open {
    ${styl.flexColumn}
      right: 0;
  }

  @media (max-width: 770px){
    margin-right: 0;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    position: unset;
  }
`;

export const DropDownContent = styled.div`
  background: white;
  padding: 20px;
`;

export const StyledDropDownContainer = styled.div`
  position: fixed;
  right: 0;
  top: 93px;
  display: block;
  ${styl.transition};
  /* ${styl.shadow("0px 0px 17px 0px rgba(77,77,77,0.2)")}; */
  width: ${props => props.isVisible ? "auto" : "0px"};
  overflow: hidden;
  z-index: 1000000000;
`

export const DropDownContainer = (props) => {
  return (
    window.innerWidth < 1024 ?
      <Alert isVisible={props.isVisible} width={300} showBehindHeader={props.showBehindHeader} background={"#FFFc"} onCloseIconClick={() => props.onCloseIconClick()} showCloseIcon={true}>
        <DropDownContent>
          {props.children}
        </DropDownContent>
      </Alert>
      :
      <StyledDropDownContainer isVisible={props.isVisible}>
        <DropDownContent>
          {props.children}
        </DropDownContent>
      </StyledDropDownContainer>
  )
}

const ImageLogo = styled.img`
  margin-right: 33.33px;
  @media (max-width: 770px){
    margin-right: 0;
  }
`

export default BurgerButton;
