// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   13.11.2020
// Last edited:     13.11.2020

import React from "react";
import styled from "styled-components";
import HeadingOne from "../../components/HeadingOne";
import Quote from "../../components/Quote";
import StyledButton from "../../components/StyledButton";
import colors from "../../constants/colors";
import constants from "../../constants/constants";
import styl from "../../styl";

const Maintenance = () => {
  return (
    <Container>
      <SplitContainerLeft>
        <Image />
      </SplitContainerLeft>
      <SplitContainerRight>
        <HeadingOne title="Wartungsarbeiten" />
        <div style={{ marginLeft: -30 }}>
          <Quote
            align="LEFT"
            mainText={
              <span>
                gutgereift isch gad am riefo,
                <br />
                kumm spätor nomol vrbi
              </span>
            }
            description={
              <span>
                gutgereift reift gerade
                <br />
                komme später nochmal vorbei
              </span>
            }
          ></Quote>
        </div>
        <a href="/home">
          <StyledButton
            title={"Nochmal probieren"}
            onClick={() => {
              window.location.href = "/home";
            }}
            fontSize={"20px"}
            color={colors.YellowBrigthButton}
            scale={"DEFAULT"}
          />
        </a>
      </SplitContainerRight>
    </Container>
  );
};

const Image = styled.div`
  min-height: 300px;
  min-width: 300px;
  width: 100%;
  height: 100%;
  max-height: 500px;
  background: url("https://api.gutgereift.at/app/uploads/2020/10/schnittig_jung_kl.gif");
  background-position-x: right;
  background-position-y: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const Container = styled.div`
  ${styl.flexRow};
  flex: 1;
  height: 100%;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    ${styl.flexCenter};
  }
`;

const SplitContainerRight = styled.div`
  ${styl.flexColumn};
  flex: 1;
  padding: 4vh 2.5vw;
  justify-content: center;
  align-items: left;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    max-width: 300px;
  }
`;

const SplitContainerLeft = styled.div`
  ${styl.flexColumn};
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  padding: 4vh 2.5vw;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    display: none;
  }
`;

export default Maintenance;
