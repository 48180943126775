import Cookies from 'universal-cookie';

const AUTH_TOKEN = 'AUTH_TOKEN';
const cookies = new Cookies();
let token;

export const getToken = async () => {
  token = await cookies.get("AuthToken")
  return token;
  };
