import React, { useEffect } from "react";
import styled from "styled-components";
import registrieren from "../../assets/images/community/1_registrieren_frei.png";
import einladen from "../../assets/images/community/2_einladen_frei.png";
import bestellschein from "../../assets/images/community/3_bestellschein_frei.png";
import paket from "../../assets/images/community/4_paket_frei.png";
import uebergabe from "../../assets/images/community/5_uebergabe_frei.png";
import sozial from "../../assets/images/community/6_sozial_frei.png";
import elefant from "../../assets/images/community/7_elefant.png"
import HeadingOne from "../../components/HeadingOne";
import constants from "../../constants/constants";
import CircleWithText from "./../../components/CircleWithText";
import ComunityComponent from "./../../components/ComunityComponent";


const Community = () => {
  useEffect(() => {
    document.title = "Community";
  }, []);
  return (
    <Wrapper>
      <Box>
        <HeadingOne title="So funktioniert's" />
        <br />
        <Content>
          <PageDividerLeft>
            {/* <BoxWrapper> */}
            <ComunityComponent
              src={registrieren}
              text={[
                <strong>1. Registriere</strong>,
                " dich bei gutgereift.at.",
                <br />,
                <Symbol>&#10093;</Symbol>,
              ]}
            />
            <ComunityComponent
              src={einladen}
              text={[
                <strong>2.</strong>,
                " lade ",
                <strong>FreundInnen, KollegInnen</strong>,
                " und ",
                <strong>Familie</strong>,
                " über ",
                <strong>gutgereift.at</strong>,
                " ein, mit dir zu bestellen.",
              ]}
            />
            <ComunityComponent
              src={bestellschein}
              text={[
                <strong>3. Schicke</strong>,
                " die gesamte ",
                <strong>Bestellliste an </strong>,
                <strong>gut gereift</strong>,
                " im Bregenzerwald ab.",
              ]}
            />
            {/* </BoxWrapper>
          <BoxWrapper> */}
            <ComunityComponent
              src={paket}
              text={[
                <strong>4.</strong>,
                " Erhalte den bestellten Käse gesammelt in einem Paket. ",
                "Die einzelnen ",
                <strong>Käsestücke</strong>,
                " sind ",
                <strong>mit Namen beschriftet.</strong>,
              ]}
            />
            <ComunityComponent
              src={uebergabe}
              text={[
                <strong>5.</strong>,
                " Informiere deine FreundInnen und KollegInnen, dass der Käse ",
                <strong>abholbereit</strong>,
                " ist oder ",
                <strong>bring</strong>,
                " ihn ",
                <strong>persönlich vorbei.</strong>,
              ]}
            />
            <ComunityComponent
              src={elefant}
              text={[
                <strong>6.</strong>,
                " Freue dich über mehr ",
                <strong>soziale Kontakte</strong>,
                " und ",
                " gut gereiften Käse aus dem Bregenzerwald.",
              ]}
            />
            {/* </BoxWrapper> */}
          </PageDividerLeft>
          <PageDividerRight onClick={() => {
                window.location.href = "./bestellen";
              }}>
            <CircleWithText font="30" size="170"
            >
              Bestellen
            </CircleWithText>
          </PageDividerRight>
        </Content>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4vh;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-bottom: 40px;
  }
`;

const Box = styled.div`
  width: 91%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1500px) {
    flex-direction: column;
  }

  @media (min-width: 1920px) {
    width: 1745px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 1360px) {
    flex-direction: column;
  }
`;

const PageDividerLeft = styled.div`
  width: 80%;
  display: grid;
  grid-template-columns: auto auto auto;

  @media (max-width: 1360px) {
    width: 100%;
  }

  @media (max-width: 1100px) {
    grid-template-columns: auto auto;
  }

  @media (max-width: 800px) {
    grid-template-columns: auto;
    justify-content: center;
  }
`;

const PageDividerRight = styled.div`
  width: 20%;
  position: fixed;
  top: 14vh;
  right: 2vw;
  cursor: pointer;

  @media (max-width: 1360px) {
    position: unset;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const Symbol = styled.p`
  font-size: 40px;
  transform: rotate(90deg);
  display: none;
  font-weight: 100;
  color: #ccb242;
  position: fixed;
  position: --webkit-sticky;
  bottom: 0px;
  left: 50vw;

  @media (max-width: 800px) {
    display: block;
  }
`;

export default Community;
