import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { GetUserContext } from '../../localStorage/Store';
import constants from './../../constants/constants';
import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";

const OverviewPage = () => {
	const [visbile, setVisible] =useState(true)
	const [user, setUser] = useContext(GetUserContext);
	return (
		<Wrapper>
			 {!user && <Box>
                {visbile  ? 
                <Content>
				<LoginPage></LoginPage>
                </Content>:
                <Content>
				<RegisterPage></RegisterPage>
                <p style={{cursor:"pointer"}} onClick={()=>setVisible(true)}>Bereits ein Konto?</p>
                </Content>}
			</Box>}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 9.2vh;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
		margin-bottom: 40px;
	}
`;

const Box = styled.div`
	width: 91%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media (max-width: 1500px) {
		flex-direction: column;
	}

  @media (min-width: 1920px) {
    width: 1745px;
  }
`;

const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
  margin-top: 40px;
  align-items: center;
	@media (max-width: 1550px) {
		width: 100%;
		margin-top: 20px;
	}

	@media (max-width: ${constants.nexusPortraitWidth}px) {
		flex-direction: column;
		padding-top: 10px;
	}
`;


export default OverviewPage;
