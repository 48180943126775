import React, { Component } from 'react';
import styled from 'styled-components';
import styl from '../styl';

/**
 * 
 * @param {boolean} props.isVisible - If this property is true, the overlay is visible. If not, the overlay is hidden.
 * @param {Component} props.chilrden - The content to display within the alert field.
 * @example <Alert isVisible={true}>
                <p>Test</p>
            </Alert>
 */

const Alert = (props) => {

    const isVisible = props.isVisible


    return (
        isVisible ?
            <Overlay showBehindHeader={props.showBehindHeader} background={props.background}>
                <Row
                    style={{ flex: 1 }}
                // onClick={() => { setIsVisible(prev => { return !prev }) }}
                />
                <Row>
                    <div
                        style={{ flex: 1 }}
                    // onClick={() => { setIsVisible(prev => { return !prev }) }}
                    />
                    <Content width={props.width} >
                        {props.showCloseIcon ?
                            <CloseIcon background={props.background} onClick={() => props.onCloseIconClick()}>

                            </CloseIcon>
                            :
                            null}
                        {props.children}

                    </Content>
                    <div
                        style={{ flex: 1 }}
                    // onClick={() => { setIsVisible(prev => { return !prev }) }}
                    />
                </Row>
                <Row
                    style={{ flex: 1 }}
                // onClick={() => { setIsVisible(prev => { return !prev }) }}
                />

            </Overlay>
            :
            <></>
    )
}

const Overlay = styled.div`
    position: fixed;
    background: ${props => props.background ? props.background : "#000c"};
    top: -${props => props.top}px;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    z-index: ${props => props.showBehindHeader ? 99999 : 1000000};
    ${styl.flexColumn};
`

const Row = styled.div`
    ${styl.flexRow};
`

const Content = styled.div`
    max-width: 545px;
    width: ${props => props.width ? "auto" : "90%"};
    background: white;
    padding: 4vh 4vw;
    box-sizing: border-box;
    position: relative;
`

const CloseIcon = styled.button`
${styl.clear};
    height: 40px;
    width: 40px;
    position: absolute;
    right: 0;
    top: -40px;
    cursor: pointer;

    &:before, &:after {
        width: 20px;
        height: 3px;
        right: 0;
        top: 20px;
        background: ${props => props.background ? "#808080" : "white"};
        content: ' ';
        display: block;
        position: absolute;
    } 

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

`

export default Alert;