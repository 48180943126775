import { gql, useMutation, useQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import React, { useContext, useReducer, useRef, useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import styled from "styled-components";
import Cookies from "universal-cookie";
import MultipleSelect from "../../components/MultipleSelect";
import RefInput from "../../components/RefInput";
import SingleSelect from "../../components/SingleSelect";
import StateInput from "../../components/StateInput";
import { GetFallbackURL, GetUserContext } from "../../localStorage/Store";
import HeadingOne from "./../../components/HeadingOne";
import StyledButton from "./../../components/StyledButton";
import colors from "./../../constants/colors";
import constants from "./../../constants/constants";
import NotifyAlert from "../../components/inviteList/NotifyAlert";

const RegisterUserMutation = gql`
  mutation REGISTER_Customer(
    $agb: String
    $dsgvo: String
    $firstName: String!
    $shippingSameAsBilling: Boolean
    $lastName: String!
    $email: String!
    $password: String!
    $username: String!
    $phone: String!
    $address1: String!
    $city: String!
    $postcode: String!
    $country: CountriesEnum
  ) {
    registerCustomer(
      input: {
        clientMutationId: "RegCust"
        agb: $agb
        dsgvo: $dsgvo
        showName: true
        showPrice: true
        shippingSameAsBilling: $shippingSameAsBilling
        username: $username
        billing: {
          address1: $address1
          city: $city
          country: $country
          email: $email
          firstName: $firstName
          lastName: $lastName
          phone: $phone
          postcode: $postcode
        }
        email: $email
        firstName: $firstName
        lastName: $lastName
        password: $password
        shipping: {
          address1: $address1
          city: $city
          firstName: $firstName
          lastName: $lastName
          postcode: $postcode
          country: $country
        }
      }
    ) {
      customer {
        id
        iban
        showPrice
        showName
        customerId
        jwtAuthToken
        jwtRefreshToken
        email
        firstName
        lastName
        username
        hasCoupon
        billing {
          address1
          city
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
        shipping {
          address1
          city
          postcode
          state
          country
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`;
const RegisterUser2Mutation = gql`
  mutation REGISTER_Customer(
    $firstName: String!
    $agb: String
    $dsgvo: String
    $shippingSameAsBilling: Boolean
    $lastName: String!
    $email: String!
    $password: String!
    $username: String!
    $phone: String!
    $address1: String!
    $city: String!
    $postcode: String!
    $address21: String!
    $city2: String!
    $postcode2: String!
    $country: CountriesEnum
    $country2: CountriesEnum
  ) {
    registerCustomer(
      input: {
        clientMutationId: "RegCust"
        agb: $agb
        dsgvo: $dsgvo
        showName: true
        showPrice: true
        shippingSameAsBilling: $shippingSameAsBilling
        username: $username
        billing: {
          address1: $address1
          city: $city
          country: $country
          email: $email
          firstName: $firstName
          lastName: $lastName
          phone: $phone
          postcode: $postcode
        }
        email: $email
        firstName: $firstName
        lastName: $lastName
        password: $password
        shipping: {
          address1: $address21
          city: $city2
          firstName: $firstName
          lastName: $lastName
          postcode: $postcode2
          country: $country2
        }
      }
    ) {
      customer {
        id
        iban
        customerId
        jwtAuthToken
        jwtRefreshToken
        email
        firstName
        lastName
        username
        showName
        showPrice
        hasCoupon
        billing {
          address1
          city
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
        shipping {
          address1
          city
          postcode
          state
          country
          firstName
          lastName
          email
          phone
        }
      }
    }
  }
`;
const GetagbversionQuery = gql`
  query MyQuery {
    __typename
    generalOptions {
      agb {
        agbversion
      }
      dsgvo {
        dsgvoversion
      }
    }
  }
`;
const cookies = new Cookies();
const RegisterPage = () => {
  const mailRef = useRef();
  // const passwordRef = useRef();
  // const password2Ref = useRef();
  const prenameRef = useRef();
  const surnameRef = useRef();
  const adressRef = useRef();

  const adress2Ref = useRef();

  const telRef = useRef();
  const isBillingSameShipping = useRef();

  const [emailValidation, setEmailValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [password2Validation, setPassword2Validation] = useState(false);
  const [prenameValidation, setPrenameValidation] = useState(false);
  const [surenameValidation, setSurenameValidation] = useState(false);
  const [plzValidation, setPlzValidation] = useState(false);
  const [adressValidation, setAdressValidation] = useState(false);
  const [townValidation, setTownValidation] = useState(false);
  const [countryValidation, setCountryValidation] = useState(false);
  const [plz, setPLZ] = useState("");
  const [town, setTown] = useState("");
  const [country, setCountry] = useState("");
  const [plz2, setPLZ2] = useState("");
  const [town2, setTown2] = useState("");
  const [country2, setCountry2] = useState("");
  const [plz2Validation, setPlz2Validation] = useState(false);
  const [adress2Validation, setAdres2sValidation] = useState(false);
  const [country2Validation, setCountry2Validation] = useState(false);
  const [town2Validation, setTown2Validation] = useState(false);
  const [telValdiation, setTelValidation] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const [showNotifyAlert, setShowNotifyAlert] = useState(false);
  const [oldOptionsShippingBilling, setOldOptionsShippingBilling] = useState([
    {
      id: 1,
      title: "Lieferadresse ist nicht gleich wie Rechnungsadresse",
    },
  ]);

  const [passwordValue, setPasswordValue] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      password1: "",
      password2: "",
    }
  );

  const [update, setUpdate] = useState();
  const [agb, setAGBVersion] = useState();
  const [dsgvo, setDSGVOVersion] = useState();
  const [agbValidation, setAGBValidation] = useState();
  const [dsgvoValidation, setDSGVOValidation] = useState();
  const [agbChecked, setAGBChecked] = useState();
  const [dsgvoChecked, setDSGVOChecked] = useState();
  const [url, setURL] = useContext(GetFallbackURL);
  const {
    loading: loading2,
    error: error3,
    data: content,
  } = useQuery(GetagbversionQuery, {
    onCompleted: (AGBVersion) => {
      setAGBVersion(AGBVersion.generalOptions.agb.agbversion);
      setDSGVOVersion(AGBVersion.generalOptions.dsgvo.dsgvoversion);
    },
  });
  const [registerCustomer, { loading: mutationLoading, error: mutationError }] =
    useMutation(RegisterUserMutation);
  const [
    register2Customer,
    { loading: mutation2Loading, error: mutation2Error },
  ] = useMutation(RegisterUser2Mutation);
  // if (mutationError) return <p>Error...</p>;
  const [user, setUser] = useContext(GetUserContext);
  const handleLogin = async () => {
    setEmailValidation(false);
    setPasswordValidation(false);
    setPassword2Validation(false);
    setPrenameValidation(false);
    setSurenameValidation(false);
    setPlzValidation(false);
    setAdressValidation(false);
    setTownValidation(false);
    setTelValidation(false);
    setPlz2Validation(false);
    setAdres2sValidation(false);
    setTown2Validation(false);
    setCountryValidation(false);
    setCountry2Validation(false);
    setAGBValidation(false);
    setDSGVOValidation(false);

    if (
      mailRef.current.value &&
      passwordValue.password1 &&
      prenameRef.current.value &&
      surnameRef.current.value &&
      plz &&
      adressRef.current.value &&
      town &&
      country &&
      passwordValue.password1 === passwordValue.password2 &&
      dsgvoChecked &&
      agbChecked &&
      ((showShipping &&
        adress2Ref.current.value &&
        plz2 &&
        town2 &&
        country2) ||
        !showShipping)
    ) {
      if (
        CheckEmailPassword(passwordValue.password1, mailRef.current.value) &&
        checkPhone(telRef.current.value) &&
        checkCountry(country, showShipping ? country2 : country)
      ) {
        if (checkZIPCode(plz, false)) {
          if ((showShipping && checkZIPCode(plz2, true)) || !showShipping) {
            if (showShipping) {
              register2Customer({
                variables: {
                  agb: agb,
                  dsgvo: dsgvo,
                  shippingSameAsBilling: false,
                  firstName: prenameRef.current.value,
                  lastName: surnameRef.current.value,
                  email: mailRef.current.value,
                  password: passwordValue.password1,
                  username: mailRef.current.value,
                  phone: telRef.current.value,
                  address1: adressRef.current.value,
                  city: town,
                  postcode: plz,
                  address21: adress2Ref.current.value,
                  city2: town2,
                  postcode2: plz2,
                  country: country.toUpperCase(),
                  country2: country.toUpperCase(),
                },
              })
                .then((response) => {
                  // cookies.set(
                  //   "AuthToken",
                  //   response.data.registerCustomer.customer.jwtAuthToken,
                  //   { path: "/" }
                  // );
                  // cookies.set(
                  //   "RefreshToken",
                  //   response.data.registerCustomer.customer.jwtRefreshToken,
                  //   { path: "/" }
                  // );
                  // setUser(response.data.registerCustomer.customer);
                  // alert("Bitte schließe deine Registrierung mit dem erhaltenen E-Mail ab.")
                  setShowNotifyAlert(true);
                })
                .catch((error) => {
                  if (
                    error
                      .toString()
                      .includes(
                        "Für diese E-Mail-Adresse existiert bereits ein Kundenkonto"
                      )
                  ) {
                    setEmailValidation(
                      "Für diese E-Mail-Adresse existiert bereits ein Kundenkonto"
                    );
                  } else {
                    alert(error);
                  }
                });
            } else {
              registerCustomer({
                variables: {
                  agb: agb,
                  dsgvo: dsgvo,
                  shippingSameAsBilling: true,
                  firstName: prenameRef.current.value,
                  lastName: surnameRef.current.value,
                  email: mailRef.current.value,
                  password: passwordValue.password1,
                  username: mailRef.current.value,
                  phone: telRef.current.value,
                  address1: adressRef.current.value,
                  city: town,
                  postcode: plz,
                  country: country.toUpperCase(),
                },
              })
                .then((response) => {
                  // cookies.set(
                  //   "AuthToken",
                  //   response.data.registerCustomer.customer.jwtAuthToken,
                  //   { path: "/" }
                  // );
                  // cookies.set(
                  //   "RefreshToken",
                  //   response.data.registerCustomer.customer.jwtRefreshToken,
                  //   { path: "/" }
                  // );
                  // setUser(response.data.registerCustomer.customer);
                  setShowNotifyAlert(true);
                })
                .catch((error) => {
                  if (
                    error
                      .toString()
                      .includes(
                        "Für diese E-Mail-Adresse existiert bereits ein Kundenkonto"
                      )
                  ) {
                    setEmailValidation(
                      "Für diese E-Mail-Adresse existiert bereits ein Kundenkonto"
                    );
                  } else {
                    alert(error);
                  }
                });
            }
          } else {
            setPlz2Validation("PLZ gibt es nicht");
          }
        } else {
          setPlzValidation("PLZ gibt es nicht");
        }
      }
    } else {
      if (passwordValue.password2 == "") {
        setPassword2Validation(true);
      }
      if (passwordValue.password1 == "") {
        setPassword2Validation(true);
      }
      if (passwordValue.password1 !== passwordValue.password2) {
        setPassword2Validation("Die Passwörter stimmen nicht überein.");
      }
      if (!prenameRef.current.value) {
        setPrenameValidation("Gib einen Vornamen an.");
      }
      if (!mailRef.current.value) {
        setEmailValidation("Gib eine E-Mail Adresse an.");
      }
      if (!surnameRef.current.value) {
        setSurenameValidation("Gib einen Nachnamen an.");
      }
      if (!adressRef.current.value) {
        setAdressValidation("Gib eine Adresse an.");
      }
      if (!plz) {
        setPlzValidation("Gib eine PLZ an.");
      }
      if (!town) {
        setTownValidation("Gib einen Ort an.");
      }
      if (!country) {
        setCountryValidation("Gib einen Länderkürzel an.");
      }
      if (showShipping && !town2) {
        setTown2Validation("Gib einen Ort an.");
      }
      if (showShipping && !plz2) {
        setPlz2Validation("Gib eine PLZ an.");
      }
      if (showShipping && !adress2Ref.current.value) {
        setAdres2sValidation("Gib eine Adresse an.");
      }
      if (showShipping && !country2) {
        setCountry2Validation("Gib einen Länderkürzel an.");
      }
      if (!agbChecked) {
        setAGBValidation(true);
      }
      if (!dsgvoChecked) {
        setDSGVOValidation(true);
      }
    }
  };

  function CheckEmailPassword(password, email) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat) && checkPassword(password)) {
      return true;
    } else if (!email.match(mailformat)) {
      setEmailValidation("Ungültige E-Mail-Adresse.");
      return false;
    } else if (!checkPassword(password)) {
      setPasswordValidation(
        "Das Passwort muss zwischen 8 und 50 Zeichen lang sein. Das Passwort muss mindestens 2 der 4 Faktoren enthalten: ein kleiner Buchstabe, ein großer Buchstabe, eine Zahl oder ein Sonderzeichen"
      );
      return false;
    } else {
      return false;
    }
  }

  function checkCountry(countryBilling, countryShipping) {
    if (
      countryBilling.toUpperCase() === "AT" ||
      countryBilling.toUpperCase() === "DE"
    ) {
      if (
        countryShipping.toUpperCase() === "AT" ||
        countryShipping.toUpperCase() === "DE"
      ) {
        return true;
      } else {
        setCountry2Validation("Nur AT oder DE möglich");
        return false;
      }
    } else {
      setCountryValidation("Nur AT oder DE möglich");
      return false;
    }
  }

  const H = window.H;
  var platform = new H.service.Platform({
    apikey: "hzdcbr05flQK6n8sOrnmm_3kROdUdeMN9QiYD2nl-Qc",
  });

  async function checkZIPCode(plz3, showShipping) {
    let valid = false;
    var service = platform.getSearchService();

    await service.geocode(
      {
        // Search query

        in: "countryCode:AUT,DEU",
        qq: `postalCode=${plz3}`,
        // Center of the search context
        at: "47.4345111,9.8987025,17",
      },
      (result) => {
        if (result.items.length > 0) {
          if (result.items[0].address.countryCode == "AUT") {
            if (showShipping) {
              setPlz2Validation(false);
              setCountry2("AT");
            } else {
              setPlzValidation(false);
              setCountry("AT");
            }
          } else if (result.items[0].address.countryCode == "DEU") {
            if (showShipping) {
              setPlz2Validation(false);
              setCountry2("DE");
            } else {
              setPlzValidation(false);
              setCountry("DE");
            }
          }
          if (result.items[0].address.city) {
            if (showShipping) {
              setTown2(result.items[0].address.city);
            } else {
              setTown(result.items[0].address.city);
            }
          }

          valid = true;
        } else {
          if (showShipping) {
            setPlz2Validation(
              "PLZ wurde nicht in Deutschland oder Österreich gefunden."
            );
          } else {
            setPlzValidation(
              "PLZ wurde nicht in Deutschland oder Österreich gefunden."
            );
          }
        }
      }
    );

    return valid;
  }

  function checkPhone(phone) {
    var phoneFormat = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

    if (phone && !phone.match(phoneFormat)) {
      setTelValidation("Ungütliges Format");
      return false;
    } else {
      return true;
    }
  }

  function checkPassword(password) {
    var counter = 0;

    if (/[a-z]/.test(password)) {
      ++counter;
    }
    if (/[A-Z]/.test(password)) {
      ++counter;
    }
    if (/[0-9]/.test(password)) {
      ++counter;
    }
    if (/[^A-Za-z0-9]/.test(password)) {
      ++counter;
    }

    if (counter >= 2 && password.length >= 8 && password.length <= 50) {
      return true;
    } else {
      return false;
    }
  }
  const handlePassword = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setPasswordValue({ [name]: newValue });
  };
  const handleShippingBilling = (value) => {
    if (value.length !== 0) {
      setShowShipping(false);
      setOldOptionsShippingBilling();
    } else {
      setPLZ2(plz);
      setTown2(town);
      setCountry2(country);
      setShowShipping(true);
    }
  };
  const handleDebounceZip = debounce((input, enable) => {
    checkZIPCode(input, enable);
  }, 50);

  return (
    <Wrapper>
      <Box>
        <HeadingOneWrapper>
          <HeadingOne title="Registrieren" />
        </HeadingOneWrapper>
        <Content>
          <ContentBox>
            <LoginContainer>
              <RefInput
                forwardedRef={prenameRef}
                type={"text"}
                error={prenameValidation}
                placeholder={"Vorname"}
                maxLength="40"
              ></RefInput>
              <RefInput
                forwardedRef={surnameRef}
                type={"text"}
                placeholder={"Nachname"}
                error={surenameValidation}
                maxLength="40"
              ></RefInput>
              <RefInput
                forwardedRef={mailRef}
                type={"text"}
                error={emailValidation}
                placeholder={"E-Mail Adresse"}
                maxLength="100"
              ></RefInput>
              <RefInput
                name={"password1"}
                forwardedRef={passwordValue.password1}
                onChange={(e) => handlePassword(e)}
                error={passwordValidation}
                type={"password"}
                placeholder={"Passwort"}
              ></RefInput>

              <PasswordStrengthBar
                password={passwordValue.password1}
                scoreWordStyle={{
                  fontFamily: "Montserrat",
                  fontSize: 13,
                  color:
                    passwordValue.password1.length < 8
                      ? colors.ErrorRed
                      : colors.Gray,
                }}
                minLength={8}
                shortScoreWord="zu kurz"
                scoreWords={["schwach", "schwach", "okay", "gut", "stark"]}
              />
              <RefInput
                name={"password2"}
                forwardedRef={passwordValue.password2}
                onChange={(e) => handlePassword(e)}
                type={"password"}
                error={password2Validation}
                placeholder={"Passwort wiederholen"}
              ></RefInput>
              <RefInput
                forwardedRef={telRef}
                type={"tel"}
                placeholder={"Telefon"}
                error={telValdiation}
                maxLength="20"
              ></RefInput>
              <MultipleSelect
                fontSize={10}
                options={[
                  {
                    id: 1,
                    title: "Lieferadresse ist gleich wie Rechnungsadresse",
                  },
                ]}
                optionTitleName={"title"}
                forwardedRef={isBillingSameShipping}
                compareValue={"id"}
                onChange={(value) => handleShippingBilling(value)}
                oldOptions={oldOptionsShippingBilling}
              />
              <React.Fragment>
                <Paragraph>
                  {showShipping
                    ? "Rechnungsadresse"
                    : "Liefer- & Rechnungsadresse"}
                </Paragraph>
                <RefInput
                  forwardedRef={adressRef}
                  type={"text"}
                  placeholder={"Straße"}
                  error={adressValidation}
                  maxLength="110"
                ></RefInput>

                <StateInput
                  name={"PLZ"}
                  forwardedRef={plz}
                  error={plzValidation}
                  onChange={(e) => {
                    setPLZ(e.target.value);

                    handleDebounceZip(e.target.value, false);
                  }}
                  type={"text"}
                  placeholder={"PLZ"}
                  maxLength="10"
                ></StateInput>
                <StateInput
                  name={"Ort"}
                  forwardedRef={town}
                  error={townValidation}
                  onChange={(e) => {
                    setTown(e.target.value);
                  }}
                  type={"text"}
                  placeholder={"Ort"}
                  maxLength="100"
                ></StateInput>
                <StateInput
                  name={"Land"}
                  forwardedRef={country}
                  error={countryValidation}
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                  type={"text"}
                  placeholder={"Land"}
                  maxLength="2"
                ></StateInput>
              </React.Fragment>

              {showShipping && (
                <React.Fragment>
                  <Paragraph>Lieferaddresse</Paragraph>
                  <RefInput
                    forwardedRef={adress2Ref}
                    type={"text"}
                    placeholder={"Adresse"}
                    error={adress2Validation}
                    originValue={adressRef.current.value}
                    maxLength="110"
                  ></RefInput>
                  <StateInput
                    name={"PLZ"}
                    forwardedRef={plz2}
                    originValue={plz}
                    error={plz2Validation}
                    onChange={(e) => {
                      setPLZ2(e.target.value);

                      handleDebounceZip(e.target.value, true);
                    }}
                    type={"text"}
                    placeholder={"PLZ"}
                    maxLength="10"
                  ></StateInput>
                  <StateInput
                    error={town2Validation}
                    name={"Ort"}
                    forwardedRef={town2}
                    originValue={town}
                    onChange={(e) => {
                      setTown2(e.target.value);
                    }}
                    type={"text"}
                    placeholder={"Ort"}
                    maxLength="110"
                  ></StateInput>
                  <StateInput
                    name={"Land"}
                    error={country2Validation}
                    forwardedRef={country2}
                    originValue={country}
                    onChange={(e) => {
                      setCountry2(e.target.value);
                    }}
                    type={"text"}
                    placeholder={"Land"}
                    maxLength="2"
                  ></StateInput>
                </React.Fragment>
              )}
              <div style={{ fontSize: "10px !important" }}>
                <SingleSelect
                  fontSize="10"
                  checked={agbChecked}
                  error={agbValidation}
                  onChange={(value) => {
                    setAGBChecked(value);
                  }}
                  caption={
                    <p>
                      Ich habe die{" "}
                      <Link
                        target="_blank"
                        href="../agb"
                        style={{ textDecoration: "underline" }}
                      >
                        AGBs
                      </Link>{" "}
                      gelesen und akzeptiere diese.
                    </p>
                  }
                />

                <SingleSelect
                  fontSize="10"
                  checked={dsgvoChecked}
                  error={dsgvoValidation}
                  onChange={(value) => {
                    setDSGVOChecked(value);
                  }}
                  caption={
                    <p>
                      Ich habe die{" "}
                      <Link
                        target="_blank"
                        href="../datenschutz"
                        style={{ textDecoration: "underline" }}
                      >
                        Datenschutzerklärung
                      </Link>{" "}
                      gelesen und akzeptiere diese.
                    </p>
                  }
                />
              </div>
              <Padding>
                <StyledButton
                  scale={"ORDER"}
                  title={"Registrieren"}
                  color={colors.YellowBrigthButton}
                  fontSize={"20px"}
                  onClick={handleLogin}
                  loading={mutationLoading || mutation2Loading}
                />
              </Padding>
            </LoginContainer>
          </ContentBox>
        </Content>
        {showNotifyAlert && (
          <NotifyAlert
            email={true}
            setShowAlert={() => {
              setShowNotifyAlert(false);
              window.location.href = "./home";
            }}
          ></NotifyAlert>
        )}
      </Box>
    </Wrapper>
  );
};

const Link = styled.a`
  color: #b2982a;
  text-decoration: none;
  z-index: 300;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
  width: calc(100% - 40px);
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-bottom: 40px;
  }
`;

const Box = styled.div`
  width: 100%;
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: space-between; */

  @media (max-width: 1500px) {
    flex-direction: column;
  }

  @media (min-width: 1920px) {
    width: 1745px;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-right: 165px;
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  @media (max-width: 1550px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    flex-direction: column;
    padding-top: 10px;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 241px;
  background-color: #ffffff;
  padding: 15px 30px;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    flex-direction: column;
    margin-top: 5px;
  }
`;
const LoginContainer = styled.div`
  padding: 20px 0;
  width: 241px;
`;

const Padding = styled.div`
  padding: 7px 0;
`;

const HeadingOneWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin: 0;
    margin-left: 20px;
  }
`;

const Paragraph = styled.p`
  font-family: "Montserrat";
  font-weight: 300;
  color: #4d4d4d;
  font-size: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 0;
`;

export default RegisterPage;
