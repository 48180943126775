
//Naming Convention:
//http://chir.ag/projects/name-that-color/
//          ColornameLookslikeUse
// f.ex.    HamptonYellowButton

const colors = {
    SolidWhiteBackground: '#FFFFFF',
    SolidBlackText: '#000000',
    SolidGreyText: '#4D4D4D',
    YellowBrigthButton: '#D6B936',
    StarkWhiteAnimatedText: '#24E394',
    GreyImpressum: '#FF0000',
    CheninYellowHoverButton: '#DCCB7A',
    PestoYellowHomepageSquare: '#84763B',
    DriftwoodYellowHomepageSquare: '#A6944A',
    LuckyYellowHomepageSquare: '#B89A1A',
    OldgoldYellowHomepageSquare: '#D6B936',
    RobroyYellowHomepageSquare: '#EDD176',
    HamptonYellowHomepageSquare: '#E6D9AC',
    OldgoldYellowLogo: '#CEB340',
    CongressBlueHeader: '#023682',
    EastbayBlueLogo: '#3E4E8A',
    MercuryGreyInputfields: '#E1E1E1',
    MineshaftGreyText: '#333333',
    IndiankhakiYellowButtonhover: '#BFB586',
    HillaryYellowButtonhover: '#ABA27D',
    LaserYellowButtonhover: '#CAB86C',
    Cararra: '#E7E6E2',
    QuoteLightBrown: '#E5DDC0',
    Gray: '#808080',
    Red: "#FF0000",
    ErrorRed: "#BC272D"
  }
  
  export default colors;