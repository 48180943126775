import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import Loader from "react-loader-spinner";
//pages
import AddProduct from "../../components/AddProduct";
import Alert from "../../components/Alert";
import OrderContainer from "../../components/OrderContainer";
import OrderOverview from "../../components/OrderOverview";
import StyledButton from "../../components/StyledButton";
import ThanksScreen from "../../components/ThanksScreen";
import UserData from "../../components/UserData";
import {
  colors,
  Cookies,
  GetUserContext,
  React,
  styled,
  useContext,
  useState,
} from "../../imports";
import { GetCurrentPage } from "../../localStorage/Store";
const cookies = new Cookies();
const SingleBuyerPage = () => {
  const getAllProducts = gql`
    query getProducts {
      __typename
      products(
        where: { status: "publish", orderby: { field: DATE, order: ASC } }
        first: 20
      ) {
        nodes {
          name
          ... on VariableProduct {
            variations {
              nodes {
                weight
                sku
                price
                variationId
              }
            }
          }
          productId
        }
      }
    }
  `;
  const SwitchToSingleBuyerMutation = gql`
    mutation MyMutation {
      __typename
      switchToIndividualOrder(input: { clientMutationId: "switch" }) {
        cart {
          lists {
            name
            total
            orderers {
              products {
                amount
                rowId
                total
                product {
                  parent {
                    name
                    coolbox {
                      coolboxActivated
                      coolboxWeight
                    }
                  }
                  name
                  price
                  weight
                  variationId
                  id
                }
              }
              name
              total
            }
          }
          total
          coolbox
          shipping
          subtotalWithTax
          minimumOrderWeight
          totalWeight
        }
      }
    }
  `;

  const ExtCheckout = gql`
    mutation MyMutation($coupon: String, $coolbox: Boolean) {
      __typename
      extCheckout(
        input: {
          clientMutationId: "ExtCheckout"
          coupon: $coupon
          coolboxdisabled: $coolbox
        }
      ) {
        order {
          discountTotal
          total
          paymentMethod
          shippingTotal
          subtotal
          totalTax
          orderKey
          orderId
          cartTax
          customerIpAddress
          orderNumber
          customer {
            bank
            firstName
            email
            lastName
            id
            customerId
          }
          billing {
            address1
            city
            country
            email
            firstName
            lastName
            phone
            postcode
            state
          }
          shipping {
            address1
            city
            company
            country
            email
            firstName
            lastName
            postcode
            state
          }
          lists {
            total
            lists {
              name
              total
              orderers {
                name
                products {
                  amount
                  total
                  rowId
                  product {
                    name
                    price
                    weight
                    metaData {
                      key
                      value
                    }
                    parent {
                      name
                    }
                  }
                }
                total
              }
            }
          }
          lineItems {
            nodes {
              product {
                parent {
                  name
                }
                name
              }
              variation {
                name
                price
                weight
                variationId
                sku
              }
              metaData {
                key
                value
              }
              quantity
              total
              subtotal
            }
          }
        }
      }
    }
  `;
  const UpdateCustomerMutation = gql`
    mutation updateCustomer($id: ID, $agb: String, $dsgvo: String) {
      __typename
      updateCustomer(
        input: {
          clientMutationId: "UpdateCustomer"
          id: $id
          agb: $agb
          dsgvo: $dsgvo
        }
      ) {
        customer {
          agb
          dsgvo
          billing {
            firstName
            address1
            city
            country
            email
            lastName
            phone
            postcode
            state
          }
          shipping {
            address1
            city
            postcode
            state
            country
            firstName
            lastName
            email
            phone
          }
          hasCoupon
          lastName
          firstName
          email
          customerId
          id
          username
          iban
          bank
          showName
          showPrice
        }
      }
    }
  `;
  const setNameMutation = gql`
    mutation updateCustomer($id: ID) {
      __typename
      updateCustomer(
        input: { clientMutationId: "UpdateCustomer", showName: false, id: $id }
      ) {
        customer {
          billing {
            firstName
            address1
            city
            country
            email
            lastName
            phone
            postcode
            state
          }
          shipping {
            address1
            city
            postcode
            state
            country
            firstName
            lastName
            email
            phone
          }
          hasCoupon
          orderCount
          lastName
          firstName
          email
          customerId
          id
          username
          iban
          bank
          showName
          showPrice
        }
      }
    }
  `;
  const GetagbversionQuery = gql`
    query MyQuery {
      __typename
      generalOptions {
        agb {
          agbversion
        }
        dsgvo {
          dsgvoversion
        }
      }
    }
  `;
  const CheckAGBMutation = gql`
    query MyQuery {
      checkAgb {
        agb
        dsgvo
      }
    }
  `;
  const GetExtCartItems = gql`
    query getExtCartItems($subordererKey: String!) {
      extCart(subordererKey: $subordererKey) {
        lists {
          name
          total
          orderers {
            products {
              amount
              rowId
              total
              product {
                parent {
                  name
                  coolbox {
                    coolboxActivated
                    coolboxWeight
                  }
                }
                name
                price
                weight
                variationId
                id
              }
            }
            name
            total
          }
        }
        total
        coolbox
        shipping
        subtotalWithTax
        minimumOrderWeight
        totalWeight
      }
    }
  `;

  const { loading, error, data } = useQuery(getAllProducts);
  const [agbVersion, setAGBVersion] = useState("");
  const [dsgvoVersion, setDSGVOVersion] = useState("");

  const [saveOrder2, { loading: mutationLoading, error: mutationError }] =
    useMutation(ExtCheckout);
  const [SwitchToIndividualOrder, { loading: loading3, error: error12 }] =
    useMutation(SwitchToSingleBuyerMutation, {
      onCompleted: () => {
        refetch();
      },
      // update: (cache, mutationResult) => {
      //   cache.writeQuery({
      //     query: GetExtCartItems,
      //     data: { extCart: mutationResult.data?.switchToIndividualOrder?.cart },
      //   });
      // },
    });
  const [
    checkAGBMutation,
    { loading: loading1, error: error1, data: checkAGBResponse },
  ] = useLazyQuery(CheckAGBMutation);
  const [
    SetNameMutation,
    { loading: mutationLoading2, error: mutationError2 },
  ] = useMutation(setNameMutation);
  const {
    loading: loading2,
    error: error3,
    data: content,
  } = useQuery(GetagbversionQuery, {
    onCompleted: (AGBVersion) => {
      setAGBVersion(AGBVersion.generalOptions.agb.agbversion);
      setDSGVOVersion(AGBVersion.generalOptions.dsgvo.dsgvoversion);
    },
  });
  const [updateCustomer, { loading: mutationLoading1, error: mutationError1 }] =
    useMutation(UpdateCustomerMutation);
  const {
    loading: loadingExtCart,
    error2,
    data: extCart,
    refetch,
  } = useQuery(GetExtCartItems, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: { subordererKey: "" },
    // onCompleted: (CartItems)=>{

    // }
  });
  const [user, setUser] = useContext(GetUserContext);
  const [adressList, setAdressList] = useState(true);
  // const [order, setOrder] = useState();
  const { currentPage, setCurrentPage, order, setOrder } =
    useContext(GetCurrentPage);
  const [showAlert, setShowAlert] = useState(false);
  const [showSwitchAlert, setShowSwitchAlert] = useState(false);
  const navigation = [
    { actualName: ["Benutzerdaten", "Bestellen"] },
    { codeName: ["UserData", "AddProduct"] },
  ];

  function SaveOrder(coupon, coolbox) {
    SetNameMutation({ variables: { id: user.id } }).then((response2) => {
      saveOrder2({ variables: { coupon, coolbox: !coolbox } })
        .then((response) => {
          // window.location.href = url;
          // cookies.set("AuthToken", response.data.login.authToken)
          // cookies.set("RefreshToken", response.data.login.refreshToken)
          // setUser(response.data.login.customer)
          setOrder(response.data.extCheckout.order);
          // setCurrentPage(false)
          return true;
        })
        .catch((error) => {
          alert("22" + error.toString());
        });
    });
  }

  useEffect(() => {
    if (checkAGBResponse) {
      if (!checkAGBResponse.checkAgb.agb || !checkAGBResponse.checkAgb.dsgvo) {
        setShowAlert(true);
      }
    }
  }, [checkAGBResponse]);

  useEffect(() => {
    if (extCart) {
      if (
        extCart.extCart.lists.length != 0 &&
        extCart.extCart.lists.some((list) => list.name != "initiator")
      ) {
        setShowSwitchAlert(true);
      }

      if (!currentPage) {
        if (user.orderCount > 0) {
          setCurrentPage("AddProduct");
        } else {
          if (extCart.extCart.lists.length > 0) {
            setCurrentPage("AddProduct");
          } else {
            setCurrentPage("UserData");
          }
        }
      }
    }
  }, [extCart]);

  useEffect(() => {
    if (currentPage) {
    }
  }, [currentPage]);

  useEffect(() => {
    checkAGBMutation();
  }, []);

  const handleCheckAGB = () => {
    updateCustomer({
      variables: { id: user.id, agb: agbVersion, dsgvo: dsgvoVersion },
    }).then((response) => {
      setShowAlert(false);
    });
  };
  const handleView = (pageName) => {
    setCurrentPage(pageName);
  };

  if (error || mutationError) return <p>Error</p>;
  if (mutationLoading) return <p>Loading2</p>;
  return (
    <React.Fragment>
      <OrderContainer
        activeView={(pageName) => handleView(pageName)}
        currentPage={currentPage}
        navigation={navigation}
        clickable={true}
        minheight4k={800}
      >
        {currentPage === "UserData" ? (
          <UserData
            parentPage={"SingleBuyerPage"}
            activeView={(pageName) => handleView(pageName)}
            location="SingleBuyerPage"
          />
        ) : currentPage === "AddProduct" && data ? (
          <AddProduct
            data={data.products.nodes}
            parentPage={"SingleBuyerPage"}
            subordererKey={""}
            saveOrder={() => SaveOrder()}
            activeView={(pageName) => handleView(pageName)}
          />
        ) : currentPage === "OrderOverview" ? (
          <OrderOverview
            parentPage={"SingleBuyerPage"}
            subordererKey={""}
            saveOrder={SaveOrder}
            activeView={(pageName) => handleView(pageName)}
          />
        ) : currentPage === "OrderComplete" && order ? (
          // <OrderComplete order={order} />
          <>
            <ThanksScreen order={order} location={"SingleBuyerPage"} />
          </>
        ) : (
          <Loader
            height={200}
            width={100}
            type="ThreeDots"
            color={colors.YellowBrigthButton}
          />
        )}

        {showAlert && user && (
          <Alert isVisible={true}>
            <p>
              Ich habe die{" "}
              <Link
                target="_blank"
                href="../agb"
                style={{ textDecoration: "underline" }}
              >
                AGBs
              </Link>{" "}
              gelesen und akzeptiere diese.
            </p>
            <p>
              Ich habe die{" "}
              <Link
                target="_blank"
                href="../datenschutz"
                style={{ textDecoration: "underline" }}
              >
                Datenschutzerklärung
              </Link>{" "}
              gelesen und akzeptiere diese.
            </p>
            <StyledButton
              // loading={mutationLoading}
              scale={"ORDER"}
              title={"Bestätigen"}
              color={colors.YellowBrigthButton}
              fontSize={"20px"}
              onClick={() => {
                handleCheckAGB();
              }}
            />
            <StyledButton
              // loading={mutationLoading}
              scale={"ORDER"}
              title={"Abbrechen"}
              color={colors.YellowBrigthButton}
              fontSize={"20px"}
              onClick={() => {
                // handleCheckAGB();
                setUser(null);
                cookies.remove("AuthToken", { path: "/" });
                cookies.remove("RefreshToken", { path: "/" });
                window.location.href = "./home";
                setShowAlert(false);
              }}
            />
          </Alert>
        )}
      </OrderContainer>
      {showSwitchAlert && (
        <Alert isVisible={true}>
          <p>
            Es besteht eine laufende Gruppenbestellung. Soll diese abgebrochen
            werden?
          </p>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <StyledButton
              // loading={mutationLoading}
              scale={"ORDER"}
              title={"Bestätigen"}
              color={colors.YellowBrigthButton}
              fontSize={"20px"}
              onClick={() => {
                SwitchToIndividualOrder().then((response) => {
                  setShowSwitchAlert(false);
                });
              }}
            />
            <StyledButton
              // loading={mutationLoading}
              scale={"ORDER"}
              title={"Abbrechen"}
              color={colors.YellowBrigthButton}
              fontSize={"20px"}
              onClick={() => {
                // handleCheckAGB();
                window.location.href = "./gemeinsam";
                setShowSwitchAlert(false);
              }}
            />
          </div>
        </Alert>
      )}
    </React.Fragment>
  );
};

const Link = styled.a`
  color: #b2982a;
  text-decoration: none;
  z-index: 300;
`;
export default SingleBuyerPage;
