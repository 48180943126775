// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   08.08.2020
// Last edited:     09.08.2020

import { gql, useMutation } from "@apollo/client";
import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import {
  GetFallbackURL,
  GetInviteLists,
  GetUserContext,
} from "../localStorage/Store";
import styl from "../styl";
import colors from "./../constants/colors";
import Alert from "./Alert";
import RefInput from "./RefInput";
import StyledButton from "./StyledButton";
const cookies = new Cookies();
const LoginMutation = gql`
  mutation LOGIN_USER($username: String!, $password: String!) {
    login(
      input: {
        username: $username
        password: $password
        clientMutationId: "LoginUser"
      }
    ) {
      authToken
      refreshToken
      user {
        inviteLists {
          invitelists {
            id
            name
            suborderers {
              fieldGroupName
              userid
            }
          }
        }

        invitedLists {
          invitedlists {
            listid
            fieldGroupName
          }
        }
        id
        name
        email
        firstName
        lastName
        username
      }
      customer {
        firstName
        lastName
        hasCoupon
        email
        bank
        iban
        orderCount
        billing {
          address1
          city
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
        shipping {
          address1
          city
          postcode
          state
          country
          firstName
          lastName
          email
          phone
        }
        customerId
        id
        showName
        showPrice
      }
    }
  }
`;
const ResendEmailMutation = gql`
  mutation MyMutation($email: String!, $password: String!) {
    __typename
    sendVerificationMail(
      input: { clientMutationId: "", email: $email, password: $password }
    ) {
      success
    }
  }
`;
const Login = ({ multiple, registerSuborderer }) => {
  const [user, setUser] = useContext(GetUserContext);
  const [getInviteLists, setInviteLists] = useContext(GetInviteLists);
  const [showEmailAlert, setShowEmailAlert] = useState(false);
  const [confirmation, setShowConfirmation] = useState(false);
  const [url, setURL] = useContext(GetFallbackURL);
  const mailRef = useRef();
  // const passwordRef = useRef();
  const [passwordValue, setPasswordValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [loginUser, { loading: mutationLoading, error: mutationError }] =
    useMutation(LoginMutation);
  const [resendEmail, { loading: mutationLoading2, error: mutationError2 }] =
    useMutation(ResendEmailMutation);

  const handleLogin = () => {
    setErrorMessage("");
    if (mailRef.current.value && passwordValue) {
      loginUser({
        variables: { username: mailRef.current.value, password: passwordValue },
      })
        .then((response) => {
          if (multiple === "stay") {
            window.location.href =
              "/dateneingabeSubbesteller" + window.location.search;
          } else {
            window.location.href = url;
          }
          cookies.set("AuthToken", response.data.login.authToken, {
            path: "/",
          });
          cookies.set("RefreshToken", response.data.login.refreshToken, {
            path: "/",
          });
          setUser(response.data.login.customer);
          setInviteLists(response.data.login.user.inviteLists.invitelists);
          localStorage.setItem(
            "InviteLists",
            JSON.stringify(response.data.login.user.invitedLists.invitedlists)
          );
        })
        .catch((error) => {
          if (error.toString() === "Error: alg_wc_ev_email_verified_error") {
            setShowEmailAlert(true);

            setErrorMessage(
              "Du hast deine E-Mail Adresse noch nicht verifiziert."
            );
          } else {
            setErrorMessage(
              "Der Login war leider nicht erfolgreich.\nBitte überprüfe Deine Eingaben."
            );
          }
        });
    }
  };

  const handlePassword = (e) => {
    setPasswordValue(e.target.value);
  };

  return (
    <LoginContainer>
      <RefInput
        forwardedRef={mailRef}
        type={"email"}
        placeholder={"E-Mail Adresse"}
        autocomplete={"on"}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleLogin();
          }
        }}
        maxLength="100"
      ></RefInput>
      <RefInput
        forwardedRef={passwordValue}
        onChange={(e) => handlePassword(e)}
        type={"password"}
        error={errorMessage}
        placeholder={"Passwort"}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleLogin();
          }
        }}
      ></RefInput>

      <Link>
        <LinkA href="./resetpw?id=1">Passwort vergessen?</LinkA>
      </Link>
      <Padding />
      <Padding>
        <StyledButton
          loading={mutationLoading}
          scale={"ORDER"}
          title={"Anmelden"}
          color={colors.YellowBrigthButton}
          fontSize={"20px"}
          onClick={handleLogin}
        />
      </Padding>
      <Padding>
        <StyledButton
          scale={"ORDER"}
          title={"Registrieren"}
          color="#B2982A"
          fontSize={"20px"}
          onClick={() => {
            if (registerSuborderer) {
              registerSuborderer();
            } else {
              window.location.href = "/register";
            }
          }}
        />
      </Padding>
      {showEmailAlert && (
        <Alert isVisible={true}>
          {confirmation ? (
            <p>Email wurde erneut gesendet</p>
          ) : (
            <p>
              Du hast deine E-Mail Adresse noch nicht verifiziert. Email erneut
              senden?
            </p>
          )}
          {!confirmation && (
            <Padding>
              <StyledButton
                scale={"ORDER"}
                title={"senden"}
                color="#B2982A"
                fontSize={"20px"}
                loading={mutationLoading2}
                onClick={() =>
                  resendEmail({
                    variables: {
                      email: mailRef.current.value,
                      password: passwordValue,
                    },
                  })
                    .then((response1) => {
                      // setShowEmailAlert(false)
                      setShowConfirmation(true);
                    })
                    .catch((error) => {
                      alert(error.toString());
                    })
                }
              />
            </Padding>
          )}
          <Padding>
            <StyledButton
              scale={"ORDER"}
              title={"Abbrechen"}
              color="#B2982A"
              fontSize={"20px"}
              onClick={() => setShowEmailAlert(false)}
            />
          </Padding>
        </Alert>
      )}
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  padding: 15px;
  width: 241px;
  background-color: white;
`;

const Padding = styled.div`
  padding: 3px 0;
`;

const Link = styled.p`
  text-align: right;
  font-size: 13px;
  padding: 0;
  margin: 0;
`;

const LinkA = styled.a`
  color: #4d4d4d;
  text-decoration: none;
  ${styl.lightDefaultFont};
`;
export default Login;
