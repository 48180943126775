// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   31.07.2020
// Last edited:     02.08.2020

import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Cookies from "universal-cookie";
import DefaultWindow from "./components/DefaultWindow";
import OrderOverviewWithId from "./components/OrderOverviewWithId";
import colors from "./constants/colors";
import { getToken } from "./constants/util";
import { GetFallbackURL, GetUserContext } from "./localStorage/Store";
import AttitudePage from "./pages/attitudePage/AttitudePage";
import Community from "./pages/community/Community";
import ContactPage from "./pages/contactPage/ContactPage";
import DataInputBestellinitiator from "./pages/dataInputBestellinitiator/DataInputBestellinitiator";
import DataInputSubbesteller from "./pages/dataInputSubbesteller/DataInputSubbesteller";
import Error404 from "./pages/error/Error404";
import Homepage from "./pages/homepage/Homepage";
import ImportOrder from "./pages/importOrder/ImportSubOrder";
import InvitePage from "./pages/invite/InvitePage";
import Landingpage from "./pages/landingpage/Landingpage";
import LandingpageSubbesteller from "./pages/landingpageSubbesteller/LandingpageSubbesteller";
import AGB from "./pages/legal/AGB";
import Datenschutz from "./pages/legal/Datenschutz";
import Impressum from "./pages/legal/impressum";
import OverviewPage from "./pages/login/OverviewPage";
import RegisterPage from "./pages/login/RegisterPage";
import MultipleBuyerPage from "./pages/multipleBuyerPage/MultipleBuyerPage";
import OrderHistory from "./pages/orderHistory/OrderHistory";
import PhilosophiePage from "./pages/philosophiePage/PhilosophiePage";
import ProductDetailPage from "./pages/productDetailPage/ProductDetailPage";
import ProductOverViewPage from "./pages/productOverviewPage/ProductOverviewPage";
import Profile from "./pages/profilePage/ProfilePage";
import ResetPasswordPage from "./pages/resetPassword/ResetPasswordPage";
import SingleBuyerPage from "./pages/singleBuyerPage/SingleBuyerPage";
import StartOrderPage from "./pages/startOrderPage/StartOrderPage";
import Hilda from "./pages/whoWeAre/Hilda";
import Melchior from "./pages/whoWeAre/Melchior";
import Partner from "./pages/whoWeAre/Partner";
import WhoWeAre from "./pages/whoWeAre/WhoWeAre";
import DiscoverPage from "./pages/discover/DiscoverPage";

const Router = () => {

  const [user, setUser] = useContext(GetUserContext);
  const cookies = new Cookies();
  const [url, setURL] = useContext(GetFallbackURL);
  const [enable, setEnable] = useState(false);

  const PrivateRoute = ({ component: Component, authed, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          )
      }
    />
  );

  useEffect(() => {
    (async () => {

      if (
        !window.location.href.includes("login") &&
        !window.location.href.includes("resetpw") &&  !window.location.href.includes("home")
      ) {
        await setURL(window.location.href);
      }
      else{
        await setURL("./bestellen");
      }
      setEnable(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const token = await getToken();
      if (!token) {
        setUser(null);
      }
    })();
  }, [setUser]);

  return (
    enable && (
      <BrowserRouter>
        <Switch>
          <DefaultWindow
            showHeader={false}
            footerFontColor={colors.SolidWhiteBackground}
            background={colors.Cararra}
            exact
            path="/"
          >
            <Landingpage />
          </DefaultWindow>
          <DefaultWindow
            showHeader={false}
            footerFontColor={colors.SolidWhiteBackground}
            background={colors.Cararra}
            exact
            path="/home"
          >
            <Homepage />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidBlackText}
            background={colors.Cararra}
            exact
            path="/werwirsind"
          >
            <WhoWeAre />
          </DefaultWindow>
          <DefaultWindow showHeader={true} exact path="/bestellen">
            <StartOrderPage />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            background={colors.Cararra}
            exact
            path="/philosophie"
          >
            <PhilosophiePage />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidBlackText}
            background={colors.Cararra}
            path="/entdecken"
          >
            <DiscoverPage />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            background={colors.SolidWhiteBackground}
            footerFontColor={colors.SolidBlackText}
            background={colors.Cararra}
            exact
            path="/haltung"
          >
            <AttitudePage />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            background={colors.Cararra}
            footerFontColor={colors.SolidBlackText}
            exact
            path="/produkte"
          >
            <ProductOverViewPage />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            // background={colors.MercuryGreyInputfields}
            background={colors.Cararra}
            exact
            path="/produktDetail"
          >
            <ProductDetailPage />
          </DefaultWindow>
          <DefaultWindow
            showHeader={false}
            footerFontColor={colors.SolidWhiteBackground}
            background={colors.Cararra}
            exact
            path="/kontakt"
          >
            <ContactPage />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidBlackText}
            background={colors.Cararra}
            exact
            path="/melchior"
          >
            <Melchior />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidBlackText}
            background={colors.Cararra}
            exact
            path="/hilda"
          >
            <Hilda />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidBlackText}
            background={colors.Cararra}
            exact
            path="/partner"
          >
            <Partner />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            background={colors.Cararra}
            exact
            path="/Community"
          >
            <Community />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            background={colors.Cararra}
            exact
            path="/impressum"
          >
            <Impressum />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            background={colors.Cararra}
            exact
            path="/datenschutz"
          >
            <Datenschutz />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            background={colors.Cararra}
            exact
            path="/agb"
          >
            <AGB />
          </DefaultWindow>
          {/* <DefaultWindow showHeader={true} footerFontColor={colors.SolidWhiteBackground} background={colors.Cararra} exact path="/einladen">
          <AddSubbesteller />
        </DefaultWindow> */}
          <DefaultWindow
            showHeader={true}
            // background={colors.Cararra}
            exact
            path="/login"
          >
            <Route
              exact
              path="/login"
              render={(props) =>
                // user ? (
                //   <Redirect
                //     to={{
                //       pathname: url.includes("einzeln") ||url.includes("gemeinsam")  ? url : "./home",
                //     }}
                //   />
                // ) : (
                    <OverviewPage />
                  // )
              }
            />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            // background={colors.Cararra}
            exact
            path="/register"
          >
            <Route
              exact
              path="/register"
              render={(props) =>
                user ? (
                  <Redirect
                    to={{
                      pathname: "./home",
                    }}
                  />
                ) : (
                    <RegisterPage />
                  )
              }
            />
          </DefaultWindow>

          <DefaultWindow
            showHeader={true}
            background={colors.Cararra}
            exact
            path="/Community"
          >
            <Community />
          </DefaultWindow>

          <DefaultWindow
            showHeader={false}
            footerFontColor={colors.SolidWhiteBackground}
            background={colors.Cararra}
            exact
            path="/landingpageSubbesteller"
          >
            <LandingpageSubbesteller />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidWhiteBackground}
            // background={colors.Cararra}
            exact
            path="/resetpw"
          >
            <ResetPasswordPage />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidWhiteBackground}
            showFooter={false}
            exact
            path="/dateneingabeSubbesteller"
          >
            <Route
              exact
              path="/dateneingabeSubbesteller"
              render={(props) => <DataInputSubbesteller />
              }
            />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidWhiteBackground}
            showFooter={false}
            background={colors.Cararra}
            exact
            path="/dateneingabeBestellinitiator"
          >
            <DataInputBestellinitiator />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidWhiteBackground}
            background={colors.Cararra}
            exact
            path="/profile"
            showFooter={false}
          >
            <PrivateRoute
              exact
              path="/profile"
              component={Profile}
              authed={user}
            />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidGreyText}
            background={colors.Cararra}
            exact
            path="/einladen"
            showFooter={false}
          >
            <PrivateRoute
              exact
              path="/einladen"
              component={InvitePage}
              authed={user}
            />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidGreyText}
            showFooter={false}
            exact
            path="/einzeln"
          >
            <PrivateRoute
              exact
              path="/einzeln"
              component={SingleBuyerPage}
              authed={user}
            />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidGreyText}
            showFooter={false}
            exact
            path="/gemeinsam"
          >
            <PrivateRoute
              exact
              path="/gemeinsam"
              component={MultipleBuyerPage}
              authed={user}
            />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidGreyText}
            exact
            path="/bestellhistorie"
            showFooter={false}
          >
            <PrivateRoute
              exact
              path="/bestellhistorie"
              component={OrderHistory}
              authed={user}
            />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidGreyText}
            background={colors.Cararra}
            exact
            path="/bestellhistorie/detail"
            showFooter={false}
          >
            <PrivateRoute
              exact
              path="/bestellhistorie/detail"
              component={OrderOverviewWithId}
              authed={user}
            />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidWhiteBackground}
            background={colors.Cararra}
            exact
            path="/import"
          >
            <PrivateRoute
              exact
              path="/import"
              component={ImportOrder}
              authed={user}
            />
          </DefaultWindow>
          <DefaultWindow
            showHeader={true}
            footerFontColor={colors.SolidBlackText}
            background={colors.Cararra}
            exact
            path="*"
          >
            <Error404 />
          </DefaultWindow>
          
        </Switch>


      </BrowserRouter>
    )
  );
};

export default Router;
