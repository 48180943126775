import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useReducer, useRef, useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import styled from "styled-components";
import Cookies from "universal-cookie";
import colors from "../constants/colors";
import constants from "../constants/constants";
import { GetUserContext } from "../localStorage/Store";
import styl from "../styl";
import NotifyAlert from "./inviteList/NotifyAlert";
import Login from "./Login";
import RefInput from "./RefInput";
import SingleSelect from "./SingleSelect";
import StyledButton from "./StyledButton";

const RegisterUser2Mutation = gql`
  mutation REGISTER_Customer(
    $firstName: String!
    $lastName: String!
    $email: String!
    $agb: String
    $dsgvo: String
    $password: String!
    $username: String!
    $phone: String!
  ) {
    registerCustomer(
      input: {
        clientMutationId: "RegCust"
        showName: true
        showPrice: true
        agb: $agb
        dsgvo: $dsgvo
        username: $username
        email: $email
        firstName: $firstName
        lastName: $lastName
        password: $password
        billing: { phone: $phone }
      }
    ) {
      customer {
        id
        iban
        customerId
        jwtAuthToken
        jwtRefreshToken
        email
        firstName
        lastName
        username
        showName
        showPrice
        billing {
          phone
        }
      }
    }
  }
`;

const GetagbversionQuery = gql`
  query MyQuery {
    __typename
    generalOptions {
      agb {
        agbversion
      }
      dsgvo {
        dsgvoversion
      }
    }
  }
`;

const cookies = new Cookies();
const detailBreak = 664;

const RegisterSuborderer = ({ activeView }) => {
  const mailRef = useRef();
  const prenameRef = useRef();
  const surnameRef = useRef();

  const telRef = useRef();

  const [registerActive, setRegisterActive] = useState(true);
  const toggling = () => setRegisterActive(!registerActive);

  const [emailValidation, setEmailValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [password2Validation, setPassword2Validation] = useState(false);
  const [prenameValidation, setPrenameValidation] = useState(false);
  const [surenameValidation, setSurenameValidation] = useState(false);
  const [dsgvoChecked, setDSGVOChecked] = useState();
  const [dsgvoValidation, setDSGVOValidation] = useState();
  const [agbChecked, setAGBChecked] = useState();
  const [agbValidation, setAGBValidation] = useState();
  const [telValdiation, setTelValidation] = useState(false);
  const [showNotifyAlert, setShowNotifyAlert] = useState(false);

  const { data: terms } = useQuery(GetagbversionQuery);

  const [passwordValue, setPasswordValue] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      password1: "",
      password2: "",
    }
  );

  const [register2Customer, { loading: mutation2Loading }] = useMutation(
    RegisterUser2Mutation
  );
  const [user, setUser] = useContext(GetUserContext);

  const handleLogin = async () => {
    setEmailValidation(false);
    setPasswordValidation(false);
    setPassword2Validation(false);
    setPrenameValidation(false);
    setSurenameValidation(false);
    setTelValidation(false);
    setDSGVOValidation(false);
    setAGBValidation(false);

    if (
      mailRef.current.value &&
      passwordValue.password1 &&
      prenameRef.current.value &&
      surnameRef.current.value &&
      passwordValue.password1 === passwordValue.password2 &&
      dsgvoChecked &&
      agbChecked
    ) {
      if (
        CheckEmailPassword(passwordValue.password1, mailRef.current.value) &&
        checkPhone(telRef.current.value)
      ) {
        register2Customer({
          variables: {
            firstName: prenameRef.current.value,
            lastName: surnameRef.current.value,
            email: mailRef.current.value,
            password: passwordValue.password1,
            username: mailRef.current.value,
            phone: telRef.current.value,
            agb: terms?.generalOptions?.agb?.agbversion,
            dsgvo: terms?.generalOptions?.dsgvo?.dsgvoversion,
          },
        })
          .then((response) => {
            cookies.set(
              "AuthToken",
              response.data.registerCustomer.customer.jwtAuthToken,
              { path: "/" }
            );
            cookies.set(
              "RefreshToken",
              response.data.registerCustomer.customer.jwtRefreshToken,
              { path: "/" }
            );
            setUser(response.data.registerCustomer.customer);
            setShowNotifyAlert(true);
          })
          .catch((error) => {
            if (
              error
                .toString()
                .includes(
                  "Für diese E-Mail-Adresse existiert bereits ein Kundenkonto"
                )
            ) {
              setEmailValidation(
                "Für diese E-Mail-Adresse existiert bereits ein Kundenkonto"
              );
            } else {
              alert(error);
            }
          });
      }
    } else {
      if (passwordValue.password2 == "") {
        setPassword2Validation(true);
      }
      if (passwordValue.password1 == "") {
        setPassword2Validation(true);
      }
      if (passwordValue.password1 !== passwordValue.password2) {
        setPassword2Validation("Die Passwörter stimmen nicht überein.");
      }
      if (!prenameRef.current.value) {
        setPrenameValidation("Gib einen Vornamen an.");
      }
      if (!mailRef.current.value) {
        setEmailValidation("Gib eine E-Mail Adresse an.");
      }
      if (!surnameRef.current.value) {
        setSurenameValidation("Gib einen Nachnamen an.");
      }
      if (!dsgvoChecked) {
        setDSGVOValidation(true);
      }
      if (!agbChecked) {
        setAGBValidation(true);
      }
    }
  };

  function CheckEmailPassword(password, email) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat) && checkPassword(password)) {
      return true;
    } else if (!email.match(mailformat)) {
      setEmailValidation("Ungültige E-Mail-Adresse.");
      return false;
    } else if (!checkPassword(password)) {
      setPasswordValidation(
        "Das Passwort muss zwischen 8 und 50 Zeichen lang sein. Das Passwort muss mindestens 2 der 4 Faktoren enthalten: ein kleiner Buchstabe, ein großer Buchstabe, eine Zahl oder ein Sonderzeichen"
      );
      return false;
    } else {
      return false;
    }
  }

  function checkPhone(phone) {
    var phoneFormat = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

    if (phone && !phone.match(phoneFormat)) {
      setTelValidation("Ungütliges Format");
      return false;
    } else {
      return true;
    }
  }

  function checkPassword(password) {
    var counter = 0;

    if (/[a-z]/.test(password)) {
      ++counter;
    }
    if (/[A-Z]/.test(password)) {
      ++counter;
    }
    if (/[0-9]/.test(password)) {
      ++counter;
    }
    if (/[^A-Za-z0-9]/.test(password)) {
      ++counter;
    }

    if (counter >= 2 && password.length >= 8 && password.length <= 50) {
      return true;
    } else {
      return false;
    }
  }
  const handlePassword = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setPasswordValue({ [name]: newValue });
  };

  return (
    <Wrapper>
      <ListHeader>Meine Daten</ListHeader>
      {registerActive ? (
        <LoginContainer>
          <RefInput
            forwardedRef={prenameRef}
            type={"text"}
            error={prenameValidation}
            placeholder={"Vorname"}
            maxLength={40}
          ></RefInput>
          <RefInput
            forwardedRef={surnameRef}
            type={"text"}
            placeholder={"Nachname"}
            error={surenameValidation}
            maxLength={40}
          ></RefInput>
          <RefInput
            forwardedRef={mailRef}
            type={"text"}
            error={emailValidation}
            placeholder={"E-Mail Adresse"}
            maxLength={100}
          ></RefInput>
          <RefInput
            name={"password1"}
            forwardedRef={passwordValue.password1}
            onChange={(e) => handlePassword(e)}
            error={passwordValidation}
            type={"password"}
            placeholder={"Passwort"}
          ></RefInput>

          <PasswordStrengthBar
            password={passwordValue.password1}
            scoreWordStyle={{
              fontFamily: "Montserrat",
              fontSize: 13,
              color:
                passwordValue.password1.length < 8
                  ? colors.ErrorRed
                  : colors.Gray,
            }}
            minLength={8}
            shortScoreWord="zu kurz"
            scoreWords={["schwach", "schwach", "okay", "gut", "stark"]}
          />
          <RefInput
            name={"password2"}
            forwardedRef={passwordValue.password2}
            onChange={(e) => handlePassword(e)}
            type={"password"}
            error={password2Validation}
            placeholder={"Passwort wiederholen"}
          ></RefInput>
          <RefInput
            forwardedRef={telRef}
            type={"tel"}
            placeholder={"Telefon"}
            error={telValdiation}
            maxLength={20}
          ></RefInput>

          <SingleSelect
            fontSize="10"
            checked={dsgvoChecked}
            error={dsgvoValidation}
            onChange={(value) => {
              setDSGVOChecked(value);
            }}
            caption={
              <p>
                Ich habe die{" "}
                <Link
                  target="_blank"
                  href="../datenschutz"
                  style={{ textDecoration: "underline" }}
                >
                  Datenschutzerklärung
                </Link>{" "}
                gelesen und akzeptiere diese.
              </p>
            }
          />
          <SingleSelect
            fontSize="10"
            checked={agbChecked}
            error={agbValidation}
            onChange={(value) => {
              setAGBChecked(value);
            }}
            caption={
              <p>
                Ich habe die{" "}
                <Link
                  target="_blank"
                  href="../agb"
                  style={{ textDecoration: "underline" }}
                >
                  AGBs
                </Link>{" "}
                gelesen und akzeptiere diese.
              </p>
            }
          />
          <Padding>
            <StyledButton
              scale={"ORDER"}
              title={"Registrieren"}
              color={colors.YellowBrigthButton}
              fontSize={"20px"}
              onClick={() => handleLogin()}
              loading={mutation2Loading}
            />
          </Padding>
          {registerActive && (
            <StyledButton
              scale={"ORDER"}
              title={"Account vorhanden"}
              color={colors.YellowBrigthButton}
              fontSize={"20px"}
              onClick={toggling}
            />
          )}
        </LoginContainer>
      ) : (
        <Login
          multiple={"stay"}
          registerSuborderer={() => setRegisterActive(true)}
        ></Login>
      )}
      {showNotifyAlert && (
        <NotifyAlert
          email={true}
          setShowAlert={() => {
            setShowNotifyAlert(false);
            activeView("AddProduct");
          }}
        ></NotifyAlert>
      )}
    </Wrapper>
  );
};

const Link = styled.a`
  color: #b2982a;
  text-decoration: none;
  z-index: 300;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 40px);
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-bottom: 40px;
  }
`;
const ListHeader = styled.div`
  ${styl.defaultFontFamily}
  width: 90%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  /* margin-bottom: 15px; */
  font-size: 15px;
  font-weight: 300;
  @media (max-width: ${detailBreak}px) {
    width: 100%;
  }
`;
const LoginContainer = styled.div`
  width: 241px;
`;

const Padding = styled.div`
  padding: 7px 0;
`;

export default RegisterSuborderer;
