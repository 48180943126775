import { gql, useMutation } from "../../imports";

const SetBuyStatusMutation = gql`
  mutation setBuyStatus($paid: Boolean!, $rowId: Int!, $orderId: Int!) {
    __typename
    setBuyStatus(
      input: {
        clientMutationId: "SetBuyStatus"
        rowId: $rowId
        orderId: $orderId
        paid: $paid
      }
    ) {
      paid {
        lists {
          name
          total
          orderers {
            name
            paid
            paidRowId
            products {
              amount
              total
              product {
                name
                price
                weight
                variationId
              }
            }
            total
          }
        }
      }
    }
  }
`;

export function useCheckAsPaid() {
  const [setBuyStatus] = useMutation(SetBuyStatusMutation);

  const checkAsPaid = (paidRowId, orderId, value) => {
    //When clicked remove
    return setBuyStatus({
      variables: {
        clientMutationId: "SetBuyStatus",
        orderId: orderId,
        paid: value,
        rowId: paidRowId,
      },
    })
      .then((response) => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  };

  return { checkAsPaid };
}

export default useCheckAsPaid;
