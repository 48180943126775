import queryString from "query-string";
import { FaTrashAlt } from "react-icons/fa";
import useAmountInput from "../../components/hooks/useAmountInput";
import constants from "../../constants/constants";
import {
  colors,
  React,
  styl,
  styled,
  useEffect,
  useState,
} from "../../imports";
import Alert from "../Alert";
import useRemoveFromCart from "../hooks/useRemoveFromCart";
import useWindowSize from "../hooks/useWindowSize";
import StyledButton from "../StyledButton";
import "./Accordion.css";
import CoolBoxInfo from "../CoolBoxInfo";

function AccordionSingleBuyer({
  data,
  product,
  i2,
  parentPage,
  setTotalCart,
  orderId,
  showCoolBoxIcon,
}) {
  const { removeSelected } = useRemoveFromCart();
  const [inputState, setInputState] = useState([]);
  const [loading, setLoading] = useState(false);
  const { debouncedInputHandle, loadingUpdateAmount } = useAmountInput();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const size = useWindowSize();

  let params = queryString.parse(window.location.search);
  const key = decodeURIComponent(params.id) || "";
  useEffect(() => {
    if (data.length == 1) {
      handleInputState(data[0].orderers[0].products);
    }
  }, []);

  const handleInputState = (products) => {
    let tmp = [];
    products.map((item) => {
      tmp.push(item.amount);
    });
    setInputState(tmp);
  };
  const removeItemFromList = async (product) => {
    setLoading(true);
    let tmp = inputState;
    setTotalCart(product.total);
    tmp.splice(i2, 1);
    setInputState(tmp);
    if (window.location.href.includes("dateneingabeSubbesteller")) {
      await removeSelected(product.rowId, key);
    } else {
      await removeSelected(product.rowId, "");
    }
    setLoading(false);
  };
  return !loading && !loadingUpdateAmount ? (
    <ProductContainer key={i2} className={i2} style={{ opacity: 1 }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <SubCheckboxLabel>
          {/* <select name="cars" id="cars">
          <option value="volvo">Volvo</option>
          <option value="saab">Saab</option>
          <option value="opel">Opel</option>
          <option value="audi">Audi</option>
        </select> */}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                maxHeight: "36px",
                minWidth: "50px",
                alignItems: "center",
              }}
            >
              {product.rowId && parentPage !== "OrderOverview" ? (
                <AmountInput
                  onChange={(e) => {
                    if (e.target.value != "") {
                      let tmp = inputState.slice();
                      if (e.target.value == "0") {
                        tmp[i2] = "1";
                      } else {
                        tmp[i2] = e.target.value;
                      }
                      setInputState(tmp);
                      if (
                        window.location.href.includes(
                          "dateneingabeSubbesteller"
                        )
                      ) {
                        debouncedInputHandle(product, tmp[i2], key);
                      } else {
                        debouncedInputHandle(product, tmp[i2], "");
                      }
                    }
                  }}
                  value={inputState[i2]}
                  type={"number"}
                  readOnly={parentPage == "OrderOverview" ? true : false}
                ></AmountInput>
              ) : (
                <Paragraph>{product.amount}x</Paragraph>
              )}
              {/* <SubCheckboxInput id={product.product.name} type="checkbox" onChange={() => { }} checked={false} /> */}
              {/* <SubCheckboxButton className="fa fa-check" /> */}
              <ProductName>{product.product.name}</ProductName>
              {showCoolBoxIcon &&
                product.product.parent?.coolbox?.coolboxActivated && (
                  <CoolBoxInfo />
                )}
            </div>
            <ProductName>
              €{" "}
              {product.total.toLocaleString("DE-DE", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </ProductName>
          </div>
          {size.width < constants.nexusPortraitWidth &&
            !orderId &&
            parentPage != "OrderOverview" && (
              <IconText
                onClick={async () => {
                  setShowDeleteAlert(true);
                }}
              >
                Produkt entfernen
              </IconText>
            )}
        </SubCheckboxLabel>
        {!orderId && parentPage != "OrderOverview" && (
          <DeleteWrapper>
            {size.width > constants.nexusPortraitWidth && (
              <Item>
                <FaTrashAlt
                  onClick={async () => {
                    setShowDeleteAlert(true);
                  }}
                  color="#E1E1E1"
                  height="16px"
                />
              </Item>
            )}
          </DeleteWrapper>
        )}
        {showDeleteAlert && (
          <Alert isVisible={true}>
            <Paragraph>Willst du dieses Produkt wirklich entfernen?</Paragraph>
            <ButtonBox>
              <div style={{ width: "180px" }}>
                <StyledButton
                  // loading={mutationLoading}
                  scale={"DAULT"}
                  title={"abbrechen"}
                  color={colors.YellowBrigthButton}
                  fontSize={"20px"}
                  onClick={() => {
                    // handleCheckAGB();
                    setShowDeleteAlert(false);
                  }}
                />
              </div>
              <div style={{ width: "10px", height: "5px" }} />
              <div style={{ width: "180px" }}>
                <StyledButton
                  // loading={mutationLoading}
                  scale={"DAULT"}
                  title={"entfernen"}
                  color={colors.YellowBrigthButton}
                  fontSize={"20px"}
                  onClick={() => {
                    // SwitchToIndividualOrder().then((response)=>{
                    //   setShowSwitchAlert(false)
                    // });
                    removeItemFromList(product);
                    setShowDeleteAlert(false);
                  }}
                />
              </div>
            </ButtonBox>
          </Alert>
        )}
      </div>
    </ProductContainer>
  ) : (
    <p>Loading...</p>
  );
}

export default AccordionSingleBuyer;

export const AmountInput = styled.input`
  width: 50px;
  border: none;
  color: #808080;
  font-family: "Montserrat";
  font-weight: 300;
  text-align: center;
`;

const ProductContainer = styled.li`
  list-style: none;
  padding: 0px;
  margin: 0px;
  transition: 0.3s ease;
  opacity: 0;
`;

const SubCheckboxLabel = styled.label`
  margin: 0px;
  padding: 0px;
  height: 36px;
  background-color: white;
  display: flex;
  flex-flow: column;
  justify-content: center;
  font-size: 14px;
  margin-left: 10px;
  width: 100%;
  border-bottom: 0.8px solid ${colors.MercuryGreyInputfields};
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    height: unset;
  }
`;

const ProductName = styled.h6`
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 300;
  color: #808080;
  padding: 0px;
  padding-left: 11px;
  margin: 0px;
`;
const IconText = styled.div`
  font-size: 12px;
  text-decoration: underline;
  color: ${colors.YellowBrigthButton};
  cursor: pointer;
  text-align: right;
  margin: 12px 0 5px 0;
  &:hover {
    opacity: 0.6;
  }
`;
const Paragraph = styled.p`
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.35;
  color: #808080;
  hyphens: auto;
  margin: 0;
`;

const Item = styled.div`
  width: 50px;

  justify-content: center;
  ${styl.flexRow};
  align-items: center;
  /* min-width: ${(props) => (props.min ? props.min : "240px")}; */
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  flex-flow: ${(props) => (props.alignment == "RIGHT" ? "row-reverse" : "row")};

  @media (max-width: 375px) {
    display: none;
  }
`;

const DeleteWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 350px;
  height: 100%;
  @media (max-width: 770px) {
    flex-direction: column;
  }
`;
