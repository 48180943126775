import { gql, useQuery } from "@apollo/client";
import queryString from "query-string";
import React from "react";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import Error404 from "../../pages/error/Error404";
import CircleWithText from "./../../components/CircleWithText";
import HeadingOne from "./../../components/HeadingOne";
import TextBox from "./../../components/PhilosophieText";
import Quote from "./../../components/Quote";
import StyledButton from "./../../components/StyledButton";
import colors from "./../../constants/colors";
import constants from "./../../constants/constants";

export default function ProductDetailPage() {
  const GetProductByID = gql`
    query getProduct($id: ID!) {
      __typename
      product(id: $id, idType: DATABASE_ID) {
        e3_hint {
          tipp
        }
        e3_origin {
          herkunft
        }
        e3_property {
          eigenschaft
        }
        ... on VariableProduct {
          name
          image {
            sourceUrl
          }
        }
      }
    }
  `;

  let params = queryString.parse(window.location.search);
  const id = params.id;

  const { loading, error, data } = useQuery(GetProductByID, {
    variables: { id },
  });

  if (loading)
    return (
      <Wrapper>
        <CircleWithTextContainer
          onClick={() => (window.location.href = "/bestellen")}
        >
          <CircleWithText size="50" font="35">Bestellen</CircleWithText>
        </CircleWithTextContainer>

        <Loader
          height={400}
          width={200}
          type="ThreeDots"
          color={colors.YellowBrigthButton}
        />
      </Wrapper>
    );
  if (error) return <Error404 />;
  return (
    <Wrapper>
      <Box>
        <CircleWithTextContainer
          onClick={() => (window.location.href = "/bestellen")}
        >
          <CircleWithText font="35">Bestellen</CircleWithText>
        </CircleWithTextContainer>
        <HeadingOne title={data.product.name} />
        <Main>
          <LeftBox>
            <Content>
              {/* Image visible only on mobile */}
              <TextBox
                heading="Herkunft"
                description={data.product.e3_origin.herkunft}
              />
              {/* not visible on mobile */}
              <ButtonBox>
                <StyledButton
                  title="zurück zur Übersicht"
                  onClick={() => {
                    window.location.href = "./produkte";
                  }}
                  color={colors.YellowBrigthButton}
                />
              </ButtonBox>
            </Content>
            <Content>
              <TextBox
                heading="Eigenschaften"
                description={data.product.e3_property.eigenschaft}
              />
            </Content>
            <Content>
              <TextBox
                heading="Tipps"
                description={data.product.e3_hint.tipp}
              />
              {/* Button for mobile */}
              <ButtonBox2>
                <StyledButton
                  title="zurück"
                  onClick={() => {
                    window.location.href = "./produkte";
                  }}
                  color={colors.YellowBrigthButton}
                  style={{ width: "100%" }}
                />
              </ButtonBox2>
            </Content>
          </LeftBox>
          <RightBox>
            <Image img={data.product.image.sourceUrl} />
            <QuoteWrapper>
              <Quote
                mainText="An l&euml;&euml;ra Sack scht&ocirc;t n&ugrave;d geen"
                description={[
                  "hungrig lässt es sich",
                  <br />,
                  "nicht gut arbeiten",
                ]}
                size="SMALL"
              />
            </QuoteWrapper>
          </RightBox>
        </Main>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4vh;
  @media (min-width: 1700px){
    padding-left: 7vw;
  }
`;

const Box = styled.div`
  width: 91%;
  display: flex;
  flex-direction: column;
  @media (min-width: 1920px) {
    width: 1745px;
  }
`;

const CircleWithTextContainer = styled.div`
  position: absolute;
  /* top: -20px; */
  /* right: -30px; */
  top: 10px;
  right: 10px;
  margin: 10;
  /* transform: scale(0.5) rotate(10deg); */
  cursor: pointer;
  @media (max-width: 770px) {
    /* display: none; */
    display: none;
  }
  @media (max-width: 500px) {
    right: -45px;
    /* top: 70px; */
    margin: 0;
    padding: 0;
  }
  @media (max-width: 1699px){
    top: 100px;
  }
  @media (min-width: 1700px){
    top: 120px;
  }
  @media (min-width: 2200px){
    right: 12vw;
  }
  @media (min-width: 2800px){
    right: 20vw;
  }
  @media (min-width: 3400px){
    right: 25vw
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  @media (max-width: 1500px) {
    flex-direction: column-reverse;
  }
`;

const LeftBox = styled.div`
  width: 65%;
  display: flex;
  flex-direction: row;
  @media (max-width: 1500px) {
    width: 100%;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1700px) {
    max-width: 300px;
    margin-right: 60px;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    flex-direction: column;
  }
`;

const ButtonBox = styled.div`
  margin-top: 20px;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    display: none;
  }
`;

const ButtonBox2 = styled(ButtonBox)`
  display: none;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    /* display: block; */
    display: block;
    width: 80%;
    margin-bottom: 20px;
  }
`;

const RightBox = styled.div`
  margin-top: 40px;
  /* height: 60vh; */
  display: flex;
  flex-direction: column;

  @media (min-width: ${constants.nexusPortraitWidth}px) and (max-width: 1500px) {
    flex-direction: row;
    justify-content: space-evenly;
    height: unset;
  }
@media (min-width: 1700px){
  justify-content: center;
}
`;

const QuoteWrapper = styled.div`
  padding-top: 50px;
  @media (max-width: 1100px) {
    display: none;
  }
`;

const Image = styled.div`
  width: 33%;
  height: 300px;
  background-image: url(${(props) => props.img});
  background-size: contain;
  background-position: center;
  box-sizing: border-box;
  background-repeat: no-repeat;
  min-width: 600px;

  @media (max-width: 770px) {
    min-width: unset;
    width: 300px;
  }
`;
