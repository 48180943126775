import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import colors from "../constants/colors";
import styl from "../styl";
/**
 * @param {string} text text of the component (required)
 * @param {string} picture picture link of the component (required)
 * @param {string} format either "row" -to position the picture right or "row-reverse" - to position the picture left. Default: "row"
 * @example <PictureText text={"test"} picture={"https://media.macphun.com/img/uploads/customer/how-to/579/15531840725c93b5489d84e9.43781620.jpg?"} format={"row-reverse"}></PictureText>
 */
export default function PictureText({ text, picture, format, subtitle }) {
  return (
    <Main>
      <Content format={format}>
        <Text>{text}</Text>
        <PictureDiv>
          <Picture src={picture} format={format}></Picture>
          <Subtitle>{subtitle}</Subtitle>
        </PictureDiv>
      </Content>
    </Main>
  );
}
PictureText.propTypes = {
  text: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  format: PropTypes.string,
  subtitle: PropTypes.string,
};

PictureText.defaultProps = {
  format: "row",
};

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 50px; */
  @media (max-width: 1100px) {
    margin-top: 10px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: ${(props) => props.format};
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1100px) {
    flex-direction: column-reverse;
    margin-left: 0;
    margin-top: 0;
    /* width: unset; */
  }
`;
const Text = styled.p`
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 14px;
  color: ${colors.SolidGreyText};
  ${styl.lightDefaultFont};
  line-height: 20px;
  max-width: 580px;
  @media (min-width: 1100px){
    max-width: unset;
  }
  @media (min-width: 1300px){
    max-width: 580px;
  }
  @media (min-width: 1700px){
    max-width: unset;
  }
  @media (max-width: 1100px) {
    height: auto;
    /* max-height: 380px; */
    width: 80vw;
    margin-left: 10vw;
    margin-bottom: 20px;
  }
`;
const Picture = styled.img`
  width: 341px;
  margin-left: ${(props) => (props.format === "row-reverse" ? "0px" : "50px")};
  margin-right: ${(props) => (props.format === "row-reverse" ? "50px" : "0px")};
  min-width: 300px;
  min-height: 200px;
  @media (max-width: 1100px) {
    height: 200px;
    width: 300px;
    margin-left: 0;
    margin-left: 0;
  }
  @media (max-width: 1100px) {
    height: auto;
    /* max-height: 380px; */
    width: 100vw;
    margin-left: 0;
    margin-bottom: 20px;
  }
  /* @media (max-width: 850px){
    width: 300px;
    max-height: 200px;
  } */
`;

const PictureDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subtitle = styled.p`
  color: #4d4d4d;
  font-size: 14px;
  font-family: 'Montserrat';
  letter-spacing: 0.35px;
  text-align: right;
  width: 341px;
  margin-left: ${(props) => (props.format === "row-reverse" ? "0px" : "50px")};
  margin-right: ${(props) => (props.format === "row-reverse" ? "50px" : "0px")};
  min-width: 300px;
  /* min-height: 75px; */
  text-transform: uppercase;
  @media (max-width: 1100px) {
    width: 90vw;
    margin-right: 5vw;
    text-align: right;
    min-height: unset;
  }
  @media (max-width: 550px) {
    width: 80vw;
    margin-right: 10vw;
    margin-top: 0;
    margin-bottom: 30px;
  }
  @media (max-width: 370px) {
    width: 100%;
    margin-left: 0;
    /* margin-right: 15vw; */
  }
`;
