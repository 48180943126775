import React, { useState } from "react";
import styled from "styled-components";
import colors from "../constants/colors";
import styl from "../styl";
import { cssInputContainer, InputTitle } from "./InputPartials";
import TablerIcon from "./TablerIcon";

/**
 * @author Marcel Simma
 * @param {Array} options An Array of objects containing all options that should be checkable.
 * @param {string} optionTitleName The property of the object that should be used as the title next to the checkbox.
 * @param {reference} forwardedRef This reference is used to get an array of all checked options.
 * @param {int} compareValue This property of the object will be used to compare if an object has already been selected or not
 * @param {function} onChange A function which is executed every time a option changes. The function takes in one parameter: An Array containing all checked options.
 * @param {string} alignment Two options: LEFT or RIGHT. If alignment equals left, the checkbox is displayed left to the text.
 * @param {Array} oldOptions An Array of objects containing all options that should be selected initially.
 * @example <MultipleSelect
                options={[
                    { 
                        "id": 1, 
                        "title": "Ich bin ein Titel" ,
                        "disabled:" true
                    },
                    { 
                        "id": 2, 
                        "title": "Ich stehe neben der Checkbox" 
                    }
                ]}
                optionTitleName={"title"}
                forwardedRef={termsAndPrivacy}
                compareValue={"id"}
                alignment={"LEFT"||"RIGHT"}
            />
 */

const MultipleSelect = (props) => {
  const [newOptions, setNewOptions] = useState(
    props.oldOptions ? props.oldOptions : []
  );

  const updateNewOptions = async (index, item) => {
    let a;
    await setNewOptions((prev) => {
      let tmpIndex = findValue(newOptions, item);
      let tmp = prev.slice();
      if (tmpIndex >= 0) {
        tmp.splice(tmpIndex, 1);
      } else {
        tmp.push(item);
      }
      a = tmp;
      return tmp;
    });
    props.onChange(a);
  };

  const findValue = (array, item) => {
    var found = -1;
    if (array[0]) {
      for (var i = 0; i < array.length; i++) {
        if (array[i][props.compareValue] == item[props.compareValue]) {
          found = i;
          break;
        }
      }
    }
    return found;
  };
  return props.options.length > 0 ? (
    <>
      <input
        style={{ display: "none" }}
        ref={props.forwardedRef}
        value={
          Array.isArray(newOptions) ? JSON.stringify(newOptions) : newOptions
        }
        readOnly
      ></input>
      <InputContainer>
        <InputTitle>{props.title}</InputTitle>
        <MultipleSelectContainer>
          {props.options.map((item, index) => (
            <Item
              min={props.min}
              alignment={props.alignment}
              disabled={item.disabled}
              paddingZero={props.paddingZero}
              onClick={
                !item.disabled ? () => updateNewOptions(index, item) : null
              }
              key={index}
            >
              <HiddenInput
                type="checkbox"
                checked={findValue(newOptions, item) >= 0 ? true : false}
                readOnly
              ></HiddenInput>
              <Checkbox error={item.error}>
                <TablerIcon
                  strokeWidth={3}
                  size={20}
                  color={colors.SolidWhiteBackground}
                  iconName={"check"}
                ></TablerIcon>
              </Checkbox>
              <Caption fontSize={props.fontSize}>
                {props.optionTitleName ? item[props.optionTitleName] : item}
              </Caption>
            </Item>
          ))}
          {/* <InfoWrapper info={props.info}>
            {props.info && (
              <InfoComponent  text={(props) => props.info} />
            )}
          </InfoWrapper> */}
        </MultipleSelectContainer>
      </InputContainer>
    </>
  ) : null;
};

export default MultipleSelect;

const Caption = styled.p`
  font-size: ${(props) => (!props.fontSize ? "unset" : props.fontSize + "px")};
  margin: 5px 0;
  flex: 1;
  color: #808080;
  font-family: "Montserrat";
  font-weight: 300;
  letter-spacing: 0.4px;
  margin-left: 5px;
`;

const MultipleSelectContainer = styled.div`
  ${styl.flexRow};
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Item = styled.div`
  ${styl.flexRow};
  align-items: center;
  position: relative;
  min-width: ${(props) => (props.min ? props.min : "240px")};
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  flex-flow: ${(props) => (props.alignment == "RIGHT" ? "row-reverse" : "row")};
`;



const HiddenInput = styled.input`
  ${styl.clear};
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  cursor: pointer;

  &:checked + div {
    background: ${colors.OldgoldYellowHomepageSquare};

    .icon-tabler {
      opacity: 1;
    }
  }
`;

const Checkbox = styled.div`
  ${styl.flexRow};
  ${styl.flexCenter};
  height: 15px;
  width: 15px;

  background: ${colors.Gray}30;
  margin-right: auto 12px;
  ${styl.transition};

  ${(props) =>
    props.error ? styl.shadow(" 0px 0px 5px 0px rgba(255,0,0,1)") : null}

  .icon-tabler {
    opacity: 0;
    ${styl.transition};
  }
`;

const InputContainer = styled.div`
  ${cssInputContainer};
  padding: ${(props) => (props.paddingZero == "FALSE" ? "0" : "0")};
`;
