import queryString from 'query-string';
import { useReducer } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
// import { colors, constants, gql, React, styled, useEffect, useMutation, useRef, useState } from '../../imports';
import { colors, constants, gql, React, styled, useEffect, useMutation, useRef, useState } from "../../imports";
import HeadingOne from './../../components/HeadingOne';
import RefInput from './../../components/RefInput';
import StyledButton from './../../components/StyledButton';
// import OrderContainer from '../../components/OrderContainer';

const ResetPassword = () => {
	const SendEmail = gql`
    mutation MyMutation($email: String!) {
  __typename
  sendPasswordResetEmail(input: {clientMutationId: "SendMail", username: $email}) {
    clientMutationId
  }
}
     `;

    const ResetPassword = gql`
    mutation MyMutation($key: String, $login: String, $password: String) {
  __typename
  resetUserPassword(input: {clientMutationId: "ResetPassword", key: $key, login: $login, password: $password}) {
    clientMutationId
  }
}
     `;

    const [passwordValue, setPasswordValue] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            password1: '',
            password2: '',
        }
    );

    useEffect(() => {

        let params = queryString.parse(window.location.search)
        const key2 = params.id;
        const key = params.key;
        if (key2 === "1") {
            setView(true)

        }
        else if (key2 === "2") {

            setKey(key)
            setView(false)
        }



    }, [])
    const mailRef = useRef();
    const [emailValidation, setEmailValidation] = useState(false)
    const [passwordValidation, setPasswordValidation] = useState(false)
    const [password2Validation, setPassword2Validation] = useState(false)

    const [sendEmail, { loading: mutationLoading2, error: mutationError2 },] = useMutation(SendEmail);
    const [resetPassword, { loading: mutationLoading3, error: mutationError3 },] = useMutation(ResetPassword);
    const [view, setView] = useState(null)
    const [key, setKey] = useState("")

    const handleLogin = () => {


        setEmailValidation(false)
        if (CheckMail(mailRef.current.value)) {
            sendEmail({ variables: { email: mailRef.current.value } }).then((response) => {
                window.location.href = "./home"
            }).catch((error) => {
                setEmailValidation("Es wurde kein Konto zu dieser E-Mail Adresse gefunden")
            })
        }
    }
    const handleResetPassword = () => {
        setPasswordValidation(false)
        setEmailValidation(false)
        setPassword2Validation(false)
        if (CheckMail(mailRef.current.value) && checkPassword(passwordValue.password1) && key && passwordValue.password1 === passwordValue.password2) {

            resetPassword({ variables: { login: mailRef.current.value, password: passwordValue.password1, key } }).then((response) => {
                window.location.href = "./login"
            }).catch((e) => {
                if (e.message === "Password reset link is invalid.")
                    setPassword2Validation("Der Link zum Zurücksetzen des Passworts ist ungültig.")
                else {
                    setPassword2Validation("Ein unerwarteter Fehler ist aufgetreten.")
                }
            })
        }
        else {
            if (!key) {
                setPassword2Validation("Du hast keine Berechtigung um diese Aktion auszuführen.")
            }
            if (passwordValue.password1 !== passwordValue.password2) {
                setPassword2Validation("Die Passwörter stimmen nicht überein.")
            }
            if (!checkPassword(passwordValue.password1)) {
                setPasswordValidation("Das Passwort muss zwischen 8 und 50 Zeichen lang sein. Das Passwort muss mindestens 2 der 4 Faktoren enthalten: ein kleiner Buchstabe, ein großer Buchstabe, eine Zahl oder ein Sonderzeichen")
            }

        }

    }

    const handlePassword = evt => {

        const name = evt.target.name;
        const newValue = evt.target.value;
        setPasswordValue({ [name]: newValue });
    }
    function CheckMail(email) {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.match(mailformat)) {

            return true;
        }
        else if (!email.match(mailformat)) {
            setEmailValidation("Ungültige E-Mail-Adresse.")
            return false;
        }
        else {

            return false;
        }

    }

    function checkPassword(password) {
        var counter = 0;

        if (/[a-z]/.test(password)) {
            ++counter;
        }
        if (/[A-Z]/.test(password)) {
            ++counter;
        }
        if (/[0-9]/.test(password)) {
            ++counter;
        }
        if (/[^A-Za-z0-9]/.test(password)) {
            ++counter;
        }

        if (counter >= 2 && password.length >= 8 && password.length <= 50) {
            return true;
        }
        else {
            return false;
        }
    }
    return (
        <Wrapper>
            <Box>
                <LeftBox>
                    <HeadingOneWrapper>
                        <HeadingOne title="Passwort zurücksetzen" />
                    </HeadingOneWrapper>
                    <br />
                    {view ?
                        <Content>
                            <ContentBox>
                                <LoginContainer>
                                    <Text>Bitte E-Mail Adresse eingeben, mit der du dich registriert hast.</Text>
                                    <RefInput forwardedRef={mailRef} type={"text"} placeholder={"E-Mail Adresse"} error={emailValidation} maxLength="100"></RefInput>

                                    <StyledButton
                                        scale={"ORDER"}
                                        title={"E-Mail senden"}
                                        color={colors.YellowBrigthButton}
                                        fontSize={"20px"}
                                        onClick={handleLogin}
                                        loading={mutationLoading2 || mutationLoading3}
                                    />

                                </LoginContainer>
                            </ContentBox>

                        </Content> :
                        <Content>
                            <ContentBox>
                                <LoginContainer>
                                    <Text>Gib hier dein neues Passwort ein:</Text>
                                    <RefInput forwardedRef={mailRef} type={"text"} placeholder={"E-Mail Adresse"}maxLength="100" error={emailValidation}></RefInput>
                                    <RefInput name={"password1"} forwardedRef={passwordValue.password1} onChange={(e) => handlePassword(e)} error={passwordValidation} type={"password"} placeholder={"neues Passwort festlegen"}></RefInput>
                                    <PasswordStrengthBar password={passwordValue.password1} scoreWordStyle={{ fontFamily: "Montserrat", fontSize: 13, color: passwordValue.password1.length < 8 ? colors.ErrorRed : colors.Gray }} minLength={8} shortScoreWord="zu kurz" scoreWords={["schwach", "schwach", "okay", "gut", "stark"]} />
                                    <RefInput name={"password2"} forwardedRef={passwordValue.password2} onChange={(e) => handlePassword(e)} type={"password"} error={password2Validation} placeholder={"Passwort erneut eingeben"}></RefInput>

                                    <StyledButton
                                        scale={"ORDER"}
                                        title={"Passwort speichern"}
                                        color={colors.YellowBrigthButton}
                                        fontSize={"20px"}
                                        onClick={handleResetPassword}
                                        loading={mutationLoading2 || mutationLoading3}
                                    />

                                </LoginContainer>
                            </ContentBox>

                        </Content>}
                </LeftBox>
            </Box>
        </Wrapper>
    );
};
const Wrapper = styled.div`
	display: flex;
	justify-content: center;
    width: 100%;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
		margin-bottom: 40px;
	}
`;
const LoginContainer = styled.div`
    padding: 30px;
    width: 241px;
    background-color: white;
`;

const Box = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
    justify-content: space-between;

	@media (max-width: 1500px) {
		flex-direction: column;
	}

	@media (min-width: 1920px) {
		width: 1745px;
	}
`;

const LeftBox = styled.div`
	width: 100%;
    /* background-color: red; */
	@media (max-width: 1500px) {
		width: 100%;
	}
`;


const ContentBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
    justify-content: center;
    align-items: center;

	@media (max-width: ${constants.nexusPortraitWidth}px) {
		flex-direction: column;
		margin-top: 5px;
	}
`;

const Content = styled.div`
	/* width: 100%; */
	display: flex;
	flex-direction: row;
  margin-top: 40px;

	@media (max-width: 1550px) {
		width: 100%;
		margin-top: 20px;
	}

	@media (max-width: ${constants.nexusPortraitWidth}px) {
		flex-direction: column;
		padding-top: 10px;
    }
`;

const Text = styled.p`
	color: #808080;
	font-size: 16px;
	font-family: "Montserrat";
	font-weight: 300;
	letter-spacing: 0.4px;
	margin: 0;
	padding: 0;
	line-height: 22px;
  margin-bottom: 5px;
`;

const HeadingOneWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 10vw;
    margin-top: 20px;
    margin-left: 5vw;
	@media (max-width: ${constants.nexusPortraitWidth}px) {
		margin: 0;
		margin-left: 20px;
	}
`;


export default ResetPassword;
