// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   02.08.2020
// Last edited:     02.08.2020

import React from "react";
import styled from "styled-components";
import Brand from "../../assets/images/gutgereift_logo_original.svg";
// import Quote from '../../components/Quote';
import image1 from "../../assets/images/landing/gutgereift_landing1-min.jpg";
import image2 from "../../assets/images/landing/gutgereift_landing2-min.jpg";
import image3 from "../../assets/images/landing/gutgereift_landing3-min.jpg";
import StyledButton from "../../components/StyledButton";
import TablerIcon from "../../components/TablerIcon";
import colors from "../../constants/colors";
import constants from "../../constants/constants";
import styl from "../../styl";

var images = [image1, image2, image3];
var actualImage = Math.floor(Math.random() * Math.floor(3));

const Landingpage = () => {
  const hour = new Date().getHours();
  return (
    <SplitScreenContainer>
      <SplitScreenRight>
        <div>
          <Wrapper instaPost={`./instapost.jpg?${hour}`}></Wrapper>
          <TextWrapper>
            <a
              href="https://www.instagram.com/gutgereiftimbregenzerwald/"
              target="_blank"
              rel="noopener noreferrer"
              style={{}}
            >
              <TablerIcon size={70} iconName="brand-instagram"></TablerIcon>
            </a>
            <StyledText
              style={{
                margin: 0,
                // marginLeft: "10px",
                textAlign: "left",
                height: "fit-content",
                padding: "10px",
              }}
            >
              Was gibt’s Neues? <br></br>Schaut rein auf unseren
              <a
                href="https://www.instagram.com/gutgereiftimbregenzerwald/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  margin: 0,
                  color: colors.CongressBlueHeader,
                  width: "fit-content",
                }}
              >
                {" "}
                Instagram Kanal.
              </a>
            </StyledText>
          </TextWrapper>
        </div>
      </SplitScreenRight>
      <SplitScreenLeft>
        <Content>
          <StyledBrand src={Brand} />
          <Padding>
            <StyledText>
              Die etwas andere Community. <br />
              Bestellen Sie gemeinsam, teilen Sie unter Familie und Freunden auf
              und profitieren Sie von einem guten Preis!
            </StyledText>
          </Padding>
          <a>
            <StyledButton
              title={"Hier wird's gut."}
              onClick={() => {
                window.location.href = "/home";
              }}
              fontSize={"27px"}
              color={colors.YellowBrigthButton}
              scale={"BIG"}
            />
          </a>
        </Content>
      </SplitScreenLeft>
    </SplitScreenContainer>
  );
};

const SplitScreenContainer = styled.div`
  min-height: 100vh;
  height: auto;
  width: 100%;
  ${styl.flexRow};
  flex-flow: row-reverse;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    ${styl.flexColumn};
    flex-flow: column-reverse;
    justify-content: center;
    align-items: flex-end;
    overflow: unset;
  }
`;

const Padding = styled.div`
  padding: 40px 0;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    padding-top: 0;
  }
`;

const SplitScreenLeft = styled.div`
  width: 50%;
  min-height: 100vh;
  height: 100%;
  height: 540px;
  ${styl.flexColumn};
  justify-content: center;
  align-items: flex-end;
  z-index: 100;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    min-height: 50vh;
    height: auto;
    width: 100%;
    ${styl.flexRow};
    justify-content: center;
    align-items: flex-end;
  }
`;

const StyledBrand = styled.img`
  width: 100%;

  @media (max-width: 1000px) {
    width: 370px;
    margin-bottom: 50px;
  }
`;

const SplitScreenRight = styled.div`
  flex: 1;
  padding: 5%;
  height: 100vh;
  box-sizing: border-box;
  ${styl.flexColumn};
  align-items: flex-start;
  justify-content: center;
  width: 500px;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    height: 50vh;
    min-height: 200px;
    width: 100%;
    padding: 0px 0;
    ${styl.flexRow};
    ${styl.flexCenter};
  }
`;
const TextWrapper = styled.div`
  ${styl.flexRow};
  max-width: 500px;
  align-items: center;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    position: sticky;
    background-color: ${colors.Cararra};
    bottom: 0;
    padding: 0px 10px;
    z-index: 1000;
  }
`;
const Wrapper = styled.div`
  height: 500px;
  background-image: url(${(props) => props.instaPost});
  background-size: cover;
  background-position: center;
  padding: 3%;
  box-sizing: border-box;
  width: 500px;
  margin-left: 5px;
  @media (max-width: 1150px) {
    margin: 0;
    height: 50vh;
    min-height: 200px;
    width: 100%;
    padding: 20px 10px;
    ${styl.flexRow};
    ${styl.flexCenter};
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin: 0;
    height: 50vh;
    min-height: 200px;
    width: 100%;
    padding: 20px 10px;
    ${styl.flexRow};
    ${styl.flexCenter};
  }
`;
const StyledText = styled.p`
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: ${colors.SolidGreyText};
  font-size: 16px;
  line-height: 22px;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    text-align: center;
  }
`;

const Content = styled.div`
  padding: 8%;
  max-width: 312px;
  min-width: 233px;
  width: 90%;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    ${styl.flexColumn};
    ${styl.flexCenter};
    padding: 30px;
    margin-bottom: -62.5px;
  }
`;

export default Landingpage;
