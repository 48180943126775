import { gql, useMutation } from "../../imports";

const RemoveItemFromExtCart = gql`
  mutation removeItemsFromExtCart($keys: Int!, $subordererKey: String!) {
    __typename
    removeItemsFromExtCart(
      input: {
        clientMutationId: "RemoveItems"
        rowId: $keys
        subordererKey: $subordererKey
      }
    ) {
      cart {
        lists {
          name
          total
          orderers {
            products {
              amount
              rowId
              total
              product {
                parent {
                  name
                  coolbox {
                    coolboxActivated
                    coolboxWeight
                  }
                }
                name
                price
                weight
                variationId
                id
              }
            }
            name
            total
          }
        }
        total
        coolbox
        shipping
        subtotalWithTax
        minimumOrderWeight
        totalWeight
      }
    }
  }
`;
const GetExtCartItems = gql`
  query getExtCartItems($subordererKey: String!) {
    extCart(subordererKey: $subordererKey) {
      lists {
        name
        total
        orderers {
          products {
            amount
            rowId
            total
            product {
              parent {
                name
                coolbox {
                  coolboxActivated
                  coolboxWeight
                }
              }
              name
              price
              weight
              variationId
              id
            }
          }
          name
          total
        }
      }
      total
      coolbox
      shipping
      subtotalWithTax
      minimumOrderWeight
      totalWeight
    }
  }
`;
export function useRemoveFromCart() {
  const [
    removeItemsFromExtCart,
    { loading: mutationLoading2, error: mutationError2 },
  ] = useMutation(RemoveItemFromExtCart, {
    refetchQueries: ["getExtCartItems"],
    awaitRefetchQueries: true,
    update: (cache, mutationResult) => {
      cache.writeQuery({
        query: GetExtCartItems,
        data: { extCart: mutationResult.cart },
      });
    },
  });

  const removeSelected = (key, subordererKey) => {
    //When clicked remove
    return removeItemsFromExtCart({
      variables: { keys: key, subordererKey: subordererKey },
    })
      .then((response) => {
        // refetchQueries()
        // setSelected([]);
        // // let Array = [];
        // // response.data.removeItemsFromExtCart.cart.products.map((cartItem) => {
        // // 	Array.push(fillList(cartItem));
        // // });
        // setSelected(response.data.removeItemsFromExtCart.cart.lists);
        return true;
      })
      .catch((error) => {
        alert(error.toString());

        return false;
      });
  };

  return { removeSelected };
}

export default useRemoveFromCart;
