import React, { useRef } from 'react';
import styled from 'styled-components';
import RefInput from '../../components/RefInput';

const DataInputBestellinitiator = () => {
	const vorname = useRef('');
	const nachname = useRef('');
	const telNummer = useRef('');
	const email = useRef('');
	const street = useRef('');
	const plz = useRef('');
	const city = useRef('');
    const country = useRef('');
    const billingstreet = useRef('');
	const billingplz = useRef('');
	const billingcity = useRef('');
	const billingcountry = useRef('');

	return (
		<Wrapper>
			<Content>
				<LoginContainer>
					<RefInput forwardedRef={vorname} placeholder={'Vorname'} maxLength="40" />
					<RefInput forwardedRef={nachname} placeholder={'Nachname'} maxLength="40"/>
					<RefInput forwardedRef={telNummer} type={'tel'} placeholder={'Telofonnummer'} maxLength="20"/>
					<RefInput forwardedRef={email} type={'email'} placeholder={'Email Adresse'} maxLength="100"/>
					<RefInput forwardedRef={street} placeholder={'Lieferadresse: Straße & Hausnummer'} maxLength="110"/>
					<RefInput forwardedRef={plz} placeholder={'Lieferadresse: PLZ'} maxLength="10"/>
					<RefInput forwardedRef={city} placeholder={'Lieferadresse: Ort'} maxLength="100"/>
					<RefInput forwardedRef={country} placeholder={'Lieferadresse: Land'}maxLength="2" />
                    <RefInput forwardedRef={billingstreet} placeholder={'Rechnungsadresse: Straße & Hausnummer'} maxLength="110"/>
					<RefInput forwardedRef={billingplz} placeholder={'rechnungsadresse: PLZ'} maxLength="10"/>
					<RefInput forwardedRef={billingcity} placeholder={'Rechnungsadresse: Ort'} maxLength="100"/>
					<RefInput forwardedRef={billingcountry} placeholder={'Rechnungsadresse: Land'} maxLength="2"/>
				</LoginContainer>
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	flex-direction: row;
`;

const Content = styled.div`
	/* width: 100%; */
	display: flex;
	flex-direction: column;
	margin-top: 40px;
	justify-content: center;
	align-items: center;
`;

const LoginContainer = styled.div`
	padding: 20px 0;
	width: 241px;
`;

export default  DataInputBestellinitiator;
