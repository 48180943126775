// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   08.08.2020
// Last edited:     09.08.2020

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import einzelbestellung from "../../assets/images/Einzelbestellung.png";
import gruppenbestellung from "../../assets/images/Gruppenbestellung.png";
import CircleWithText from "../../components/CircleWithText";
import HeadingOne from "../../components/HeadingOne";
import Login from "../../components/Login";
import constants from "../../constants/constants";
import { GetFallbackURL, GetUserContext } from "../../localStorage/Store";
import styl from "../../styl";
import DiscoverItem from "../../components/DiscoverItem";

const DiscoverPage = () => {

  const [keyword, setKeyword] = useState("")

  const DiscoverData = [
    {
      heading: "Werkraum Bregenzerwald, Andelsbuch",
      description: "Hier trifft Handwerk auf Gestaltung. Eine einzigartige Kooperation von Bregenzerwälder Handwerkerinnen und Handwerkern.",
      number: "1",
      color: "#B2982A",
      link: "https://www.google.at/maps/place/Werkraum+Bregenzerwald/@47.411975,9.8918207,17z/data=!4m12!1m6!3m5!1s0x479b68d391d9a1a1:0x1cc6d942e3fa6cd7!2sWerkraum+Wirtschaft!8m2!3d47.411975!4d9.8940094!3m4!1s0x479b68d3f414bb63:0xaffd6685d821aff2!8m2!3d47.4112718!4d9.8931476",
      filter: ["Essen", "Handwerk", "Kultur"]
    },
    {
      heading: "Schtûbat, Andelsbuch",
      description: "Wolfgang Mätzler ist Gastgeber und begnadeter Lebensmittelhandwerker.",
      number: "2",
      color: "#D7C061",
      link: "https://www.google.at/maps/place/Scht%C3%BBbat+Urlaub+%26+Stammtisch/@47.4135603,9.8879351,17z/data=!3m1!4b1!4m8!3m7!1s0x479b68d5045b14c9:0x691089c526c0feb7!5m2!4m1!1i2!8m2!3d47.4135603!4d9.8901238",
      filter: ["Essen"]
    },
    {
      heading: "Bergbahnen Andelsbuch",
      description: "Im Sommer im nostalgischen Zweiersessel auf die Niedere. Weite genießen bei der Theodul-Kapelle.",
      number: "3",
      color: "#8D7833",
      link: "https://www.google.at/maps/place/Bergbahnen+Andelsbuch/@47.413223,9.9066746,17z/data=!3m1!4b1!4m5!3m4!1s0x479b68cb267e28c3:0x75093dcac7706374!8m2!3d47.413223!4d9.9088633",
      filter: ["Handwerk", "Landschaft"]
    },
    {
      heading: "kulturverein bahnhof, Andelsbuch",
      description: "Beeindruckendes und abwechslungsreiches Kulturprogramm.",
      number: "4",
      color: "#675926",
      link: "https://www.google.at/maps/place/kulturverein+bahnhof+Andelsbuch/@47.4114671,9.8912718,17z/data=!3m1!4b1!4m5!3m4!1s0x479b68d3f3799e4f:0x1089be63e820328!8m2!3d47.4114671!4d9.8934605",
      filter: ["Kultur"]
    },
    {
      heading: "Hotel Rössle, Au",
      description: "Gemütlich. Kaffee von Trevo Coffee.",
      number: "5",
      color: "#D7C061",
      link: "https://www.google.at/maps/place/Hotel+R%C3%B6ssle/@47.3058479,9.7479127,12z/data=!4m15!1m6!2m5!1shotel+r%C3%B6ssle!5m3!5m2!4m1!1i2!3m7!1s0x479b5d8cc8d221e5:0x25d5345820abdcea!5m2!4m1!1i2!8m2!3d47.3221016!4d9.9797935",
      filter: ["Essen"]
    },
    {
      heading: "Taube, Bizau",
      description: "Auch am Mittag geöffnet. Eines der besten Wiener Schnitzel.",
      number: "6",
      color: "#D7C061",
      link: "https://www.google.at/maps/place/Gasthaus+Pension+Taube+Bizau/@47.369043,9.9216303,15z/data=!4m11!1m2!2m1!1staube+bezau!3m7!1s0x479b67d3b9bfac83:0x955fb27b39f2143d!5m2!4m1!1i2!8m2!3d47.369043!4d9.930385",
      filter: ["Essen"]
    },
    {
      heading: "Biohotel Schwanen, Bizau",
      description: "Aufs Wesentliche reduziert. Konsequentes, stimmiges Konzept.",
      number: "7",
      color: "#D7C061",
      link: "https://www.google.at/maps/place/Biohotel+Schwanen/@47.3689193,9.9258356,17z/data=!3m1!4b1!4m8!3m7!1s0x479b67d40aa3111f:0x7caa977ddf57c72!5m2!4m1!1i2!8m2!3d47.3689193!4d9.9280243",
      filter: ["Essen"]
    },
    {
      heading: "Sennerei Hof-Messmerreuthe, Egg",
      description: "Unser Reifelager. Eine stillgelegte kleine Sennerei.",
      number: "8",
      color: "#B2982A",
      link: "#",
      filter: ["Handwerk", "Landschaft"]
    },
    {
      heading: "Gasthaus Tonele, Egg",
      description: "Die besten Kässpätzle. Ein Traditionsgasthaus.",
      number: "9",
      color: "#D7C061",
      link: "https://www.google.at/maps/place/Gasthaus+Tonele+Gams/@47.4264773,9.9060405,17z/data=!3m1!4b1!4m5!3m4!1s0x479b6f3bace75971:0xe80c02fcaaa762d0!8m2!3d47.4264773!4d9.9082292",
      filter: ["Essen"]
    },
    {
      heading: "Sennerei Mühle, Egg",
      description: "Eine der letzten Dorfsennereien, in der noch von Hand gesennt wird.",
      number: "10",
      color: "#B2982A",
      link: "https://www.google.at/maps/place/Sennerei+M%C3%BChle-Hub/@47.4345111,9.8987025,17z/data=!3m1!4b1!4m5!3m4!1s0x479b6f0c75ba38a7:0xa104fc944b0faf95!8m2!3d47.4345111!4d9.9008912",
      filter: ["Handwerk"]
    },
    {
      heading: "Brauerei Egg",
      description: "Die kleinste Privatbrauerei Vorarlbergs. Und das in Egg.",
      number: "11",
      color: "#B2982A",
      link: "https://www.google.at/maps/place/Brauerei+Egg/@47.4314817,9.8980623,17z/data=!3m1!4b1!4m5!3m4!1s0x479b6f217cd8fda3:0xec916f33f5551764!8m2!3d47.4314817!4d9.900251",
      filter: ["Handwerk"]
    },
    {
      heading: "Gasthaus Adler, Großdorf",
      description: "Vorarlbergs erstes Sonntagsgasthaus. Gastköche und Hausfrauen kredenzen Ihre Lieblingsmahlzeiten.",
      number: "12",
      color: "#D7C061",
      link: "https://www.google.at/maps/place/Adler+Grossdorf/@47.4347376,9.9137838,17z/data=!3m1!4b1!4m5!3m4!1s0x479b6f15b933b34b:0x3222d2d940401179!8m2!3d47.4347376!4d9.9159725",
      filter: ["Essen"]
    },
    {
      heading: "Vorsäß Hammeratsberg-Eggatsberg",
      description: "Erwacht in den Frühjahrs- und Herbstmonaten zum kleinen Dorf.",
      number: "13",
      color: "#8D7833",
      link: "#",
      filter: ["Landschaft"]
    },
    {
      heading: "Frauenmuseum, Hittisau",
      description: "Das erste und einzige Frauenmuseum in Österreich. Und das im Bregenzerwald.",
      number: "14",
      color: "#B2982A",
      link: "https://www.google.at/maps/place/Frauenmuseum+Hittisau/@47.4563222,9.9588329,17z/data=!3m1!4b1!4m5!3m4!1s0x479b657ba9b87631:0xc6917c7b723ad7ae!8m2!3d47.4563222!4d9.9610216",
      filter: ["Handwerk", "Kultur"]
    },
    {
      heading: "Ladenwirtschaft Ernele, Hittisau",
      description: "Ambiente, Essen, Gastgeber Felix Groß: stimmig",
      number: "15",
      color: "#D7C061",
      link: "https://www.google.at/maps/place/Ernele+%E2%80%93+die+Ladenwirtschaft/@47.4564474,9.961512,17z/data=!3m1!4b1!4m5!3m4!1s0x479b6526b1d6e439:0xe7e06a42eb55de57!8m2!3d47.4564474!4d9.9637007",
      filter: ["Essen"] 
    },
    {
      heading: "Kapelle Salgenreuthe, Krumbach",
      description: "Eines von vielen Architektur-Highlights in Krumbach.",
      number: "16",
      color: "#8D7833",
      link: "https://www.google.at/maps/place/Kapelle+Salgenreute/@47.4910679,9.9159984,17z/data=!3m1!4b1!4m5!3m4!1s0x479b6fd57bc97589:0x27b9a3d3ced12e45!8m2!3d47.4910679!4d9.9181871",
      filter: ["Handwerk", "Landschaft"]
    },
    {
      heading: "Naturlehrpfad Quelltuff, Lingenau",
      description: "Seltenes Naturphänomen.",
      number: "17",
      color: "#8D7833",
      link: "https://www.google.com/maps?client=firefox-b-d&q=Naturlehrpfad+Quelltuff,+Lingenau&um=1&ie=UTF-8&sa=X&ved=2ahUKEwiV0KGC_qDtAhUPzIUKHUSHDd4Q_AUoAXoECAYQAw",
      filter: "Landschaft"
    },
    {
      heading: "Der Wälderhof, Lingenau",
      description: "Barbara Wild ist Gastgeberin mit Leib und Seele. Das spürt man schon beim Eintreten.",
      number: "18",
      color: "#D7C061",
      link: "https://www.google.com/maps/place/Gasthof+W%C3%A4lderhof/@47.4507711,9.924915,17z/data=!3m1!4b1!4m8!3m7!1s0x479b6f756a90d497:0xa935170d3fb910f3!5m2!4m1!1i2!8m2!3d47.4507711!4d9.9271037",
      filter: ["Essen"]
    },
    {
      heading: "Café Deli, Mellau",
      description: "Entspannte Atmosphäre und Superfrühstück.",
      number: "19",
      color: "#D7C061",
      link: "https://www.google.com/maps/place/Caf%C3%A9+Deli/@47.3510251,9.8802697,17z/data=!3m1!4b1!4m5!3m4!1s0x479b683bc14f90c5:0x2062a3d0a6ce0686!8m2!3d47.3510251!4d9.8824584",
      filter: ["Essen"]
    },
    {
      heading: "Juppenwerkstatt, Riefensberg",
      description: "Schlicht und elegant. Die Tracht der Bregenzerwälderinnen.",
      number: "20",
      color: "#B2982A",
      link: "https://www.google.com/maps/place/Juppenwerkstatt+Riefensberg/@47.5051662,9.9617275,17z/data=!3m1!4b1!4m5!3m4!1s0x479b700b85be923f:0xaecdb78675114129!8m2!3d47.5051662!4d9.9639162",
      filter: ["Handwerk", "Kultur"]
    },
    {
      heading: "Der Adler, Schoppernau",
      description: "Klein und fein mit wunderschöner Bauernstube. Großartige Gastgeberfamilie.",
      number: "21",
      color: "#D7C061",
      link: "https://www.google.com/maps/place/Hotel+Gasthof+Adler/@47.3111591,10.0180275,17z/data=!3m1!4b1!4m8!3m7!1s0x479b5e16b36e648b:0x606c6ed6d24322ae!5m2!4m1!1i2!8m2!3d47.3111591!4d10.0202162",
      filter: ["Essen"]
    },
    {
      heading: "Waldbad, Schoppernau",
      description: "Schön gelegen im Wald, mit Spielpatz nebenan.",
      number: "22",
      color: "#D7C061",
      link: "https://www.google.com/maps/place/Waldbad+Schoppernau/@47.309333,10.0091516,16z/data=!4m8!1m2!2m1!1sschwimmbad+schoppernau!3m4!1s0x479b5fb2ac3513bd:0x384a61bece78cc5f!8m2!3d47.309333!4d10.013529",
      filter: ["Landschaft"]
    },
    {
      heading: "Franz Michael Felder Museum, Schoppernau",
      description: "Ausstellung über Leben und Werk des Sozialreformers.",
      number: "23",
      color: "#675926",
      link: "https://www.google.com/maps/place/Felder+Museum+Schoppernau/@47.3121927,10.0137875,17z/data=!3m1!4b1!4m5!3m4!1s0x479b5fa25555b7ef:0x67107588daa463de!8m2!3d47.3121927!4d10.0159762",
      filter: ["Handwerk", "Kultur"]
    },
    {
      heading: "Hotel Hirschen, Schwarzenberg",
      description: "Das Haus, das Essen, das Team: nichts hinzuzufügen",
      number: "24",
      color: "#D7C061",
      link: "https://www.google.com/maps/place/Hotel+Gasthof+Hirschen+Schwarzenberg/@47.413975,9.8499433,17z/data=!3m1!4b1!4m8!3m7!1s0x479b694ddf4c3f1f:0xc02a896c4f502a9e!5m2!4m1!1i2!8m2!3d47.413975!4d9.852132",
      filter: ["Essen"]
    },
    {
      heading: "Schwimmbad, Schwarzenberg",
      description: "Nostalgisch. Schön.",
      number: "25",
      color: "#8D7833",
      link: "https://www.google.com/maps/place/Schwimmbad+Schwarzenberg/@47.413391,9.8473655,17z/data=!3m1!4b1!4m5!3m4!1s0x479b69faca3c957f:0xec40a38a0669e509!8m2!3d47.413391!4d9.8495542",
      filter: ["Landschaft"]
    },
    {
      heading: "Angelika Kauffmann Museum, Schwarzenberg",
      description: "Über das Leben und Schaffen der Ausnahmekünstlerin. Allein schon die Architektur ist einen Besuch wert.",
      number: "26",
      color: "#675926",
      link: "https://www.google.com/maps/place/Schwimmbad+Schwarzenberg/@47.413391,9.8473655,17z/data=!3m1!4b1!4m5!3m4!1s0x479b69faca3c957f:0xec40a38a0669e509!8m2!3d47.413391!4d9.8495542",
      filter: ["Handwerk", "Kultur"]
    },
    {
      heading: "Gasthaus Krone, Hittisau",
      description: "Nächtigen im Werkraum Zimmer und herausragende Küche genießen",
      number: "27",
      color: "#675926",
      link: "",
      filter: ["Essen"]
    },
    {
      heading: "Hotel Krone, Au",
      description: "In diesem Haus trifft Gastgeben auf Kultur.",
      number: "28",
      color: "#675926",
      link: "https://www.google.at/maps/place/Hotel+Krone+in+Au/@47.3244105,9.9742982,17z/data=!3m1!4b1!4m8!3m7!1s0x479b5d8b8cdc6e55:0x99b915fde88cb554!5m2!4m1!1i2!8m2!3d47.3244105!4d9.9764869",
      filter: ["Essen"]
    },
    {
      heading: "Jöslar, Andelsbuch",
      description: "Wer im Bregenzerwald gewesen ist, muss im Jöslar zugekehrt haben.",
      number: "29",
      color: "#675926",
      link: "https://www.google.at/maps/place/Hotel+Krone+in+Au/@47.3244105,9.9742982,17z/data=!3m1!4b1!4m8!3m7!1s0x479b5d8b8cdc6e55:0x99b915fde88cb554!5m2!4m1!1i2!8m2!3d47.3244105!4d9.9764869",
      filter: ["Essen"]
    },
    {
      heading: "Café Angelikahöhe, Schwarzenberg",
      description: "Mein liebstes Café mit der besten Kuchenauswahl.",
      number: "30",
      color: "#675926",
      link: "https://www.google.at/maps/place/Hotel+Krone+in+Au/@47.3244105,9.9742982,17z/data=!3m1!4b1!4m8!3m7!1s0x479b5d8b8cdc6e55:0x99b915fde88cb554!5m2!4m1!1i2!8m2!3d47.3244105!4d9.9764869",
      filter: ["Essen"]
    },
  ]

  useEffect(() => {
    document.title = "Gutes entdecken";
  }, []);

  return (
    <Wrapper>
      <HeadingOne title="Der Bregenzerwald" onClick={() => setKeyword("")} />
      <Navigation>
        <NavigationItem style={{ color: '#D7C061' }} onClick={() => setKeyword("eating")}>Essen und Trinken</NavigationItem>
        <NavigationItem style={{ color: '#B2982A' }} onClick={() => setKeyword("architecture")}>Handwerk und Architektur</NavigationItem>
        <NavigationItem style={{ color: '#8D7833' }} onClick={() => setKeyword("landscape")}>Landschaft</NavigationItem>
        <NavigationItem style={{ color: '#675926' }} onClick={() => setKeyword("culture")}>Kultur</NavigationItem>
      </Navigation>
      <Content>
        {
          DiscoverData.map((item) =>
            (
              keyword == "" ?
                <DiscoverItem heading={item.heading} description={item.description} number={item.number} color={item.color} link={item.link} />
                : keyword == "eating" ? (
                  item.filter.includes("Essen") && (
                    <DiscoverItem heading={item.heading} description={item.description} number={item.number} color={item.color} link={item.link} />
                  )
                ) : keyword == "architecture" ? (
                  item.filter.includes("Handwerk") && (
                    <DiscoverItem heading={item.heading} description={item.description} number={item.number} color={item.color} link={item.link} />
                  )
                ) : keyword == "landscape" ? (
                  item.filter.includes("Landschaft") && (
                    <DiscoverItem heading={item.heading} description={item.description} number={item.number} color={item.color} link={item.link} />
                  )
                ) : item.filter.includes("Kultur") && (
                  <DiscoverItem heading={item.heading} description={item.description} number={item.number} color={item.color} link={item.link} />
                )
            )
          )
        }
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 8vw;
  margin-top: 20px;
  margin-bottom: 130px;
  /* margin-top: 70px; */
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-bottom: 60px;
  }
  @media (max-width: 770px){
    margin-top: 20px;
  }
  @media (max-width: 1680px){
    margin-bottom: 260px;
  }
  @media (max-width: 1210px){
    margin-bottom: 540px;
  }
  @media (max-width: 790px){
    margin-bottom: 750px;
  } 
  @media (max-width: 350px){
    margin-bottom: 730px;
  } 
`;

const Navigation = styled.div`
  width: 700px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;

  @media (max-width: 750px){
    width: 600px;
  }
  @media (max-width: 650px){
    flex-direction: column;
  }
`

const NavigationItem = styled.p`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  @media (max-width: 770px){
    font-size: 12px;
    line-height: 14px;
  }
  
  @media (max-width: 650px){
    margin: 5px 0;
  }
`

const Content = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(8, 1fr);
  grid-row-gap: 3%;
  grid-column-gap: 10px;
  grid-template-columns: 380px 380px 380px 380px;
  margin-bottom: 30px;

  @media (max-width: 1680px){
    grid-template-rows: repeat(10, 1fr);
  }
  @media (max-width: 1210px){
    grid-template-rows: repeat(15, 1fr);
  }
  @media (max-width: 790px){
    grid-template-rows: repeat(30, 1fr);
    grid-row-gap: 1%;
  }
  @media (max-width: 350px){
    grid-row-gap: 1%;
  }
`

export default DiscoverPage;
