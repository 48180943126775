// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   02.08.2020
// Last edited:     02.08.2020

import React, { useState } from 'react';
import styled from 'styled-components';
import constants from '../../constants/constants';
import styl from '../../styl';
import BurgerButton from './BurgerButton';
import NavigationItem from './NavigationItem';

const Navigation = () => {

    const [navState, setNavState] = useState(false);

    return (
        <NavigationContainer>
            <Positioner>
                <BurgerButton navState={navState} onClick={() => setNavState(prevNavState => !prevNavState)} />
            </Positioner>
            <NavigationWrapper className={navState == true ? "open" : null}>
                <NavigationItem href={"/philosophie"} text={"Philosophie"} />
                <NavigationItem href={"/produkte"} text={"Produkte"} />
                <NavigationItem href={"/community"} text={"Community"} />
                <NavigationItem href={"/bestellen"} text={"Bestellen"} />
                <NavigationItem href={"/werwirsind"} text={"Wer wir sind"} />
                <NavigationItem href={"/kontakt"} text={"Kontakt"} />
            </NavigationWrapper>
        </NavigationContainer>
    )
};

const Positioner = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
`

const NavigationContainer = styled.div`
    ${styl.flexColumn};   
    flex: 1; 
    max-width: 1000px;

    @media (max-width: ${constants.nexusPortraitWidth}px) {
        margin-bottom: -32px;
        margin-top: -42px;
        /* margin-right: 26px; */
        align-items: center;
        width: 100%;

        .open {
            display: block;
            ${styl.flexColumn};
            ${styl.flexCenter};
            width: 100%;
            padding: 20px 0px;
            box-sizing: border-box;
        }
    }
`;

// This component is also impacted by the .open class which is defined in the NavigationContainer component.
const NavigationWrapper = styled.div`
    height: 100%;
    ${styl.flexRow};
    justify-content: space-around;

    @media (max-width: ${constants.nexusLandscapeWidth}px) {
        margin: 0px;
    }

    @media (max-width: ${constants.nexusPortraitWidth}px) {
        display: none;
    }
`;

export default Navigation;