import { React } from "../../imports";
import UserData from '../../components/UserData';
import OrderContainer from "../../components/OrderContainer";

const ProfilePage = () =>{
  const navigation = [{actualName: ["Profil"]}, {codeName: ["Profile"]}]

	return (
		<OrderContainer
      currentPage={"Profile"}
      navigation={navigation}
      clickable={false}
    >    
      <UserData activeView={() => {}} location={"Profile"}></UserData>  
		</OrderContainer>
	);
};

export default ProfilePage;